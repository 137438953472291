import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FolderInterface } from 'app/models/folder.model';
import { FolderEditDialogComponent } from './components/dialogs/folder-edit-dialog/folder-edit-dialog.component';
import { FolderResourcesDialogComponent, FolderResourcesDialogDataInterface } from './components/dialogs/folder-resources-dialog/folder-resources-dialog.component';
import { FolderResourcesSelectorDialogComponent, FolderResourcesSelectorDialogDataInterface } from './components/dialogs/folder-resources-selector-dialog/folder-resources-selector-dialog.component';
import { FolderImportDataInterface, FolderImportDialogComponent } from './components/dialogs/folder-import-dialog/folder-import-dialog.component';
import { BrandInterface } from 'app/models/brand.model';

@Injectable({
  providedIn: 'root',
})
export class FolderService {

  constructor(
    private _matDialog: MatDialog,
  ) { }

  openFolderEditDialog(folder?: FolderInterface) {
    return this._matDialog.open(FolderEditDialogComponent, {
      panelClass: 'folder-edit-dialog',
      data: {
        folder,
      },
      autoFocus: false,
    });
  }

  openFolderResourcesDialog(data?: FolderResourcesDialogDataInterface) {
    return this._matDialog.open(FolderResourcesDialogComponent, {
      panelClass: 'folder-resources-dialog',
      data,
      autoFocus: false,
    });
  }

  openFolderImportDialog(data: FolderImportDataInterface) {
    return this._matDialog.open(FolderImportDialogComponent, {
      panelClass: 'folder-import-dialog',
      data,
      autoFocus: false,
      disableClose: true,
    });
  }

  openFolderResourcesSelectorDialog(data?: FolderResourcesSelectorDialogDataInterface) {
    return this._matDialog.open(FolderResourcesSelectorDialogComponent, {
      width: '80vw',
      height: '80vh',
      panelClass: 'folder-resources-selector-dialog',
      data,
      autoFocus: false,
    });
  }
}
