<div fxLayout="column" [formGroup]="group">
  <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-xs="16px">
    <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
      <mat-form-field fxFlex="calc(50% - 16px)" formGroupName="templateOptions">
        <input matInput name="templateOptions.placeholder" formControlName="placeholder"
          [placeholder]="'Segnaposto' | translate" />
      </mat-form-field>
    </div>
    <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
      <div class="pt-8 mb-8" fxFlex="calc(50% - 16px)" formGroupName="templateOptions">
        <mat-checkbox name="templateOptions.required" formControlName="required">{{
          'Obbligatorio' | translate
        }}</mat-checkbox>
      </div>
    </div>
  </div>
</div>