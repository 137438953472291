import { Component, Input } from '@angular/core';
import { FluidFileLikeObject } from 'app/modules/uploader/s3/s3-file-item.class';

/**
 *
 *
 * @export
 * @class UploaderQueueInvalidFileComponent
 * @description Full name formatter from User entity object or from 'name' and 'surname'
 * @implements {OnInit}
 */
@Component({
  selector: 'app-uploader-queue-invalid-file',
  templateUrl: './uploader-queue-invalid-file.component.html',
  styleUrls: ['./uploader-queue-invalid-file.component.scss']
})
export class UploaderQueueInvalidFileComponent {

  @Input() item: FluidFileLikeObject;

  constructor() {}
}
