import { ScreenInterface } from 'app/modules/system/components/screens/screens.interface';

export class ResourceDialogPickerConfig {
  width: number | string;
  height: number | string;
  types: ('images' | 'videos')[]; // [] --> all
  multi: boolean = false;
  dynamic: boolean = false;
  screens: ScreenInterface[];
  excludedIdsMap: { [key: string]: number };
  constructor(width?: number, height?: number) {
    this.width = width || '*';
    this.height = height || '*';
  }
  setMulti(multi: boolean) {
    this.multi = multi;
  }
  setDynamic(dynamic: boolean) {
    this.dynamic = dynamic;
  }
  setScreens(screens: ScreenInterface[]) {
    this.screens = screens;
  }
  setTypes(types: ('images' | 'videos')[]) {
    if (Array.isArray(types)) {
      this.types = types;
    } else {
      this.types = []; //['images', 'videos'];
    }
  }
  setExcludedIdsMap(ids: { [key: string]: number }) {
    if (!!ids) {
      this.excludedIdsMap = ids;
    } else {
      this.excludedIdsMap = {};
    }
  }
}
