import { Component, Input, OnChanges } from '@angular/core';
import { UploaderService } from '../../uploader.service';
import { UPLOADER_STATUS, UPLOADER_STATUS_MODE, UPLOADER_TYPE } from '../../uploader.interface';

/**
 *
 *
 * @export
 * @class UploaderStatusComponent
 * @description Uploader component
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-uploader-status',
  templateUrl: './uploader-status.component.html',
  styleUrls: ['./uploader-status.component.scss']
})
export class UploaderStatusComponent implements OnChanges {

  UPLOADER_STATUS = UPLOADER_STATUS;
  UPLOADER_STATUS_MODE = UPLOADER_STATUS_MODE;

  @Input() status: UPLOADER_STATUS = UPLOADER_STATUS.ready;
  @Input() type: UPLOADER_TYPE = UPLOADER_TYPE.file;
  @Input() mode: UPLOADER_STATUS_MODE = UPLOADER_STATUS_MODE.icon;
  @Input() multiple: boolean;
  @Input() errors: number;
  @Input() progress: number;
  @Input() inline: boolean;

  labels: string[] = [];
  icon: string = '';

  constructor(
    private _uploaderService: UploaderService
  ) {}

  ngOnChanges(changes) {
    this.labels = this._uploaderService.getStatusLabels(this.status, this.type, this.multiple, this.errors, this.progress);
    this.icon = this._uploaderService.getStatusIcon(this.status, this.type, this.multiple);
  }

  get isIcon() {
    return this.mode === UPLOADER_STATUS_MODE.icon;
  }

  get isLabel() {
    return this.mode === UPLOADER_STATUS_MODE.label;
  }
}
