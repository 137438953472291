import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResourcesPickerComponent } from '../components/resources-picker/resources-picker.component';
import { ResourceDialogPickerConfig } from '../directives/resoruce-dialog-selector/resource-dialog-config';

@Injectable({
    providedIn: 'root'
})
export class ResourceDialogService {

    constructor(
        private _dialog: MatDialog,
    ) { }

    open(config: ResourceDialogPickerConfig) {
        return this._dialog.open(ResourcesPickerComponent, {
            width: '80vw',
            height: '80vh',
            panelClass: 'resource-selector-dialog',
            data: config,
        })
    }
}
