<!-- <app-autocomplete
  fxFlex="100"
  [formControl]="control"
  [floatLabel]="floatLabel"
  [list]="brandList"
  [placeholder]="placeholder"
  [required]="required"
  [disableControl]="disabled"
  [delay]="200"
  [minChars]="2"
  [hint]="hint"
  [loading]="loading"
  [keepPrevSearch]="true"
  [filterItemsFn]="filterItemsFn"
  prop="name"
  (changeSelection)="onBrandChange($event)"
  (changeFilteredList)="changeFilteredList.emit($event)"
></app-autocomplete> -->
<!-- [formControl]="control" -->


<app-custom-autocomplete [formControl]="control" [placeholder]="placeholder" [list]="brandList" [required]="required"
  [disabled]="disabled" [hint]="hint" [floatLabel]="floatLabel" (changeSelection)="onBrandChange($event)">
</app-custom-autocomplete>