import { Injectable } from '@angular/core';
import { ResourcesRepositoryService } from 'app/repositories/resources-repository.service';
import { S3FileItem } from '../s3';
import { Observable } from 'rxjs';
import { Resource } from 'app/models/resource.model';
import { take } from 'rxjs/operators';

export interface ResourceCreationOptions {
  noWorkflow: boolean;
  notPrivate: boolean;
  orphan?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class S3ResourceService {
  constructor(
    private _repo: ResourcesRepositoryService,
  ) { }
  createResourceFromS3FileItem(item: S3FileItem, options: ResourceCreationOptions = { noWorkflow: false, orphan: false, notPrivate: true }, existing_model?: Resource): Observable<Resource> {
    if (!existing_model) {
      //Create a new resoure
      return this._repo.create(
        {
          source: item.additionalParameter.key,
          noWorkflow: options.noWorkflow,
          orphan: true,
          notPrivate: options.notPrivate
        })
        .pipe(take(1));
    } else {
      //Edit an existing one, overwriting some values
      const temp = existing_model;
      temp.name = item.file.name;
      temp.source = item.additionalParameter.key;
      temp.thumb = undefined;
      return this._repo.put(temp)
        .pipe(take(1));
    }
  }
}

