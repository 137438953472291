<mat-form-field fxFlex="100">
  <input matInput autocomplete="off" [matDatepicker]="monthpicker" [formControl]="formControl" [value]="value"
    [required]="required" [disableControl]="disabled" [placeholder]="placeholder" [min]="min" [max]="max"
    [clearable]="clearable && !disabled" (focus)="monthpicker.open()" (dateChange)="dateChangeEvent($event.value)"
    (blur)="blur($event)" />
  <mat-datepicker-toggle matSuffix [for]="monthpicker" [disabled]="disabled"></mat-datepicker-toggle>
  <mat-datepicker #monthpicker [disabled]="disabled" startView="multi-year" (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event, monthpicker)">
  </mat-datepicker>
  <mat-error>
    <div *ngIf="formControl?.hasError('required')">{{ 'Obbligatorio' | translate }}</div>
    <div *ngIf="formControl?.hasError('min')">{{ 'Minimo errato' | translate }}</div>
    <div *ngIf="formControl?.hasError('max')">{{ 'Massimo errato' | translate }}</div>
  </mat-error>
</mat-form-field>