export interface ScreenInterface {
  depth?: number;
  color?: string;
  guid?: string;
  idx?: number;
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  name?: string;
  fit?: boolean;
  fit_constrains?: ResolutionInterface[];
}

export class Screen implements ScreenInterface {
  depth: number = 1;
  color: string;
  guid: string;
  idx: number = 10;
  width: number = 0;
  height: number = 0;
  top: number = 0;
  left: number = 0;
  name: string = '';
  fit: boolean = false;
  fit_constrains?: ResolutionInterface[] = [];

  /**
   * Constructor
   *
   * @param _screen
   */
  constructor(private _screen?: ScreenInterface) {
    Object.assign(this, this._screen);

    if (!this.color) {
      this.color = this.getRandomColor();
    }

    delete this._screen;
  }

  getRandomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
  }
}

export class ScreenFitConstrain implements ResolutionInterface {
  width: number;
  height: number;

  constructor(private _conf?: ScreenInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface ResolutionInterface {
  width?: number;
  height?: number;
}
