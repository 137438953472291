import { Component, Input, EventEmitter, Output, forwardRef, ViewChild, AfterViewChecked, Optional, Self, Injector, OnInit } from '@angular/core';
import { FormControl, Validator, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgControl } from '@angular/forms';

import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { isMoment, Moment } from 'moment';
import { startOfMonth } from 'date-fns';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MONTHPICKER_FORMAT_INPUT, MONTHPICKER_FORMAT_MONTH_YEAR } from 'app/modules/global/constants';

const monthpickerFormatsFactory = (i18nService: I18nService) => {
  return {
    parse: {
      dateInput: i18nService.translate(MONTHPICKER_FORMAT_INPUT),
    },
    display: {
      dateInput: i18nService.translate(MONTHPICKER_FORMAT_INPUT),
      monthLabel: 'MMMM',
      monthYearLabel: i18nService.translate(MONTHPICKER_FORMAT_MONTH_YEAR),
      // dateA11yLabel: 'LL', ???
      // monthYearA11yLabel: 'MMMM YYYY', ???
    },
  };;
}

/**
 *
 *
 * @export
 * @class MonthpickerComponent
 * @description Monthpicker component
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: monthpickerFormatsFactory,
      deps: [
        I18nService,
      ],
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthpickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MonthpickerComponent),
      multi: true,
    }
  ],
})
export class MonthpickerComponent implements OnInit, ControlValueAccessor, Validator, AfterViewChecked {

  @Input() placeholder: string = this._i18nService.translate(_('Mese'));
  @Input() min: number | Date;
  @Input() max: number | Date;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() clearable: boolean = true;
  @Input() startView: string = 'multi-year';

  @Output() selectionChange: EventEmitter<FormControl> = new EventEmitter<FormControl>();

  @ViewChild(MatInput) matInput: MatInput;
  // ngControl: FormControl;

  private _value;
  private _onChange = (event: any) => { };
  private _onTouched: any = () => { };

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(newValue) {
    if (typeof newValue !== 'undefined') {
      this._value = newValue;
    }
  }

  minError(min) {
    return min && this.value && new Date(this.value).getTime() < new Date(min).getTime();
  }

  maxError(max) {
    return max && this.value && new Date(this.value).getTime() > new Date(max).getTime();
  }

  validate(c: FormControl) {
    // this.ngControl = c;

    // custom errors
    const err: { required?: boolean; min?: Date; max?: Date; outOfRange?: boolean } = {};
    if (this.required && !this._value) {
      err.required = true;
    }

    // if (this.min && this.value && new Date(this.value).getTime() < new Date(this.min).getTime()) {
    if (this.minError(this.min)) {
      err.min = new Date(this.min);
    }
    // if (this.max && this.value && new Date(this.value).getTime() > new Date(this.max).getTime()) {
    if (this.maxError(this.max)) {
      err.max = new Date(this.max);
    }

    // console.log('errs are', err, 'status?', this.ngControl.status);

    if (Object.keys(err).length) {
      return err;
    }
    return null;
  }


  constructor(
    // @Optional() @Self() public ngControl: NgControl,
    private _i18nService: I18nService,
    private injector: Injector,
  ) {
    // if (this.ngControl != null) {
    //   this.ngControl.valueAccessor = this;
    //   // console.log('ngControl', this.ngControl);
    // }
  }

  ngControl: NgControl;

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    console.log('ngControl', this.ngControl);
  }

  ngAfterViewChecked() {
    if (!!this.formControl && !!this.matInput && !!this.matInput.ngControl && !!this.matInput.ngControl.control) {
      if (this.formControl.touched && !this.matInput.ngControl.control.touched) {
        setTimeout(() => {
          this.matInput.ngControl.control.markAsTouched();
        });
      }
    }
  }

  get formControl() {
    return (this.ngControl as any).control;
  }

  blur(event) {
    this.formControl.markAsTouched();
  }

  set value(value) {
    this.writeValue(value);
    this._onChange(this._value);
    this.selectionChange.emit(this.value);
  }

  get value() {
    return this._value;
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this._value ? new Date(this._value) : new Date();
    ctrlValue.setFullYear(normalizedYear.year());
    this.value = startOfMonth(ctrlValue);
    console.log('chosenYearHandler', normalizedYear, ctrlValue, this.value);
  }

  chosenMonthHandler(normalizedMonth: Moment, monthpicker: MatDatepicker<Moment>) {
    const ctrlValue = this._value ? new Date(this._value) : new Date();
    ctrlValue.setMonth(normalizedMonth.month());
    this.value = startOfMonth(ctrlValue);
    monthpicker.close();
    console.log('chosenMonthHandler', normalizedMonth, 'ctrl', ctrlValue, 'value', this.value);
  }

  dateChangeEvent(value: Moment | Date) {
    console.log('dateChangeEvent value', value);

    if (!!value) {
      if (isMoment(value)) {
        value = value.toDate();
      }
      console.log('current', this.value, 'incoming', value);
      if (!this.value || this.value.toString() !== value.toString()) {
        this.value = startOfMonth(value);
      } else {
        console.log('same date, ignore');
      }

    } else {
      this.value = null;
    }
  }
}
