// import { FileType } from 'ng2-file-upload/file-upload/file-type.class';
export class FluidFileType { //  extends FileType
  public static fileTypeDetection(inputFilename: string = ''): string {
    const types: { [key: string]: string } = {
      jpg: 'image',
      jpeg: 'image',
      tif: 'image',
      psd: 'image',
      bmp: 'image',
      png: 'image',
      nef: 'image',
      tiff: 'image',
      cr2: 'image',
      dwg: 'image',
      cdr: 'image',
      ai: 'image',
      indd: 'image',
      pin: 'image',
      cdp: 'image',
      skp: 'image',
      stp: 'image',
      '3dm': 'image',
      mp3: 'audio',
      wav: 'audio',
      wma: 'audio',
      mod: 'audio',
      m4a: 'audio',
      compress: 'compress',
      zip: 'compress',
      rar: 'compress',
      '7z': 'compress',
      lz: 'compress',
      z01: 'compress',
      bz2: 'compress',
      gz: 'compress',
      pdf: 'pdf',
      xls: 'xls',
      xlsx: 'xls',
      ods: 'xls',
      mp4: 'video',
      webm: 'video',
      avi: 'video',
      wmv: 'video',
      mpg: 'video',
      mts: 'video',
      flv: 'video',
      '3gp': 'video',
      vob: 'video',
      m4v: 'video',
      mpeg: 'video',
      m2ts: 'video',
      mov: 'video',
      doc: 'doc',
      docx: 'doc',
      eps: 'doc',
      txt: 'doc',
      odt: 'doc',
      rtf: 'doc',
      ppt: 'ppt',
      pptx: 'ppt',
      pps: 'ppt',
      ppsx: 'ppt',
      odp: 'ppt'
    };

    const chunks = inputFilename.split('.');
    if (chunks.length < 2) {
      return 'application';
    }
    const extension = chunks[chunks.length - 1].toLowerCase();
    if (types[extension] === undefined) {
      return 'application';
    } else {
      return types[extension];
    }
  }
}
