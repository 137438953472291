import { Component, Input, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GroupInterface } from 'app/models/group.model';

@Component({
  selector: 'app-field-multiselect',
  templateUrl: './field-multiselect.component.html',
  styleUrls: ['./field-multiselect.component.scss']
})
export class FieldMultiselectComponent implements OnInit, OnChanges {

  @Input() group: FormGroup;
  @Input() groupList: GroupInterface[] = [];

  constructor(
    protected _cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }
}
