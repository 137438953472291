import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, SimpleChanges, TemplateRef, OnDestroy, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable, of, Subject } from 'rxjs';
import { AutocompleteList } from '../autocomplete/autocomplete.component';
import { AutocompleteService } from '../autocomplete.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { FavoritesEnum, FavoritesService } from '../../services/favorites.service';
import { SelectionModel } from '@angular/cdk/collections';
import { HistoryEnum, HistoryService } from '../../services/history.service';

export enum EntitiesSelectorTypeEnum {
  search = 'search',
  favorite = 'favorite',
  history = 'history',
}

export type EntitiesSelectorList<T> = T[] | Observable<T[]> | AutocompleteList<T>;

@Component({
  selector: 'app-entities-selector',
  templateUrl: './entities-selector.component.html',
  styleUrls: ['./entities-selector.component.scss'],
})
export class EntitiesSelectorComponent<T> implements OnInit, OnChanges, OnDestroy {

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  // @ViewChild('menuTrigger', { static: false, read: ElementRef }) menuElement: ElementRef<HTMLElement>;
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef }) menuElement: ElementRef<HTMLElement>;

  get menuElementData() {
    return {
      width: this.menuElement.nativeElement.clientWidth
    };
  }

  @Input() label: string;
  @Input() icon: string;
  @Input() list: EntitiesSelectorList<T>;
  @Input() values: any[];
  @Input() favoritesType: FavoritesEnum;
  @Input() historyType: HistoryEnum;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() touched: boolean = false;
  @Input() multiple: boolean = false;
  @Input() selectable: boolean = false;
  @Input() chosenItem: any;
  @Input() allowDuplicates: boolean = false;
  @Input() fullName: boolean = true; // no ellipsis

  @Input() filterItemsFn: (filteredItems: any[]) => any[] = (list) => list;

  @Input() hasSearch: boolean = true;
  @Input() hasFavorites: boolean = true;
  @Input() hasHistory: boolean = true;
  @Input() rowHeight: number = 48;

  @Input() filterProp: string = 'name';
  @Input() filterPlaceholder: string = this._i18nService.translate(_('Filtra per nome'));

  @Input() listEmptyLabel: string = this._i18nService.translate(_('Nessun elemento selezionato'));

  @Input() listTpl: TemplateRef<any>;
  @Input() preItemTpl: TemplateRef<any>;
  @Input() itemTpl: TemplateRef<any>;
  @Input() itemActionsTpl: TemplateRef<any>;

  @Input() selectedListHeaderTpl: TemplateRef<any>;
  @Input() selectedListHeaderActionsTpl: TemplateRef<any>;
  @Input() selectedListTpl: TemplateRef<any>;
  @Input() selectedPreItemTpl: TemplateRef<any>;
  @Input() selectedItemTpl: TemplateRef<any>;
  @Input() selectedItemActionsTpl: TemplateRef<any>;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() chosenItemChange: EventEmitter<any> = new EventEmitter();

  loading = false;

  selectorsList: any[] = [];
  filteredList: { [key: string]: any[] } = {};

  EntitiesSelectorTypeEnum = EntitiesSelectorTypeEnum;
  lastSelector: EntitiesSelectorTypeEnum = EntitiesSelectorTypeEnum.search;
  selector: EntitiesSelectorTypeEnum;

  private _unsubscribeAll: Subject<any> = new Subject();
  filterControl: FormControl = new FormControl('');

  component = this;

  selectedList: any[] = [];
  valuesMap: { [id: string]: any } = {};

  /** constructor */
  constructor(
    private systemRepositoryService: SystemRepositoryService,
    private _toastrService: ToastrService,
    private _i18nService: I18nService,
    private _autocompleteService: AutocompleteService,
    public favoritesService: FavoritesService,
    public historyService: HistoryService,
  ) {

  }

  choose(event: MouseEvent, item: any, index: number) {
    this.chosenItem = item;
    this.chosenItemChange.emit(this.chosenItem);
    // console.log('chosen item', item);
  }

  menuOpened() {
    // console.log('menuOpened');
    this.filterControl.updateValueAndValidity();
    // clearTimeout(this.timeout);
    // this.visible = true;
    // this.loadUnit();
  }

  menuClosed() {
    // console.log('menuClosed');
    this.selector = null;
    // clearTimeout(this.timeout);
    // this.timeout = setTimeout(() => {
    //   this.visible = false;
    // }, 500);
  }

  searchAll() {
    this.selectorChange({
      value: EntitiesSelectorTypeEnum.search,
    }, this.filterControl.value);
  }

  get menuHeight() {
    const realHeight = !!this.filteredList && Array.isArray(this.filteredList[this.selector]) && (this.filteredList[this.selector].length * this.rowHeight);
    if (typeof realHeight === 'number') {
      if (realHeight < 300) {
        return realHeight;
      }
      return 300;
    }
    return 0;
  }

  selectorChange(event, filterText = '') {
    // console.log('selectorChange', event);
    if (event.value) {
      this.selector = event.value;
      this.lastSelector = event.value;
      this.menuTrigger.openMenu();

      if (!filterText) {
        this.filterControl.setValue('', {
          emitEvent: false,
        });
      } else {
        this.filterControl.updateValueAndValidity();
      }

      switch (this.selector) {
        // case EntitiesSelectorTypeEnum.search: {
        //   this.filteredList = [];
        //   break;
        // }
        case EntitiesSelectorTypeEnum.favorite: {
          // console.log('favoritesService', this.favoritesService);
          this.filteredList[this.selector] = this.filterItemsFn(this.favoritesService.getFavorites(this.favoritesType) || []);
          break;
        }
        case EntitiesSelectorTypeEnum.history: {
          // console.log('historysService', this.historyService);
          this.filteredList[this.selector] = this.filterItemsFn(this.historyService.getHistory(this.historyType) || []);
          break;
        }
      }
    }
  }

  toggleFavorite(event, item) {
    event.stopPropagation();
    this.favoritesService.toggle(this.favoritesType, item);
    if (this.selector === EntitiesSelectorTypeEnum.favorite) {
      this.filterControl.updateValueAndValidity();
    }
  }

  filter(term: string, list: any[]): any[] {
    return this._autocompleteService.isInArray(term, list, {
      translate: (v) => v[this.filterProp],
    });
  }

  get filterValue() {
    return this.filterControl.value || '';
  }

  addAll(event: MouseEvent) {
    this.filteredList[this.selector].forEach(item => {
      if (!this.isSelected(item)) {
        this.select(event, item, false);
      }
    });
    this.changeEvent();
  }

  removeAll(event: MouseEvent) {
    this.selectedList = [];
    this.valuesMap = {};
    this.changeEvent();
  }

  toggle(event: MouseEvent, item, index?: number) {
    if (!this.isSelected(item)) {
      this.select(event, item);
    } else {
      this.deselect(event, item, index);
    }
  }

  toggleMulti(event: MouseEvent, item, index?: number) {
    event.stopPropagation();
    this.toggle(event, item, index);
  }

  isSelected(item) {
    return typeof this.valuesMap[item.id] !== 'undefined';
  }

  select(event: MouseEvent, item, emitEvent = true) {
    if (this.allowDuplicates || !this.isSelected(item)) {
      if (!this.multiple) {
        this.selectedList = [];
        this.valuesMap = {};
      }
      this.selectedList.push(item);
      this.valuesMap[item.id] = item;
      if (emitEvent) {
        this.changeEvent();
      }
    }
  }

  selectSingle(event: MouseEvent, item) {
    this.select(event, item);
    this.menuTrigger.closeMenu();
  }

  deselect(event: MouseEvent, item, index?: number) {
    if (typeof index === 'undefined') {
      index = this.selectedList.findIndex(v => v.id === item.id);
    }
    // this.valuesMap[item.id];
    if (index !== -1) {
      this.selectedList.splice(index, 1);
      delete this.valuesMap[item.id];
      this.changeEvent();
    }
  }

  changeEvent() {
    this.selectionChange.emit(this.multiple ? this.selectedList : (this.selectedList.length ? this.selectedList[0] : null));
  }

  updateValuesMap() {
    this.valuesMap = this.selectedList.reduce((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});
  }

  setValues() {
    if (!!this.values && !!this.values) {
      if (Array.isArray(this.values)) {
        this.selectedList = this.values;
      } else {
        this.selectedList = [this.values];
      }
    } else {
      this.selectedList = [];
    }
  }

  ngOnInit() {
    this.setValues();
    this.updateValuesMap();

    let delay;

    this.filterControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((value) => {
          // console.log('tap', value);

          if (value) {
            this.loading = true;
            delay = this._autocompleteService.isStaticList(this.list) ? 0 : 400;
          } else {
            delay = 0;
          }
        }),
        debounceTime(
          delay
        ),
        switchMap(() => {
          // console.log('valueChanges switchMap', this.list);
          switch (this.selector) {
            case EntitiesSelectorTypeEnum.search: {
              if (this._autocompleteService.isObservableList(this.list)) {
                return <Observable<T[]>>this.list;
              } else if (this._autocompleteService.isCallbackList(this.list)) {
                return (<AutocompleteList<T>>this.list)(this.filterValue);
              } else {
                return of(this.filter(this.filterValue, <T[]>this.list));
              }
            }
            case EntitiesSelectorTypeEnum.favorite: {
              return of(this.filter(this.filterValue, this.favoritesService.getFavorites(this.favoritesType) || []));
            }
            case EntitiesSelectorTypeEnum.history: {
              return of(this.filter(this.filterValue, this.historyService.history[this.historyType] || []));
            }
            default: {
              return of([]);
            }
          }
        }),
      ).subscribe(items => {
        this.loading = false;
        // console.log('items', items);
        // this.menuTrigger.openMenu();
        this.filteredList[this.selector] = this.filterItemsFn(Array.isArray(items) ? items : []);
      });

    this.buildLabel();

    if (this.hasFavorites) {
      this.selectorsList.push({
        name: EntitiesSelectorTypeEnum.favorite,
        label: this._i18nService.translate(_('Preferiti')),
        icon: 'favorite',
      });
    }

    if (this.hasHistory) {
      this.selectorsList.push({
        name: EntitiesSelectorTypeEnum.history,
        label: this._i18nService.translate(_('Recenti')),
        icon: 'history',
      });
    }
  }

  buildLabel() {
    if (this.hasSearch) {
      const item = this.selectorsList.find(l => l.name === EntitiesSelectorTypeEnum.search);
      console.log('buildLabel search item', item);
      if (item) {
        item.label = this.label || this._i18nService.translate(_('Cerca'));
        item.icon = this.icon || 'search';
      } else {
        this.selectorsList.push({
          name: EntitiesSelectorTypeEnum.search,
          label: this.label || this._i18nService.translate(_('Cerca')),
          icon: this.icon || 'search',
        });
      }
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {


    if (changes && changes.values && !changes.values.firstChange) {

      // console.log('changes.values.currentValue', changes.values.currentValue.map(v => v.id));
      // console.log('changes.values.previousValue', changes.values.previousValue.map(v => v.id));

      if (!this._autocompleteService.isSameObject(changes.values.currentValue, changes.values.previousValue)) {
        // console.log('entities ngOnChanges', changes.values.currentValue.map(v => v.id));
        this.setValues();
        this.updateValuesMap();
      } else {
        // console.log('entities ngOnChanges ignored duplicate');
      }
    }

    if (
      changes &&
      (changes.label && !changes.label.firstChange) ||
      (changes.icon && !changes.icon.firstChange)
    ) {
      this.buildLabel();
    }

    //   const currentIds = this.systems.map((v) => v.id).join(',');
    //   if (this.systemIds !== currentIds) {
    //     this.fetchSystems();
    //   }
    // }
  }

  // fetchSystems() {
  //   this.systemRepositoryService
  //     .findAll({ ids: this.systemIds })
  //     .pipe(first())
  //     .subscribe((data: any) => {
  //       this.systems = <System[]>(data && (data.items || data));
  //       this.emitEditEvent(0, 'init', null);
  //     });
  // }

  // removeSystem(index: number, system: System) {
  //   this.systems.splice(index, 1);
  //   this.emitEditEvent(index, 'remove', system);
  // }

  // clearSystems() {
  //   this.systems = [];
  //   this.emitEditEvent(0, 'init', null);
  // }

  // addSystem(system: System) {
  //   if (system && system.id) {
  //     if (this.systems.find((s) => s.id === system.id)) {
  //       this._toastrService.info(this._i18nService.translate(_('Impianto già presente in elenco')));
  //       this.system.reset();
  //       return;
  //     }
  //     this.systems.push(system);
  //     this.emitEditEvent(this.systems.length - 1, 'add', system);
  //     this.system.reset();
  //   }
  // }

  // emitEditEvent(index: number, action: string, system: System) {
  //   const event = {
  //     index: index,
  //     action: action,
  //     system: system,
  //     systems: this.systems,
  //   };
  //   this.changeSelection.emit(event);
  // }
}
