import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { StringModule } from 'app/modules/common/string/string.module';
import { UtilsModule } from 'app/modules/common/utils/ultils.module';
import { DatetimeModule } from 'app/modules/common/datetime/datetime.module';
import { ResourcesModule } from 'app/modules/resources/resources.module';
import { TimeslotModule } from 'app/modules/common/timeslot/timeslot.module';
import { AutocompleteModule } from 'app/modules/common/autocompletes/autocomplete.module';
import { InputsModule } from 'app/modules/common/inputs/inputs.module';
import { FolderInfoSidebarComponent } from './folder-info-sidebar.component';
import { NewUploaderModule } from 'app/modules/common/uploader/uploader.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FolderInfoSidebarComponent],
  imports: [
    GlobalModule,
    StringModule,
    UtilsModule,
    DatetimeModule,
    ResourcesModule,
    TimeslotModule,
    AutocompleteModule,
    InputsModule,
    NewUploaderModule,
    RouterModule,
  ],
  exports: [FolderInfoSidebarComponent],
})
export class FolderInfoSidebarModule { }
