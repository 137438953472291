import { Component, OnInit, Input } from '@angular/core';
import { RESOURCE_TYPE, Resource } from 'app/models/resource.model';

@Component({
  selector: 'app-resource-status',
  templateUrl: './resource-status.component.html',
  styleUrls: ['./resource-status.component.scss']
})
export class ResourceStatusComponent implements OnInit {

  @Input() resource: Resource;

  resourceType = RESOURCE_TYPE;

  constructor() { }

  ngOnInit() {
  }

}
