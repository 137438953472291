import { Injectable } from '@angular/core';
import { I18nService } from 'app/services/i18n.service';
import { UPLOADER_TYPE, UPLOADER_STATUS } from './uploader.interface';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root',
})
export class UploaderService {
  constructor(private _i18nService: I18nService) { }

  getTypeLabel(type: UPLOADER_TYPE, multiple: boolean = false) {
    switch (true) {
      case this.isFile(type):
        return multiple ? this._i18nService.translate(_('file')) : this._i18nService.translate(_('file'));
      case this.isImage(type):
        return multiple ? this._i18nService.translate(_('immagini')) : this._i18nService.translate(_('immagine'));
      case this.isVideo(type):
        return multiple ? this._i18nService.translate(_('video')) : this._i18nService.translate(_('video'));
      case this.isDocument(type):
        return multiple ? this._i18nService.translate(_('documenti')) : this._i18nService.translate(_('documento'));
      case this.isImagesVideos(type):
        return multiple ? this._i18nService.translate(_('immagini o video')) : this._i18nService.translate(_('immagini o video'));
    }
  }

  getTypeIcon(type: UPLOADER_TYPE, multiple: boolean = false) {
    switch (true) {
      case this.isFile(type):
        return multiple ? 'library_add' : 'note_add';
      case this.isImage(type):
        return multiple ? 'photo_library' : 'add_a_photo';
      case this.isVideo(type):
        return multiple ? 'video_library' : 'videocam';
      case this.isDocument(type):
        return multiple ? 'library_books' : 'description';
      case this.isImagesVideos(type):
        return multiple ? 'art_track' : 'art_track';
    }
  }

  getStatusIcon(status: UPLOADER_STATUS, type: UPLOADER_TYPE = UPLOADER_TYPE.file, multiple: boolean = false) {
    switch (true) {
      case this.isReady(status):
        return this.getTypeIcon(type, multiple);
      case this.isValidating(status):
        return 'autorenew';
      case this.isPaused(status):
        return 'cloud_upload';
      case this.isUploading(status):
        return 'cloud_upload';
      case this.isComplete(status):
        return 'check';
      case this.isIncomplete(status):
        return 'link_off';
      case this.isDisabled(status):
        return 'file_upload_off';
      case this.isError(status):
        return 'error_outline';
    }
  }

  getStatusLabels(
    status: UPLOADER_STATUS,
    type: UPLOADER_TYPE = UPLOADER_TYPE.file,
    multiple: boolean = false,
    errors: number = 0,
    progress: number = 0,
  ) {
    switch (true) {
      case this.isReady(status):
        return [this._i18nService.translate(_('Seleziona o trascina')), this.getTypeLabel(type, multiple)];
      case this.isValidating(status):
        return [this._i18nService.translate(_('Validazione')), this._i18nService.translate(_('in corso'))];
      case this.isPaused(status):
      case this.isUploading(status):
        return [progress.toString()];
      case this.isComplete(status):
        return [
          this._i18nService.translate(_('Completato')),
          this._i18nService.translate(
            _(`con { errors, plural, =0{successo} one{ {errors} errore } other{ {errors} errori } }`),
            {
              errors: errors,
            },
          ),
        ];
      case this.isIncomplete(status):
        return [this._i18nService.translate(_('Errore'))];
      case this.isDisabled(status):
        return [this._i18nService.translate(_('Non abilitato'))];
      case this.isError(status):
        return [this._i18nService.translate(_('Errore'))];
    }
  }

  isError(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.error;
  }

  isReady(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.ready;
  }

  isValidating(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.validate;
  }

  isPaused(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.pause;
  }

  isUploading(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.upload;
  }

  isComplete(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.complete;
  }

  isIncomplete(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.incomplete;
  }

  isDisabled(status: UPLOADER_STATUS) {
    return status === UPLOADER_STATUS.disabled;
  }

  isFile(type: UPLOADER_TYPE) {
    return type === UPLOADER_TYPE.file;
  }

  isImage(type: UPLOADER_TYPE) {
    return type === UPLOADER_TYPE.image;
  }

  isVideo(type: UPLOADER_TYPE) {
    return type === UPLOADER_TYPE.video;
  }

  isDocument(type: UPLOADER_TYPE) {
    return type === UPLOADER_TYPE.document;
  }

  isImagesVideos(type: UPLOADER_TYPE) {
    return type === UPLOADER_TYPE.imagesAndVideos;
  }
}
