import { FilenameService } from 'app/modules/uploader/service/filename.service';
import { GuidService } from 'app/modules/global/services/guid.service';
import { S3FileItem } from './s3-file-item.class';
import {
  FileUploader,
  FilterFunction,
  FileLikeObject
} from 'ng2-file-upload';
import { S3ResourceService } from '../service/s3.resource.service';
import { S3UploadOptions } from './policy-interface';
import { Resource } from 'app/models/resource.model';

export class S3FileUploader extends FileUploader {
  options: S3UploadOptions;
  queue: S3FileItem[];
  filenameService: FilenameService;
  guidService: GuidService;
  constructor(
    options: S3UploadOptions,
    private _S3ResourceService: S3ResourceService
  ) {
    super(options);
    this.filenameService = new FilenameService();
    this.guidService = new GuidService();
  }
  public loadResourcesAll() {
    this.queue.forEach(item => {
      if (!item.hasResource) {
        item.loadResource(this.options.resource);
      }
    });
  }
  public addToQueue(
    files: File[],
    options?: S3UploadOptions,
    filters?: FilterFunction[] | string
  ): void {
    let list: File[] = [];
    for (let file of files) {
      list.push(file);
    }
    let arrayOfFilters = this._getFilters(filters);
    let count = this.queue.length;
    let addedFileItems: S3FileItem[] = [];
    list.map((some: File) => {
      if (!options) {
        options = this.options;
      }


      let temp = new FileLikeObject(some);
      if (this._isValidFile(temp, arrayOfFilters, options)) {
        let fileItem = new S3FileItem(this, some, options, this._S3ResourceService);
        addedFileItems.push(fileItem);
        this.queue.push(fileItem);
        this._onAfterAddingFile(fileItem);
      } else {
        let filter = arrayOfFilters[this._failFilterIndex];
        this._onWhenAddingFileFailed(temp, filter, options);
      }
    });
    if (this.queue.length !== count) {
      this._onAfterAddingAll(addedFileItems);
      this.progress = this._getTotalProgress();
    }
    this._render();
    if (this.options.autoUpload) {
      this.uploadAll();
    }
  }
  public uploadItem(value: S3FileItem): void {
    const index: number = this.getIndexOfItem(value);
    const item: any = this.queue[index];
    const transport = this.options.isHTML5
      ? '_xhrTransport'
      : '_iframeTransport';
    item._prepareToUploading();
    if (this.isUploading) {
      return;
    }
    item.appendAdditionalParamenter(
      'key',
      'tmp/' +
      this.guidService.generate() +
      '/' +
      this.filenameService.normalize(item.file.name)
    );
    item.appendAdditionalParamenter('Content-Type', item.file.type);
    this.isUploading = true;
    (this as any)[transport](item);
  }
  protected _xhrTransport(item: S3FileItem): any {
    let that = this;
    let xhr = (item._xhr = new XMLHttpRequest());
    let sendable: any;

    this._onBeforeUploadItem(item);

    if (typeof item._file.size !== 'number') {
      throw new TypeError('The file specified is no longer valid');
    }
    if (!this.options.disableMultipart) {
      sendable = new FormData();
      this._onBuildItemForm(item, sendable);

      if (item.additionalParameter !== undefined) {
        Object.keys(item.additionalParameter).forEach((key: string) => {
          let paramVal = item.additionalParameter[key];
          // Allow an additional parameter to include the filename
          if (
            typeof paramVal === 'string' &&
            paramVal.indexOf('{{file_name}}') >= 0
          ) {
            paramVal = paramVal.replace('{{file_name}}', item.file.name);
          }
          sendable.append(key, paramVal);
        });
      }
      // For AWS, Additional Parameters must come BEFORE Files
      if (this.options.additionalParameter !== undefined) {
        Object.keys(this.options.additionalParameter).forEach((key: string) => {
          let paramVal = this.options.additionalParameter[key];
          // Allow an additional parameter to include the filename
          if (
            typeof paramVal === 'string' &&
            paramVal.indexOf('{{file_name}}') >= 0
          ) {
            paramVal = paramVal.replace('{{file_name}}', item.file.name);
          }
          sendable.append(key, paramVal);
        });
      }
      const appendFile = () =>
        sendable.append(item.alias, item._file, item.file.name);
      if (!this.options.parametersBeforeFiles) {
        appendFile();
      }

      if (this.options.parametersBeforeFiles) {
        appendFile();
      }
    } else {
      sendable = this.options.formatDataFunction(item);
    }

    xhr.upload.onprogress = (event: any) => {
      let progress = Math.round(
        event.lengthComputable ? (event.loaded * 100) / event.total : 0
      );
      this._onProgressItem(item, progress);
    };
    xhr.onload = () => {
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      let response = this._transformResponse(xhr.response, headers);
      let gist = this._isSuccessCode(xhr.status) ? 'Success' : 'Error';
      let method = '_on' + gist + 'Item';
      (this as any)[method](item, response, xhr.status, headers);
      this._onCompleteItem(item, response, xhr.status, headers);
    };
    xhr.onerror = () => {
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      let response = this._transformResponse(xhr.response, headers);
      this._onErrorItem(item, response, xhr.status, headers);
      this._onCompleteItem(item, response, xhr.status, headers);
    };
    xhr.onabort = () => {
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      let response = this._transformResponse(xhr.response, headers);
      this._onCancelItem(item, response, xhr.status, headers);
      this._onCompleteItem(item, response, xhr.status, headers);
    };
    xhr.open(item.method, item.url, true);
    xhr.withCredentials = item.withCredentials;
    if (this.options.headers) {
      for (let header of this.options.headers) {
        xhr.setRequestHeader(header.name, header.value);
      }
    }
    if (item.headers.length) {
      for (let header of item.headers) {
        xhr.setRequestHeader(header.name, header.value);
      }
    }
    if (this.authToken) {
      xhr.setRequestHeader(this.authTokenHeader, this.authToken);
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        that.response.emit(xhr.responseText);
      }
    };
    if (this.options.formatDataFunctionIsAsync) {
      sendable.then((result: any) => xhr.send(JSON.stringify(result)));
    } else {
      xhr.send(sendable);
    }
    this._render();
  }
}
