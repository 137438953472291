import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupInterface } from 'app/models/group.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserRepositoryService } from 'app/repositories/user-repository.service';
import { RoleInterface } from 'app/models/user.model';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';

@Component({
  selector: 'app-groups-roles-dialog',
  templateUrl: './groups-roles-dialog.component.html',
  styleUrls: ['./groups-roles-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupsRolesDialogComponent {

  constructor(
    public matDialogRef: MatDialogRef<GroupsRolesDialogComponent>,
    private _currentUserService: CurrentUserService,
    @Inject(MAT_DIALOG_DATA) public data: {
      name?: string
      groups: FormControl;
      roles: FormControl;
      groupList: GroupInterface[];
      roleList: string[];
    },
  ) { }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  get groups(): FormControl {
    return this.data && this.data.groups;
  }

  get roles(): FormControl {
    return this.data && this.data.roles;
  }

  get name(): string {
    return this.data && this.data.name;
  }

  get groupList(): GroupInterface[] {
    return this.data && this.data.groupList || [];
  }

  get roleList(): string[] {
    return this.data && this.data.roleList || [];
  }

  save() {
    console.log('save', this.groups, this.roles);
    this.matDialogRef.close(<{ groups: GroupInterface[], roles: string[] }>{
      groups: this.groups.value,
      roles: this.roles.value
    });
  }
}
