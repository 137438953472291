import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AutocompleteList } from '../autocomplete/autocomplete.component';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CustomerInterface } from 'app/models/customer.model';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';

/**
 *
 *
 * @export
 * @class GeoComponent
 * @description Input address field with map and geolocatiob
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-customer-autocomplete',
  templateUrl: './customer-autocomplete.component.html',
  styleUrls: ['./customer-autocomplete.component.scss'],
})
export class CustomerAutocompleteComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() hint: string;
  @Input() placeholder: string;

  @Output() changeSelection: EventEmitter<CustomerInterface> = new EventEmitter<CustomerInterface>();

  customerList: AutocompleteList<CustomerInterface> | CustomerInterface[];

  constructor(
    private _cdr: ChangeDetectorRef,
    private _customerRepositoryService: CustomerRepositoryService,
    private _i18nService: I18nService,
    private _currentUserService: CurrentUserService,
  ) { }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  ngOnInit() {

    console.log('CustomerAutocompleteComponent ngOnInit');

    this.changeSelection.subscribe(data => {
      console.log('CustomerAutocompleteComponent subscribe data', data)
    });

    if (this.isAdmin) {
      this.customerList = (q: string): Observable<CustomerInterface[]> => {
        return q && q.length ? this._customerRepositoryService.search(q) : of([]);
      }
    } else {
      this.customerList = this._currentUserService.currentUser.customers;

      // if (this.required && !this.control?.value) {
      //   if (this.customerList.length === 1) {
      //     this.onCustomerChange(this.customerList[0]);
      //   } else {
      //     if (this.customerList.length > 1 && !this.control.value) {
      //       this.onCustomerChange(this.customerList[0]);
      //     }
      //   }
      // }
    }
  }

  onCustomerChange(customer) {
    console.log('CustomerAutocompleteComponent onCustomerChange', this.changeSelection);

    this.control.setValue(customer);
    this.changeSelection.emit(customer);
  }

  ngOnChanges(changes) {
    // if (changes.control && changes.control.currentValue && !changes.control.firstChange) {
    //   console.log('>> folder autocomplete control changes', changes);
    //   this.changeSelection.emit(this.control.value);
    // }
    // if (changes.required && !changes.required.firstChange) {
    //   console.log('>> folder autocomplete changes', changes);
    //   this._cdr.detectChanges();
    // }
  }
}
