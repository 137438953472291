import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StringService } from 'app/modules/common/string/string.service';
import { FileSizePipe } from 'app/modules/global/pipes/file-size.pipe';

/**
 *
 *
 * @export
 * @class FormatComponent
 * @description Full name formatter from User entity object or from 'name' and 'surname'
 * @implements {OnInit}
 */
@Component({
  selector: 'app-filesize',
  templateUrl: './filesize.component.html',
  styleUrls: ['./filesize.component.scss'],
})
export class FileSizeComponent implements OnInit, OnChanges {
  @Input() bytes: number;
  @Input() decimals: number = 2;
  @Input() placeholder: string = '--';


  formattedValue: any;

  constructor(private _stringService: StringService) { }

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges(changes) {
    if (changes['bytes'] && !changes['bytes'].firstChange) {
      this.refresh();
    }
  }

  refresh() {
    if (typeof this.bytes === 'undefined') {
      this.formattedValue = this.placeholder;
    } else {
      this.formattedValue = new FileSizePipe().transform(this.bytes, this.decimals);
    }
  }
}
