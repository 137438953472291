<div fxLayout="column" fxFlex="100" class="fields-component">
  <div fxLayout="row wrap" fxLayoutAlign="flex-end center">
    <!-- CONTROLS -->
    <div *ngIf="formArray.length > 1" fxLayout="row" class="mb-12">
      <button mat-button type="button" (click)="accordion.openAll()">{{ 'Espandi tutto' | translate }}</button>
      <button mat-button type="button" (click)="accordion.closeAll()">{{ 'Nascondi' | translate }}</button>
    </div>

    <!-- ADD FIELD -->
    <div class="mb-12">
      <button type="button" class="w-140" mat-raised-button [matMenuTriggerFor]="menu.childMenu" aria-label="Add">
        {{ 'Aggiungi' | translate }} <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <app-fields-menu #menu [items]="fieldtypes" (clicked)="addField($event)"></app-fields-menu>
    </div>
  </div>

  <mat-accordion *ngIf="formArray.length > 0" multi [dragula]="dragGroupName" [dragulaModel]="formArray.controls"
    (dragulaModelChange)="onOrderChange($event)" class="mb-24">
    <ng-container *ngFor="let group of formArray.controls; let i = index; let first = first; let last = last">
      <mat-expansion-panel class="group-panel" [expanded]="selectedTab === i" [formGroup]="group">
        <mat-expansion-panel-header>
          <mat-panel-title class="ellipsis" ngClass.xs="mr-0" fxFlex="1 1">
            <!-- DRAG -->
            <div fxFlex="0 1 40px" class="mat-cell">
              <button mat-icon-button type="button" (click)="$event.stopPropagation()">
                <mat-icon class="fluid-drag-{{dragGroupName}}" aria-label="Drag">drag_indicator</mat-icon>
              </button>
            </div>
            <!-- TITLE -->
            <div class="ellipsis mr-12" fxLayout="column">
              <!-- NAME -->
              <div class="ellipsis">
                <div *ngIf="isNamable(group.get('type').value)">
                  <div *ngIf="fieldService.getLabel(group)" class="ellipsis accent-fg"
                    [ngClass]="{ 'warn-400-fg': group.touched && group.invalid }">
                    {{ fieldService.getLabel(group) }}
                  </div>
                  <div *ngIf="!fieldService.getLabel(group)" class="ellipsis grey-400-fg"
                    [ngClass]="{ 'warn-400-fg': group.touched && group.invalid }">
                    {{ 'Senza nome' | translate }}
                  </div>
                </div>
                <div *ngIf="!isNamable(group.get('type').value)">
                  <div class="ellipsis" *ngIf="!fieldService.isTypeInput(group)">
                    <strong>{{ fieldtypesMap[group.get('type').value] || ('Tipo' | translate) }}</strong>
                  </div>
                </div>
              </div>
              <!-- DESCRIPTION -->
              <div class="ellipsis">
                <span *ngIf="isNamable(group.get('type').value)">
                  <small class="ellipsis" *ngIf="!fieldService.isTypeInput(group)">{{
                    fieldtypesMap[group.get('type').value] || ('Tipo' | translate)
                  }}</small>
                  <small class="ellipsis" *ngIf="fieldService.isTypeInput(group)">{{
                    fieldinputtypesMap[group.get('templateOptions').get('type').value] || ('Tipo' | translate)
                  }}</small>
                  <small class="ml-4" *ngIf="group.get('type').value === FieldEnum.textEditor"><a
                      href="https://it.wikipedia.org/wiki/WYSIWYG" target="_blank">WYSIWYG</a></small>
                  &nbsp;-&nbsp;
                  <small class="ellipsis grey-400-fg">{{ group.get('key').value || ('chiave' | translate) }}</small>
                </span>
                <span *ngIf="!isNamable(group.get('type').value)">
                  <small class="ellipsis grey-400-fg">{{ 'campo speciale' | translate }}</small>
                </span>

                <small *ngIf="isAdmin">
                  [<strong class="warn-fg" matRipple [matMenuTriggerFor]="editMenu.childMenu"
                    (click)="onFieldEditClick($event)">
                    {{ 'modifica' | translate }}
                  </strong>]
                  <app-fields-menu #editMenu [items]="fieldtypes"
                    (clicked)="editField($event, group)"></app-fields-menu>
                </small>

              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description ngClass.xs="mr-4">
            <div fxLayout="row" fxHide.xs>
              <!-- SET MAIN COLUMN -->
              <button *ngIf="hasPrimary && isPrimariable(group.get('type').value)"
                [matTooltip]="'Colonna principale' | translate" mat-icon-button type="button"
                (click)="$event.stopPropagation(); setMain(group)" [disabled]="isMain(group)">
                <mat-icon aria-label="NonMain" *ngIf="!fieldService.isMain(group)"
                  class="grey-400-fg">star_border</mat-icon>
                <mat-icon aria-label="Main" *ngIf="fieldService.isMain(group)" class="yellow-800-fg">star</mat-icon>
              </button>
              <!-- HIDE COLUMN -->
              <button *ngIf="hasVisibility && isHiddable(group.get('type').value)"
                [matTooltip]="group.get('templateOptions').get('column').get('hidden').value ? ('Colonna nascosta nella lista' | translate) : ('Colonna visibile nella lista' | translate)"
                mat-icon-button type="button" (click)="
                  $event.stopPropagation();
                  group.get('templateOptions').get('column').get('hidden').setValue(!fieldService.isHidden(group))
                ">
                <mat-icon aria-label="Hidden" *ngIf="fieldService.isHidden(group)"
                  class="grey-400-fg">visibility_off</mat-icon>
                <mat-icon aria-label="Visiible" *ngIf="!fieldService.isHidden(group)">visibility</mat-icon>
              </button>
              <!-- DULICATE -->
              <button [matTooltip]="'Duplica' | translate" mat-icon-button type="button"
                (click)="$event.stopPropagation(); duplicate($event, group, i)" stopPropagation="true">
                <mat-icon aria-label="Delete">filter_none</mat-icon>
              </button>
              <!-- REMOVE FIELD -->
              <button color="warn" mat-icon-button type="button" (clickConfirm)="formArray.removeAt(i)"
                stopPropagation="true">
                <mat-icon aria-label="Delete">delete_outline</mat-icon>
              </button>
            </div>

            <div fxHide.gt-xs>
              <button mat-icon-button type="button" [matMenuTriggerFor]="fieldMenu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #fieldMenu="matMenu">
                <!-- SET MAIN COLUMN -->
                <button *ngIf="hasPrimary && isPrimariable(group.get('type').value)" fxHide.gt-xs mat-menu-item
                  type="button" (click)="$event.stopPropagation(); setMain(group)" [disabled]="isMain(group)">
                  <mat-icon *ngIf="isMain(group)" class="yellow-800-fg">star</mat-icon>
                  <mat-icon *ngIf="!isMain(group)">star_border</mat-icon>
                  <span *ngIf="!isMain(group)">{{ 'Rendi predefinito' | translate }}</span>
                  <span *ngIf="isMain(group)">{{ 'Predefinito' | translate }}</span>
                </button>
                <!-- HIDE COLUMN -->
                <button *ngIf="hasVisibility && isHiddable(group.get('type').value)" type="button" mat-menu-item
                  (click)="
                    $event.stopPropagation();
                    group.get('templateOptions').get('column').get('hidden').setValue(!fieldService.isHidden(group))
                  ">
                  <mat-icon aria-label="Hidden" *ngIf="fieldService.isHidden(group)"
                    class="grey-400-fg">visibility_off</mat-icon>
                  <mat-icon aria-label="Visible" *ngIf="!fieldService.isHidden(group)">visibility</mat-icon>
                  <span *ngIf="!fieldService.isHidden(group)">{{ 'Nascondi colonna' | translate }}</span>
                  <span *ngIf="fieldService.isHidden(group)">{{ 'Rendi visibile' | translate }}</span>
                </button>
                <!-- DULICATE -->
                <button mat-menu-item mat-menu-item type="button" (click)="duplicate($event, group, i)">
                  <mat-icon aria-label="Delete">filter_none</mat-icon>
                  <span>
                    {{ 'Duplica' | translate }}

                  </span>
                </button>
                <!-- REMOVE FIELD -->
                <button mat-menu-item type="button" (clickConfirm)="formArray.removeAt(i)">
                  <mat-icon color="warn">delete</mat-icon>
                  <span>{{ 'Elimina' | translate }}</span>
                </button>
              </mat-menu>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div fxLayout="column">
          <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-xs="16px">

            <div *ngIf="isNamable(group.get('type').value)" fxFlex="100" fxLayout="column" fxLayout.gt-xs="row"
              fxLayoutGap.gt-xs="16px">
              <mat-form-field fxFlex="calc(50% - 16px)" formGroupName="templateOptions">
                <input matInput required name="templateOptions.label" formControlName="label"
                  [placeholder]="'Etichetta' | translate" />
                <mat-error>
                  <div *ngIf="group.get('templateOptions').get('label').hasError('required')">
                    {{ 'Obbligatorio' | translate }}
                  </div>
                  <div *ngIf="group.get('templateOptions').get('label').hasError('duplicate')">
                    {{ 'Duplicato' | translate }}
                  </div>
                </mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="calc(50% - 16px)">
                <input matInput required name="key" formControlName="key" [pattern]="REGEXP_SLUG"
                  [placeholder]="'Nome della chiave' | translate" />
                <mat-error>
                  <div *ngIf="group.get('key').hasError('required')">{{ 'Obbligatorio' | translate }}</div>
                  <div *ngIf="group.get('key').hasError('duplicate')">{{ 'Duplicato' | translate }}</div>
                  <div *ngIf="group.get('key').hasError('pattern')">{{ 'Usare lettere numeri e _' | translate }}</div>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- TIPO E TIPOLOGIA -->
            <!-- <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
              <mat-form-field fxFlex="calc(50% - 16px)">
                <mat-select
                  required
                  formControlName="type"
                  placeholder="Tipo campo"
                  (selectionChange)="onTypeChange(group)"
                >
                  <mat-option *ngFor="let type of fieldtypes" [value]="type.value">
                    {{ type.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                fxFlex="calc(50% - 16px)"
                formGroupName="templateOptions"
                [hidden]="!fieldService.isTypeInput(group)"
              >
                <mat-select
                  [required]="fieldService.isTypeInput(group)"
                  name="templateOptions.type"
                  formControlName="type"
                  placeholder="Tipologia"
                  (selectionChange)="onTypeChange(group)"
                >
                  <mat-option *ngFor="let type of fieldinputtypes" [value]="type.value">
                    {{ type.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->
          </div>

          <!-- LIST -->
          <ng-container *ngIf="fieldService.isTypeList(group)">
            <app-field-list [hidden]="!fieldService.isTypeList(group)" [group]="group" [index]="index"
              [groupList]="groupList"></app-field-list>
          </ng-container>

          <!-- TEXTAREA -->
          <app-field-textarea [hidden]="!fieldService.isTypeTextarea(group)" [group]="group"></app-field-textarea>
          <!-- TEXTAREA ADVANCED -->
          <app-field-text-editor [hidden]="!fieldService.isTypeTextEditor(group)"
            [group]="group"></app-field-text-editor>
          <!-- INPUT -->
          <app-field-input [hidden]="!fieldService.isTypeInput(group)" [group]="group"></app-field-input>
          <!-- CHECKBOX -->
          <app-field-checkbox [hidden]="!fieldService.isTypeCheckbox(group)" [group]="group"></app-field-checkbox>
          <!-- COLOR -->
          <app-field-color [hidden]="!fieldService.isTypeColor(group)" [group]="group"></app-field-color>
          <!-- RADIO -->
          <app-field-radio [hidden]="!fieldService.isTypeRadio(group)" [group]="group"
            [groupList]="groupList"></app-field-radio>
          <!-- SELECT -->
          <app-field-select [hidden]="!fieldService.isTypeSelect(group)" [group]="group"
            [groupList]="groupList"></app-field-select>
          <!-- MULTISELECT -->
          <app-field-multiselect [hidden]="!fieldService.isTypeMultiselect(group)" [group]="group"
            [groupList]="groupList"></app-field-multiselect>
          <!-- IMAGE -->
          <app-field-image [hidden]="!fieldService.isTypeImage(group)" [group]="group"></app-field-image>
          <!-- VIDEO -->
          <app-field-video [hidden]="!fieldService.isTypeVideo(group)" [group]="group"></app-field-video>

          <!-- <pre>{{ group.value|json }}</pre> -->

          <!-- GROUPS & ROLES -->
          <ng-container *ngIf="isAdmin">
            <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px" class="mt-12">
              <!-- GROUPS -->
              <ng-container *ngIf="groupList?.length > 0">

                <!-- GROUP ACTIONS (READ WRITE SELECTOR) -->
                <ng-template #actionsTpl let-item="item" let-index="index" let-component="component">
                  <mat-select [(ngModel)]="item.write" [ngModelOptions]="{standalone: true}" placeholder="W"
                    [matTooltip]="'R: Sola lettura, W: Lettura e scrittura' | translate"
                    class="px-4 py-8 text-center w-36 ml-8 font-size-10 border-radius-4"
                    (click)="$event.stopPropagation()"
                    [ngClass]="{ 'green-300': !item.write, 'orange-300': !!item.write }">
                    <mat-option [value]="true">
                      W
                    </mat-option>
                    <mat-option [value]="false">
                      R
                    </mat-option>
                  </mat-select>
                </ng-template>

                <app-autocomplete-chips fxFlex="100" fxFlex.gt-sm="0 1 calc(50% - 16px)"
                  [formControl]="group.get('templateOptions').get('groups')" [actionsTpl]="actionsTpl" prop="name"
                  [list]="parsedGroupList" [placeholder]="'Visibilità Gruppi' | translate"></app-autocomplete-chips>

                <!-- <pre>{{ group.get('templateOptions').get('groups').value | json }}</pre> -->
              </ng-container>
              <!-- ROLES -->
              <ng-container *ngIf="roleList?.length > 0">
                <app-autocomplete-chips fxFlex="100" fxFlex.gt-sm="0 1 calc(50% - 16px)"
                  [formControl]="group.get('templateOptions').get('roles')" prop="label" decorateProp="value"
                  [list]="roleList" [placeholder]="'Visibilità Ruoli' | translate"></app-autocomplete-chips>
              </ng-container>
            </div>
          </ng-container>


        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

  <!-- EMPTY -->
  <div *ngIf="!formArray.length" class="grey-400-fg text-center">{{ 'nessun campo impostato' | translate }}</div>

  <!-- ERRORS -->
  <ng-container *ngIf="formArray.touched">
    <!-- REQUIRED -->
    <mat-error *ngIf="formArray.hasError('required')">{{ 'Obbligatorio' | translate }}</mat-error>
    <!-- DUPLICATES -->
    <mat-error *ngIf="formArray.hasError('duplicates')">
      {{
        'Il campo di tipo {types} può essere inserito una volta sola'
          | translate: { types: formArray.errors.duplicates.join(', ') }
      }}
    </mat-error>
    <!-- PRIMARY COLUMN -->
    <mat-error *ngIf="formArray.hasError('primaryColumnRequired')">{{
      'Devi impostare un campo principale' | translate
    }}</mat-error>
  </ng-container>
</div>