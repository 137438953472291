import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'image-thumb',
  templateUrl: './image-thumb.component.html',
  styleUrls: ['./image-thumb.component.scss'],
})
export class ImageThumbComponent implements OnInit {
  @Input() value: string;
  @Input() icon: string = 'image';

  constructor() {}

  ngOnInit() {}
}
