import { Component, Input, EventEmitter, Output, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, Validator, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { Resource } from 'app/models/resource.model';
import { forkJoin, Observable, zip } from 'rxjs';
import { S3FileUploader, S3Policy, S3FileItem } from 'app/modules/uploader/s3';
import { AwsRepositoryService } from 'app/repositories/aws-repository.service';
import { S3FileuploaderFactoryService } from 'app/modules/uploader/service/s3.fileuploader.factory.service';
import { take } from 'rxjs/operators';
import { S3ResourceService } from 'app/modules/uploader/service/s3.resource.service';
import { UPLOADER_ERROR } from './components/uploader-error/uploader-error.component';
import { FileItem, FileLikeObject } from 'ng2-file-upload';
import { ResourceFromS3FileResponse, FluidFileLikeObject } from 'app/modules/uploader/s3/s3-file-item.class';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { mimeExtensions, mimes } from 'app/modules/uploader/classes/file-types';
import {
  UPLOADER_TYPE,
  UPLOADER_STATUS,
  UPLOADER_TYPE_MODE,
  UPLOADER_STATUS_MODE,
  UPLOADER_FILE_ERROR,
} from './uploader.interface';

/**
 *
 *
 * @export
 * @class UploaderComponent
 * @description Uploader component
 */
@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploaderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UploaderComponent),
      multi: true,
    },
  ],
})
export class UploaderComponent implements ControlValueAccessor, Validator {
  // @Input() accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.png,image/jpeg';
  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  @Input() set disabled(value: boolean) {
    if (value) {
      this.status = this.UPLOADER_STATUS.disabled;
    }
    this._disabled = coerceBooleanProperty(value);
  }
  @Input() set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }
  @Input() set wide(value: boolean) {
    this._wide = coerceBooleanProperty(value);
  }
  @Input() set editable(value: boolean) {
    this._editable = coerceBooleanProperty(value);
  }
  @Input() set removable(value: boolean) {
    this._removable = coerceBooleanProperty(value);
  }
  @Input() set showRequirements(value: boolean) {
    this._showRequirements = coerceBooleanProperty(value);
  }
  @Input() set showFileInfo(value: boolean) {
    this._showFileInfo = coerceBooleanProperty(value);
  }
  @Input() set showFileInfoVertical(value: boolean) {
    this._showFileInfoVertical = coerceBooleanProperty(value);
  }
  @Input() set showTextInfo(value: boolean) {
    this._showTextInfo = coerceBooleanProperty(value);
  }
  @Input() set removeAfterUpload(value: boolean) {
    this._removeAfterUpload = coerceBooleanProperty(value);
  }

  @Input() uploadCallback: (item: S3FileItem) => Observable<Resource>;

  // ON CLICK IT WILL OPEN SEARCH DIALOG (NEVER UPLOAD MANUALLY!)
  @Input() onlySearch: boolean = false;

  @Input() allowSearchTypes: ('images' | 'videos')[]; // [] --> all

  @Input() hasPlaceholder: boolean = true;
  @Input() placeholder;
  @Input() type = UPLOADER_TYPE.file;
  @Input() accept; // = '.png,.gif,.jpeg,.jpg';
  @Input() allowedMimeType: string[]; // = ['image/png', 'image/jpeg', 'image/x-png'];
  @Input() allowedExtensions: string[]; // = ['png', 'jpeg', 'jpg'];
  @Input() capture = true;
  @Input() minFileSize; // 'MB'
  @Input() maxFileSize; // 'MB'
  @Input() minWidth; // 'px'
  @Input() minHeight; // 'px'
  @Input() timeout = 30000; // 'ms'
  @Input() notPrivateResource: boolean = true;
  @Input() autoUpload: boolean = true;
  @Input() editView: boolean = false;
  @Input() maxHeight: number;

  @Input() defaultValue: any;

  @Output() beforeUploadItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() whenAddingFileFailed: EventEmitter<FluidFileLikeObject> = new EventEmitter<FluidFileLikeObject>();
  @Output() uploadedFile: EventEmitter<S3FileItem> = new EventEmitter<S3FileItem>();
  @Output() uploadedFileError: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadedResource: EventEmitter<Resource> = new EventEmitter<Resource>();
  @Output() uploadedResourceError: EventEmitter<any> = new EventEmitter<any>();
  @Output() paused: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadedAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() resourceUploadedAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();

  // uploader conf
  uploader: S3FileUploader;
  // application
  // image
  // video
  // audio
  // pdf
  // compress
  // doc
  // xls
  // ppt
  allowedFileType: string[];

  // component vars
  status: number = UPLOADER_STATUS.ready;
  currentPreview: string;
  currentResource: Resource;
  allFiles: any[] = [];
  invalidFiles: any[] = [];
  uploaderErrors: any[] = [];

  allUploadedFiles: S3FileItem[] = [];

  // component consts
  UPLOADER_STATUS = UPLOADER_STATUS;
  UPLOADER_TYPE_MODE = UPLOADER_TYPE_MODE;
  UPLOADER_STATUS_MODE = UPLOADER_STATUS_MODE;
  UPLOADER_ERROR = UPLOADER_ERROR;
  UPLOADER_FILE_ERROR = UPLOADER_FILE_ERROR;
  UPLOADER_TYPE = UPLOADER_TYPE;

  protected _disabled = false;
  get disabled() {
    return this._disabled;
  }
  protected _required = false;
  get required() {
    return this._required;
  }
  protected _multiple = false;
  get multiple() {
    return this._multiple;
  }
  protected _wide = false;
  get wide() {
    return this._wide;
  }
  protected _editable = true;
  get editable() {
    return this._editable;
  }
  protected _removable = false;
  get removable() {
    return this._removable;
  }
  protected _showFileInfo = false;
  get showFileInfo() {
    return this._showFileInfo;
  }
  protected _showFileInfoVertical = false;
  get showFileInfoVertical() {
    return this._showFileInfoVertical;
  }
  protected _showTextInfo = false;
  get showTextInfo() {
    return this._showTextInfo;
  }
  protected _removeAfterUpload = false;
  get removeAfterUpload() {
    return this._removeAfterUpload;
  }
  protected _showRequirements = true;
  get showRequirements() {
    return this._showRequirements;
  }
  protected _allowSearchTypes = null; // ['images', 'videos'];
  get allowSearch() {
    return this._allowSearchTypes;
  }

  protected _resource: Resource; // = <Resource>{};

  protected _onChange = (event: any) => { };
  protected _onTouched: any = () => { };

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(value) {
    if (typeof value !== 'undefined' && !this.isMultiple && value !== this._resource) {
      this._resource = value;
      this._onChange(value);
    }
  }

  validate(c: AbstractControl) {
    return this.required && !c.value ? { required: true } : null;
  }

  constructor(
    protected _awsRepo: AwsRepositoryService,
    protected _S3ResourceService: S3ResourceService,
    protected _S3FileuploaderFactoryService: S3FileuploaderFactoryService,
    protected _cdr: ChangeDetectorRef,
  ) {
    this.getUploader().subscribe((uploader: S3FileUploader) => {
      this.uploader = uploader;
      // console.log(`UPL ${this.type} ${this.multiple}`, this.uploader);

      if (this.editView) {
        this.editResource(this.resource);
      }
    });
  }

  set resource(value: Resource) {
    if (!this.isMultiple) {
      this.writeValue(value);
    }
  }

  get resource() {
    return this.isMultiple ? null : this._resource;
  }

  restoreDefaultValue(event) {
    console.log('restoreDefaultValue');
    event.preventDefault();
    this.resource = this.defaultValue;
  }

  reset() {
    if (!this.isMultiple) {
      this.resource = null;
    }
    if (this.uploader) {
      this.uploader.clearQueue();
    }
    this.currentPreview = '';
    this.allFiles = [];
    this.invalidFiles = [];
    this.uploaderErrors = [];
    this.status = this.UPLOADER_STATUS.ready;
    this.allUploadedFiles = [];
  }

  getUploader() {
    return new Observable<S3FileUploader>((observer) => {
      if (this.uploader) {
        observer.next(this.uploader);
        observer.complete();
      } else {
        this._awsRepo
          .getSignature()
          .pipe(take(1))
          .subscribe(
            (policy: S3Policy) => {
              observer.next(this.initUploader(policy));
              observer.complete();
            },
            (errors) => {
              observer.error(errors);
              observer.complete();
            },
          );
      }
    });
  }

  initUploader(policy: S3Policy): S3FileUploader {
    // DEFAULT MIME TYPES
    if (!this.accept && !this.allowedMimeType) {
      switch (this.type) {
        case UPLOADER_TYPE.image:
          this.allowedMimeType = mimes.images;
          this.allowedExtensions = mimeExtensions.images;
          break;
        case UPLOADER_TYPE.video:
          this.allowedMimeType = mimes.videos;
          this.allowedExtensions = mimeExtensions.videos;
          break;
        case UPLOADER_TYPE.document:
          this.allowedMimeType = mimes.documents;
          this.allowedExtensions = mimeExtensions.documents;
          break;
        case UPLOADER_TYPE.imagesAndVideos: {
          this.allowedMimeType = [...mimes.images, ...mimes.videos];
          this.allowedExtensions = [...mimeExtensions.images, ...mimeExtensions.videos];
          break;
        }
        case UPLOADER_TYPE.file:
        default:
          break;
      }

      this.accept = this.allowedExtensions.map(v => `.${v}`).join(',');
    }

    // console.log('Uploader', 'type', this.type, 'accept', this.accept, 'mime types', this.allowedMimeType, 'extensions', this.allowedExtensions);

    const uploader = this._S3FileuploaderFactoryService.create(policy, {
      resource: this.resource,
      autoUpload: this.autoUpload,
      removeAfterUpload: false,
      notPrivate: !!this.notPrivateResource,
      allowedMimeType: this.allowedMimeType?.length > 0 ? this.allowedMimeType : undefined,
      allowedFileType: this.allowedFileType?.length > 0 ? this.allowedFileType : undefined,
      // BREAK S3
      // additionalParameter: {
      //   notPrivate: !!this.notPrivateResource
      // }
    });

    // FILTERS
    this.setDefaultFilters(uploader.options.filters);
    this.setCustomFilters(uploader.options.filters);

    // EVENTS
    this.setDefaultUploaderEvents(uploader);
    this.setCustomUploaderEvents(uploader);

    return uploader;
  }

  setDefaultFilters(filters) {
    const hasExtensionCheck = Array.isArray(this.allowedExtensions) && this.allowedExtensions.length;

    if (this.accept || hasExtensionCheck) {
      filters.push({
        name: 'accept',
        fn: (file: any, options) => {
          console.log('file', file, options);
          let checkByAccept = true; // DOESN'T WORK ("videos/quicktime" can't match ".mov/.avi/.mp4/.webm...")
          // if (this.accept) {
          //   checkByAccept = file.type.match(this.buildAcceptMimesRegExp(this.accept));
          // }
          let checkByMimeType = true; // EQUIVALENT TO checkByExtension
          // if (this.accept) {
          //   checkByMimeType = this.allowedMimeType.includes(file.type);
          // }
          let checkByExtension = true;
          if (hasExtensionCheck) {
            const ext = (file.name.split('.').pop() || '').toLowerCase();
            checkByExtension = this.allowedExtensions.map(v => ('' + v).trim().toLowerCase()).indexOf(ext) !== -1;
          }
          return checkByAccept && checkByMimeType && checkByExtension;
        },
      });
    }
    if (this.minFileSize) {
      filters.push({
        name: 'minSize',
        fn: (file: any, options) => {
          return file.size / 1024 / 1024 >= this.minFileSize;
        },
      });
    }
    if (this.maxFileSize) {
      filters.push({
        name: 'maxSize',
        fn: (file: any, options) => {
          return file.size / 1024 / 1024 <= this.maxFileSize;
        },
      });
    }
  }

  setCustomFilters(filters) { }

  setDefaultUploaderEvents(uploader: S3FileUploader) {
    uploader.onAfterAddingAll = (addedFiles) => {
      console.log('onAfterAddingAll', addedFiles);
      this.beforeUploadItem.emit();
      this.status = this.UPLOADER_STATUS.pause;
      this._cdr.detectChanges();
    };

    uploader.onBeforeUploadItem = (item) => {
      // console.log('onBeforeUploadItem', item);
      item.withCredentials = false;
      this._cdr.detectChanges();
    };

    uploader.onAfterAddingFile = (item: FileItem) => {
      // console.log('onAfterAddingFile', item);
    };

    uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter) => {
      // console.log('onWhenAddingFileFailed', filter, item);

      let errorKey = filter.name;
      if (filter.name === 'mimeType' || filter.name === 'fileType') {
        errorKey = 'accept';
      }

      const fluidItem = new FluidFileLikeObject(item);
      if (this.UPLOADER_FILE_ERROR[errorKey]) {
        fluidItem.error = this.UPLOADER_FILE_ERROR[errorKey];
      } else {
        fluidItem.error = this.UPLOADER_FILE_ERROR.unknown;
      }
      // ADD TO INVALID FILES
      this.invalidFiles.push(fluidItem);
      // CHECK IF ALL FILES ARE BROKEN
      if (this.allFiles.length === this.invalidFiles.length) {
        this.status = this.UPLOADER_STATUS.error;
      }
      this.whenAddingFileFailed.emit(fluidItem);
      this._cdr.detectChanges();
    };

    uploader.onProgressAll = () => {
      this.status = UPLOADER_STATUS.upload;
      this._cdr.detectChanges();
    };

    uploader.onErrorItem = (item: S3FileItem, response) => {
      this.uploadedFileError.emit({
        item: item,
        response: response,
      });
      this._cdr.detectChanges();
    };

    uploader.onSuccessItem = (item: S3FileItem) => {
      this.uploadedFile.emit(item);

      // ALL UPLOADED FILE
      this.allUploadedFiles.push(item);

      this._cdr.detectChanges();
    };

    uploader.onCompleteAll = () => {
      if (this.uploader.getNotUploadedItems().length > 0 || this.hasUploadErrors) {
        this.status = this.UPLOADER_STATUS.pause;
        this.paused.emit();
      } else {
        this.uploadedAll.emit();
      }

      const obsArr = this.allUploadedFiles.map(
        item => item.loadResource(this.currentResource, this.uploadCallback)
      );

      console.log('onCompleteAll this.allUploadedFiles', this.allUploadedFiles, obsArr);

      zip(
        ...obsArr
      ).subscribe((items: ResourceFromS3FileResponse[]) => {

        console.log('onCompleteAll forkJoin items', items, new Date().toISOString());

        items.forEach((data: ResourceFromS3FileResponse, index: number) => {

          const item = this.allUploadedFiles[index];

          if (data.resource) {
            // RESOURCE
            if (!this.isMultiple) {
              this.resource = data.resource;
            }
            this.uploadedResource.emit(<any>{ ...data.resource, additionalParameter: item.additionalParameter });
          } else if (data.errors) {
            // SERVER ERROR

            this.uploadedResourceError.emit({
              item: item,
              response: data.errors,
            });
          }
          if (this.hasResourceErrors) {
            if (!this.uploader.getNotUploadedItems().length) {
              this.status = this.UPLOADER_STATUS.incomplete;
            }
            // console.log('EMIT onResourcesCompleteAll in any case');
            // this.resourceUploadedAll.emit();
          } else {
            // console.log('EMIT onResourcesCompleteAll', data.resource);
            // this.onResourcesCompleteAll();
          }
        });

        // this._cdr.detectChanges();

        this.onResourcesCompleteAll();
      }, (err) => {
        console.log('onCompleteAll forkJoin err', err);
      });

      // this._cdr.detectChanges();

    };
  }

  get hasResourceErrors() {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      const item = this.uploader.queue[i];
      if (item.isResourceError) {
        return true;
      }
    }
    return false;
  }

  get hasUploadErrors() {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      const item = this.uploader.queue[i];
      if (item.isError) {
        return true;
      }
    }
    return false;
  }

  get isError() {
    return this.status === UPLOADER_STATUS.error;
  }

  get showQueue() {
    return this.isMultiple && this.uploader && this.uploader.queue.length;
  }

  onResourcesCompleteAll() {

    console.log('onResourcesCompleteAll');

    this.status = this.UPLOADER_STATUS.complete;
    this.resourceUploadedAll.emit();

    if (this.removeAfterUpload) {
      this.uploader.clearQueue();
    }

    this._cdr.detectChanges();
  }

  setCustomUploaderEvents(uploader: S3FileUploader) { }

  buildAcceptMimesRegExp(accept = ''): RegExp {
    const e = accept.replace(/[\/]/g, '\\$&');
    const c = e.replace(/\,/g, '|');
    const d = c.replace(/\s+/g, '');
    return new RegExp(d);
  }

  get isMultiple() {
    return this.multiple;
  }

  get isWide() {
    return !!this.wide || !!this.isMultiple;
  }

  get isSquare() {
    return !this.isWide;
  }

  get isEmpty() {
    return !this.resource;
  }

  handleFiles(files: File[]) {
    // RESET INPUT FOR SINGLE UPLOAD
    // IN MULTIPLE STATE LET ADD MORE FILES GRADUALLY
    if (!this.isMultiple) {
      this.reset();
    }

    // now files selected
    if (files.length === 0) {
      this.setUploaderErrors([this.UPLOADER_ERROR.empty]);
    }

    // multiple files on a single uploader
    if (!this.isMultiple && files.length > 1) {
      this.setUploaderErrors([this.UPLOADER_ERROR.multiple]);
    }

    this.uploadFiles(files);
  }

  uploadFiles(files: File[]) {
    this.allFiles = files;
    this.upload(files);
  }

  setUploaderErrors(errors: any[]) {
    this.uploaderErrors = errors;
    this.status = this.UPLOADER_STATUS.error;
  }

  upload(files: File[]) {
    this.getUploader().subscribe(
      (uploader) => {
        this.uploader = uploader;
        this.uploader.addToQueue(files);
      },
      (errors) => {
        this.setUploaderErrors([this.UPLOADER_ERROR.unavailable]);
      },
    );
  }

  removeEvent(resource: Resource) {
    this.reset();
    this.remove.emit(resource);
  }

  get isImageResource() {
    return this.resource && this.resource.type === 'images';
  }

  get isVideoResource() {
    return this.resource && this.resource.type === 'videos';
  }

  get isMediaResource() {
    return this.isImageResource || this.isVideoResource;
  }

  editResource(resource: Resource) {
    console.log('editResource is now', resource);
    let preview = '';
    if (this.isImageResource) {
      preview = resource.publicAvailableSource;
    }
    if (this.isVideoResource) {
      preview = resource.thumb;
    }
    this.reset();
    this.currentPreview = preview;
    this.currentResource = resource;
    console.log('currentResource is now', this.currentResource);
  }

  download(resource: Resource) {
    console.log('download', resource);
  }

  // selectResource(event) {
  //   console.log('selectResource', event);
  //   if (event && Array.isArray(event)) {
  //     if (this.isMultiple) {
  //       event.forEach((resource) => {
  //         this.uploadedResource.emit(resource);
  //       });
  //     } else {
  //       this.resource = event[0];
  //       this.uploadedResource.emit(event[0]);
  //     }
  //   }
  // }

  selectResource(event) {
    console.log('selectResource', event);
    if (event) {
      if (Array.isArray(event) && event.length) {
        if (this.isMultiple) {
          event.forEach((resource) => {
            this.uploadedResource.emit(resource);
          });
        } else {
          this.resource = event[0];
          this.uploadedResource.emit(event[0]);
        }
      } else {
        this.resource = event;
        this.uploadedResource.emit(event);
      }
    }
  }

}
