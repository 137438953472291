import { Component, Input, EventEmitter, Output, Directive, TemplateRef, ContentChild } from '@angular/core';
import { Resource } from 'app/models/resource.model';
import { UPLOADER_TYPE } from '../../uploader.interface';

@Directive({
  selector: '[appUploaderResourceActions]',
})
export class UploaderResourceActionsDirective {
  constructor(public template: TemplateRef<any>) { }
}

/**
 *
 *
 * @export
 * @class UploaderResourceComponent
 * @description Uploader component
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-uploader-resource',
  templateUrl: './uploader-resource.component.html',
  styleUrls: ['./uploader-resource.component.scss'],
})
export class UploaderResourceComponent {
  @Input() resource: Resource;
  @Input() editable: boolean = true;
  @Input() removable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: UPLOADER_TYPE = UPLOADER_TYPE.file;

  @Output() download: EventEmitter<Resource> = new EventEmitter<Resource>();
  @Output() edit: EventEmitter<Resource> = new EventEmitter<Resource>();
  @Output() remove: EventEmitter<Resource> = new EventEmitter<Resource>();

  @ContentChild(UploaderResourceActionsDirective, {
    static: true,
  })
  actionsTemplate: UploaderResourceActionsDirective;

  constructor() { }

  get status() {
    return this.resource ? this.resource.status : null;
  }

  // get isUsable() {
  //   return this.resource ? this.status === 'usable' : false;
  // }

  get isUsable() {
    return this.resource ? this.status === 'usable' || this.status === 'analyzing' : false;
  }

  get isImageResource() {
    return this.resource && this.resource.type === 'images';
  }

  get isVideoResource() {
    return this.resource && this.resource.type === 'videos';
  }

  get isMediaResource() {
    return this.isImageResource || this.isVideoResource;
  }

  get publicUrl() {
    return this.resource ? this.resource.publicSource : null;
  }

  get thumb() {
    return this.resource && this.resource.thumb ? this.resource.thumb : this.isImageResource ? this.publicUrl : null;
  }
}
