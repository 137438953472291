import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';
import { Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface CustomAutocompleteOption {
  id: string; // Unique identifier for each option
  [key: string]: any; // Allow dynamic properties
}

@Component({
  selector: 'app-custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: ['./custom-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomAutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomAutocompleteComponent),
      multi: true,
    },
  ],
})
export class CustomAutocompleteComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {

  // @ViewChild('searchText') searchText: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, {
    read: MatAutocompleteTrigger
  })
  matAutocompleteTrigger: MatAutocompleteTrigger;

  @Input() list; //: CustomAutocompleteOption[] = []; // Autocomplete list
  @Input() loadListOnInit: boolean;
  @Input() prop: string = 'name'; // Property to display
  @Input() required: boolean = false; // If field is required
  @Input() disabled: boolean = false; // Input disabled state
  @Input() clearable: boolean = true; // Input clearable
  @Input() placeholder: string = this._i18nService.translate(_(`Seleziona un'opzione`));
  @Input() hint: string;
  @Input() minChars: number = 2;
  @Input() floatLabel: string;

  @Output() changeSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSearchText: EventEmitter<any> = new EventEmitter<any>();

  searchTextCtrl: FormControl = new FormControl('');

  filteredOptions!: CustomAutocompleteOption[];
  selectedOption!: CustomAutocompleteOption | null; // Currently selected option
  touched = false; // Track touched status
  errors: any = {}; // Internal validation errors

  searchTimeout;
  sub: Subscription;
  loading: boolean;

  private onChange: (value: CustomAutocompleteOption | null) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(
    private _i18nService: I18nService,
  ) { }

  ngOnInit() {
    // console.log('CustomAutocompleteComponent ngOnInit');

    if (this.loadListOnInit || Array.isArray(this.list)) {
      this._refreshFilteredItems('');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.setDisabledState(this.disabled);
    }
  }

  // Handle manual text input
  onInputChange(): void {
    // console.log('onInputChange searchTextCtrl', this.searchTextCtrl);

    // console.log('matAutocompleteTrigger', this.matAutocompleteTrigger);
    // Reset the selected option when the user types
    this.resetModel();

    const searchText = (this.searchTextCtrl.value || '').trim();
    this.changeSearchText.emit(searchText);

    if (searchText.length === 0) {
      this.clear();
    } else {
      if (searchText.length >= this.minChars) {
        this._refreshFilteredItems(searchText);
      }
    }

    this.validate();
  }

  clear() {
    this.searchTextCtrl.setValue('');
    this._refreshFilteredItems('');

    this.resetModel();

    this.validate();
  }

  resetModel() {
    if (this.selectedOption !== null) {
      this.selectedOption = null;
      this.emitEvent(this.selectedOption);
    }
  }

  // Handle option selection from dropdown
  onOptionSelected(option: CustomAutocompleteOption): void {

    console.log('onOptionSelected', option);

    this.searchTextCtrl.setValue(!!option && option[this.prop] || '');

    this.selectedOption = option; // Set selected option
    this.emitEvent(this.selectedOption);

    // Mark as touched and validate immediately
    this.touched = true;
    this.onTouched(); // Call onTouched for form state
    this.validate(); // Validate after selection

  }

  emitEvent(value) {
    this.changeSelection.emit(value);
    this.onChange(value);
  }

  // Mark the field as touched when blurred
  onBlur(): void {
    // this.searchTextCtrl.setErrors({});
    // this.searchTextCtrl.markAsTouched();

    // Delay marking as touched and validation to avoid flashing errors
    setTimeout(() => {
      this.touched = true; // Mark as touched
      this.onTouched(); // Call onTouched for form state
      this.validate(); // Revalidate on blur

      if (this.errors?.required) {
        this.searchTextCtrl.setErrors({
          required: true,
        });
        this.searchTextCtrl.markAsTouched();
      }
    }, 100);
  }

  // Filter the autocomplete list
  private _refreshFilteredItems(value: string): void {
    const filterValue = value.toLowerCase();

    if (Array.isArray(this.list)) {
      if (filterValue) {
        this.filteredOptions = this.list.filter(option => option[this.prop]?.toLowerCase().includes(filterValue));
      } else {
        this.filteredOptions = this.list;
      }
    }

    if (typeof this.list === 'function') {
      // console.log('CustomAutocompleteComponent this.list fn');

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.loadListFn(filterValue);
      }, 400);

    }

  }

  loadListFn(filterValue) {
    console.log('CustomAutocompleteComponent loadListFn', filterValue);
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.loading = true;
    this.sub = this.list(filterValue).pipe(
      catchError(err => {
        this.loading = false;
        return of([]);
      })
    ).subscribe(data => {
      this.filteredOptions = data;
      // console.log('CustomAutocompleteComponent this.list', this.filteredOptions);
      this.loading = false;
    });
  }

  // Write the value from the parent form into the component
  writeValue(value: CustomAutocompleteOption | null): void {
    this.selectedOption = value;
    if (this.selectedOption) {
      this.searchTextCtrl.setValue(this.selectedOption[this.prop])
    }
    this.validate(); // Validate when value is written
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Set the disabled state of the input
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled; // Update the disabled state
    if (this.disabled) {
      this.searchTextCtrl.disable();
    } else {
      this.searchTextCtrl.enable();
    }
  }

  // Custom validation logic for required
  validate(control?: AbstractControl): ValidationErrors | null {
    this.errors = {}; // Reset errors

    if (this.required && !this.selectedOption) {
      this.errors.required = true;
    }

    // // OVERRIDE ERRORS
    // this.searchTextCtrl.setErrors(this.errors);

    return Object.keys(this.errors).length ? this.errors : null;
  }
}
