import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ClickConfirmService, ClickConfirmItemInterface } from './click-confirm.service';

@Directive({
  selector: '[clickConfirm]'
})
export class ClickConfirmDirective {

  @Input() options: ClickConfirmItemInterface;
  @Input() clickPropagate: boolean = true;
  @Output() clickConfirm = new EventEmitter();
  @Output() clickOptional = new EventEmitter();
  @Output() clickCancel = new EventEmitter();

  constructor(private _clickConfirmService: ClickConfirmService) { }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    if (this.clickPropagate) {
      event.stopPropagation();
    }
    this._clickConfirmService.open(this.options).afterClosed().subscribe((result) => {
      if (result === true) {
        this.clickConfirm.emit(result);
      } else if (result === 'optional') {
        this.clickOptional.emit(result);
      } else { // false or undefined
        this.clickCancel.emit({ backdrop: typeof result === 'undefined' });
      }
    });
  }
}
