import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TicketInterface } from 'app/models/ticket.model';
import { I18nService } from 'app/services/i18n.service';
import { TicketDialogComponent } from './dialogs/ticket-dialog/ticket-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private _i18nService: I18nService, private _matDialog: MatDialog) {
  }

  openTicketDialog(item?: TicketInterface, opts?: MatDialogConfig<any>) {
    const dialogOptions = Object.assign(
      {
        panelClass: ['ticket-dialog'], //, 'yellow-100-bg'],
      },
      opts,
    );

    dialogOptions.data = item;

    return this._matDialog.open(TicketDialogComponent, dialogOptions);
  }
}
