import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupInterface } from 'app/models/group.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserRepositoryService } from 'app/repositories/user-repository.service';
import { RoleInterface } from 'app/models/user.model';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';

@Component({
  selector: 'app-groups-with-permissions-dialog',
  templateUrl: './groups-with-permissions-dialog.component.html',
  styleUrls: ['./groups-with-permissions-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupsWithPermissionsDialogComponent implements OnInit {

  parsedGroupList: any[];

  constructor(
    public matDialogRef: MatDialogRef<GroupsWithPermissionsDialogComponent>,
    private _currentUserService: CurrentUserService,
    @Inject(MAT_DIALOG_DATA) public data: {
      name?: string
      groups: FormControl;
      groupList: GroupInterface[];
    },
  ) { }

  ngOnInit(): void {
    this.parsedGroupList = (JSON.parse(JSON.stringify(this.groupList)) || []).map(group => {
      return {
        id: group.id,
        name: group.name,
        write: true,
      }
    });
  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  get groups(): FormControl {
    return this.data && this.data.groups;
  }

  get name(): string {
    return this.data && this.data.name;
  }

  get groupList(): GroupInterface[] {
    return this.data && this.data.groupList || [];
  }

  save() {
    console.log('save', this.groups);
    this.matDialogRef.close(<{ groups: GroupInterface[] }>{
      groups: this.groups.value,
    });
  }
}
