export interface UpdatedByInterface {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
}

export class UpdatedBy implements UpdatedByInterface {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;

  constructor(private _conf?: UpdatedByInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}