import { Component, Input, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { I18nService } from 'app/services/i18n.service';
import { FIELD_TYPES_ARRAY, FieldEnum } from '../fields/fields.interface';
import { GroupInterface } from 'app/models/group.model';

@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss']
})
export class FieldListComponent implements OnInit, OnChanges {

  @Input() public group: FormGroup;

  @Input() public index: number = 0;
  @Input() groupList: GroupInterface[] = [];

  fieldtypes = this._i18nService.translateArray(FIELD_TYPES_ARRAY.filter(v => {
    return ![FieldEnum.image, FieldEnum.video, FieldEnum.list].includes(v.value);
  }));

  constructor(
    protected _cdr: ChangeDetectorRef,
    protected _i18nService: I18nService
  ) {
  }

  get odd() {
    return this.index % 2 === 0;
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

}

