import { Component, OnInit, Inject, ViewEncapsulation, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { Resource } from 'app/models/resource.model';
import { SystemInterface } from 'app/models/system.model';
import { Ticket, TICKET_CATEGORY_LIST, TICKET_PRIORITY_LIST, TicketInterface } from 'app/models/ticket.model';
import { AutocompleteList } from 'app/modules/common/autocompletes/autocomplete/autocomplete.component';
import { TICKET_DESCR_MAX_LENGTH, TICKET_URL_MAX_LENGTH } from 'app/modules/global/constants';
import { ClickConfirmService } from 'app/modules/global/directives/click-confirm/click-confirm.service';
import { LS_TICKET_NEW } from 'app/modules/global/localstorage';
import { REGEXP_GENERIC_URL } from 'app/modules/global/regexps';
import { ResourcesRepositoryService } from 'app/repositories/resources-repository.service';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { TicketRepositoryService } from 'app/repositories/ticket-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDialogComponent implements OnInit {

  form: FormGroup;
  formSub: Subscription;

  systemCtrl: FormControl = new FormControl();

  TICKET_PRIORITY_LIST = this._i18nService.translateArray(TICKET_PRIORITY_LIST, ['label']);
  TICKET_CATEGORY_LIST = this._i18nService.translateArray(TICKET_CATEGORY_LIST, ['label']);

  TICKET_DESCR_MAX_LENGTH = TICKET_DESCR_MAX_LENGTH;
  TICKET_URL_MAX_LENGTH = TICKET_URL_MAX_LENGTH;
  REGEXP_GENERIC_URL = REGEXP_GENERIC_URL;

  systemList: AutocompleteList<SystemInterface>;
  categoryCompare: any;

  showUploader = false;
  uploading = false;

  constructor(
    private _i18nService: I18nService,
    private _systemRepositoryService: SystemRepositoryService,
    public matDialogRef: MatDialogRef<TicketDialogComponent>,
    private _currentUserService: CurrentUserService,
    private _clickConfirmService: ClickConfirmService,
    private _resourceRepositoryService: ResourcesRepositoryService,
    private _ticketRepositoryService: TicketRepositoryService,
    private _toastrService: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: TicketInterface
  ) {
    this.systemList = (q: string): Observable<SystemInterface[]> => {
      return this._systemRepositoryService.search(q);
    };

    this.categoryCompare = (a, b) => a?.value === b?.value;
  }

  ngOnInit(): void {

    const cached: TicketInterface = new Ticket(JSON.parse(window.localStorage.getItem(LS_TICKET_NEW)));
    console.log('cached', cached);
    this.showUploader = cached.custom_fields.cf_resources.length > 0;

    // if (cached?.custom_fields?.cf_impianto) {
    //   this._systemRepositoryService.findAll({
    //     filters: `[{ "field": "name", "type": "search", "value": "${cached.custom_fields.cf_impianto}" }]`
    //   }).subscribe(data => {
    //     if (data.items.length) {
    //       this.systemCtrl.setValue(data.items[0]);
    //       this.updateTicketSubject();
    //     }
    //   });
    // }

    this.form = this.buildForm(cached);
    this.updateTicketSubject();
  }

  toggleUploader() {
    this.showUploader = !this.showUploader;
  }

  onSystemSelect(system: SystemInterface) {
    if (system) {
      this.form.get('custom_fields').get('cf_impianto').setValue(system.name);
    } else {
      this.form.get('custom_fields').get('cf_impianto').setValue('');
    }
    this.updateTicketSubject();
  }

  onCategoryChange(category) {
    this.updateTicketSubject();
  }

  updateTicketSubject() {

    let main = this.systemsCtrl.value.length > 0 ? this.systemsCtrl.value[0].name.substring(0, 23).padEnd(25, '...') : this._currentUserService.getGroups()[0]?.name;
    main = main ? `[${main}]` : 'Senza Gruppo';

    const secondary = this.form.get('category').value?.label ?? 'Senza Categoria';

    this.form.get('subject').setValue(
      `Ticket: ${main} - ${secondary}`
    );
  }

  onSystemsChange(systems: SystemInterface[]) {
    console.log('onSystemsChange systems', systems);
    if (systems?.length > 0) {
      this.form.get('custom_fields').get('cf_impianti').setValue(systems.map(v => { return { id: v.id, name: v.name } }));
    } else {
      this.form.get('custom_fields').get('cf_impianti').setValue([]);
    }
    this.updateTicketSubject();
  }

  buildForm(data: TicketInterface = {}) {
    const ticket = new Ticket(data);
    const form = new FormGroup({
      subject: new FormControl(this._currentUserService.getAnyName()),
      category: new FormControl(ticket.category, [Validators.required]),
      description: new FormControl(ticket.description, [Validators.required, Validators.maxLength(TICKET_DESCR_MAX_LENGTH)]),
      priority: new FormControl(ticket.priority),
      // status: new FormControl(ticket.status),
      custom_fields: new FormGroup({
        // cf_impianto: new FormControl(ticket.custom_fields.cf_impianto),
        cf_impianti: new FormControl(ticket.custom_fields.cf_impianti),
        cf_url: new FormControl(ticket.custom_fields.cf_url, [Validators.maxLength(TICKET_URL_MAX_LENGTH)]),
        cf_resources: new FormArray(ticket.custom_fields.cf_resources.map(v => new FormControl(v))),
      }),
    });

    console.log('form', form);

    this.formSub?.unsubscribe();
    this.formSub = form.valueChanges.subscribe(data => {
      // console.log('data', data);
      window.localStorage.setItem(LS_TICKET_NEW, JSON.stringify(data));
    });

    return form;
  }

  get systemsCtrl() {
    return this.form.get('custom_fields').get('cf_impianti');
  }

  get resourcesCtrl(): FormArray {
    return this.form.get('custom_fields').get('cf_resources') as FormArray;
  }

  uploadStarted() {
    this.uploading = true;
    console.log('uploadStarted');
  }

  onAllResourcesUploaded() {
    this.uploading = false;
    console.log('uploadEnded');
  }

  onUploadedResource(resource: Resource) {
    this.resourcesCtrl.push(new FormControl(resource));
    this.resourcesCtrl.updateValueAndValidity();
  }

  removeFromPhotos(item: Resource) {
    this.resourcesCtrl.removeAt(this.resourcesCtrl.value.findIndex(v => !v || v.id === item.id));
    this.resourcesCtrl.updateValueAndValidity();
    this._resourceRepositoryService.deleteResource(item).pipe(take(1)).subscribe();
  }

  reset() {
    this.form = this.buildForm({});
    window.localStorage.removeItem(LS_TICKET_NEW);
    this.showUploader = false;
    this.uploading = false;
  }

  get postData() {
    return {
      ...this.form.value,
      category: this.form.value.category?.label,
      priority: this.form.value.category?.priority,
      custom_fields: {
        ...this.form.value.custom_fields,
        cf_impianti: this.form.value.custom_fields.cf_impianti.map(v => v.name),
        cf_resources: this.showUploader ? this.form.value.custom_fields.cf_resources.map(v => v.id) : [],
      }
    }
  }

  save() {
    console.log('send', this.form.value, this.form);
    console.log('postData', this.postData);
    // return;
    this._ticketRepositoryService.create(this.postData).subscribe(data => {
      this._toastrService.success(this._i18nService.translate(_('Ticket inviato. Riceverai una risposta via email.')));
      this.reset();
      this.matDialogRef.close();
    }, () => {
      this._toastrService.error(this._i18nService.translate(_('Si è verificato un errore')));
    });
  }
}
