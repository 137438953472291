<h1 mat-dialog-title>{{ 'Attenzione' | translate }}</h1>
<div mat-dialog-content>
  <p>{{ title }}</p>
  <ul *ngIf="showItems && items?.length > 0" style="word-break: break-all">
    <li *ngFor="let item of items; let index = index;">
      <strong>{{ item || (('Senza nome' | translate) + ' (' + (index + 1) + ')')  }}</strong>
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="space-between center">
    <div fxFlex.xs="100" fxFlex.gt-xs="0 0 auto">
      <button fxFlex class="dialog-cancel mb-8" mat-raised-button [mat-dialog-close]="false">{{ cancelLabel }}</button>
    </div>
    <div fxFlex.gt-xs fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="flex-end center" fxLayoutGap="0"
      fxLayoutGap.gt-xs="8px">
      <div *ngIf="optionalLabel" class="mb-8">
        <button fxFlex="100" class="dialog-optional m-0 w-100-p" mat-raised-button color="accent"
          [mat-dialog-close]="'optional'">
          {{ optionalLabel }}
        </button>
      </div>
      <div class="mb-8">
        <button fxFlex="100" class="dialog-confirm m-0 w-100-p" mat-raised-button color="primary"
          [mat-dialog-close]="true" cdkFocusInitial>
          {{ confirmLabel }}
        </button>
      </div>
    </div>
  </div>
</div>