import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SystemInterface } from 'app/models/system.model';
import { ScreenInterface, Screen, ResolutionInterface, ScreenFitConstrain } from './screens.interface';

@Injectable({
  providedIn: 'root',
})
export class ScreensService {
  buildItems(items: ScreenInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildItem(item));
    });
  }

  buildItem(item?: ScreenInterface) {
    item = new Screen(item);
    return {
      idx: new FormControl(item.idx),
      guid: new FormControl(item.guid),
      depth: new FormControl(item.depth),
      color: new FormControl(item.color),
      width: new FormControl(item.width, [Validators.required, Validators.min(1)]),
      height: new FormControl(item.height, [Validators.required, Validators.min(1)]),
      top: new FormControl(item.top),
      left: new FormControl(item.left),
      name: new FormControl(item.name, Validators.required),
      fit: new FormControl(item.fit),
      fit_constrains: new FormArray(this.buildFitConstrains(item.fit_constrains), this.uniqueFitResolutionValidator),
    };
  }

  buildFitConstrains(fitItems: ResolutionInterface[] = []) {
    return fitItems.map((fitItem, index) => {
      return new FormGroup(this.buildFitConstrain(fitItem));
    });
  }

  buildFitConstrain(fitItem?: ResolutionInterface) {
    fitItem = new ScreenFitConstrain(fitItem);
    return {
      width: new FormControl(fitItem.width, [Validators.required, Validators.min(1)]),
      height: new FormControl(fitItem.height, [Validators.required, Validators.min(1)]),
    };
  }

  getFullScreen(system: SystemInterface): Screen {
    return new Screen({
      guid: 'fullscreen',
      name: 'FULLSCREEN',
      width: system.width,
      height: system.height,
      top: 0,
      left: 0,
    });
  }

  uniqueFitResolutionValidator(parentArray: FormArray) {
    const _resKey = (fitItem: ResolutionInterface) => fitItem.width + 'x' + fitItem.height;

    // console.log('uniqueFitResolutionValidator');

    const resMap = {};
    for (const groupItem of parentArray.controls) {
      if (!resMap[_resKey(groupItem.value)]) {
        resMap[_resKey(groupItem.value)] = 1;
      } else {
        return {
          duplicate: true,
        };
      }
    }

    return null;
  }

  getResolutionKey(w: string | number, h: string | number, unit: string = 'px') {
    return `${w}x${h}${unit}`;
  }

  getCompatibleScreens(resolution: ResolutionInterface, screens: ScreenInterface[] = []) {
    return screens.filter((screen: ScreenInterface) => {
      console.log('screen', screen);
      return this.isScreenCompatible(resolution, screen);
    });
  }

  getAllScreens(screens: ScreenInterface[] = [], system?: SystemInterface): ScreenInterface[] {
    if (!!system && (screens.length > 1 || (screens.length === 1 && !this.isSameResolution(screens[0], system)))) {
      return [...screens, this.getFullScreen(system)];
    }
    return screens;
  }

  getUniqueAvailableResolutionsFromSystem(system: SystemInterface) {
    const fullscreen = this.getFullScreen(system);
    return this.getUniqueAvailableResolutions([...system.screens, fullscreen]);
  }

  getUniqueAvailableResolutions(screens: ScreenInterface[] = []): ResolutionInterface[] {

    const resolutionsMap = {};
    const availableResolutions: (ResolutionInterface & { area: number })[] = [];

    for (let i = 0; i < screens.length; i++) {
      const screen = screens[i];
      if (this.isEverySizeAllowed(screen)) {
        return []; // '*'; ACCEPT ALL RESOLUTIONS
      }
      const key = this.getResolutionKey(screen.width, screen.height);
      if (!resolutionsMap[key]) {
        resolutionsMap[key] = 1;
        availableResolutions.push({ width: screen.width, height: screen.height, area: screen.width * screen.height });
      }
      if (this.hasFitConstains(screen)) {
        screen.fit_constrains.forEach(fitScreen => {
          const fitKey = this.getResolutionKey(fitScreen.width, fitScreen.height);
          if (!resolutionsMap[fitKey]) {
            resolutionsMap[fitKey] = 1;
            availableResolutions.push({ width: fitScreen.width, height: fitScreen.height, area: fitScreen.width * fitScreen.height });
          }
        });
      }
    }
    // console.log('availableResolutions', availableResolutions);
    return availableResolutions.sort((a, b) => {
      return b.area - a.area; // DESC sort
    }).map(({ width, height }) => {
      return {
        width,
        height,
      };
    });
  }

  hasFitConstains(screen: ScreenInterface) {
    return !!screen.fit && Array.isArray(screen.fit_constrains) && !!screen.fit_constrains.length;
  }

  hasCompatibleResolutions(resolution: ResolutionInterface, resolutions: ResolutionInterface[] = []) {
    return !!resolutions.find((r: ResolutionInterface) => {
      return this.isSameResolution(resolution, r);
    });
  }

  getCompatibleResolutions(resolution: ResolutionInterface, resolutions: ResolutionInterface[] = []) {
    return resolutions.filter((r: ResolutionInterface) => {
      return this.isSameResolution(resolution, r);
    });
  }

  isSameResolution(a: ResolutionInterface, b: ResolutionInterface) {
    const sameResolution = (a.width === b.width && a.height === b.height);
    return sameResolution;
  }

  isEverySizeAllowed(screen: ScreenInterface) {
    return screen.fit && (!Array.isArray(screen.fit_constrains) || !screen.fit_constrains.length);
  }

  isScreenCompatible(resolution: ResolutionInterface, screen: ScreenInterface) {
    const everySize = this.isEverySizeAllowed(screen);
    const sameResolution = this.isSameResolution(resolution, screen);
    const hasCompatibleResolutions = this.hasFitConstains(screen) && this.hasCompatibleResolutions(resolution, screen.fit_constrains);
    console.log('everySize', everySize, 'sameResolution', sameResolution, 'hasCompatibleResolutions', hasCompatibleResolutions);

    return everySize || sameResolution || hasCompatibleResolutions;
  }
}
