<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'Imposta gruppi' | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-12 m-0" ngClass.gt-xs="p-24" mat-dialog-content>
    <div *ngIf="name" class="mb-12">
      {{ 'Gruppi per' | translate }}: <strong>{{ name }}</strong>
    </div>
    <app-group-chips [control]="groups"></app-group-chips>
  </div>

  <div mat-dialog-actions class="m-0 p-12" ngClass.gt-xs="p-24" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
    <button color="primary" type="button" mat-raised-button (click)="save()">{{ 'Conferma' | translate }}</button>
  </div>
</div>
