<mat-accordion>
  <mat-expansion-panel #expandPanel (afterExpand)="expandToggle(expandPanel)"
    (afterCollapse)="expandToggle(expandPanel)" [expanded]="panelExpanded">
    <mat-expansion-panel-header class="px-12">
      <!-- QUEUE TITLE -->
      <mat-panel-title>
        <div fxLayout="row" fxLayoutAlign="flex-start center">
          <span *ngIf="uploader?.isUploading" class="mr-8">
            <mat-progress-spinner color="accent" diameter="24" mode="determinate" [value]="uploader?.progress"
              strokeWidth="3"></mat-progress-spinner>
          </span>
          <div>
            <strong>{{ 'Caricamento' | translate }} <span>{{ uploader?.queue?.length || 0 }}</span>
              {{ 'file' | translate }}</strong>
          </div>
        </div>
      </mat-panel-title>
      <!-- QUEUE DESCRIPTION -->
      <mat-panel-description *ngIf="invalidFiles?.length > 0">
        {{ '{count} { count, plural, =0{errore} other{errori} }' | translate: { count: invalidFiles?.length } }}
      </mat-panel-description>

      <ng-container *ngIf="!expandPanel.expanded && uploader?.queue?.length > 0">
        <button class="mr-12" *ngIf="uploader?.isUploading" mat-raised-button color="primary"
          (click)="cancel.emit()">{{'Annulla' | translate}}</button>
        <button class="mr-12" *ngIf="!uploader?.isUploading" mat-raised-button color="primary"
          (click)="clear.emit()">{{'Carica altro' | translate}}</button>
      </ng-container>

    </mat-expansion-panel-header>

    <div fxLayout="column">
      <!-- VALID FILES BLOCK -->
      <div *ngFor="let item of uploader?.queue; let last = last">
        <app-uploader-queue-valid-file [item]="item"
          [isUploading]="uploader?.isUploading"></app-uploader-queue-valid-file>
        <mat-divider *ngIf="!last"></mat-divider>
      </div>
      <mat-divider *ngIf="uploader?.queue?.length > 0 && invalidFiles?.length> 0"></mat-divider>
      <!-- INVALID FILES BLOCK -->
      <div *ngFor="let item of invalidFiles; let last = last">
        <app-uploader-queue-invalid-file [item]="item"></app-uploader-queue-invalid-file>
        <mat-divider *ngIf="!last"></mat-divider>
      </div>
    </div>

    <!-- QUEUE ACTIONS -->
    <mat-action-row>
      <button *ngIf="uploader?.isUploading" mat-button color="primary"
        (click)="cancel.emit()">{{'Annulla' | translate}}</button>
      <button [disabled]="uploader?.isUploading" mat-button color="primary"
        (click)="clear.emit()">{{'Carica altro' | translate}}</button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>