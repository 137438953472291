import { Component, Input, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss']
})
export class FieldInputComponent implements OnInit, OnChanges {

  @Input()
  public group: FormGroup;

  constructor(
    protected _cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  get isTimeBased() {
    return ['date', 'month', 'time'].indexOf(this.group.get('templateOptions').get('type').value) !== -1;
  }
}

