<div class="upl-resource-content">
  <div *ngIf="thumb" class="upl-resource" [style.background-image]="'url(' + thumb + ')'"></div>
  <div *ngIf="!thumb" class="upl-placeholder">
    <app-uploader-type [type]="type"></app-uploader-type>
    <div *ngIf="resource?.name" class="upl-placeholder-name">
      <app-name-ext [value]="resource?.name"></app-name-ext>
    </div>
  </div>
  <!-- ACTIONS -->
  <div *ngIf="isUsable" class="upl-resource-actions">
    <ng-container *ngIf="actionsTemplate?.template">
      <ng-container *ngTemplateOutlet="actionsTemplate?.template"></ng-container>
    </ng-container>
    <ng-container *ngIf="!actionsTemplate?.template">
      <ng-container *ngIf="!isMediaResource">
        <a [ngClass]="{ disabled: !publicUrl }" tabindex="0" [href]="publicUrl" target="_blank" download
          (click)="download.emit(resource)">
          <mat-icon>arrow_downward</mat-icon>
          <span>{{ 'Scarica' | translate }}</span>
        </a>
        <a *ngIf="editable" tabindex="0" (clickConfirm)="edit.emit(resource)"
          [options]="{ message: 'La risorsa attuale verrà sovrascritta. Continiare?' | translate }"
          [ngClass]="{ 'fluid-disabled': disabled }">
          <mat-icon>edit</mat-icon>
          <span>{{ 'Sostituisci' | translate }}</span>
        </a>
        <a *ngIf="removable" tabindex="0" (click)="remove.emit(resource)" [ngClass]="{ 'fluid-disabled': disabled }">
          <mat-icon>close</mat-icon>
          <span>{{ 'Rimuovi' | translate }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="isMediaResource">
        <a tabindex="0" [openResource]="resource">
          <mat-icon>fullscreen</mat-icon>
          <span>{{ 'Apri' | translate }}</span>
        </a>
        <a *ngIf="editable" tabindex="0" (clickConfirm)="edit.emit(resource)"
          [options]="{ message: 'La risorsa attuale verrà sovrascritta. Continiare?' | translate }"
          [ngClass]="{ 'fluid-disabled': disabled }">
          <mat-icon>edit</mat-icon>
          <span>{{ 'Sostituisci' | translate }}</span>
        </a>
        <a *ngIf="removable" tabindex="0" (click)="remove.emit(resource)" [ngClass]="{ 'fluid-disabled': disabled }">
          <mat-icon>close</mat-icon>
          <span>{{ 'Rimuovi' | translate }}</span>
        </a>
      </ng-container>
    </ng-container>
  </div>
  <ng-container [ngSwitch]="status">
    <div *ngSwitchCase="'analyzing'" class="upl-preview-processing" [matTooltip]="'Analisi metadata' | translate">
      <mat-icon>cloud_circle</mat-icon>
    </div>
    <div *ngSwitchCase="'framing'" class="upl-preview-processing" [matTooltip]="'Estrazione frame' | translate">
      <mat-icon>cloud_circle</mat-icon>
    </div>
    <div *ngSwitchCase="'transcoding'" class="upl-preview-processing" [matTooltip]="'Transcodifica' | translate">
      <mat-icon>cloud_circle</mat-icon>
    </div>
    <div *ngSwitchCase="'resizing'" class="upl-preview-processing" [matTooltip]="'Ridimensionamento' | translate">
      <mat-icon>cloud_circle</mat-icon>
    </div>
  </ng-container>
</div>