import { Component, OnInit, OnDestroy, Inject, ViewContainerRef } from '@angular/core';
import { of, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Platform } from '@angular/cdk/platform';
import { navigation } from 'app/navigation/navigation';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { GrantService } from './core/auth/services/grant.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { NavPermissionService } from './navigation/services/nav-permission.service';
import { I18nService } from './services/i18n.service';
import { HintService } from './modules/common/hint/hint.service';
import { environment } from 'environments/environment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SentryService } from './services/sentry.service';
import { IconsRegistryService } from './modules/global/components/icon/icons-registry.service';
import { TicketService } from './modules/common/hint/ticket.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _platform: Platform,
    private _grantService: GrantService,
    private _ngxPermissionService: NgxPermissionsService,
    private _navPermissionService: NavPermissionService,
    private _i18nService: I18nService,
    private _hintService: HintService,
    private _sentryService: SentryService,
    private _ticketService: TicketService,
    private _iconsRegistryService: IconsRegistryService, // INJECTS CUSTOM ICONS
    public vcRef: ViewContainerRef, // [DON'T REMOVE!] USED FOR COLORPICKER ABSOLUTE POSITIONING in app-colorpicker
  ) {
    // Get default navigation
    this.navigation = navigation({
      I18nService: this._i18nService,
      HintService: this._hintService,
      SentryService: this._sentryService,
      TicketService: this._ticketService,
    });

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // INIT TRASNLATIONS BEHAVIOR
    this._i18nService.init();

    // INIT SENTRY MONITORING
    if (environment.sentry) {
      this._sentryService.init();
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;

      // Color theme - Use normal for loop for IE11 compatibility
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];

        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.fuseConfig.colorTheme);
    });

    this._grantService.permissions
      .pipe(
        mergeMap((data: any) => {
          const type = data.type;
          const grants = data.grants;
          this._ngxPermissionService.flushPermissions();
          this._ngxPermissionService.loadPermissions(grants.map((x) => x.name));
          // ignore first permissions (from localStorage.user.roles)
          // update menu on real permissions got from server
          if (type === 'remote') {
            this._navPermissionService.updateNavigationFromPermissions(grants.map((x) => x.name));
            // console.log(
            //   'remote permissions',
            //   grants.map((x) => x.name),
            // );
          } else {
            // console.log('hmm some permissions arrived LOCAL');
            // console.log(
            //   'localStorage permissions',
            //   grants.map((x) => x.name),
            // );
          }
          return of(true);
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
