import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  forwardRef,
  AfterContentInit,
  ContentChild,
  TemplateRef,
  Directive,
  OnChanges,
} from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS } from '@angular/forms';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Directive({
  selector: '[appAutocompleteMultipleOption]',
})
export class AutocompleteMultipleOptionDirective {
  constructor(public template: TemplateRef<any>) { }
}

@Directive({
  selector: '[appAutocompleteMultipleItem]',
})
export class AutocompleteMultipleItemDirective {
  constructor(public template: TemplateRef<any>) { }
}

/**
 *
 *
 * @export
 * @class AutocompleteMultipleComponent
 * @description Autocomplete with multiple selection
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-autocomplete-multiple',
  templateUrl: './autocomplete-multiple.component.html',
  styleUrls: ['./autocomplete-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteMultipleComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutocompleteMultipleComponent),
      multi: true,
    },
  ],
})
export class AutocompleteMultipleComponent
  implements ControlValueAccessor, Validator, OnInit, AfterContentInit, OnChanges {
  @ContentChild(AutocompleteMultipleOptionDirective, {
    static: true,
  })
  autocompleteMultipleOption: AutocompleteMultipleOptionDirective;

  @ContentChild(AutocompleteMultipleItemDirective, {
    static: true,
  })
  autocompleteMultipleItem: AutocompleteMultipleItemDirective;

  // ARRAY | FN (x) => Observable | Observable
  @Input() list: any;
  @Input() prop: string;
  @Input() placeholder: string = this._i18nService.translate(_(`Seleziona un'opzione`));
  @Input() mapBy: string = 'id';
  @Input() label: string;
  @Input() listLabel: string;
  @Input() floatLabel: string;
  @Input() hideEmptyList: boolean = false;
  @Input() listEmptyLabel: string = this._i18nService.translate(_('nessun elemento selezionato'));
  @Input() filterItemsFn: (filteredItems: any[]) => any[];

  @Input() set forceColumnLayout(value: boolean) {
    this._forceColumnLayout = coerceBooleanProperty(value);
  }
  @Input() set required(values: boolean) {
    this._required = coerceBooleanProperty(values);
  }
  @Input() set disabled(values: boolean) {
    this._disabled = coerceBooleanProperty(values);
  }

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(AutocompleteComponent)
  autocomplete: AutocompleteComponent;

  values: any[] = [];
  model: any;

  map: any = {};

  constructor(private _i18nService: I18nService) { }

  protected _forceColumnLayout = false;
  get forceColumnLayout() {
    return this._forceColumnLayout;
  }
  protected _disabled = false;
  get disabled() {
    return this._disabled;
  }
  protected _required = false;
  get required() {
    return this._required;
  }

  private _onChange = (event: any) => { };
  private _onTouched: any = () => { };

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(values = []) {
    this.values = values;

    console.log('autocomlete multiple update MAP!!!', this.values);
    this.map = this.values.reduce((acc, value) => {
      this.setMap(value, acc);
      return acc;
    }, {});
  }

  validate(c: FormControl) {
    if (this.required && !this.values.length) {
      return {
        required: true,
      };
    }
    return null;
  }

  ngOnInit() { }

  setMap(value, map = this.map) {
    map[value[this.mapBy]] = value;
  }

  getMap(value, map = this.map) {
    return map[value[this.mapBy]];
  }

  deleteMap(value, map = this.map) {
    delete map[value[this.mapBy]];
  }

  compareMap(item1, item2) {
    return item1[this.mapBy] === item2[this.mapBy];
  }
  ngAfterContentInit() { }

  ngOnChanges() { }

  isSelected(value) {
    return this.getMap(value);
  }

  onValueChange(value) {
    if (value) {
      if (this.isSelected(value)) {
        // this.remove(null, value);
      } else {
        this.add(null, value);
      }
      // this.autocomplete.remove();
    }
  }

  add($event, item: any, index?: number) {
    console.log('ADD ? ??');
    if ($event) {
      $event.stopPropagation();
    }
    this.setMap(item);
    this.values.push(item);
    this._onChange(this.values);
    this.selectionChange.emit(this.values);
  }

  remove($event, item: any, index?: number) {
    console.log('REMOVEE ? ??');
    if ($event) {
      $event.stopPropagation();
    }
    const i = this.values.findIndex((value) => this.compareMap(item, value));
    this.values.splice(i, 1);
    this.deleteMap(item);
    this._onChange(this.values);
    this.selectionChange.emit(this.values);
  }
}
