<div fxLayout="row" fxLayoutAlign.gt-sm="center center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="8px"
  class="py-4">
  <!-- PROGRESS SPINNER -->
  <div fxHide.gt-sm fxFlex="0 1 calc(24px - 8px)">
    <mat-progress-spinner *ngIf="item?.isUploading || item.isError || item.isResourceError || item.isSuccess"
      [color]="item.isError || item.isResourceError ? 'warn' : item.isSuccess ? 'accent' : 'primary'" diameter="24"
      mode="determinate" [value]="item.isUploaded ? 100 : item.progress" strokeWidth="3"></mat-progress-spinner>
    <mat-icon *ngIf="!(item?.isUploading || item.isError || item.isResourceError || item.isSuccess)"
      class="grey-400-fg">hourglass_empty</mat-icon>
  </div>
  <!-- NAME -->
  <div fxLayout="column" fxFlex fxFlex.gt-sm="0 1 calc(300px - 8px)" class="ellipsis">
    <strong><app-name-ext [value]="item.file.name"></app-name-ext></strong>
    <div fxHide.gt-sm style="white-space: normal">
      <small *ngIf="item.isError" class="red-400-fg">{{ 'errore del server, riprova' | translate }}</small>
      <small *ngIf="item.isResourceError" class="red-400-fg">{{
        'impossibile creare la risorsa, riprova' | translate
      }}</small>
    </div>
  </div>
  <!-- SIZE -->
  <div fxHide.lt-md fxFlex="1 1 0%" class="grey-400-fg text-center">
    <app-filesize style="white-space: nowrap;" [bytes]="item.file.size"></app-filesize>
  </div>
  <!-- RESOLUTION (optional) -->
  <div *ngIf="item.file.customRawFile.customData?.width && item.file.customRawFile.customData?.height" fxHide.lt-md
    fxFlex="1 1 0%" class="grey-400-fg text-center">
    <app-resolution [width]="item.file.customRawFile.customData?.width"
      [height]="item.file.customRawFile.customData?.height"></app-resolution>
  </div>
  <!-- PROGRESS BAR -->
  <div fxLayout="column" fxHide.lt-md fxFlex="0 1 calc(30% - 8px)">
    <mat-progress-bar *ngIf="!(item.isError || item.isResourceError)"
      [color]="item.isError ? 'warn' : item.isSuccess ? 'accent' : 'primary'" mode="determinate"
      [value]="item.progress"></mat-progress-bar>
    <div *ngIf="item.isError || item.isResourceError" fxHide.lt-md style="white-space: normal">
      <div *ngIf="item.isError" class="red-400-fg">{{ 'errore del server, riprova' | translate }}</div>
      <div *ngIf="item.isResourceError" class="red-400-fg">
        {{ 'impossibile creare la risorsa, riprova' | translate }}
      </div>
    </div>
  </div>
  <!-- ACTIONS -->
  <div fxFlex="0 1 calc(48px - 8px)">
    <ng-container [ngSwitch]="true">
      <!-- RESOURCE ERROR -->
      <div *ngSwitchCase="item.isResourceError">
        <button mat-icon-button aria-label="resource error" (click)="item.loadResource()" [disabled]="isUploading">
          <!-- <mat-icon color="warn">link_off</mat-icon> -->
          <mat-icon>cloud_upload</mat-icon>
        </button>
      </div>
      <!-- ERROR -->
      <!-- <div *ngSwitchCase="item.isError">
                <button mat-icon-button aria-label="error" (click)="item.upload()" [disabled]="isUploading">
                    <mat-icon color="warn">cloud_off</mat-icon>
                </button>
            </div> -->
      <!-- CANCEL -->
      <div *ngSwitchCase="item?.isUploading">
        <button (click)="item.cancel()" mat-icon-button aria-label="cancel">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <!-- SUCCESS -->
      <div *ngSwitchCase="item.isSuccess && item.hasResource">
        <button disabled mat-icon-button aria-label="success">
          <mat-icon color="accent">check_circle</mat-icon>
        </button>
      </div>
      <!-- PROCESSING -->
      <div *ngSwitchCase="!item.isResourceError && item.isSuccess && !item.hasResource">
        <button disabled mat-icon-button aria-label="processing">
          <mat-icon>cloud_circle</mat-icon>
        </button>
      </div>
      <!-- UPLOAD -->
      <div *ngSwitchDefault>
        <button (click)="item.upload()" [disabled]="isUploading" mat-icon-button aria-label="upload">
          <mat-icon>cloud_upload</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>