<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ !folder ? 'Nuova cartella' : 'Modifica cartella' | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-24 m-0" mat-dialog-content>
    <form [formGroup]="form" name="editFolder" novalidate>
      <div fxLayout="column" fxFlex="100">

        <!-- NAME -->
        <div class="mb-12">
          <mat-form-field fxFlex="100">
            <input matInput appInputAutofocus required name="folder_name" formControlName="name"
              [placeholder]="'Nome' | translate" [pattern]="REGEXP_FOLDER_NAME" [maxlength]="FOLDER_NAME_MAX_LENGTH" />
            <mat-error>
              <div *ngIf="form.get('name').hasError('required')">{{ 'Obbligatorio' | translate }}</div>
              <div *ngIf="form.get('name').hasError('pattern')">{{ 'Lettere, numeri, spazi, -, _' | translate }}</div>
              <div *ngIf="form.get('name').hasError('maxLength')">{{ 'Lunghezza massima superata' | translate }}</div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- DESCRIPTION -->
        <div class="mb-12">
          <mat-form-field fxFlex="100">
            <textarea matInput rows="3" name="folder_description" formControlName="description"
              [placeholder]="'Descrizione' | translate"></textarea>
          </mat-form-field>
        </div>

        <!-- CUSTOMERS -->
        <div *ngIf="!!folder && isAdmin" class="mb-24">
          <!-- <div class="mb-12"> -->
          <app-entities-selector [label]="'Seleziona clienti' | translate" [list]="customersList" [multiple]="true"
            [required]="!!folder" [values]="form.get('customers').value" [hasHistory]="false" [hasFavorites]="false"
            (selectionChange)="customersChanged($event)"></app-entities-selector>
          <div class="pt-4 red-400-fg" *ngIf="form.get('customers').invalid && form.get('customers').touched">
            {{'Obbligatorio' | translate}}
          </div>
        </div>

        <div *ngIf="isAdmin" class="mb-12">
          <mat-checkbox name="folder_lock" formControlName="lock">
            {{ 'Cartella protetta' | translate }}</mat-checkbox>
        </div>

      </div>
    </form>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
    <button color="primary" type="button" mat-raised-button (click)="save()">{{ 'Conferma' | translate }}</button>
  </div>
</div>