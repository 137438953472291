<span *ngIf="error === UPLOADER_ERROR.unavailable">{{
  'Uploader non disponibile, controlla la tua connessione' | translate
}}</span>
<span *ngIf="error === UPLOADER_ERROR.empty">{{ 'Nessun file selezionato' | translate }}</span>
<span *ngIf="error === UPLOADER_ERROR.multiple">{{ 'È possibile caricare un solo file alla volta' | translate }}</span>
<span *ngIf="error === UPLOADER_ERROR.timeout">{{ 'Validazione è fallita, riprovare' | translate }}</span>
<span *ngIf="error === UPLOADER_ERROR.invalid">{{
  'Impossibile caricare i file forniti, leggere i dettagli' | translate
}}</span>
<span *ngIf="error === UPLOADER_ERROR.unknown">{{
  'Si è verificato un errore sconosciuto, riprovare' | translate
}}</span>
