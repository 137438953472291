import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Folder, FolderInterface } from 'app/models/folder.model';
import { PaginatedResponse } from 'app/models/pagination.model';
import { UpdatedBy } from 'app/models/update-by.model';
import { GuidService } from 'app/modules/global/services/guid.service';
import { ResourcesRepositoryService } from './resources-repository.service';
import { Customer } from 'app/models/customer.model';
import { GroupInterface } from 'app/models/group.model';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { Resource } from 'app/models/resource.model';

@Injectable({
  providedIn: 'root',
})
export class FolderRepositoryService {

  demoFolders = new PaginatedResponse({
    items: [new Folder({
      id: '5c3d9fffd40cae03af32d959',
      name: 'FirstFolder',
      created_at: new Date(),
      updated_at: new Date(),
      updated_by: new UpdatedBy({
        username: 'taras',
      }),
      nFiles: 42,
      customers: [new Customer({
        id: '5de13eb1345c0f07ef2a8392',
        name: 'FluidNext',
      })]
    })],
    pagination: {},
  });

  constructor(
    private _http: HttpClient,
    private _resourcesRepositoryService: ResourcesRepositoryService,
    private _currentUserService: CurrentUserService,
  ) { }

  // findByCustomer(customerId) {

  // }

  findAll(_params: HttpParams | any = {}): Observable<PaginatedResponse<FolderInterface>> {
    return this._http.get<any>('folders', {
      params: _params,
    });

    //.pipe(
    //   catchError(() => {

    // SORT DESC BY UPDATED
    this.demoFolders.items.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

    return of(
      JSON.parse(
        JSON.stringify(
          this.demoFolders
        )
      )
    );
    //   })
    // );
  }

  search(customerId: string, q: string): Observable<FolderInterface[]> {
    // if (typeof q !== 'string' || !q.trim()) return of([]);
    // return this._http.get<FolderInterface[]>(`folders/${encodeURIComponent(q.trim())}/search`).pipe(
    //   switchMap(() => {
    // return this.findAll().pipe(
    //   map(v => v.items)
    // );

    return this.findAll(
      {
        cid: customerId,
        sorting: 'updatedAt',
        direction: 'desc',
        filters: JSON.stringify([{
          field: 'name',
          type: 'search',
          value: (q || '').trim()
        }]),
      }
    ).pipe(
      map(v => v.items)
    );


    //   })
    // );
  }


  create(data: FolderInterface): Observable<any> {
    return this._http.post(`folders`, data);
    //.pipe(
    //   catchError(() => {
    const newFolder = new Folder({
      id: GuidService.generate(),
      name: data.name,
      created_at: new Date(),
      updated_at: new Date(),
      updated_by: new UpdatedBy({
        username: 'taras',
      }),
      nFiles: 0,
    });
    this.demoFolders.items.push(newFolder)
    // console.log('create', newFolder);
    return of(newFolder);
    //   })
    // )
  }

  update(id: string, data: FolderInterface): Observable<any> {
    return this._http.put(`folders/${id}`, data);
    //.pipe(
    //   catchError(() => {
    const foundFolderIndex = this.demoFolders.items.findIndex(f => f.id === id);
    if (foundFolderIndex !== -1) {
      this.demoFolders.items[foundFolderIndex] = Object.assign(
        this.demoFolders.items[foundFolderIndex],
        data,
      )
    }
    // console.log('update', this.demoFolders.items[foundFolderIndex]);
    return of(this.demoFolders.items[foundFolderIndex]);
    //   })
    // );
  }

  get(id: string): Observable<FolderInterface> {
    return this._http.get<FolderInterface>(`folders/${id}`);
    //.pipe(
    //   catchError(() => {
    console.log('GET', id, this.demoFolders);
    const foundFolder = this.demoFolders.items.find(f => f.id === id);
    if (foundFolder) {
      return of(foundFolder);
    }
    return of(null);
    //   })
    // )
  }

  delete(id: string): Observable<any> {
    return this._http.delete<FolderInterface>(`folders/${id}`);
    //.pipe(
    //   catchError(() => {
    const foundFolderIndex = this.demoFolders.items.findIndex(f => f.id === id);
    if (foundFolderIndex !== -1) {
      delete this.demoFolders.items[foundFolderIndex];
      // console.log('delete');
      return of(true);
    }
    return of(false);
    //   })
    // )
  }

  getResources(folderId: string, queryParams?: any) {
    // return this._http.get<PaginatedResponse<Resource>>(`folders/${folderId}/resources`).pipe(
    //   catchError(() => {
    // FLUIDNEXT BRAND ID
    return this._resourcesRepositoryService.findAllByFolderPaginated(folderId, queryParams || {
      ascending: false,
      page: 1,
      count: 20,
      query: '',
      types: '[]',
      sizes: JSON.stringify({ "width": "*", "height": "*" }),
    });
    //   })
    // )
  }

  getResourcesByCustomer(customerId: string, queryParams?: any) {
    return this._resourcesRepositoryService.findAllByCustomerPaginated(customerId, queryParams || {
      ascending: false,
      page: 1,
      count: 20,
      query: '',
      types: '[]',
      sizes: JSON.stringify({ "width": "*", "height": "*" }),
    });
  }

  getGroups(id: string): Observable<GroupInterface[]> {
    return this._http.get<GroupInterface[]>(`folders/${id}/groups`);
  }

  updateGroups(id: string, groups: GroupInterface[]): Observable<any> {
    return this._http.patch(`folders/${id}/groups`, { groups: groups });
  }

  copyResourcesTo(customer_id: string, resources_ids: string[]) {
    return this._http.post(`folders/${customer_id}/copyResources`, resources_ids);
  }

  moveResources(data: { from?: string; to?: string; resources?: string[] }) {
    return this._http.post(`folders/moves/resources`, data);
  }

  importResourcesFromBrandPre(brandId: string, groups: GroupInterface[] = []): Observable<any> {
    const qParams = groups.length > 0 ? { params: { groups: groups.map(g => g.id).join(',') } } : undefined;
    return this._http.get<any>(`resourceimports/${brandId}/pre`, qParams);
  }

  importResourcesFromBrand(brandId: string, folderId: string, groups: GroupInterface[] = []): Observable<any> {
    const qParams = groups.length > 0 ? { params: { groups: groups.map(g => g.id).join(',') } } : undefined;
    return this._http.get<any>(`resourceimports/${brandId}/imports/${folderId}`, qParams);
  }

}
