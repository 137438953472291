import { Component, forwardRef } from '@angular/core';
import { UploaderComponent } from './uploader.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UPLOADER_TYPE } from './uploader.interface';
import { mimeExtensions, mimes } from 'app/modules/uploader/classes/file-types';

/**
 *
 *
 * @export
 * @class UploaderImagesVideosComponent
 * @description Uploader component
 */
@Component({
  selector: 'app-uploader-images-videos',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploaderImagesVideosComponent),
      multi: true
    }
  ]
})
export class UploaderImagesVideosComponent extends UploaderComponent {
  type = UPLOADER_TYPE.imagesAndVideos;
}
