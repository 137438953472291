import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";

export const RESOURCE_DURATION_DEFAULT = 10;
export const RESOURCE_DURATION_MIN = 2;
export const RESOURCE_RESERVATION_STEPS_MIN = 10;
export const RESOURCE_RESERVATION_MULTPLE = 10;
export const FAVORITES_MAX = 6;
export const HISTORY_MAX = 10;
export const SUPPORT_EMAIL = 'support@fluidnext.com';
export const PAGE_SIZE_OPTIONS = [10, 20];
export const NOTIFICATIONS_FETCH_FREQUENCY_IN_SECONDS = 30;
export const NOTIFICATIONS_WIDGET_MAX = 15;
export const FOLDER_NAME_MAX_LENGTH = 50;
export const TICKET_DESCR_MAX_LENGTH = 1000;
export const TICKET_URL_MAX_LENGTH = 512;

export const ISO_DATETIME_FORMAT_WITH_SECONDS = 'yyyy-MM-ddTHH:mm:ss';
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_YEAR_MONTH_FORMAT = 'yyyy-MM';

// export const DATETIME_FORMAT = _('dd/MM/yy, HH:mm@@Formato data e ora');
// export const DATETIME_FORMAT_WITH_SECONDS = _('dd/MM/yy, HH:mm:ss@@Formato data e ora con secondi'); // USE angular date pipe 'medium'
// export const DATE_FORMAT = _('dd/MM/yy@@Formato data');
export const TIME_FORMAT = _('HH:mm@@Formato orario 24h');
export const TIME_FORMAT_WITH_SECONDS = _('HH:mm:ss@@Formato orario 24h con secondi');
export const TIME_FORMAT_H12 = _('hh:mm aaa@@Formato orario 12h');
export const TIME_FORMAT_H12_WITH_SECONDS = _('hh:mm:ss aaa@@Formato orario con secondi 12h');
export const MONTH_YEAR_FORMAT = _('MMM yyyy@@Formato mese e anno');
export const DAY_MONTH_FORMAT = _('d MMM@@Formato giorno e mese');

export const DATEPICKER_FORMAT_INPUT = _('DD/MM/YYYY@@Datepicker Moment.js formato input data');
export const DATEPICKER_FORMAT_MONTH_YEAR = _('MMM YYYY@@Datepicker Moment.js formato mese anno');

export const MONTHPICKER_FORMAT_INPUT = _('MM/YYYY@@Monthpicker Moment.js formato input data');
export const MONTHPICKER_FORMAT_MONTH_YEAR = _('MMM YYYY@@Monthpicker Moment.js formato mese anno');

// this._i18nService.translate(TIME_FORMAT)
// config.I18nService.translate(TIME_FORMAT)
// date | (TIME_FORMAT | translate)

// 'EEEE d MMMM y'

// EN Angular DatePipe pre-defined format options
// https://angular.io/api/common/DatePipe

// 'short'	        'M/d/yy, h:mm a'	                6 / 15 / 15, 9:03 AM                                DATETIME_FORMAT                               
// 'medium'	        'MMM d, y, h:mm:ss a'	            Jun 15, 2015, 9:03:01 AM                            DATETIME_FORMAT_WITH_SECONDS
// 'long'	        'MMMM d, y, h:mm:ss a z'	        June 15, 2015 at 9:03:01 AM GMT + 1
// 'full'	        'EEEE, MMMM d, y, h:mm:ss a zzzz'	Monday, June 15, 2015 at 9:03:01 AM GMT +01:00
// 'shortDate'	    'M/d/yy'	                        6 / 15 / 15                                         DATE_FORMAT
// 'mediumDate'	    'MMM d, y'	                        Jun 15, 2015
// 'longDate'	    'MMMM d, y'	                        June 15, 2015
// 'fullDate'	    'EEEE, MMMM d, y'	                Monday, June 15, 2015
// 'shortTime'	    'h:mm a'	                        9:03 AM                                             TIME_FORMAT
// 'mediumTime'	    'h:mm:ss a'	                        9:03:01 AM                                          TIME_FORMAT_WITH_SECONDS
// 'longTime'	    'h:mm:ss a z'	                    9:03:01 AM GMT + 1
// 'fullTime'	    'h:mm:ss a zzzz'	                9:03:01 AM GMT +01:00
