import { Component, Input } from '@angular/core';
import { S3FileItem } from 'app/modules/uploader/s3';

/**
 *
 *
 * @export
 * @class UploaderQueueValidFileComponent
 * @description Full name formatter from User entity object or from 'name' and 'surname'
 * @implements {OnInit}
 */
@Component({
  selector: 'app-uploader-queue-valid-file',
  templateUrl: './uploader-queue-valid-file.component.html',
  styleUrls: ['./uploader-queue-valid-file.component.scss']
})
export class UploaderQueueValidFileComponent {

  @Input() item: S3FileItem;
  @Input() isUploading: boolean;

  constructor() {}
}
