<mat-list>
  <mat-list-item>
    <span>{{ 'Risoluzione' | translate }}</span> <span> {{ metadata.width }}x{{ metadata.height }} </span>
  </mat-list-item>
  <mat-list-item>
    <span>{{ 'Tipo' | translate }}</span>
    <span>
      {{ metadata.container }}
    </span>
  </mat-list-item>
  <mat-list-item>
    <span>{{ 'Mime' | translate }}</span>
    <span>
      {{ metadata.contentType }}
    </span>
  </mat-list-item>
  <mat-list-item>
    <span>{{ 'Peso' | translate }}</span>
    <span>
      <app-filesize [bytes]="head?.size"></app-filesize>
    </span>
  </mat-list-item>
  <ng-container *ngIf="metadata?.type === 'video'">
    <mat-list-item>
      <span>{{ 'Durata' | translate }}</span>
      <span>
        {{ metadata.duration | fluidSecondsToDuration }}
      </span>
    </mat-list-item>
    <mat-list-item>
      <span>{{ 'FPS' | translate }}</span>
      <span>
        <app-fps [value]="metadata.fps"></app-fps>
      </span>
    </mat-list-item>
    <mat-list-item>
      <span>{{ 'Bit rate' | translate }}</span>
      <span>
        {{ metadata.bit_rate | bitrate }}
      </span>
    </mat-list-item>
    <mat-list-item>
      <span>{{ 'Codec' | translate }}</span>
      <span>
        {{ metadata.codec }}
      </span>
    </mat-list-item>
    <mat-list-item>
      <span>{{ 'Flussi video' | translate }}</span>
      <span>
        {{ metadata.videoStream || 0 }}
      </span>
    </mat-list-item>
    <mat-list-item>
      <span>{{ 'Flussi audio' | translate }}</span>
      <span>
        {{ metadata.audioStream || 0 }}
      </span>
    </mat-list-item>
  </ng-container>
</mat-list>