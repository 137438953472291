import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FolderRepositoryService } from 'app/repositories/folder-repository.service';
import { BrandInterface } from 'app/models/brand.model';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { CachedParamsService } from 'app/core/auth/services/cached-params.service';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { LS_FOLDER_RESOURCES_SELECTOR } from 'app/modules/global/localstorage';
import { FolderInterface } from 'app/models/folder.model';
import { CustomerInterface } from 'app/models/customer.model';

export enum FolderImportModeEnum {
  brand = 0,
  folder = 1,
}

export enum FolderImportStatusEnum {
  ready = 0,
  importing = 1,
  done = 2,
  error = 3,
}

export interface FolderImportDataInterface {
  brand?: BrandInterface;
  customer?: CustomerInterface;
  folder?: FolderInterface;
}

@Component({
  selector: 'app-folder-import-dialog',
  templateUrl: './folder-import-dialog.component.html',
  styleUrls: ['./folder-import-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FolderImportDialogComponent implements OnInit {

  form: FormGroup = new FormGroup({
    groups: new FormControl([]),
    brand: new FormControl(null, [Validators.required]),
    customer: new FormControl(null, [Validators.required]),
    folder: new FormControl(null, [Validators.required]),
    resourcesCount: new FormControl(null, [Validators.min(1)]),
  });

  loading: boolean;


  FolderImportModeEnum = FolderImportModeEnum;
  mode: FolderImportModeEnum;

  FolderImportStatusEnum = FolderImportStatusEnum;
  status: FolderImportStatusEnum = FolderImportStatusEnum.ready;

  defaults: {
    cid: string;
    folderId: string;
  };

  constructor(
    public matDialogRef: MatDialogRef<FolderImportDialogComponent>,
    private _folderRepositoryService: FolderRepositoryService,
    private _currentUserService: CurrentUserService,
    private _cachedParamsService: CachedParamsService,
    private _customerRepositoryService: CustomerRepositoryService,
    @Inject(MAT_DIALOG_DATA) public data: FolderImportDataInterface,
  ) {
  }

  onGroupsChange(event) {
    console.log('onGroupsChange', event);
    this.onBrandChange(this.form.get('brand').value);
  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  async ngOnInit() {

    this.mode = this.data.brand ? FolderImportModeEnum.brand : FolderImportModeEnum.folder;

    if (this.mode === FolderImportModeEnum.brand) {
      // SET DATA
      this.form.get('brand').setValue(this.data.brand);

      this.form.get('customer').setValue(
        await this._customerRepositoryService.getSelectedCustomer()
      );
    }

    if (this.mode === FolderImportModeEnum.folder) {
      this.form.get('customer').setValue(this.data.customer);
      this.form.get('folder').setValue(this.data.folder);
    }

    console.log('HEY', this.data);
    console.log('HEY', this.form.value);

    // NO CUSTOMER
    if (!this.data.customer) {
      this.loadCachedCustomer();
    }

    // YES BRAND
    if (this.data.brand) {
      this.onBrandChange(this.data.brand);
    }
  }

  get hasOneCustomer() {
    return this._customerRepositoryService.hasOneCustomer;
  }

  loadCachedCustomer() {
    this.defaults = {
      ...this._cachedParamsService.getCachedParams(LS_FOLDER_RESOURCES_SELECTOR),
    };
    if (this.defaults.cid) {
      this._customerRepositoryService.get(this.defaults.cid).subscribe(customer => {
        this.form.get('customer').setValue(customer);
        this.onCustomerChange(customer);
      }, () => {
      });
    }
  }

  onBrandChange(brand: BrandInterface) {
    if (brand) {
      this.loading = true;
      this._folderRepositoryService.importResourcesFromBrandPre(brand.id, this.form.get('groups').value).subscribe(data => {
        this.form.get('resourcesCount').setValue(data?.total);
        this.loading = false;
      }, () => {
        this.loading = false;
        this.status = FolderImportStatusEnum.error;
      });
    } else {
      this.form.get('resourcesCount').reset();
    }
  }

  onCustomerChange(event) {
    console.log('onCustomerChange', event);
    this.form.get('folder').reset();
  }

  onFolderChange(event) {
    console.log('onFolderChange', event);
  }

  save() {
    this.status = FolderImportStatusEnum.importing;
    this._folderRepositoryService.importResourcesFromBrand(this.form.get('brand').value.id, this.form.get('folder').value.id, this.form.get('groups').value).subscribe(data => {
      this.status = FolderImportStatusEnum.done;
    }, () => {
      this.status = FolderImportStatusEnum.error;
    });
  }

  finish() {
    this.matDialogRef.close(true);
  }
}
