import { Component, Inject, ViewEncapsulation, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { CustomerInterface } from 'app/models/customer.model';
import { FolderInterface } from 'app/models/folder.model';
import { AutocompleteList } from 'app/modules/common/autocompletes/autocomplete/autocomplete.component';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { FOLDER_NAME_MAX_LENGTH } from 'app/modules/global/constants';
import { REGEXP_FOLDER_NAME } from 'app/modules/global/regexps';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-folder-edit-dialog',
  templateUrl: './folder-edit-dialog.component.html',
  styleUrls: ['./folder-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FolderEditDialogComponent implements OnInit {

  form: FormGroup;

  REGEXP_FOLDER_NAME = REGEXP_FOLDER_NAME;
  FOLDER_NAME_MAX_LENGTH = FOLDER_NAME_MAX_LENGTH;

  customersList: AutocompleteList<CustomerInterface> | Observable<CustomerInterface[]> | CustomerInterface[];

  constructor(
    public matDialogRef: MatDialogRef<FolderEditDialogComponent>,
    private _toastrService: ToastrService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
    private _customerRepositoryService: CustomerRepositoryService,
    private _currentUserService: CurrentUserService,
    @Inject(MAT_DIALOG_DATA) public data: { folder: FolderInterface },
  ) {
    if (this.isAdmin) {
      this.customersList = (q: string): Observable<CustomerInterface[]> => {
        return q && q.length ? this._customerRepositoryService.search(q) : of([]);
      }
    } else {
      this.customersList = this._currentUserService.currentUser.customers;
    }
  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  get folder() {
    return this.data?.folder;
  }

  ngOnInit(): void {
    console.log('FolderEditDialogComponent', this);
    this.form = this.createFolderForm();
  }

  createFolderForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(this.folder?.name, [
        Validators.required,
        Validators.pattern(REGEXP_FOLDER_NAME),
        Validators.maxLength(FOLDER_NAME_MAX_LENGTH),
      ]),
      description: new FormControl(this.folder?.description),
      customers: new FormControl(this.folder?.customers || [], (control) => {
        return !this.folder || !this.isAdmin ? null : Validators.required(control);
      }),
      lock: new FormControl(this.folder?.lock),
    })
  }

  customersChanged(customers: CustomerInterface[] = []) {
    console.log('customersChanged', customers);
    this.form.get('customers').setValue(customers);
  }

  save() {
    FormUtils.validateAllFormFields(this.form);
    console.log('save', this.form);

    if (this.form.valid) {
      this.matDialogRef.close(
        this.form.value
      );
    } else {
      this._toastrService.error(this._i18nService.translate(_('Compila correttamente tutti i campi')));
    }
  }
}
