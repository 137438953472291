<div fxLayout="column" [fxLayout.gt-xs]="forceColumnLayout ? 'column' : 'row'"
  [fxLayoutGap.gt-xs]="forceColumnLayout ? '0' : '12px'" fxFlex="100">
  <div fxLayout="column" [fxFlex.gt-xs]="forceColumnLayout ? 100 : 'calc(50% - 12px)'">
    <div>
      <app-autocomplete #autocomplete fxFlex="100" [(ngModel)]="model" [list]="list" [prop]="prop"
        [placeholder]="placeholder" [disabled]="disabled" [delay]="200" [minChars]="2" [label]="label"
        [floatLabel]="floatLabel" [filterItemsFn]="filterItemsFn" [keepPrevSearch]="true"
        (changeSelection)="onValueChange($event)">
        <!-- [hint]="'Elementi selezionati (' + (values?.length || 0) + ')'" -->
        <ng-template appAutocompleteOption let-item="item" let-index="index" let-items="items">
          <div fxFlex="row" fxLayoutAlign="flex-start center" class="ellipsis">
            <ng-container *ngIf="autocompleteMultipleOption?.template">
              <ng-container *ngTemplateOutlet="
                  autocompleteMultipleOption?.template;
                  context: { item: item, index: index, items: items, autocomplete: autocomplete }
                "></ng-container>
            </ng-container>
            <ng-container *ngIf="!autocompleteMultipleOption?.template">
              <div fxFlex="100" class="ellipsis">{{ autocomplete.translate(item) }}</div>
            </ng-container>
            <div>
              <button mat-icon-button [disabled]="disabled" color="success" *ngIf="!isSelected(item)" type="button"
                (click)="add($event, item, index)">
                <mat-icon class="mr-0">add</mat-icon>
              </button>
              <button mat-icon-button [disabled]="disabled" color="warn" *ngIf="isSelected(item)" type="button"
                (click)="remove($event, item, index)">
                <mat-icon class="mr-0">remove_circle</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </app-autocomplete>
    </div>
  </div>
  <div *ngIf="!(values?.length === 0 && hideEmptyList)" fxLayout="column"
    [fxFlex.gt-xs]="forceColumnLayout ? 100 : 'calc(50% - 12px)'">
    <div class="mb-12 text-bold">
      {{ listLabel || ('Elementi selezionati' | translate) + ' (' + (values?.length || 0) + ')' }}
    </div>
    <div class="border mb-12">
      <div *ngIf="!values?.length" class="px-12 py-8 grey-400-fg text-center">{{ listEmptyLabel }}</div>
      <div class="ellipsis" *ngFor="let item of values; let index = index; let last = last">
        <div class="px-12 py-4" [ngClass]="{ 'border-bottom': !last }">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center">
            <ng-container *ngIf="autocompleteMultipleItem?.template || autocompleteMultipleOption?.template">
              <ng-container *ngTemplateOutlet="
                  autocompleteMultipleItem?.template || autocompleteMultipleOption?.template;
                  context: { item: item, index: index, last: last }
                "></ng-container>
            </ng-container>
            <ng-container *ngIf="!autocompleteMultipleItem?.template && !autocompleteMultipleOption?.template">
              <div class="ellipsis" fxFlex>{{ autocomplete.translate(item) }}</div>
            </ng-container>
            <!-- *ngIf="isSelected(item)" TODO: should i delete this?! -->
            <button mat-icon-button color="warn" type="button" class="app-autocomplete-multiple-item-remove"
              [disabled]="disabled" (click)="remove($event, item, index)">
              <mat-icon>remove_circle</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>