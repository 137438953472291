<mat-form-field fxFlex="100" XXXappearance="fill" [floatLabel]="floatLabel">
  <!-- <mat-label>{{ placeholder }}</mat-label> -->
  <input [formControl]="searchTextCtrl" matInput [disabled]="disabled" [matAutocomplete]="auto"
    (input)="onInputChange()" (blur)="onBlur()" [required]="required" [placeholder]="placeholder"
    [value]="selectedOption ? (selectedOption[prop] || '') : (searchTextCtrl?.value || '')" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event.option.value)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{ option[prop] }}
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix *ngIf="loading" class="app-icon-xs app-rotate">autorenew</mat-icon>
  <button *ngIf="clearable && !disabled && searchTextCtrl?.value && !loading" matSuffix class="app-icon-xs"
    mat-icon-button (click)="clear()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="touched && errors.required">{{ 'Obbligatorio' | translate }}</mat-error>
</mat-form-field>

<!-- <pre>Disabled: {{ disabled }}</pre> -->