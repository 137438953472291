import { Component, Input, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-field-color',
  templateUrl: './field-color.component.html',
  styleUrls: ['./field-color.component.scss']
})
export class FieldColorComponent implements OnInit, OnChanges {

  @Input()
  public group: FormGroup;

  constructor(
    protected _cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }
}
