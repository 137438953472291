import { Component, Input, EventEmitter, Output, forwardRef, AfterViewChecked, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';
import { MatInput } from '@angular/material/input';

/**
 *
 *
 * @export
 * @class ColorpickerComponent
 * @description Colorpicker component
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorpickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ColorpickerComponent),
      multi: true,
    },
  ],
})
export class ColorpickerComponent implements ControlValueAccessor, Validator, AfterViewChecked {

  @Input() placeholder: string = this._i18nService.translate(_('Colore'));
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() clearable: boolean = true;

  @Output() selectionChange: EventEmitter<FormControl> = new EventEmitter<FormControl>();

  @ViewChild(MatInput) matInput: MatInput;
  ngControl: FormControl;

  private _value;
  private _onChange = (event: any) => {};
  private _onTouched: any = () => {};

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(newValue) {
    if (typeof newValue !== 'undefined') {
      this._value = newValue;
      // console.log('colore changed', this.value, 'ngControl value', this.ngControl);
    }
  }

  validate(c: FormControl) {
    // console.log('colorpicker validation triggered');
    this.ngControl = c;
    if (this.required && !this._value) {
      return {
        required: true
      };
    }
    return null;
  }

  constructor(
    private _i18nService: I18nService,
  ) {}

  ngAfterViewChecked() {
    if (!!this.ngControl && !!this.matInput && !!this.matInput.ngControl && !!this.matInput.ngControl.control) {
      if (this.ngControl.touched && !this.matInput.ngControl.control.touched) {
        setTimeout(() => {
          this.matInput.ngControl.control.markAsTouched();
        });
      }
    }
  }

  blur(event) {
    this.ngControl.markAsTouched();
  }

  set value(value) {
    this.writeValue(value);
    this._onChange(this.value);
    this.selectionChange.emit(this.value);
  }

  get value() {
    return this._value;
  }

  colorChanged(color) {
    this.value = color;
  }
}
