import { Component, Input } from '@angular/core';

/**
 *
 *
 * @export
 * @class UploaderErrorComponent
 * @description Full name formatter from User entity object or from 'name' and 'surname'
 * @implements {OnInit}
 */
@Component({
  selector: 'app-uploader-error',
  templateUrl: './uploader-error.component.html',
  styleUrls: ['./uploader-error.component.scss']
})
export class UploaderErrorComponent {

  @Input() error: UPLOADER_ERROR; // enum type

  UPLOADER_ERROR = UPLOADER_ERROR;

  constructor() {}
}

export enum UPLOADER_ERROR {
  unavailable,
  empty,
  multiple,
  timeout,
  invalid,
  unknown
}
