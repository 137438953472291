import { Injectable } from '@angular/core';
import { DataTransformAbstractUp } from 'app/modules/global/classes/data.transform.abstract.up';
import { FolderInterface } from 'app/models/folder.model';

@Injectable({
  providedIn: 'root',
})
export class FolderDataTransformUp extends DataTransformAbstractUp {

  transform(folder: FolderInterface): FolderInterface {
    return folder;
  }

}
