import { Component, forwardRef } from '@angular/core';
import { UploaderComponent } from './uploader.component';
import { Observable, Subject } from 'rxjs';
import { S3FileItem } from 'app/modules/uploader/s3';
import { CustomFile } from 'app/modules/uploader/s3/s3-file-item.class';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UPLOADER_TYPE } from './uploader.interface';
import { mimeExtensions, mimes } from 'app/modules/uploader/classes/file-types';

/**
 *
 *
 * @export
 * @class UploaderImageComponent
 * @description Uploader component
 */
@Component({
  selector: 'app-uploader-image',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploaderImageComponent),
      multi: true,
    },
  ],
})
export class UploaderImageComponent extends UploaderComponent {

  type = UPLOADER_TYPE.image;

  maxFileSize = 5; // 5MB

  uploadFiles(files: File[]) {
    this.allFiles = files;
    this.loadPreviewAndSize(files).subscribe(
      () => {
        this.upload(files);
      },
      (response) => {
        console.log('validate FAILED', response);
      },
    );
  }

  loadPreviewAndSize(files: File[] = []) {
    this.status = this.UPLOADER_STATUS.validate;

    return new Observable((observer) => {
      const errTimeout = setTimeout(() => {
        observer.error([this.UPLOADER_ERROR.timeout]);
        observer.complete();
      }, this.timeout);

      const validFiles: File[] = [];
      const errorFiles: File[] = [];

      const isDone = () => {
        if (validFiles.length + errorFiles.length >= files.length) {
          clearTimeout(errTimeout);
          if (errorFiles.length) {
            observer.error({
              validFiles: validFiles,
              errorFiles: errorFiles,
            });
          } else {
            observer.next(validFiles);
          }
          observer.complete();
        }
      };

      const subject = new Subject<any>();
      subject.subscribe(
        (file) => {
          validFiles.push(file);
          isDone();
        },
        (response) => {
          errorFiles.push(response);
          isDone();
        },
      );

      Array.from(files).forEach((file: CustomFile) => {
        if (this.isImage(file)) {
          readLocalFile(file).subscribe((data) => {
            file.customData = {
              src: data.src,
              width: data.width,
              height: data.height,
            };
            subject.next(file);
          });
        } else {
          subject.next(file);
        }
      });
    });
  }

  isImage(file) {
    return file && file['type'].split('/')[0] === 'image';
  }

  setCustomFilters(filters) {
    if (this.minWidth) {
      filters.push({
        name: 'minWidth',
        fn: (file: any, options) => {
          return file.customData.width > this.minWidth;
        },
      });
    }
    if (this.minHeight) {
      filters.push({
        name: 'minHeight',
        fn: (file: any, options) => {
          return file.customData.height > this.minHeight;
        },
      });
    }
  }

  setCustomUploaderEvents(uploader) {
    uploader.onBeforeUploadItem = (item: S3FileItem) => {
      item.withCredentials = false;
      this.currentPreview = item.file.customRawFile.customData ? item.file.customRawFile.customData.src : null;
    };
  }
}

export const readLocalFile = (file) => {
  return new Observable<{ src?: string; width?: number; height?: number }>((observer) => {
    const response = {
      src: null,
      width: null,
      height: null,
    };
    const reader = new FileReader();
    reader.onload = (e) => {
      // SET FILE BASE64
      response.src = (<any>e.target).result;
      preloadImage(response.src).subscribe(
        (img: HTMLImageElement) => {
          // SET FILE WIDTH/HEIGHT
          response.width = img.width;
          response.height = img.height;
          observer.next(response);
          observer.complete();
        },
        () => {
          observer.next(response);
          observer.complete();
        },
      );
    };
    reader.onerror = (e) => {
      observer.error(e);
      observer.complete();
    };
    reader.readAsDataURL(file);
  });
};

export const preloadImage = (src) => {
  return new Observable((observer) => {
    const img = new Image();
    img.onload = () => {
      observer.next(img);
      observer.complete();
    };
    img.onerror = (error) => {
      observer.error(error);
      observer.complete();
    };
    img.src = src;
  });
};
