import { Resource, ResourceStatus, RESOURCE_TYPE } from 'app/models/resource.model';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostBinding } from '@angular/core';
import { PusherBridgeService } from 'app/modules/global/services/pusher.subscribe';
import { Subscription } from 'rxjs';
import { BrowserTabService } from 'app/modules/global/services/browser.tab.service';
import { ResourcePreviewService } from '../../services/resource.preview.service';
import { DropdownOptionInterface } from 'app/modules/common/utils/dropdown/dropdown.component';

@Component({
  selector: 'resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss'],
})
export class ResourceCardComponent implements OnInit, OnDestroy {
  @Input()
  resource: Resource;

  @Input()
  resourceOptions: DropdownOptionInterface[];
  // Opened changed
  @Output()
  selectEvent = new EventEmitter();

  @Output()
  resourceOptionClick = new EventEmitter();

  @HostBinding('class.error-resource') get getColor() {
    return this.resource.status === ResourceStatus.ERROR;
  }

  subscription: Subscription;

  resourceType = RESOURCE_TYPE;

  /**
   *Creates an instance of ResourceCardComponent.
   * @param {PusherBridgeService} _PusherBridgeService
   * @memberof ResourceCardComponent
   */
  constructor(
    private _PusherBridgeService: PusherBridgeService,
    private _previewService: ResourcePreviewService,
    private _tabService: BrowserTabService,
  ) {}

  ngOnInit() {
    this.subscription = this._PusherBridgeService.getObservable().subscribe((message) => {
      if (message.id === 'update.resource' && this.resource.id === JSON.parse(message.data).id) {
        this.resource = JSON.parse(message.data);
      }
    });
  }
  //#region Event emitters
  download() {
    this._tabService.openInBlank(this.resource.publicAvailableSource);
  }

  preview() {
    this._previewService.open(this.resource);
  }
  choose() {
    if (this.resource.status === ResourceStatus.USABLE) {
      if (this.isSelectable) {
        this.selectEvent.emit(this.resource);
      } else {
        this.preview();
      }
    }
  }

  get isSelectable() {
    return this.selectEvent.observers.length > 0;
  }

  //#endregion
  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
  getMainClass(): string | null {
    if (!this.resource) {
      return null;
    }
    if (this.resource.status === ResourceStatus.ERROR) {
      return ResourceStatus.ERROR;
    } else if (this.resource.status !== ResourceStatus.USABLE) {
      return 'progress';
    }
  }
}
