import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { S3Policy } from 'app/modules/uploader/s3';

export class S3SignatureOptions {
  static readonly SIZE_LARGE: string = 'large';
  static readonly SIZE_SMALL = 'small';
  static readonly ACL_PUBLIC = 'public-read';
  static readonly ACL_PRIVATE = 'private';
}

@Injectable({
  providedIn: 'root'
})
export class AwsRepositoryService {
  /**
   *Creates an instance of SystemRepositoryService.
   * @param {HttpClient} _http
   * @memberof SystemRepositoryService
   */
  constructor(private _http: HttpClient) { }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof SystemRepositoryService
   */
  getSignature(max: string = S3SignatureOptions.SIZE_LARGE, acl: string = S3SignatureOptions.ACL_PUBLIC): Observable<S3Policy> {
    return this._http.get<S3Policy>(`aws/signature?acl=${acl}&max=${max}`);
  }
}

