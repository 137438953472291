<!-- TODO:

- circular
- multi images
- handle errors for single / multi -->
<div class="upl-container" fxLayout="column"
  [ngClass]="{ 'upl-required': required, 'upl-has-placehodler': hasPlaceholder }">

  <ng-container *ngIf="!onlySearch">
    <div *ngIf="hasPlaceholder" class="upl-placeholder app-inputs-label-sm ellipsis">
      <span *ngIf="placeholder">
        {{ placeholder }}
      </span>
      <app-uploader-status *ngIf="!placeholder" [status]="UPLOADER_STATUS.ready" [type]="type"
        [mode]="UPLOADER_STATUS_MODE.label" [inline]="true"></app-uploader-status>
    </div>

    <!-- resourceDialog [multi]="multiple" (selected)="onResourceAdd($event)" -->

    <div fxFlex="100" fxLayout="column" [fxLayout.gt-xs]="showFileInfoVertical ? 'column' : 'row'" fxLayoutGap="16px">
      <div class="upl-wrapper" [ngClass]="{ wide: isWide, square: isSquare }"
        [style.height]="maxHeight ? ('' + maxHeight + 'px') : ''">
        <!-- EDIT 2024: removed class "upl-full" from upl-wrapper -->
        <div class="upl-wrapper-content">
          <!-- UPLOAD -->
          <app-uploader-droparea *ngIf="isEmpty" class="upl-full" [type]="type" [status]="status"
            [previewUrl]="currentPreview" [progress]="uploader?.progress" [accept]="accept" [capture]="capture"
            [multiple]="multiple" [showTextInfo]="showTextInfo" [errors]="invalidFiles.length"
            (added)="handleFiles($event)" (upload)="$event.stopPropagation(); uploader?.uploadAll()"
            (cancel)="$event.stopPropagation(); uploader?.cancelAll()"
            (generate)="$event.stopPropagation(); uploader?.loadResourcesAll()"
            (reset)="$event.stopPropagation(); reset()"></app-uploader-droparea>

          <!-- PREVIEW -->
          <app-uploader-resource *ngIf="!isEmpty" class="upl-full" [resource]="resource" [editable]="editable"
            [removable]="removable" [disabled]="disabled" (edit)="editResource($event)" (download)="download($event)"
            (remove)="removeEvent($event)">
          </app-uploader-resource>
        </div>
      </div>
      <div *ngIf="!isEmpty && !isMultiple && showFileInfo" fxLayout="column" class="ellipsis">
        <div class="mb-8">
          <small>{{ 'Specifiche del file' | translate }}</small>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="mb-4">
          <app-uploader-type [type]="type" class="grey-400-fg mr-4"></app-uploader-type>
          <span class="word-break-all">
            {{ resource.name }}
          </span>
          <!-- <app-name-ext class="ellipsis" [value]="resource.name"></app-name-ext> -->
        </div>
        <div *ngIf="resource.metadata?.width && resource.metadata?.height" fxLayout="row"
          fxLayoutAlign="flex-start center" class="mb-4">
          <mat-icon class="grey-400-fg mr-4">aspect_ratio</mat-icon>
          <app-resolution [width]="resource.metadata?.width" [height]="resource.metadata?.height"></app-resolution>
        </div>
        <div *ngIf="resource?.updated_at" fxLayout="row" fxLayoutAlign="flex-start center" class="mb-4">
          <mat-icon class="grey-400-fg mr-4">date_range</mat-icon>
          <app-date [date]="resource?.updated_at" formatter="short"></app-date>
        </div>
      </div>
    </div>

    <app-uploader-requirements class="upl-hint grey-400-fg mt-8" [hidden]="!showRequirements || !isEmpty"
      [accept]="accept" [minFileSize]="minFileSize" [maxFileSize]="maxFileSize" [minWidth]="minWidth"
      [minHeight]="minHeight"></app-uploader-requirements>

    <!-- FILE ERRORS -->
    <div class="mt-8 red-400-fg" *ngIf="!isMultiple && invalidFiles.length > 0">
      <app-uploader-queue-invalid-file-error [error]="invalidFiles[0].error"></app-uploader-queue-invalid-file-error>
    </div>

    <!-- QUEUE -->
    <app-uploader-queue *ngIf="isMultiple && (invalidFiles.length > 0 || uploader?.queue?.length > 0)" class="mt-8"
      [uploader]="uploader" [invalidFiles]="invalidFiles" (cancel)="uploader?.cancelAll()"
      (clear)="reset()"></app-uploader-queue>

    <!-- ERRORS -->
    <div class="mt-8" *ngIf="uploaderErrors.length > 0">
      <span class="red-400-fg" *ngFor="let err of uploaderErrors; let last = last">
        <app-uploader-error [error]="err"></app-uploader-error>
        <span *ngIf="!last">, </span>
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="!!allowSearchTypes">
    <div *ngIf="!onlySearch" class="upl-select-resource" [class.mt-8]="!onlySearch">
      <div class="upl-select-resource-label">{{ 'oppure' | translate }}</div>
      <button mat-raised-button resourceDialog [multi]="multiple" [types]="allowSearchTypes"
        (selected)="selectResource($event)" [disabled]="disabled">
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="UPLOADER_TYPE.image">
            {{ 'Seleziona immagine' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="UPLOADER_TYPE.video">
            {{ 'Seleziona video' | translate }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ 'Seleziona risorsa' | translate }}
          </ng-container>
        </ng-container>
      </button>
    </div>

    <div *ngIf="onlySearch">

      <div *ngIf="hasPlaceholder" class="upl-placeholder app-inputs-label-sm ellipsis">
        <span *ngIf="placeholder">
          {{ placeholder }}
        </span>
        <app-uploader-status *ngIf="!placeholder" [status]="UPLOADER_STATUS.ready" [type]="type"
          [mode]="UPLOADER_STATUS_MODE.label" [inline]="true"></app-uploader-status>
      </div>

      <mat-button-toggle-group class="app-uploader-button-group" [disabled]="disabled">
        <mat-button-toggle #btn1 (change)="btn1.checked = false" resourceDialog [multi]="multiple"
          [types]="allowSearchTypes" (selected)="selectResource($event)">
          <span fxLayout="row" fxLayoutAlign="flex-start center" *ngIf="!resource">
            <mat-icon class="mr-4">add</mat-icon>
            <ng-container [ngSwitch]="type">
              <ng-container *ngSwitchCase="UPLOADER_TYPE.image">
                {{ 'Imposta immagine' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="UPLOADER_TYPE.video">
                {{ 'Imposta video' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'Imposta risorsa' | translate }}
              </ng-container>
            </ng-container>
          </span>
          <span fxLayout="row" fxLayoutAlign="flex-start center" *ngIf="resource">
            <mat-icon color="accent" class="mr-4">check</mat-icon>
            <ng-container [ngSwitch]="type">
              <ng-container *ngSwitchCase="UPLOADER_TYPE.image">
                {{ 'Modifica immagine' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="UPLOADER_TYPE.video">
                {{ 'Modifica video' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'Modifica risorsa' | translate }}
              </ng-container>
            </ng-container>
          </span>
        </mat-button-toggle>
        <!-- DELETE -->
        <mat-button-toggle [matTooltip]="'Rimuovi' | translate" #btn3 (change)="btn3.checked = false"
          [disabled]="disabled || !resource" (click)="reset()">
          <mat-icon>delete_outline</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="mt-12" *ngIf="resource">
        <app-resource-content class="ellipsis" fxFlex="100" [content]="resource" [icon]="true"
          [modalPreview]="true"></app-resource-content>
      </div>

    </div>
  </ng-container>

  <div *ngIf="!!defaultValue && defaultValue?.id !== resource?.id" class="mt-12">
    <small>
      <a href="javascript:void(0)" (click)="restoreDefaultValue($event)">{{'Ripristina predefinito' | translate}}</a>
    </small>
  </div>

</div>