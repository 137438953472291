import { NgModule } from '@angular/core';
import { NewUploaderModule } from '../../uploader/uploader.module';
import { GlobalModule } from 'app/modules/global/global.module';
import { DatetimeModule } from '../../datetime/datetime.module';
import { StringModule } from '../../string/string.module';
import { UtilsModule } from '../../utils/ultils.module';
import { FieldImageComponent } from './field-image/field-image.component';
import { FieldVideoComponent } from './field-video/field-video.component';


@NgModule({
    declarations: [
        FieldImageComponent,
        FieldVideoComponent,
    ],
    imports: [
        GlobalModule,
        StringModule,
        DatetimeModule,
        UtilsModule,
        NewUploaderModule,
    ],
    exports: [
        FieldImageComponent,
        FieldVideoComponent,
    ]
})
export class CustomFieldsModule { }
