import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserTabService {
  openInBlank(url: string): void {
      window.open(url, '_blank');
  }
}
