import { FormControl } from '@angular/forms';
import { SelectManager } from 'app/modules//global/classes/select.manager';
import { Resource } from 'app/models/resource.model';
import {
  debounceTime,
  map,
  first,
  takeUntil,
  tap,
  mergeMap,
  filter,
  switchMap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Subject, forkJoin, of } from 'rxjs';
import { ResourceDialogPickerConfig } from 'app/modules/resources/directives/resoruce-dialog-selector/resource-dialog-config';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Inject, ViewChildren, QueryList, OnDestroy, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListItem } from '@angular/material/list';
import { MatPaginator } from '@angular/material/paginator';
import { BrandRepositoryService } from 'app/repositories/brand-repository.service';
import { ResourcesRepositoryService } from 'app/repositories/resources-repository.service';
import { BrandInterface } from 'app/models/brand.model';
import { ScreenInterface } from 'app/modules/system/components/screens/screens.interface';
import {
  LS_RESOURCE_PICKER_BRAND_SEARCH,
  LS_RESOURCE_PICKER_BRAND_RESOURCE_SEARCH,
  LS_RESOURCE_PICKER_BRAND,
  LS_DCONTENT_PICKER_BRAND_RESOURCE_SEARCH,
  LS_RESOURCES_SELECTOR_VERSION,
} from 'app/modules/global/localstorage';
import { DatasetRepositoryService } from 'app/repositories/dataset-repository.service';
import { DatasetInterface } from 'app/models/dataset.model';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ToastrService } from 'ngx-toastr';
import { PAGE_SIZE_OPTIONS, RESOURCE_DURATION_MIN } from 'app/modules/global/constants';
import { PaginatedResponseInterface } from 'app/models/pagination.model';
import { CachedParamsService } from 'app/core/auth/services/cached-params.service';
import { FolderService } from 'app/modules/folder/folder.service';

export interface ResourceParameters {
  brand: BrandInterface;
  size?: {
    width?: string | number;
    height?: string | number;
  };
  screens?: ScreenInterface[];
  pagination: {
    index: number;
    size: number;
  };
  searchKey?: string;
}

@Component({
  selector: 'resources-picker',
  templateUrl: './resources-picker.component.html',
  styleUrls: ['./resources-picker.component.scss'],
  animations: [
    trigger('searchAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
    ]),
  ],
  // encapsulation: ViewEncapsulation.None
})
export class ResourcesPickerComponent implements OnInit, OnDestroy {
  brands: BrandInterface[] = [];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  private readonly brandSearchTermKey = LS_RESOURCE_PICKER_BRAND_SEARCH;
  private _brandSearchTerm: string;
  private readonly resourceSearchTermKey = LS_RESOURCE_PICKER_BRAND_RESOURCE_SEARCH;
  private readonly dcontentSearchTermKey = LS_DCONTENT_PICKER_BRAND_RESOURCE_SEARCH;
  private _resourceSearchTerm: string;

  set brandSearchTerm(term) {
    this._brandSearchTerm = term;
    if (term) {
      localStorage.setItem(this.brandSearchTermKey, term);
    } else {
      localStorage.removeItem(this.brandSearchTermKey);
    }
  }

  get brandSearchTerm(): string {
    if (typeof this._brandSearchTerm === 'undefined') {
      this._brandSearchTerm = localStorage.getItem(this.brandSearchTermKey) || '';
    }
    return this._brandSearchTerm;
  }

  get contentSearchKey(): string {
    return this.config.dynamic ? this.dcontentSearchTermKey : this.resourceSearchTermKey;
  }

  set resourceSearchTerm(value: string) {
    this._resourceSearchTerm = value;
    if (value) {
      localStorage.setItem(this.contentSearchKey, value);
    } else {
      localStorage.removeItem(this.contentSearchKey);
    }
    if (this.brandSelected) {
      if (this.paginator) {
        this.paginator.firstPage();
      }
      // && !this.config.dynamic) {
      this.onParametersChange.next(this.buildResourceParameters(this.brandSelected, 0));
    }
  }
  get resourceSearchTerm(): string {
    if (typeof this._resourceSearchTerm === 'undefined') {
      this._resourceSearchTerm = localStorage.getItem(this.contentSearchKey) || '';
    }
    return this._resourceSearchTerm;
  }

  onParametersChange: Subject<ResourceParameters>;
  resourceReferences: {
    items: Resource[];
    pagination: any;
  };
  filteredResources: Resource[];
  collection: Resource[];
  selectManager: SelectManager;
  loading: boolean;
  loadingBrands: boolean;
  empty: boolean;
  showSelected: boolean = false;
  // brandControl = new FormControl(); // BrandInterface
  brandSearchTermControl = new FormControl(); // BrandInterface
  brandSelected: BrandInterface;
  selectedScreen: any = '';

  resourceTypes = [
    {
      label: this._i18nService.translate(_('Immagini')),
      value: 'images',
      icon: 'image',
    },
    {
      label: this._i18nService.translate(_('Video')),
      value: 'videos',
      icon: 'videocam',
    },
  ];

  selectedResourceTypes: ('images' | 'videos')[] = []; // [] --> all
  selectedResourceTypesDisabled: boolean;

  private _unsubscribeAll: Subject<Object> = new Subject();
  @ViewChildren(MatListItem) brandsComponents: QueryList<MatListItem>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _brandRepositoryService: BrandRepositoryService,
    private _datasetRepositoryService: DatasetRepositoryService,
    private _resourcesRepositoryService: ResourcesRepositoryService,
    public dialogRef: MatDialogRef<ResourcesPickerComponent>,
    private _toastrService: ToastrService,
    private _i18nService: I18nService,
    private _cachedParamsService: CachedParamsService,
    private _folderService: FolderService,
    @Inject(MAT_DIALOG_DATA) public config: ResourceDialogPickerConfig,
  ) {
    this.onParametersChange = new Subject();
    this.collection = [];
  }

  openNewSelector() {
    console.log('openNewSelector');
    this._cachedParamsService.cacheParams(LS_RESOURCES_SELECTOR_VERSION, {
      version: 1,
    });
    this._folderService.openFolderResourcesSelectorDialog({
      options: {
        // HARDCODE
        allowUpload: true,
        // OPTIONS
        allowedSizes: this.config.screens,
        allowedTypes: this.config.types,
        multiple: this.config.multi,
        excludedIdsMap: this.config.excludedIdsMap,
      }
    }).afterClosed().subscribe((resources) => {
      console.log('NEW CLOSED', resources);
      this.dialogRef.close(resources);
    })
  }

  ngOnInit() {
    this.brandSearchTermControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((x, y) => {
          if (this.isObject(y) || x === y) {
            return true;
          }
          return false;
        }),
        mergeMap((term) => {
          // console.log('term change', term);
          this.loadingBrands = true;
          this.brandSearchTerm = term;
          if (term) {
            return this._brandRepositoryService.fullSearch(term, 15);
          } else {
            return of([]);
          }
        }),
        tap(() => {
          this.loadingBrands = false;
        }),
      )
      .subscribe((brands) => {
        // console.log('CHECK BRAND EXISTANCE');
        if (!this.brandSelected) {
          // console.log('IF CACHED BRAND ID');
          const cachedBrandId = localStorage.getItem(LS_RESOURCE_PICKER_BRAND);
          if (cachedBrandId) {
            const foundBrand = this.brandIdInList(cachedBrandId, brands);
            if (foundBrand) {
              // console.log('CACHED BRAND IS INSIDE CACHED TERM LIST');
              this.selectBrand(foundBrand);
              this.scrollTobrand(cachedBrandId);
            }
          }
        } else {
          // console.log('bround exists');
          const foundBrand = this.brandIdInList(this.brandSelected.id, brands);
          if (!foundBrand) {
            // console.log('should place in top coz not in list');
            brands.unshift(this.brandSelected);
          }
        }
        this.brands = brands;
        // console.log('brands list is', this.brands);
      });

    // console.log('IF CACHED SEARCH TERM, GET LIST');
    if (this.brandSearchTerm) {
      this.brandSearchTermControl.setValue(this.brandSearchTerm);
    } else {
      // console.log('NO CACHED SEARCH TERM, GET CACHED BRAND DIRECTLY IF EXISTS');
      this.getCachedBrand();
    }

    //When page changes
    this.paginator.page.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.onParametersChange.next(this.buildResourceParameters(this.brandSelected));
    });

    //When resources parameters changes
    this.onParametersChange
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        filter((parameters) => !!parameters.brand),
        tap((parameters) => {
          this.loading = true;
        }),
        mergeMap((parameters) => {
          // console.log('...LOADING RESOURCES', parameters);
          if (!this.config.dynamic) {
            // GET RESOURCES
            return this.buildResourcesRequest(parameters);
          } else {
            // GET DATASETS
            return this.buildDatasetsRequest(parameters);
          }
        }),
        map((response) => {
          console.log('map response', response);
          if (!this.config.dynamic) {
            // LIST RESOURCES
            return this.buildResourcesResponse(<PaginatedResponseInterface<Resource>>response);
          } else {
            // LIST DATASETS
            return this.buildDatasetsResponse(<PaginatedResponseInterface<DatasetInterface>>response);
          }
        }),
        // FILTER BY EXCLUDED IDS
        // UPDATE: preferred "disable" aproach
        // map((resources: PaginatedResponseInterface<Resource | DatasetInterface>) => {
        //   if (!!this.config.excludedIdsMap) {
        //     resources.items = resources.items.filter(v => {
        //       return !this.config.excludedIdsMap[v.id];
        //     });
        //   }
        //   return resources;
        // }),
      )
      .subscribe((resources: any) => {
        this.loading = false;
        this.resourceReferences = resources;
      });

    this.selectManager = new SelectManager(this.config.multi);

    if (Array.isArray(this.config.types) && this.config.types.length) {
      this.selectedResourceTypes = this.config.types;
      this.selectedResourceTypesDisabled = true;
    }
  }

  scrollTobrand(id) {
    this.brandsComponents.changes.pipe(first()).subscribe(() => {
      const matBrandComponent: MatListItem = this.brandsComponents.find((element) => {
        return element._getHostElement().getAttribute('brand_id') === id;
      });
      setTimeout(() => {
        matBrandComponent._getHostElement().scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
      });
    });
  }

  getCachedBrand() {
    const cachedBrandId = localStorage.getItem(LS_RESOURCE_PICKER_BRAND);
    if (cachedBrandId) {
      this._brandRepositoryService
        .get(cachedBrandId)
        .pipe(first())
        .subscribe((brand) => {
          if (brand) {
            brand.thumb = brand.logo ? brand.logo.thumb : null;
            this.brands = [brand];
            this.selectBrand(brand);
          }
        });
    }
  }

  brandIdInList(id, list) {
    return list.find((brand) => {
      return brand.id === id;
    });
  }

  isObject(value) {
    return value !== null && typeof value === 'object';
  }

  buildDatasetsResponse(response: PaginatedResponseInterface<DatasetInterface>) {
    return response;
  }

  buildResourcesResponse(response: PaginatedResponseInterface<Resource>) {
    return response;
  }

  buildDatasetsRequest(parameters) {
    const conf = {
      page: parameters.pagination.index,
      count: parameters.pagination.size,
      query: parameters.searchKey || '',
      // ALWAYS SEND SCREEN AS AN ARRAY
      sizes: JSON.stringify([
        {
          width: parameters.size.width || '*',
          height: parameters.size.height || '*',
        },
      ]),
    };

    if (this.hasMultipleScreens) {
      // MULTIPLE SCREENS ARRAY
      conf.sizes = JSON.stringify(
        parameters.screens.map((screen) => {
          return {
            width: screen.fit ? '*' : screen.width,
            height: screen.fit ? '*' : screen.height,
          };
        }),
      );
    }

    return this._datasetRepositoryService.findAllByBrandPaginated(parameters.brand.id, conf);
  }

  buildResourcesRequest(parameters) {
    const conf = {
      page: parameters.pagination.index,
      count: parameters.pagination.size,
      query: parameters.searchKey || '',
      types: parameters.types,
      // ALWAYS SEND SCREEN AS AN ARRAY
      sizes: JSON.stringify([
        {
          width: parameters.size.width || '*',
          height: parameters.size.height || '*',
        },
      ]),
    };

    console.log('buildResourcesRequest', this.hasMultipleScreens, conf);

    if (this.hasMultipleScreens) {
      // MULTIPLE SCREENS ARRAY
      conf.sizes = JSON.stringify(
        parameters.screens.map((screen) => {
          return {
            width: screen.fit ? '*' : screen.width,
            height: screen.fit ? '*' : screen.height,
          };
        }),
      );
    }

    return this._resourcesRepositoryService.findAllByBrandPaginated(parameters.brand.id, conf);
  }

  onResourceTypeChange(event) {
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.onParametersChange.next(this.buildResourceParameters(this.brandSelected, 0));
  }

  displayFn(brand?: BrandInterface): string | undefined {
    return brand ? brand.name : undefined;
  }

  get hasScreenArray() {
    return Array.isArray(this.config.screens) && this.config.screens.length > 0;
  }

  get hasMultipleScreens() {
    return this.hasScreenArray && this.config.screens.length > 1;
  }

  screenChanged() {
    this.onParametersChange.next(this.buildResourceParameters(this.brandSelected, 0));
  }

  buildResourceParameters(brand: BrandInterface, pageIndex?: number) {
    let screens;

    if (this.hasScreenArray) {
      if (this.hasMultipleScreens) {
        screens = this.config.screens;
        if (this.selectedScreen) {
          screens = [this.selectedScreen];
        }
      } else {
        this.config.width = this.config.screens[0].width;
        this.config.height = this.config.screens[0].height;
      }
    }

    return {
      brand,
      size: {
        width: this.config.width,
        height: this.config.height,
      },
      types: JSON.stringify(this.selectedResourceTypes),
      screens: screens,
      pagination: {
        index: (pageIndex || this.paginator.pageIndex) + 1,
        size: this.paginator.pageSize,
      },
      searchKey: this.resourceSearchTerm,
    };
  }

  brandAutocompleteChange(event: MatAutocompleteSelectedEvent) {
    console.log('eventAutocompleteChange', event);

    if (event && event.option && event.option.value) {
      if (this.paginator) {
        this.paginator.firstPage();
      }
      this.brandSearchTermControl.patchValue(event.option.value.name, {
        onlySelf: true,
        emitEvent: false,
      });
      this.selectBrand(event.option.value);
    }
  }

  selectBrand(brand) {
    console.log('resourcepicker: Brand Changed', brand);
    if (this.paginator) {
      this.paginator.firstPage();
    }
    if (!brand || !this.brandSelected || brand.id !== this.brandSelected.id) {
      // this.brandSelected = brand;
      this.brandSelected = brand;
    }
    if (this.brandSelected) {
      // SAVE NEW BRAND VALUE
      localStorage.setItem(LS_RESOURCE_PICKER_BRAND, this.brandSelected.id);
      this.onParametersChange.next(this.buildResourceParameters(this.brandSelected, 0));
    } else {
      localStorage.removeItem(LS_RESOURCE_PICKER_BRAND);
    }
  }

  select(resource: Resource) {
    if (resource.type !== 'videos' || parseFloat(<string>resource.metadata.duration) >= RESOURCE_DURATION_MIN) {
      this.collection = this.selectManager.select(resource);
    } else {
      this._toastrService.warning(
        this._i18nService.translate(_('Durata minima richiesta: {seconds} secondi'), {
          seconds: RESOURCE_DURATION_MIN,
        }),
      );
    }
  }

  isSelected(resource: Resource) {
    return this.selectManager.isSelected(resource);
  }

  clear() {
    this.collection = this.selectManager.clear();
    this.showSelected = false;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
