import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupInterface } from 'app/models/group.model';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { ToastrService } from 'ngx-toastr';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-groups-edit-dialog',
  templateUrl: './groups-edit-dialog.component.html',
  styleUrls: ['./groups-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupsEditDialogComponent {
  /**
   * Constructor
   *
   * @param {MatDialogRef<GroupsEditComponent>} dialogRef
   */
  constructor(
    public matDialogRef: MatDialogRef<GroupsEditDialogComponent>,
    private _toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { groups: FormControl; name: string },
    private _i18nService: I18nService,
  ) {}

  get groups() {
    return this.data && this.data.groups;
  }

  get name() {
    return this.data && this.data.name;
  }

  save() {
    FormUtils.validateFormField(this.groups);

    console.log('save', this.groups);

    if (this.groups.valid) {
      this.matDialogRef.close(<GroupInterface[]>this.groups.value);
    } else {
      this._toastrService.error(this._i18nService.translate(_('Compila correttamente tutti i campi')));
    }
  }
}
