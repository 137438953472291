<ng-template #otherActionsTpl let-item="item" let-index="index" let-component="component">
  <ng-container *ngIf="isAdmin">
    <!-- GROUPS & ROLES -->
    <button type="button" class="ml-12 app-icon-btn-sm" [matTooltip]="'Visibilità Gruppi' | translate"
      [matBadge]="(item.value.groups?.length + item.value.roles?.length) || ''" matBadgeColor="accent" mat-icon-button
      (click)="openGroupsRolesDialog($event, item, index)">
      <mat-icon aria-label="Add groups and roles">group</mat-icon>
    </button>
  </ng-container>
</ng-template>

<app-list-options fxFlex="calc(50% - 16px)" [formArray]="group.get('templateOptions').get('options')"
  [otherActionsTpl]="otherActionsTpl" [additionalKeys]="['groups', 'roles']"></app-list-options>