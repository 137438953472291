import { mimes } from '../classes/file-types';
import { FileUploaderOptions } from 'ng2-file-upload';
import { Resource } from 'app/models/resource.model';

export interface S3Policy {
  policy: string;
  signature: string;
  key: string;
}

// TODO: remove this
export interface S3UploadOptions extends FileUploaderOptions {
  notPrivate?: boolean;
  resource?: Resource;
}

export const defaultS3UploadOptions: FileUploaderOptions = {
  allowedMimeType: [].concat(mimes.videos, mimes.images),
  autoUpload: true
};

export const imageS3UploadOptions: FileUploaderOptions = {
  allowedMimeType: mimes.images,
  maxFileSize: 5 * 1024 * 1024, //5MB
  autoUpload: true
};

export const videoS3UploadOptions: FileUploaderOptions = {
  allowedMimeType: mimes.videos,
  autoUpload: true
};

export const documentS3UploadOptions: FileUploaderOptions = {
  allowedMimeType: mimes.documents,
  autoUpload: true
};
