import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandInterface } from 'app/models/brand.model';
import { PaginatedResponse } from 'app/models/pagination.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BrandRepositoryService {
  constructor(private _http: HttpClient) { }

  /**
   *
   *
   * @returns {Observable<BrandInterface[]>}
   * @memberof BrandRepositoryService
   */
  getAll(): Observable<BrandInterface[]> {
    return this._http.get<BrandInterface[]>('brands/all');
  }

  /**
   *
   *
   * @param {(HttpParams | any)} [_params={}]
   * @returns {Observable<PaginatedResponse<BrandInterface>>}
   * @memberof BrandRepositoryService
   */
  findAll(_params: HttpParams | any = {}): Observable<PaginatedResponse<BrandInterface>> {
    return this._http.get<PaginatedResponse<BrandInterface>>('brands', {
      params: _params,
    });
  }

  /**
   *
   *
   * @returns {Observable<BrandInterface[]>}
   * @memberof BrandRepositoryService
   */
  fullSearch(q: string, count: any = 5): Observable<any> {
    return this._http
      .get<any>('brands', {
        params: {
          count: '' + count,
          sorting: 'name',
          direction: 'asc',
          filters: JSON.stringify([
            {
              field: 'name',
              type: 'search',
              value: q,
            },
          ]),
        },
      })
      .pipe(
        map((response): BrandInterface[] => {
          return response.items;
        }),
      );
  }

  /**
   *
   *
   * @param {string} q
   * @returns {Observable<BrandInterface[]>}
   * @memberof BrandRepositoryService
   */
  search(q: string): Observable<BrandInterface[]> {
    if (typeof q !== 'string' || !q.trim()) return of([]);
    return this._http.get<BrandInterface[]>(`brands/${encodeURIComponent(q.trim())}/search`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<BrandInterface>}
   * @memberof BrandRepositoryService
   */
  get(id: string): Observable<BrandInterface> {
    return this._http.get<BrandInterface>(`brands/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<BrandInterface>}
   * @memberof BindRepositoryService
   */
  delete(id: string): Observable<BrandInterface> {
    return this._http.delete<BrandInterface>(`brands/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<BrandInterface>}
   * @memberof BindRepositoryService
   */
  update(id: string, data: BrandInterface): Observable<any> {
    return this._http.put(`brands/${id}`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<BrandInterface>}
   * @memberof BindRepositoryService
   */
  create(data: BrandInterface): Observable<any> {
    return this._http.post(`brands`, data);
  }
}
