import { GrantService } from './grant.service';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { User, UserInterface } from 'app/models/user.model';
import { UserRole, Roles } from 'app/models/user-role';
import { AuthenticationService } from './authentication.service';
import { Subject, Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { AuthRepositoryService } from 'app/repositories/auth-repository.service';
import { Group } from 'app/models/group.model';
import { catchError, first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LS_GLOBALS_TIME } from 'app/modules/global/localstorage';
import { TimepickerFormatEnum } from 'app/modules/common/datetime/datetime.interface';
import { CachedParamsService } from './cached-params.service';

/**
 * Current user
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUser: UserInterface;
  onRefresh: Subject<UserInterface>;
  /** constructor */
  constructor(
    private authenticationService: AuthenticationService,
    private grantService: GrantService,
    private _authRepositoryService: AuthRepositoryService,
    private _router: Router,
    private _toastr: ToastrService,
    private _i18nService: I18nService,
    private _cachedParamsService: CachedParamsService,
  ) {
    this.onRefresh = new Subject<UserInterface>();
    if (this.authenticationService.hasToken()) {
      // GET USER FROM LS
      if (this.hasUser()) {
        this.loadUserFromLocalStorage();
      }
      // GET REMOTE USER DATA
      this.loadUserFromRemote().subscribe();
    }
  }

  loadUserFromLocalStorage() {
    // console.log('auth loadUserFromLocalStorage')
    // console.log('loadUserFromLocalStorage');
    this.currentUser = this.getUser();
    // TARAS: use cached permissions to allow current url position
    // es. /systems/123123121312
    // otherwise it always redirects to /dashboard because remote permissions
    // are not available yet
    this.grantService.permissions.next({ type: 'local', grants: this.currentUser.grants });
  }

  hasUser() {
    return this.getUser() !== null;
  }

  getUser(): UserInterface {
    const cachedUser = JSON.parse(localStorage.getItem('currentUser'));
    return !!cachedUser && new User(cachedUser);
  }

  /**
   * Get user avatar, defaults to gravatar
   */
  getAvatarUrl(): string {
    if (this.currentUser.avatar && this.currentUser.avatar.publicAvailableSource) {
      return this.currentUser.avatar.publicAvailableSource;
    }
    return 'assets/images/fluid-user.png';
  }

  getId(): string {
    return this.currentUser?.id;
  }

  getRoles(): string[] {
    return this.currentUser?.roles as any[] || [];
  }

  getGroups(): Group[] {
    return this.currentUser?.groups || [];
  }

  /**
   * Get full name or username
   */
  getAnyName(): string {
    if (this.currentUser?.fullname.trim() !== '') {
      return this.currentUser.fullname;
    }
    return this.currentUser.username;
  }

  getName(): string {
    if (this.currentUser.firstName.trim() !== '') {
      return this.currentUser.firstName;
    }
    return this.currentUser.username;
  }

  /**
   * Returns true if the uses has the ADMIN role
   */
  isAdmin(user: UserInterface = this.currentUser): boolean {
    // return false;
    return this.hasRole(Roles.ADMIN, user);
  }

  /**
   * Returns true if the uses has a role
   */
  hasRole(role: UserRole, user: UserInterface = this.currentUser): boolean {
    return this.grantService.isGranted(user, role);
  }

  /**
   * Returns true if the uses has roles
   */
  hasRoles(roles: UserRole[]): boolean {
    return roles.some((role) => {
      return this.hasRole(role);
    });
  }

  /**
   *
   * @returns boolean
   * @memberof CurrentUserService
   */
  isEnabled(): boolean {
    return this.currentUser?.enabled;
  }

  /**
   * @deprecated
   * @return String
   */
  getAccessToken(): string {
    return localStorage.getItem('accessToken');
  }

  impersonateUser(username: string, mantainCurrentLocation: boolean = false) {
    localStorage.setItem('userImpersonated', username);
    localStorage.setItem('masterUser', localStorage.getItem('currentUser'));
    this.loadUserFromRemote().subscribe((user) => {
      this._toastr.success(this._i18nService.translate(_(`Loggato come`)) + `: ${this.getAnyName()}`);
      if (!mantainCurrentLocation) {
        location.href = '/dashboard';
      }
    });
  }

  isImpersonatedUser() {
    return !!localStorage.getItem('userImpersonated');
  }

  logoutImpersonatedUser() {
    localStorage.removeItem('userImpersonated');
    localStorage.removeItem('masterUser');
    this.loadUserFromRemote().subscribe((user) => {
      location.href = '/users';
    });
  }

  getMasterUser(): UserInterface {
    return JSON.parse(localStorage.getItem('masterUser'));
  }

  loadUserFromRemote(): Observable<User> {
    return this._authRepositoryService.getRemoteUserMe()
      .pipe(
        first(),
        tap((user) => {
          // console.log('auth loadUserFromRemote', user);
          // SAVE USER IN LOCAL STORAGE
          localStorage.setItem('currentUser', JSON.stringify(new User(user)));
          // SET USER
          this.currentUser = new User(user);
          // SET USER PERMISSIONS
          this.grantService.permissions.next({ type: 'remote', grants: user.grants });
          // EMIT EVENT
          this.onRefresh.next(this.currentUser);
        },
        )
      );
  }

  get timeFormat() {
    if (!this.isAdmin()) {
      return TimepickerFormatEnum.h24;
    }
    const userGlobalsTime = this._cachedParamsService.getCachedParams(LS_GLOBALS_TIME);
    let format = userGlobalsTime.format;
    if (!format) {
      if (this._i18nService.currentLanguageCode === 'en') {
        format = TimepickerFormatEnum.h12;
      } else {
        format = TimepickerFormatEnum.h24;
      }
      // this._cachedParamsService.cacheParams(LS_GLOBALS_TIME, {
      //   ...userGlobalsTime,
      //   format
      // });
    }
    return format;
  }

  setTimeFormat(value: TimepickerFormatEnum) {
    if (!this.isAdmin()) {
      return;
    }
    const userGlobalsTime = this._cachedParamsService.getCachedParams(LS_GLOBALS_TIME);
    this._cachedParamsService.cacheParams(LS_GLOBALS_TIME, {
      ...userGlobalsTime,
      format: value
    });
  }
}
