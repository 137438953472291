<!-- <app-autocomplete fxFlex="100" [formControl]="control" [list]="customerList" [placeholder]="placeholder"
  [required]="required" [disableControl]="disabled" [delay]="200" [minChars]="2" [hint]="hint" [keepPrevSearch]="true"
  [showFullList]="true" prop="name" (changeSelection)="onCustomerChange($event)"></app-autocomplete> -->

<ng-container *ngIf="isAdmin">
  <app-custom-autocomplete [loading]="loading" [formControl]="control" [placeholder]="'Cerca cliente' | translate"
    [list]="customerList" [loadListOnInit]="true" [required]="required" [disabled]="disabled" [hint]="hint"
    (changeSelection)="onCustomerChange($event)">
  </app-custom-autocomplete>
</ng-container>

<ng-container *ngIf="!isAdmin">
  <mat-form-field fxFlex="100">
    <mat-select [formControl]="control" [placeholder]="placeholder || ('Seleziona cliente' | translate)"
      [required]="required" [disabled]="disabled" (selectionChange)="onCustomerChange($event.value)">
      <ng-container *ngFor="let customer of customerList">
        <mat-option [value]="customer">{{ customer.name }}</mat-option>
      </ng-container>
    </mat-select>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error>{{ 'Obbligatorio' | translate }}</mat-error>
  </mat-form-field>
</ng-container>