<div id="{{ dcontent.id }}" class="mat-elevation-z2" fxLayout="column" (click)="choose()">
  <header fxFlex="50px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
    class="p-8 cursor-pointer ellipsis">
    <!-- TITLE -->
    <div class="ellipsis" fxLayout="row" fxLayoutAlign="flex-start center">
      <mat-icon class="mr-8">unarchive</mat-icon>
      <div class="ellipsis" fxFlex fxLayout="column">
        <div class="ellipsis" [matTooltip]="dcontent.name" matTooltipClass="app-tooltip-wide"
          [matTooltipPosition]="'above'">
          {{ dcontent.name }}
        </div>
        <small class="subheader grey-400-fg">
          <app-date [date]="dcontent.updated_at" formatter="short"></app-date>
        </small>
      </div>
    </div>
  </header>
  <footer fxFlex="40px" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
    <div fxLayout fxFlex>
      <div fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center" class="pl-8">
        <!-- DURATION -->
        <ng-container>
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <mat-icon class="app-icon-xs grey-400-fg mr-4">timer</mat-icon>
            <small>
              <app-duration [time]="dcontent.duration" [options]="{ hideEmpty: true }"></app-duration>
            </small>
          </div>
        </ng-container>
        <!-- SIZE -->
        <ng-container *ngIf="dcontent.template">
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <small>{{ dcontent.template.width }}x{{ dcontent.template.height }}px</small>
          </div>
        </ng-container>
        <!-- ACTION -->
        <ng-container>
          <a mat-icon-button [matTooltip]="'Val al Set di Dati' | translate" [routerLink]="'/datasets/' + dcontent.id"
            target="_blank" (click)="$event.stopPropagation()">
            <mat-icon>link</mat-icon>
          </a>
        </ng-container>
      </div>
    </div>
  </footer>
</div>