import { Component, Input } from '@angular/core';
import { UPLOADER_FILE_ERROR } from '../../uploader.interface';

/**
 *
 *
 * @export
 * @class UploaderQueueInvalidFileErrorComponent
 * @description Full name formatter from User entity object or from 'name' and 'surname'
 * @implements {OnInit}
 */
@Component({
  selector: 'app-uploader-queue-invalid-file-error',
  templateUrl: './uploader-queue-invalid-file-error.component.html',
  styleUrls: ['./uploader-queue-invalid-file-error.component.scss']
})
export class UploaderQueueInvalidFileErrorComponent {

  @Input() error: UPLOADER_FILE_ERROR; // enum type

  UPLOADER_FILE_ERROR = UPLOADER_FILE_ERROR;

  constructor() {}
}
