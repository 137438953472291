import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharIndexComponent } from './char-index/char-index.component';
import { FullnameComponent } from './fullname/fullname.component';
import { NameExtComponent } from './name-ext/name-ext.component';
import { AnimatedCounterComponent } from './animated-counter/animated-counter.component';
import { PriceComponent } from './price/price.component';
import { FormatComponent } from './format/format.component';
import { ResolutionComponent } from './resolution/resolution.component';
import { FormatNumberPipe } from './format-number.pipe';
import { FpsComponent } from './fps/fps.component';
import { DiffComponent } from './diff/diff.component';
// import { MatIconModule } from '@angular/material';
import { GlobalModule } from 'app/modules/global/global.module';
import { SignalHealthIconComponent } from './signal-health-icon/signal-health-icon.component';
import { FileSizeComponent } from './filesize/filesize.component';

@NgModule({
  declarations: [
    CharIndexComponent,
    FullnameComponent,
    NameExtComponent,
    AnimatedCounterComponent,
    FormatComponent,
    PriceComponent,
    ResolutionComponent,
    FpsComponent,
    FormatNumberPipe,
    DiffComponent,
    SignalHealthIconComponent,
    FileSizeComponent,
  ],
  imports: [GlobalModule],
  exports: [
    CharIndexComponent,
    FullnameComponent,
    NameExtComponent,
    AnimatedCounterComponent,
    FormatComponent,
    PriceComponent,
    ResolutionComponent,
    FpsComponent,
    FormatNumberPipe,
    DiffComponent,
    SignalHealthIconComponent,
    FileSizeComponent,
  ],
})
export class StringModule { }
