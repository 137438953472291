import * as _ from 'lodash';

export class SelectManager {
  multi: boolean;
  list: any[];
  /**
   *Creates an instance of SelectManager.
   * @param {boolean} [multi=false]
   * @memberof SelectManager
   */
  constructor(multi: boolean = false) {
    this.multi = multi;
    this.list = [];
  }
  /**
   *
   *
   * @param {*} item
   * @returns {any[]}
   * @memberof SelectManager
   */
  select(item: any): any[] {
    if (this.multi) {
      this.isSelected(item) ? this.remove(item) : this.add(item);
    } else {
      this.set(item);
    }
    return this.list;
  }
  /**
   *
   *
   * @param {*} item
   * @returns
   * @memberof SelectManager
   */
  isSelected(item: any) {
    return this.list.find((_item: any) => {
      return _item.id === item.id;
    });
  }
  /**
   *
   *
   * @param {*} item
   * @memberof SelectManager
   */
  add(item: any) {
    this.list.push(item);
  }
  /**
   *
   *
   * @param {*} item
   * @memberof SelectManager
   */
  set(item: any) {
    this.list = Array(item);
  }
  /**
   *
   *
   * @param {*} item
   * @memberof SelectManager
   */
  remove(item: any) {
    _.remove(this.list, _item => {
      return _item.id === item.id;
    });
  }
  /**
   *
   *
   * @returns
   * @memberof SelectManager
   */
  clear() {
    this.list = [];
    return this.list;
  }
}
