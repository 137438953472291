import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TicketInterface } from 'app/models/ticket.model';

@Injectable({
  providedIn: 'root'
})
export class TicketRepositoryService {

  constructor(private _http: HttpClient) { }

  create(data: TicketInterface): Observable<TicketInterface> {
    return this._http.post<TicketInterface>(`supports/creates/tickets`, data);
  }
}

