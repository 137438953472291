import { FileItem, FileLikeObject } from 'ng2-file-upload';
import { S3ResourceService } from '../service/s3.resource.service';
import { Resource } from 'app/models/resource.model';
import { S3UploadOptions } from './policy-interface';
import { Observable, Subject } from 'rxjs';

export class S3FileItem extends FileItem {

  loadResourceObservable$: Observable<Resource>;

  resourceSubject$: Subject<ResourceFromS3FileResponse> = new Subject<ResourceFromS3FileResponse>();

  file: FluidFileLikeObject;
  hasResource: boolean = false;
  isResourceError: boolean = false;
  additionalParameter?: { [key: string]: any } = {};

  constructor(
    protected uploader,
    protected some: File,
    protected options: S3UploadOptions,
    private _S3ResourceService: S3ResourceService
  ) {
    super(uploader, some, options);

    this.file = new FluidFileLikeObject(this.file.rawFile);
  }

  appendAdditionalParamenter(key: string, value: string) {
    this.additionalParameter[key] = value;
  }

  callResource(resource?: Resource, uploadCallback?: (item: S3FileItem) => Observable<Resource>) {

    // DEFAULT UPLOAD ORPHAN
    let observable = this._S3ResourceService.createResourceFromS3FileItem(
      this, {
      noWorkflow: false,
      notPrivate: this.uploader.options.notPrivate
    },
      resource
    );

    if (typeof uploadCallback === 'function') {
      observable = uploadCallback(this);
    }

    observable.subscribe(response => {
      this.hasResource = true;
      if (
        (!response.metadata || Array.isArray(response.metadata)) &&
        this.file.customRawFile && this.file.customRawFile.customData
      ) {
        response.metadata = {
          width: this.file.customRawFile.customData.width,
          height: this.file.customRawFile.customData.height
        };
      }
      this.resourceSubject$.next({ resource: response });
    }, errors => {
      this.isResourceError = true;
      this.resourceSubject$.next({ errors: errors });
    });
  }

  loadResource(resource?: Resource, uploadCallback?: (item: S3FileItem) => Observable<Resource>): Observable<ResourceFromS3FileResponse> {
    this.isResourceError = false;
    this.hasResource = false;
    this.callResource(resource, uploadCallback);
    return this.resourceSubject$;
  }
}

export interface ResourceFromS3FileResponse {
  resource?: Resource;
  errors?: any;
}

export class CustomFile extends File {
  customData?: {
    [key: string]: any;
  };
}

export class FluidFileLikeObject extends FileLikeObject {
  error: any;
  rawFile: string;
  get customRawFile(): CustomFile {
    return <any>this.rawFile;
  }
}
