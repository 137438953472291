import { RersourceConstraint } from './../../resources/resource.options.services';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ResolveConstraintService {
  getFromExt(text: string): RersourceConstraint {
    switch (text) {
      case 'webm':
        return { codec: ['vp8', 'vp9'], container: 'webm' };
      case 'mp4':
        return { codec: ['h264'], container: 'mp4' };
    }
  }
}

