import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  DailyPriceInterface,
  ListOptionInterface,
  ListOptionOptionsInterface,
  ListOption,
  ListOptionOptions,
} from './inputs.interface';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Injectable({
  providedIn: 'root',
})
export class InputsService {
  constructor() { }

  buildDailyPrices(items: DailyPriceInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildDailyPrice(item));
    });
  }

  buildDailyPrice(val: DailyPriceInterface = <DailyPriceInterface>{}) {
    return {
      days: new FormControl(val.days, [Validators.required, Validators.min(0)]),
      amount: new FormControl(val.amount, [Validators.required, Validators.min(0)]),
    };
  }

  buildListOptions(
    items: ListOptionInterface[] = [],
    options?: ListOptionOptionsInterface,
    uniqueKey?: boolean,
    uniqueValue?: boolean,
    additionalKeys?: string[],
  ) {
    return items.map((item) => {
      return new FormGroup(this.buildListOption(item, options, uniqueKey, uniqueValue, additionalKeys));
    });
  }

  buildListOption(
    item?: ListOptionInterface,
    options?: ListOptionOptionsInterface,
    uniqueKey?: boolean,
    uniqueValue?: boolean,
    additionalKeys?: string[],
  ) {
    item = new ListOption(item);
    options = new ListOptionOptions(options);
    const obj = {};
    obj[options.keyField] = new FormControl(item[options.keyField], uniqueKey ? RxwebValidators.unique() : []);
    obj[options.valueField] = new FormControl(item[options.valueField], uniqueValue ? RxwebValidators.unique() : []);
    if (Array.isArray(additionalKeys)) {
      for (let i = 0; i < additionalKeys.length; i++) {
        const key = additionalKeys[i];
        obj[key] = new FormControl(item[key]);
      }
    }

    console.log('buildListOption', additionalKeys, obj);

    return obj;
  }
}
