import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject, of, Observable, Subscription } from 'rxjs';
import { takeUntil, startWith, debounceTime, switchMap, tap, distinctUntilChanged } from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AutocompleteService } from '../autocomplete.service';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Directive({
  selector: '[appAutocompleteOption]',
})
export class AutocompleteOptionDirective {
  constructor(public template: TemplateRef<any>) { }
}

/**
 *
 *
 * @export
 * @class AutocompleteComponent
 * @description AutocompleteComponent to draw <mat-select> with autocomplete filter
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() logs: boolean = false;

  @ContentChild(AutocompleteOptionDirective, {
    static: true,
  })
  autocompleteOption: AutocompleteOptionDirective;

  @ViewChild(MatAutocompleteTrigger, {
    read: MatAutocompleteTrigger
  })
  matAutocompleteTrigger: MatAutocompleteTrigger;

  // ARRAY | FN (x) => Observable | Observable
  @Input() list: any;
  @Input() decorateProp: string;
  @Input() prop: string;
  @Input() mapBy: string;
  @Input() label: string;
  @Input() placeholder: string = this._i18nService.translate(_(`Seleziona un'opzione`));

  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  @Input() set loading(value: boolean) {
    this.setLoading(value);
  }
  protected _loading = false;

  @Input() set preselectFirstOption(value: boolean) {
    this._preselectFirstOption = coerceBooleanProperty(value);
    this.autoActiveFirstOption = coerceBooleanProperty(value);
  }
  protected _preselectFirstOption = false;

  @Input() set preselectMatchedOption(value: boolean) {
    this._preselectMatchedOption = coerceBooleanProperty(value);
  }
  protected _preselectMatchedOption = false;

  @Input() set showFullList(value: boolean) {
    this._showFullList = coerceBooleanProperty(value);
  }
  protected _showFullList = false;

  @Input() listTitle: string;
  @Input() inputType: string = 'text'; // text || number
  @Input() sort: boolean = true;
  @Input() delay: number = 400;
  @Input() caseSensitive: boolean = false;
  @Input() minChars: number = 2;
  @Input() keepPrevSearch: boolean;
  @Input() clearable: boolean = true;
  @Input() allowNewValue: boolean = false;
  @Input() hint: string;
  @Input() floatLabel: string;
  @Input() filterable: boolean = true;
  @Input() filterRule: string = 'contains'; // contains, startsWith
  @Output() changeSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeFilteredList: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchChange: EventEmitter<any> = new EventEmitter<any>();

  autocompleteControl: FormControl = new FormControl('');
  filteredItems: any[] = [];
  autoActiveFirstOption = false;

  component = this;

  private _forceChange = false;
  private _validationRequired = false;
  private _panelOpenRequired = false;
  private _listRefreshRequired = false;
  private _unsubscribeAll: Subject<any>;
  private _value: any = null;
  protected _disabled = false;
  get disabled() {
    return this._disabled;
  }
  protected _required = false;
  get required() {
    return this._required;
  }
  private _firstSyncValidation = true;

  // LOCAL FILTER FN
  @Input() filterFn: (term: string, list: any[]) => any[];
  // LIST FILTER FN
  @Input() filterItemsFn: (filteredItems: any[]) => any[];
  // LIST SORT FN
  @Input() sortFn: (a: any, b: any) => number;
  // VALUE DECORATOR FN
  @Input() decorateFn: (values: any) => any;
  // VALUE VALIDATOR FN
  @Input() validateFn: (value: any, filteredItems: any[]) => any;
  // VALUE VIEW TRANSLATOR FN
  @Input() translateFn: (value: any) => any = (v) => v;

  translate: (v: any) => any;
  decorate: (v: any) => any;

  subscriber: Subscription;
  panelCloseSubscriber: Subscription;

  constructor(
    protected _autocompleteService: AutocompleteService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
  ) {
    this._unsubscribeAll = new Subject();

    this.translate = (item: any) => {
      if (this.hasTranslator) {
        if (!!this.prop) {
          return item && this.prop ? item[this.prop] : item;
        }
        return this.translateFn(item);
      }
      return item;
    };

    this.decorate = (item: any) => {
      if (this.hasDecorator) {
        if (!!this.decorateProp) {
          return item && this.decorateProp ? item[this.decorateProp] : item;
        }
        return this.decorateFn(item);
      }
      return item;
    };
  }

  private _onChange = (event: any) => { };
  private _onTouched: any = () => { };

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(newValue) {
    if (typeof newValue !== 'undefined') {
      this.log('::::::::::::: writeValue', newValue, this._value);
      this._value = newValue;
      this.setInitialViewValue();
    }
  }

  setLoading(value) {
    this._loading = value;
  }

  get loading() {
    return this._loading;
  }

  onBlur(event) {
    if (this.matAutocompleteTrigger.panelOpen) {
      this.autocompleteControl.markAsPristine();
      this.autocompleteControl.markAsUntouched();
    }
    this.blur.emit(this.searchValue);
  }

  onKeyUp(event) {
    console.log('HELLO???', this.searchValue);
    this.searchChange.emit(this.searchValue);
  }

  onClick(event) {
    if (!this.disabled && !this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.openPanel();
    }
  }

  get value() {
    return this._value;
  }

  ngOnInit() {
    // console.log('ngOnInit autocomplete', this);
    this.subscriber = this.autocompleteControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        distinctUntilChanged((x, y) => {
          // this.isObject(x) ||
          const isSameAsPreviousOrInvalid = this._autocompleteService.isObject(y) || x === y;
          const _forceChange = this._forceChange;
          this._forceChange = false;
          this.log(
            '> distinctUntilChanged',
            'this._forceChange',
            _forceChange,
            'isSameAsPreviousOrInvalid?',
            isSameAsPreviousOrInvalid,
            'x',
            x,
            'y',
            y,
          );
          return _forceChange ? false : isSameAsPreviousOrInvalid;
        }),
        tap((value) => {
          this.log('>> PRE LOADING ...<<', this.list);
          if (value && this.hasLoader) {
            this.setLoading(true);
            this.log('>> LOADING ...<<');
          }
        }),
        debounceTime(this._autocompleteService.isStaticList(this.list) ? 0 : this.delay),
        switchMap(() => {
          this.log('> switchMap', this.searchValue);
          if (this.isItemSelected()) {
            this.log('isItemSelected TRUE', this.searchValue);

            if (!this._listRefreshRequired) {
              // keep prev search
              if (this.keepPrevSearch && this.filteredItems.length) {
                this.log('keepPrevSearch TRUE');
                return of(this.filteredItems);
              }
            }
            this._listRefreshRequired = false;
            if (this._showFullList && this._autocompleteService.isStaticList(this.list)) {
              this.log('showFullList && static TRUE');
              return of(this.list);
            }
          } else {
            this.log('isItemSelected FALSE', this.searchValue);
            if (this.value) {
              this.log('clearValue');
              this.updateValue(null, this.searchValue);
            }

            if (this.allowNewValue && this.searchValue) {
              this.log('set searched value', this.searchValue);
              this.updateValue(this.searchValue, this.searchValue);
            }
          }
          this._listRefreshRequired = false;
          let searchText = this.searchValue;

          if (this.inputType === 'text') {
            searchText = searchText.toString().trim();
          }

          if (this._autocompleteService.isStaticList(this.list)) {
            if (searchText) {
              return of(this.filter(searchText, this.list));
            } else {
              return of(this.list);
            }
          }
          if (this._autocompleteService.isCallbackList(this.list)) {
            if (searchText.length >= this.minChars) {
              return this.list(searchText);
            }
          }
          if (this._autocompleteService.isObservableList(this.list)) {
            this._validationRequired = true;
            return this.list;
          }
          return of([]);
        }),
      )
      .subscribe((items: any[] = []) => {
        this.log('> items', items);
        if (this.hasLoader && this.loading) {
          this.setLoading(false);
          this.log('>> END LOADING ...<<');
        }

        this.filteredItems = Array.isArray(items) ? items : [];

        // filter results
        if (typeof this.filterItemsFn === 'function') {
          this.filteredItems = this.filterItemsFn(this.filteredItems);
        }

        // sort
        if (this.sort) {
          this.filteredItems.sort((a, b) => this.sortAlgorithm(a, b));
        }

        this.changeFilteredList.emit(this.filteredItems);

        if (this._validationRequired) {
          this.list = <any>this.filteredItems;
          this._validationRequired = false;
          this.validateAutocompleteControl();
        }

        // refresh view
        if (!this.disabled && this._panelOpenRequired) {
          this._panelOpenRequired = false;
          this.matAutocompleteTrigger.openPanel();
        }

        if (!this._cdr['destroyed']) {
          this._cdr.detectChanges();
        }
      });
  }

  sortAlgorithm(a, b) {
    if (this.sortFn && typeof this.sortFn === 'function') {
      return this.sortFn(a, b);
    }
    let aa = this.translate(a);
    let bb = this.translate(b);

    if (this.inputType === 'number') {
      return aa - bb;
    }

    return aa.localeCompare(bb);
  }

  customValidator() {
    if (this.validateFn && typeof this.validateFn === 'function') {
      return this.validateFn(this.value, this.filteredItems);
    }
    return false;
  }

  filter(term: string, list: any[]) {
    if (this.filterFn && typeof this.filterFn === 'function') {
      return this.filterFn(term, list);
    }
    return this._autocompleteService.isInArray(term, list, {
      rule: this.filterRule,
      caseSensitive: this.caseSensitive,
      translate: this.translate,
    });
  }

  get hasDecorator() {
    return (this.decorateFn && typeof this.decorateFn === 'function') || !!this.decorateProp;
  }

  getValueByDecorateProp(value: any) {
    if (this.hasDecorator && value && Array.isArray(this.list)) {
      const valsMap = this.list.reduce((acc, v) => {
        acc[JSON.stringify(this.decorate(v))] = v;
        return acc;
      }, {});
      this.log('getValueByDecorateProp valsMap', valsMap);
      return valsMap[JSON.stringify(value)];
    }
    return value;
  }

  get hasTranslator() {
    return (this.translateFn && typeof this.translateFn === 'function') || !!this.prop;
  }

  getViewValue(value) {
    return this.translate(this.getValueByDecorateProp(value));
  }

  setInitialViewValue() {
    this.log('setInitialViewValue');
    this._listRefreshRequired = true;
    this.autocompleteControl.setValue(this.getViewValue(this.value));
    if (this.searchValue) {
      this.validateAutocompleteControl();
    }
    // this._cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.log('ngAfterViewInit');

    // VALIDATE ON BLUR OR CLOSE OF AUTOCOMPLETE OPTIONS
    this.panelCloseSubscriber = this.matAutocompleteTrigger.panelClosingActions
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (!this.value) {
          if (this.searchValue && this.filteredItems.length) {
            let newValue;
            if (this._preselectFirstOption) {
              newValue = this.filteredItems[0];
              this.log('set _preselectFirstOption', newValue);
            }
            if (this._preselectMatchedOption) {
              newValue = this.getExistentMatchedOption() || newValue;
              this.log('set preselectMatchedOption', newValue);
            }
            if (newValue) {
              this.log('overwrite value with an automatic one', newValue);
              this.updateValue(this.decorate(newValue));
            }
          }
          this.validateAutocompleteControl();
        } else {
          this.autocompleteControl.markAsDirty();
          this.autocompleteControl.markAsTouched();
        }
      });
  }

  get searchValue() {
    return this.autocompleteControl.value || (this.inputType === 'text' ? '' : null);
  }

  getExistentMatchedOption() {

    if (this.inputType === 'number') {
      return this.filteredItems.find(listItem => {
        return this.searchValue === this.translate(listItem);
      });
    }

    return this._autocompleteService.getExistentMatchedOption(this.searchValue.toString().trim(), this.filteredItems, {
      translate: this.translate,
    });
  }

  validateAutocompleteControl() {
    // this.autocompleteControl.markAsDirty();
    // this.autocompleteControl.markAsTouched();

    this.log('@@@ validateAutocompleteControl');
    if (
      this.loading ||
      this.customValidator() ||
      !this.required ||
      (this.getExistentMatchedOption() && this.isItemSelected()) ||
      this._firstSyncValidation
    ) {
      // reset firstValidation
      if (!this.loading && this._firstSyncValidation) {
        this._firstSyncValidation = false;
      }
      this.log('@@@ VALID');
      this.autocompleteControl.setErrors(null);
    } else {
      if (this.required) {
        this.log('@@@ INVALID');
        if (!this.searchValue) {
          this.autocompleteControl.setErrors({
            required: true,
          });
        } else {
          if (this.allowNewValue) {
            this.log('ALLOW NEW VALUE VALIDATION', this.searchValue);
          } else {
            this.autocompleteControl.setErrors({
              invalidValue: true,
            });
          }
        }
      }
    }
  }

  validate(c: FormControl) {
    if (c && c.touched) {
      this.autocompleteControl.markAsDirty();
      this.autocompleteControl.markAsTouched();
    }

    this.validateAutocompleteControl();
    // if (this.required) {
    //   return this.autocompleteControl.errors;
    // }
    return null;
  }

  ngOnChanges(changes) {
    if (changes.list) {
      if (!changes.list.firstChange) {
        this.filteredItems = [];
        this._forceChange = true;
        // DOWNLOAD NEW LIST AND SHOW SELECTED ITEM IN THE LIST
        this.log('ngOnChanges updateValueAndValidity', changes);
        this.autocompleteControl.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._cdr.detach();
    this.subscriber.unsubscribe();
    this.panelCloseSubscriber.unsubscribe();
  }

  get hasLoader() {
    return this._autocompleteService.isAsyncList(this.list);
  }

  isSameModelViewValue(item = this.value, needle = this.searchValue) {
    // const realItemValue = this.getViewValue(item);
    // this.log('isSameModelViewValue', needle, '===', realItemValue);
    // return this.equals(needle, realItemValue);
    return needle === this.getViewValue(item); // case sensitive
    // return this._autocompleteService.isSameObject(this.getViewValue(item), needle);
  }

  isItemSelected() {
    return this.value && this.isSameModelViewValue(); //!this.searchValue ||
  }

  optionSelected(event: MatAutocompleteSelectedEvent): void {
    this.log('> optionSelected', this.searchValue);
    this.updateValue(this.searchValue);
  }

  remove(): void {
    if (this.clearable) {
      this._panelOpenRequired = true;
      this._listRefreshRequired = true;
      this.updateValue(null);
    }
  }

  updateValue(modelValue: any, viewValue?: any) {
    this.log('> updateValue', 'model:', modelValue, 'view:', viewValue);
    if (typeof viewValue !== 'undefined') {
      this.log('> autocompleteControl value', viewValue);
      // RESET TO NULL IF PROVIDED
      this.autocompleteControl.setValue(viewValue);
    } else {
      this.log('> autocompleteControl value default by model value', this.getViewValue(modelValue));
      // SET STRING VALUE OF CURRENT SELECTION
      this.autocompleteControl.setValue(this.getViewValue(modelValue));
    }

    // CHECK IF IS DIFFERENT VALUE
    if (!this._autocompleteService.isSameObject(this.value, modelValue, this.mapBy)) {
      // console.log('not isSameObject', JSON.stringify(this.value), JSON.stringify(modelValue));
      this._value = modelValue;
      this._onChange(this._value);
      this.changeSelection.emit(this._value);
      this.log(':::: on Change', this._value);
    }
  }

  log(...args) {
    if (this.logs) {
      console.log(...args);
    }
  }
}

export declare type AutocompleteList<T> = (term: string) => Observable<T[]>;
// (term: string) => (Observable<T[]> | T[])
// |
// T[] |
// Observable<T[]>
