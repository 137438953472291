import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { FolderInterface } from 'app/models/folder.model';
import { SystemRepositoryService } from 'app/repositories/system-repository.service';
import { AutocompleteList } from '../autocomplete/autocomplete.component';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FolderRepositoryService } from 'app/repositories/folder-repository.service';
import { filter, map } from 'rxjs/operators';

/**
 *
 *
 * @export
 * @class GeoComponent
 * @description Input address field with map and geolocatiob
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-folder-autocomplete',
  templateUrl: './folder-autocomplete.component.html',
  styleUrls: ['./folder-autocomplete.component.scss'],
})
export class FolderAutocompleteComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() customerId: string;
  @Input() placeholder: string = this._i18nService.translate(_('Cerca una cartella'));
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() hint: string;
  @Input() floatLabel: string;
  @Input() filterList: FolderInterface[] = [];

  @Output() changeSelection: EventEmitter<FolderInterface> = new EventEmitter<FolderInterface>();

  folderList: AutocompleteList<FolderInterface>;

  sub: Subscription;

  loadingFolders: boolean;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _folderRepositoryService: FolderRepositoryService,
    private _i18nService: I18nService,
  ) { }

  ngOnInit() {
    this.folderList = (q: string): Observable<FolderInterface[]> => {
      if (!this.customerId) return of([]);
      return this._folderRepositoryService.search(this.customerId, q).pipe(
        map(results => {
          console.log('results', results);
          return results.filter(r => !this.filterList.map(v => v.id).includes(r.id));
        }),
      );
    };
  }

  onFolderChange(folder: FolderInterface) {
    console.log('>> FolderAutocompleteComponent onFolderChange', folder);
    this.control?.setValue(folder);
    this.changeSelection.emit(this.control.value);
  }

  ngOnChanges(changes) {

  }
}
