<div fxLayout="column" [formGroup]="group">
  <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-xs="16px">
    <!-- OPTIONS WITH GROUPS -->
    <app-field-options-with-groups [group]="group" [groupList]="groupList"></app-field-options-with-groups>
    <!-- REQUIRED -->
    <div class="pt-8 mb-8" fxFlex="calc(50% - 16px)" formGroupName="templateOptions">
      <mat-checkbox name="templateOptions.required" formControlName="required">{{
        'Obbligatorio' | translate
      }}</mat-checkbox>
    </div>
  </div>
</div>