<div class="dialog-content-wrapper" [formGroup]="form">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        {{ 'Trasferimento risorse' | translate }}
      </span>
      <!-- <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button> -->
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-0 m-0" mat-dialog-content>
    <div class="p-24" fxLayout="column">

      <div class="mb-12">
        {{ 'Questa procedura permette di importare le risorse nella nuova sezione Cartelle dalla attuale sezione Caricamento > Brand che nel prossimo futuro verrà deprecata.' | translate }}
      </div>

      <div class="h4 mb-12">
        <strong>{{ 'Trasferisci risorse da' | translate }}</strong>
      </div>

      <!-- GROUPS -->
      <div *ngIf="isAdmin" class="mb-12">
        <app-group-chips fxFlex="100" [placeholder]="'Filtra per gruppi specifici' | translate"
          [control]="form.get('groups')" [disabled]="status !== FolderImportStatusEnum.ready"
          (changeSelection)="onGroupsChange($event)"></app-group-chips>
      </div>

      <!-- SHOW BRAND SELECTOR -->
      <div *ngIf="mode === FolderImportModeEnum.folder" class="mb-12">
        <app-brand-autocomplete [required]="true" [control]="form.get('brand')"
          [disabled]="status !== FolderImportStatusEnum.ready"
          (changeSelection)="onBrandChange($event)"></app-brand-autocomplete>
      </div>

      <!-- BRAND INFO -->
      <div *ngIf="form.get('brand').value" class="mb-24 grey-100-bg p-12 border-radius-4"
        [ngClass]="{ 'loading' : loading }">
        <div fxLayout="row">
          <image-thumb [value]="form.get('brand').value?.thumb" class="mr-8"></image-thumb>
          <div fxLayout="column" fxFlex>
            <div class="app-inputs-label-sm">{{'Brand' | translate}}</div>
            <div class="h3">
              <ng-container *ngIf="form.get('brand').value">
                <span class="font-weight-600">
                  {{ form.get('brand').value?.name }}
                </span>
              </ng-container>
              <ng-container *ngIf="!form.get('brand').value">
                <span class="text-italic">
                  {{ 'Non impostato' | translate }}
                </span>
              </ng-container>
            </div>
            <div>
              {{'risorse disponibili' | translate}}:
              <strong
                [ngClass]="{ 'green-fg': form.get('resourcesCount').value > 0, 'red-fg': form.get('resourcesCount').value === 0 }">{{ form.get('resourcesCount').value || '--' }}</strong>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="status === FolderImportStatusEnum.ready">

        <div *ngIf="form.get('resourcesCount').value === 0" class=" mb-24 p-12 red-100-bg border-radius-4">
          {{ 'Non ci sono risorse disponibili per il trasferimento. Provare da un altro Brand.' | translate }}
        </div>

        <ng-container *ngIf="mode === FolderImportModeEnum.brand">
          <div [ngClass]="{ 'fluid-disabled': loading || form.get('resourcesCount').value === 0 }" class="mb-24">

            <div [hidden]="hasOneCustomer">
              <div class="h4 font-weight-600 mb-8">{{ 'Seleziona il cliente' | translate }}</div>

              <!-- CUSTOMER -->
              <div class="mb-12">
                <app-customer-autocomplete [required]="true" [placeholder]="'Cliente' | translate"
                  [control]="form.get('customer')"
                  (changeSelection)="onCustomerChange($event)"></app-customer-autocomplete>
              </div>
            </div>

            <div class="h4 font-weight-600 mb-8">
              {{ 'Seleziona la cartella di destinazione o creane una nuova' | translate }}
            </div>

            <!-- FOLDER TREE -->
            <div class="mb-12 h-300">
              <app-folder-tree-selector [customerId]="form.get('customer').value?.id" [folderCtrl]="form.get('folder')"
                (folderChange)="onFolderChange($event)"></app-folder-tree-selector>

              <!-- <app-folder-autocomplete [customerId]="data.customer?.id" [control]="form.get('folder')"
              (changeSelection)="onFolderChange($event)"></app-folder-autocomplete> -->
            </div>

          </div>
        </ng-container>

      </ng-container>

      <ng-container
        *ngIf="mode === FolderImportModeEnum.folder || status === FolderImportStatusEnum.importing || status === FolderImportStatusEnum.done">

        <div class="h4 mb-12">
          <strong>{{ 'alla destinazione' | translate }}</strong>
        </div>

        <div class="mb-24 grey-100-bg p-12 border-radius-4" fxLayout="row" fxLayoutAlign="flex-start center">

          <ng-container *ngIf="!hasOneCustomer">
            <div fxFlex fxLayout="column">
              <div class="app-inputs-label-sm">{{ 'Cliente' | translate }}</div>
              <div class="h3">
                <span class="font-weight-600">
                  {{ form.get('customer').value?.name }}
                </span>
              </div>
            </div>

            <mat-icon class="grey-400-fg">double_arrow</mat-icon>
          </ng-container>

          <div fxFlex fxLayout="column" class="text-right">
            <div class="app-inputs-label-sm">{{ 'Cartella' | translate }}</div>
            <div class="h3">
              <span class="font-weight-600">
                {{ form.get('folder').value?.name }}
              </span>
            </div>
          </div>

        </div>
      </ng-container>

      <ng-container *ngIf="status === FolderImportStatusEnum.importing">

        <div class="text-center pt-32">
          <strong>{{ 'Importazione in corso' | translate }}&hellip;<br>{{ 'Attendere la fine del processo' | translate }}</strong>
        </div>
        <div class="h-80 loading"></div>
      </ng-container>

      <ng-container *ngIf="status === FolderImportStatusEnum.done">
        <div class="text-center pt-32">
          <div class="mb-8">
            <mat-icon class="app-icon-lg green-600-fg">check</mat-icon>
          </div>
          <div class="h2 green-fg">{{ 'Operazione completata con successo!' | translate }}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="status === FolderImportStatusEnum.error">
        <div class="text-center pt-32">
          <div class="mb-8">
            <mat-icon class="app-icon-lg red-400-fg">report</mat-icon>
          </div>
          <div class="h2 red-fg">{{ 'Si è verificato un errore. Riprovare più tardi.' | translate }}&hellip;</div>
        </div>
      </ng-container>

    </div>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container *ngIf="status === FolderImportStatusEnum.ready">
      <button mat-button (click)="matDialogRef.close()">
        {{ 'Annulla' | translate }}
      </button>
      <button color="primary" type="button" [disabled]="loading || form.invalid" mat-raised-button
        (clickConfirm)="save()" [options]="{
        message: ('Verranno importate le risorse disponibili dal Brand selezionato. Continuare?' | translate)
      }">
        {{ 'Avvia importazione' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="status === FolderImportStatusEnum.error">
      <div fxFlex></div>
      <button mat-button (click)="matDialogRef.close()">
        {{ 'Chiudi' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="status === FolderImportStatusEnum.done">
      <div fxFlex></div>
      <button mat-button (click)="finish()">
        {{ 'Chiudi' | translate }}
      </button>
    </ng-container>
  </div>
</div>