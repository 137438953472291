<div fxLayout="column" fxFlex="1 0 0" class="list-option-component ellipsis">
  <div class="mb-12 border list-option-items">
    <div *ngFor="let formGroup of formArray.controls; let i = index; let first = first; let last = last"
      [formGroup]="formGroup" class="ellipsis list-option-item">
      <div class="p-12" fxLayout="row" fxLayoutAlign="flex-start center">
        <!-- FIELDS -->
        <div fxLayout="column" fxFlex.gt-xs="100" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px">
          <!-- KEY -->
          <mat-form-field *ngIf="!suggestedKeys?.length" fxFlex.gt-xs="calc(34% - 8px)" fxFlex="100">
            <input matInput type="text" [name]="keyField" [formControl]="formGroup.get(keyField)"
              [placeholder]="keyPlaceholder" [required]="required" [disableControl]="disabled" />
            <mat-error>
              <span *ngIf="formGroup.get(keyField).errors?.required">{{ 'Obbligatorio' | translate }}</span>
              <span *ngIf="formGroup.get(keyField).errors?.unique">{{ 'Chiave duplicata' | translate }}</span>
            </mat-error>
          </mat-form-field>
          <!-- AUTOCOMPLETE KEY -->
          <ng-container *ngIf="suggestedKeys?.length > 0">
            <div fxLayout="column" fxFlex.gt-xs="calc(34% - 8px)" fxFlex="100">
              <app-autocomplete fxFlex="100" [formControl]="formGroup.get(keyField)" [list]="suggestedKeys"
                [placeholder]="keyPlaceholder" [required]="required" [allowNewValue]="true" [disableControl]="disabled"
                [listTitle]="'Suggerimenti' | translate" [showFullList]="true"></app-autocomplete>
              <small>
                <mat-error *ngIf="formGroup.get(keyField).touched && formGroup.get(keyField).invalid">
                  <span *ngIf="formGroup.get(keyField).errors?.unique">{{ 'Chiave duplicata' | translate }}</span>
                </mat-error>
              </small>
            </div>
          </ng-container>

          <!-- VALUE -->
          <mat-form-field fxFlex.gt-xs="calc(34% - 8px)" fxFlex="100">
            <input matInput type="text" [name]="valueField" [formControl]="formGroup.get(valueField)"
              [placeholder]="valuePlaceholder" [required]="required" [disableControl]="disabled" />
            <mat-error>
              <span *ngIf="formGroup.get(valueField).errors?.required">{{ 'Obbligatorio' | translate }}</span>
              <!-- <span *ngIf="formGroup.get(valueField).errors?.unique">Valore duplicato</span> -->
            </mat-error>
          </mat-form-field>
        </div>

        <!-- OTHER ACTIONS -->
        <ng-container
          *ngTemplateOutlet="otherActionsTpl ? otherActionsTpl : defaultOtherActionsTpl; context: { item: formGroup, index: index, component: component }"></ng-container>

        <!-- DELETE -->
        <button type="button" color="warn" class="ml-12 list-option-remove" mat-icon-button [disabled]="disabled"
          (clickConfirm)="formArray.removeAt(i)">
          <mat-icon aria-label="Remove hook">remove_circle</mat-icon>
        </button>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
    <div *ngIf="!formArray.length" class="text-center p-12 grey-400-fg">
      {{ 'nessuna opzione inserita' | translate }}
    </div>
  </div>

  <div>
    <button color="primary" type="button" class="list-option-add" mat-raised-button (click)="addOption()"
      [disabled]="disabled">
      {{ btnPlaceholder }}
    </button>
  </div>
  <!-- ERROR -->
  <mat-error class="mt-8" *ngIf="formArray.hasError('required') && formArray.touched">{{
    'Nessuna opzione inserita' | translate
  }}</mat-error>
</div>

<ng-template #defaultOtherActionsTpl let-item="item" let-index="index" let-component="component">
</ng-template>