import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CachedParamsService } from 'app/core/auth/services/cached-params.service';
import { FolderInterface } from 'app/models/folder.model';
import { FolderRepositoryService } from 'app/repositories/folder-repository.service';
import { I18nService } from 'app/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { FolderService } from '../../folder.service';
import { FolderMenuSectionEnum } from '../dialogs/folder-resources-selector-dialog/folder-resources-selector-dialog.component';
import { merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-folder-tree-selector',
  templateUrl: './folder-tree-selector.component.html',
  styleUrls: ['./folder-tree-selector.component.scss'],
})
export class FolderTreeSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customerId: string;
  @Input() folderCtrl: FormControl;
  @Input() disabled: boolean;
  @Input() filterList: FolderInterface[] = [];

  @Output() folderChange: EventEmitter<FolderInterface> = new EventEmitter<FolderInterface>();

  form: FormGroup = new FormGroup({
    searchText: new FormControl(''),
    ascending: new FormControl(false),
  })

  loadingFolders: boolean = false;
  foldersList: any;

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private _toastrService: ToastrService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
    private _folderRepositoryService: FolderRepositoryService,
    private _cachedParamsService: CachedParamsService,
    private _folderService: FolderService,
  ) {
  }

  ngOnInit(): void {
    this.refreshFolders();

    // REFRESH FOLDERS
    merge(
      this.form.get('searchText').valueChanges,
    ).pipe(
      takeUntil(this._unsubscribeAll),
      debounceTime(400),
    ).subscribe(data => {
      // console.log('text processed', data);
      this.refreshFolders();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerId'] && !changes['customerId'].firstChange) {
      this.refreshFolders();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleDirection() {
    this.form.get('ascending').setValue(
      !this.form.get('ascending').value,
    );
    this.refreshFolders();
  }

  async refreshFolders() {
    // this.foldersList = null;

    if (this.customerId) {
      this.loadingFolders = true;

      try {
        this.foldersList = await this._folderRepositoryService.findAll(
          {
            cid: this.customerId,
            sorting: 'updatedAt',
            direction: this.form.get('ascending').value ? 'asc' : 'desc',
            filters: JSON.stringify([{
              field: 'name',
              type: 'search',
              value: this.form.get('searchText').value || ''
            }]),
          }
        ).toPromise();

        if (this.filterList.length > 0) {
          this.foldersList.items = this.foldersList.items.filter(f => !this.filterList.map(v => v.id).includes(f.id));
        }

        console.log(this.foldersList);
        this.loadingFolders = false;
        this.setFolderById(this.folderCtrl.value?.id || this.folderCtrl.value);
      } catch (err) {
        this.foldersList = null;
        this.loadingFolders = false;
        this.selectFolder(null);
        this._toastrService.error(this._i18nService.translate(_(`Non è stato possibile scaricare le cartelle del cliente`)))
      }
    }
  }

  setFolderById(folderId?: string) {
    console.log('setFolderById', folderId);
    if (folderId) {
      const foundFolder = (this.foldersList?.items || []).find((f: FolderInterface) => {
        return f.id === folderId;
      });
      if (foundFolder) {
        this.selectFolder(foundFolder);
      } else {
        this.selectFolder(null);
      }
    } else {
      this.selectFolder(null);
    }
  }


  get folder(): FolderInterface {
    return this.folderCtrl.value;
  }

  selectFolder(folder) {
    this.folderCtrl.setValue(folder);
    this.folderChange.emit(folder);
  }

  addFolder() {
    console.log('addFolder')
    this._folderService.openFolderEditDialog().afterClosed().subscribe((newFolder: FolderInterface) => {
      if (newFolder) {
        newFolder.customers = [{ id: this.customerId }];
        this._folderRepositoryService.create(newFolder).subscribe((createdFolder: FolderInterface) => {
          console.log('_folderRepositoryService.create response', createdFolder);
          this._toastrService.success(this._i18nService.translate(_(`Cartella creata`)));
          this.selectFolder(createdFolder);
          this.refreshFolders();
        }, (err) => {
          console.log('_folderRepositoryService.create error', err);
        });
      }
    });
  }
}
