<div fxLayout="column" [formGroup]="group">
  <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-xs="16px">
    <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
      <div fxFlex="calc(50% - 16px)">
        <ng-container [ngSwitch]="group.get('templateOptions').get('type').value">
          <!-- TIMEPICKER -->
          <app-timepicker *ngSwitchCase="'time'" [formControl]="group.get('defaultValue')"
            [placeholder]="'Inserisci l\'ora' | translate"></app-timepicker>
          <!-- DATEPICKER -->
          <app-datepicker *ngSwitchCase="'date'" [formControl]="group.get('defaultValue')"
            [placeholder]="'Inserisci la data' | translate"></app-datepicker>
          <!-- MONTHPICKER -->
          <app-monthpicker *ngSwitchCase="'month'" [formControl]="group.get('defaultValue')"
            [placeholder]="'Inserisci il mese' | translate"></app-monthpicker>
          <!-- DEFAULT -->
          <mat-form-field *ngSwitchDefault fxFlex="100">
            <input matInput name="defaultValue" [type]="group.get('templateOptions').get('type').value"
              formControlName="defaultValue" [placeholder]="'Predefinito' | translate" />
          </mat-form-field>
        </ng-container>
      </div>
      <mat-form-field [hidden]="isTimeBased" fxFlex="calc(50% - 16px)" formGroupName="templateOptions">
        <input matInput name="templateOptions.placeholder" formControlName="placeholder"
          [placeholder]="'Segnaposto' | translate" />
      </mat-form-field>
    </div>
    <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
      <div class="pt-8 mb-8" fxFlex="calc(50% - 16px)" formGroupName="templateOptions">
        <mat-checkbox name="templateOptions.required" formControlName="required">{{
          'Obbligatorio' | translate
        }}</mat-checkbox>
      </div>
    </div>
  </div>
</div>