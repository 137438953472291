import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent {
  title: string;
  items: string[];
  showItems: boolean;
  confirmLabel: string;
  cancelLabel: string;
  optionalLabel: string;

  /**
   * Constructor
   *
   * @param {MatDialogRef<ConfirmDialogComponent>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
  }

}
