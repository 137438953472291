import { Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { of } from 'rxjs';
import { Subject } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class ClickConfirmService {
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  subject: Subject<boolean>;

  constructor(public _matDialog: MatDialog, private _i18nService: I18nService) {
    // this.subject = new Subject();
  }
  /**
   *
   *
   * @param {ClickConfirmItemInterface} [item]
   * @returns
   * @memberof ClickConfirmService
   */
  openBasic(item?: ClickConfirmItemInterface) {
    return of(confirm(this.setTitle(item)));
  }

  /**
   *
   *
   * @param {ClickConfirmItemInterface} [item]
   * @returns {MatDialogRef<ConfirmDialogComponent>}
   * @memberof ClickConfirmService
   */
  open(item?: ClickConfirmItemInterface): MatDialogRef<ConfirmDialogComponent> {
    item = new ClickConfirmItem(item, this._i18nService);

    const panelClasses = ['confirm-dialog'].concat(item.classes);
    if (Array.isArray(item.items) && item.items.length) {
      panelClasses.push('confirm-dialog-with-items');
    }

    this.confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
      panelClass: panelClasses,
      disableClose: false,
    });
    Object.assign(this.confirmDialogRef.componentInstance, {
      title: this.setTitle(item),
      items: item.items,
      showItems: Array.isArray(item.items) && item.items.filter(v => !!v).length > 0,
      confirmLabel: item.confirmLabel,
      cancelLabel: item.cancelLabel,
      optionalLabel: item.optionalLabel,
    });

    this.confirmDialogRef.afterClosed().subscribe(() => {
      this.confirmDialogRef = null;
    });
    return this.confirmDialogRef;
  }

  /**
   *
   *
   * @param {*} item
   * @returns
   * @memberof ClickConfirmService
   */
  setTitle(item: ClickConfirmItemInterface) {
    if (item.message) {
      return item.message;
    } else {
      switch (item.type) {
        case ClickConfirmType.delete:
          return this._i18nService.translate(_(`Sei sicuro di voler cancellare { name }?`), { name: item.name });
        case ClickConfirmType.archive:
          return this._i18nService.translate(_(`Sei sicuro di voler archiviare { name }?`), { name: item.name });
        case ClickConfirmType.reflow:
          return this._i18nService.translate(_(`Sei sicuro di voler rianalizzare { name }?`), { name: item.name });
        case ClickConfirmType.import:
          return this._i18nService.translate(_(`Sei sicuro di voler importare { name }?`), { name: item.name });
        default:
          return `${item.name}`;
      }
    }
  }
}

export interface ClickConfirmItemInterface {
  type?: number;
  classes?: string[];
  message?: string;
  name?: string;
  items?: string[];
  basic?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  optionalLabel?: string;
}

export class ClickConfirmItem implements ClickConfirmItemInterface {
  type: number = ClickConfirmType.delete;
  classes: string[] = [];
  name: string = this._i18nService.translate(_('questo elemento'));
  items: string[];
  basic: boolean = false; // basic: TRUE: window.confirm(), FALSE: MatModal
  confirmLabel: string = this._i18nService.translate(_('Conferma'));
  cancelLabel: string = this._i18nService.translate(_('Annulla'));
  optionalLabel: string;

  /**
   *Creates an instance of ClickConfirmItem.
   * @param {ClickConfirmItemInterface} _conf
   * @memberof ClickConfirmItem
   */
  constructor(private _conf: ClickConfirmItemInterface, private _i18nService: I18nService) {
    Object.assign(this, this._conf);
    delete this._conf;

    if (this.items && this.items.length > 1) {
      // this.name = `(${this.items.length}) elementi`;
      this.name = this._i18nService.translate(_('{count} {count, plural, one{elemento} other{elementi} }'), {
        count: this.items.length,
      });
    }
  }
}

export enum ClickConfirmType {
  delete,
  replace,
  reflow,
  archive,
  import,
}
