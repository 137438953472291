<input [hidden]="!(isReady || isError)" uploaderFiles [accept]="accept" [multiple]="multiple"
  (added)="added.emit($event)" (dragoverState)="dragoverState($event)" />

<div class="upl-drop-box" [ngClass]="{
    'upl-drop-box-ready': isReady,
    'upl-drop-box-error': isError,
    'upl-drop-box-hover': isDragHover,
    'upl-drop-box-hover-invalid': isDragInvalid,
    'upl-drop-box-validating': isValidating,
    'upl-drop-box-paused': isPaused,
    'upl-drop-box-uploading': isUploading,
    'upl-drop-box-complete': isComplete,
    'upl-drop-box-incomplete': isIncomplete,
    'upl-drop-box-disabled': isDisabled
  }">
  <div class="upl-drop-content">
    <div class="upl-resource-preview" [style.background-image]="previewUrl ? 'url(' + previewUrl + ')' : null"></div>
    <div class="upl-icon">
      <mat-icon *ngIf="isDragHover && !isDragInvalid">arrow_downward</mat-icon>
      <mat-icon *ngIf="isDragHover && isDragInvalid">block</mat-icon>
      <div *ngIf="!isDragHover">
        <app-uploader-status [status]="status" [type]="type" [mode]="UPLOADER_STATUS_MODE.icon" [errors]="errors"
          [multiple]="multiple"></app-uploader-status>
      </div>
    </div>
    <div fxHide.lt-sm class="upl-text" [ngClass]="{ 'upl-text-hide': !showTextInfo }">
      <span *ngIf="isDragHover && !isDragInvalid">{{ 'Rilascia qui' | translate }}</span>
      <span *ngIf="isDragHover && isDragInvalid">
        <div *ngIf="isDragInvalidFile">{{ 'File non valido' | translate }}</div>
        <div *ngIf="isDragInvalidMultiple">{{ '1 file ammesso' | translate }}</div>
      </span>
      <div *ngIf="!isDragHover">
        <app-uploader-status [status]="status" [type]="type" [mode]="UPLOADER_STATUS_MODE.label" [errors]="errors"
          [progress]="progress" [multiple]="multiple"></app-uploader-status>
      </div>
      <div *ngIf="isUploading">
        <button type="button" (click)="cancel.emit($event)">{{ 'annulla' | translate }}</button>
      </div>
      <div *ngIf="isPaused">
        <button type="button" (click)="upload.emit($event)">{{ 'carica' | translate }}</button>
      </div>
      <div *ngIf="isIncomplete">
        <button type="button" (click)="generate.emit($event)">{{ 'riprova' | translate }}</button>
      </div>
      <div *ngIf="isPaused || isIncomplete">
        <button type="button" (click)="reset.emit($event)">{{ 'resetta' | translate }}</button>
      </div>
    </div>
  </div>
</div>