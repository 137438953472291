import { Component, Input, OnChanges } from '@angular/core';
import { UploaderService } from '../../uploader.service';
import { UPLOADER_TYPE, UPLOADER_TYPE_MODE } from '../../uploader.interface';

/**
 *
 *
 * @export
 * @class UploaderTypeComponent
 * @description Uploader component
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-uploader-type',
  templateUrl: './uploader-type.component.html',
  styleUrls: ['./uploader-type.component.scss']
})
export class UploaderTypeComponent implements OnChanges {

  UPLOADER_TYPE = UPLOADER_TYPE;
  UPLOADER_TYPE_MODE = UPLOADER_TYPE_MODE;

  @Input() type: UPLOADER_TYPE = UPLOADER_TYPE.file;
  @Input() mode: UPLOADER_TYPE_MODE = UPLOADER_TYPE_MODE.icon;
  @Input() multiple: boolean;

  label: string = '';
  icon: string = '';

  constructor(
    private _uploaderService: UploaderService
  ) {
  }

  ngOnChanges(changes) {
    this.label = this._uploaderService.getTypeLabel(this.type, this.multiple);
    this.icon = this._uploaderService.getTypeIcon(this.type, this.multiple);
  }

  get isIcon() {
    return this.mode === UPLOADER_TYPE_MODE.icon;
  }

  get isLabel() {
    return this.mode === UPLOADER_TYPE_MODE.label;
  }
}
