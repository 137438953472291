import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resource } from 'app/models/resource.model';
import { GroupInterface } from 'app/models/group.model';
import { GroupRepositoryService } from 'app/repositories/group-repository.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FolderRepositoryService } from 'app/repositories/folder-repository.service';
import { CustomerInterface } from 'app/models/customer.model';
import { FolderInterface } from 'app/models/folder.model';
import { CustomerRepositoryService } from 'app/repositories/customer-repository.service';
import { LS_FOLDER_RESOURCES_SELECTOR } from 'app/modules/global/localstorage';
import { CachedParamsService } from 'app/core/auth/services/cached-params.service';

export interface FolderResourcesDialogDataInterface {
  customer: CustomerInterface;
  folder: FolderInterface;
  resources: Resource[];
}

@Component({
  selector: 'app-folder-resources-dialog',
  templateUrl: './folder-resources-dialog.component.html',
  styleUrls: ['./folder-resources-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FolderResourcesDialogComponent implements OnInit {

  form: FormGroup = new FormGroup({
    customer: new FormControl(null, [Validators.required]),
    folder: new FormControl(null, [Validators.required])
  });

  constructor(
    public matDialogRef: MatDialogRef<FolderResourcesDialogComponent>,
    private _folderRepositoryService: FolderRepositoryService,
    private _customerRepositoryService: CustomerRepositoryService,
    private _cachedParamsService: CachedParamsService,
    @Inject(MAT_DIALOG_DATA) public data: FolderResourcesDialogDataInterface
  ) {
  }

  ngOnInit(): void {

    // NO CUSTOMER
    if (!this.data.customer) {
      this.loadCachedCustomer();
    } else {
      this.form.get('customer').setValue(this.data.customer);
    }
  }

  loadCachedCustomer() {
    const defaults = {
      ...this._cachedParamsService.getCachedParams(LS_FOLDER_RESOURCES_SELECTOR),
    };
    if (defaults.cid) {
      this._customerRepositoryService.get(defaults.cid).subscribe(customer => {
        this.form.get('customer').setValue(customer);
        this.onCustomerChange(customer);
      }, () => {
      });
    }
  }


  onFolderChange(event) {
    console.log('onFolderChange', event);
  }

  onCustomerChange(event) {
    console.log('onCustomerChange', event);
  }

  save() {
    this.matDialogRef.close(this.form.value);
  }


  get hasOneCustomer() {
    return this._customerRepositoryService.hasOneCustomer;
  }

}
