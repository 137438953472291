<!-- <app-autocomplete fxFlex="100" [formControl]="control" [list]="folderList" [placeholder]="placeholder"
  [required]="required" [disableControl]="disabled" [delay]="200" [minChars]="2"
  [hint]="('Cartelle trovate' | translate) + ': ' + folderList?.length" [loading]="loadingFolders"
  [keepPrevSearch]="true" prop="name" (changeSelection)="onFolderChange($event)" (searchChange)="onSearchChange($event)"
  [showFullList]="true"></app-autocomplete> -->


<app-custom-autocomplete [formControl]="control" [placeholder]="placeholder" [list]="folderList" [loadListOnInit]="true"
  [required]="required" [disabled]="disabled" [hint]="hint" [floatLabel]="floatLabel"
  (changeSelection)="onFolderChange($event)">
</app-custom-autocomplete>