import { Directive, EventEmitter, HostListener, Input, Output, HostBinding } from '@angular/core';

@Directive({
  selector: '[uploaderFiles]'
})
export class UploaderFilesDirective {

  @HostBinding('attr.type') @Input() type: string = 'file';
  // @HostBinding('attr.capture') @Input() capture: string = 'camera'; // fix: remove for IOS where camera was opening directly without letting to chose from library
  @HostBinding('attr.accept') @Input() accept: boolean;
  @HostBinding('attr.multiple') @Input() multiple: boolean;

  @Output() added: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() dragoverState: EventEmitter<number> = new EventEmitter<number>();

  isDragHover: boolean;
  dragoverCode: number = 0;
  fileName: string;

  @HostListener('click', ['$event']) clickEvent(event) {
    // this.clickConfirm.emit(null);
  }
  @HostListener('change', ['$event']) changeEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.emitAddedEvent(event.target.files);
  }
  @HostListener('dragenter', ['$event']) dragenterEvent(event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragHover = true;
    this.dragoverCode = this.getDragoverCode(event);
  }
  @HostListener('dragleave', ['$event']) dragleaveEvent(event) {
    event.stopPropagation();
    event.preventDefault();
    this.isDragHover = false;
    this.dragoverCode = this.getDragoverCode();
  }
  @HostListener('dragover', ['$event']) dragoverEvent(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) dropEvent(event) {
    event.stopPropagation();
    event.preventDefault();
    this.dragleaveEvent(event);
    if (this.dragoverCode <= UPLOADER_DRAGOVER_STATE.hover) {
      this.emitAddedEvent(event.dataTransfer.files);
    }
  }

  getDragoverCode(event?) {
    let state = UPLOADER_DRAGOVER_STATE.none;
    if (event) {
      const dt = event.dataTransfer;
      if (dt.types.length > 1 || dt.types[0] !== 'Files') {
        state = UPLOADER_DRAGOVER_STATE.typeNotAllowed;
      } else {
        if (!this.multiple && dt.items.length > 1) {
          state = UPLOADER_DRAGOVER_STATE.multipleNotAllowed;
        } else {
          state = UPLOADER_DRAGOVER_STATE.hover;
        }
      }
    }
    this.dragoverState.emit(state);
    return state;
  }

  emitAddedEvent(selectedFiles: File[]) {
    this.added.emit(selectedFiles);
  }
}

export enum UPLOADER_DRAGOVER_STATE {
  none,
  hover,
  typeNotAllowed,
  multipleNotAllowed
}
