import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { GroupInterface } from 'app/models/group.model';
import { UserInterface } from 'app/models/user.model';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from 'app/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class GroupRepositoryService {

  /**
   *Creates an instance of GroupRepositoryService.
   * @param {HttpClient} _http
   * @memberof GroupRepositoryService
   */
  constructor(private _http: HttpClient) { }
  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<PaginatedResponse<GroupInterface>>}
   * @memberof GroupRepositoryService
   */
  findAll(_params: any = {}): Observable<PaginatedResponse<GroupInterface>> {
    return this._http.get<PaginatedResponse<GroupInterface>>('groups', {
      params: _params
    });
  }
  /**
   *
   * @param {*} [_params={}]
   * @returns {Observable<PaginatedResponse<GroupInterface>>}
   * @memberof GroupRepositoryService
   */
  getAll(_params: any = {}): Observable<GroupInterface[]> {
    return this._http.get<GroupInterface[]>(`groups/all`, {
      params: _params
    });
  }

  /**
   *
   *
   * @param {string} q
   * @returns {Observable<UserInterface[]>}
   * @memberof UserRepositoryService
   */
  search(q: string): Observable<GroupInterface[]> {
    if (typeof q !== 'string' || !q.trim()) return of([]);
    return this.findAll({
      filters: JSON.stringify([{
        field: 'name',
        type: 'search',
        value: q.trim(),
      }]),
    }).pipe(
      map(v => {
        return v.items;
      })
    )
    // return this._http.get<GroupInterface[]>(`groups/${encodeURIComponent(q)}/search`);
  }

  /**
   *
   * @param {string} id
   * @param {*} [_params={}]
   * @returns {Observable<UserInterface[]>}
   * @memberof GroupRepositoryService
   */
  getUsers(id: string, _params: any = {}): Observable<UserInterface[]> {
    return this._http.get<UserInterface[]>(`groups/${id}/users`, {
      params: _params
    });
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof GroupRepositoryService
   */

  get(id: string): Observable<GroupInterface> {
    return this._http.get<GroupInterface>(`groups/${id}`);
  }
  /**
   *
   *
   * @param {string} id
   * @returns {Observable<GroupInterface>}
   * @memberof GroupRepositoryService
   */
  delete(id: string, analyze?: boolean): Observable<GroupInterface> {
    return this._http.delete<GroupInterface>(`groups/${id}`, {
      params: {
        analyze: analyze ? analyze.toString() : undefined
      }
    }
    );
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<GroupInterface>}
   * @memberof GroupRepositoryService
   */
  update(id: string, data: GroupInterface): Observable<any> {
    return this._http.put(`groups/${id}`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<GroupInterface>}
   * @memberof GroupRepositoryService
   */
  create(data: GroupInterface): Observable<any> {
    return this._http.post(`groups`, data);
  }
}
