import { Component, Input, OnChanges, OnInit } from '@angular/core';

/**
 *
 *
 * @export
 * @class UploaderRequirementsComponent
 * @description Full name formatter from User entity object or from 'name' and 'surname'
 * @implements {OnInit}
 */
@Component({
  selector: 'app-uploader-requirements',
  templateUrl: './uploader-requirements.component.html',
  styleUrls: ['./uploader-requirements.component.scss']
})
export class UploaderRequirementsComponent implements OnInit, OnChanges {

  @Input() accept;
  @Input() minFileSize;
  @Input() maxFileSize;
  @Input() minWidth;
  @Input() minHeight;

  constructor() { }

  requirements: string;

  ngOnInit() {
    this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    this.requirements = this.buildRequirements();
  }

  buildRequirements() {
    const arr1 = [];
    if (this.minFileSize) {
      arr1.push(this.minFileSize + 'MB');
    }
    if (this.minWidth || this.minHeight) {
      if (this.minWidth && this.minHeight) {
        arr1.push(this.minWidth + 'x' + this.minHeight + 'px');
      } else {
        if (this.minWidth) {
          arr1.push('w. ' + this.minWidth + 'px');
        }
        if (this.minHeight) {
          arr1.push('h. ' + this.minHeight + 'px');
        }
      }
    }
    if (arr1.length) {
      arr1[0] = 'MIN: ' + arr1[0];
    }

    const arr2 = [];
    if (this.maxFileSize) {
      arr2.push(this.maxFileSize + 'MB');
    }
    if (arr2.length) {
      arr2[0] = 'MAX: ' + arr2[0];
    }

    const arr3 = [];
    if (typeof this.accept === 'string' && !!this.accept) {
      arr3.push(this.accept.split(',').join(', '));
    }

    return [].concat(arr1, arr2, arr3).join(', ');
  }
}
