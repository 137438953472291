import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { DatetimeModule } from 'app/modules/common/datetime/datetime.module';
import { TimeslotComponent } from './timeslot/timeslot.component';
import { TimeslotAlwaysComponent } from './timeslot-always/timeslot-always.component';
import { TimeslotsAlwaysComponent } from './timeslots-always/timeslots-always.component';
import { TimeslotViewComponent } from './timeslot-view/timeslot-view.component';
import { TimeslotsComponent } from './timeslots/timeslots.component';
import { TimeslotsBrightnessComponent } from './timeslots-brightness/timeslots-brightness.component';
import { TimeslotsListComponent } from './timeslots-list/timeslots-list.component';
import { TimeslotsMinutesComponent } from './timeslots-minutes/timeslots-minutes.component';
import { TimeslotsDialogComponent } from './timeslots-dialog/timeslots-dialog.component';
import { TimeslotsSchedulingDialogComponent } from './timeslots-scheduling-dialog/timeslots-scheduling-dialog.component';
import { TimeslotsWeekComponent } from './timeslots-week/timeslots-week.component';
import { TimeslotsWeekListComponent } from './timeslots-week-list/timeslots-week-list.component';
import { TimeslotsStatusComponent } from './timeslots-status/timeslots-status.component';
import { TimeslotsCalendarComponent } from './timeslots-calendar/timeslots-calendar.component';
import { DragulaModule } from 'ng2-dragula';
import { SchedulingViewComponent } from './scheduling-view/scheduling-view.component';
import { TimeslotsSchedulingInputComponent } from './timeslots-scheduling-input/timeslots-scheduling-input.component';

@NgModule({
    declarations: [
        TimeslotComponent,
        TimeslotsComponent,
        TimeslotAlwaysComponent,
        TimeslotsAlwaysComponent,
        TimeslotsBrightnessComponent,
        TimeslotsListComponent,
        TimeslotsMinutesComponent,
        TimeslotsDialogComponent,
        TimeslotsSchedulingDialogComponent,
        TimeslotViewComponent,
        TimeslotsWeekComponent,
        TimeslotsWeekListComponent,
        TimeslotsStatusComponent,
        TimeslotsCalendarComponent,
        SchedulingViewComponent,
        TimeslotsSchedulingInputComponent,
    ],
    imports: [GlobalModule, DatetimeModule, DragulaModule],
    exports: [
        TimeslotComponent,
        TimeslotsComponent,
        TimeslotAlwaysComponent,
        TimeslotsAlwaysComponent,
        TimeslotsBrightnessComponent,
        TimeslotsListComponent,
        TimeslotsMinutesComponent,
        TimeslotsDialogComponent,
        TimeslotsSchedulingDialogComponent,
        TimeslotViewComponent,
        TimeslotsWeekComponent,
        TimeslotsWeekListComponent,
        TimeslotsStatusComponent,
        TimeslotsCalendarComponent,
        SchedulingViewComponent,
        TimeslotsSchedulingInputComponent,
    ]
})
export class TimeslotModule { }
