export const LS_FAVORITES = 'favorites';
export const LS_HISTORY = 'history';
export const LS_CLIPBOARD_PLAYLIST_RESOURCE = 'clipboard.playlist.resource';
export const LS_TABLE_PARAMS_OLD = 'table.filters.cache';
export const LS_TABLE_PARAMS = 'table.params.cache';
export const LS_TABLE_COLUMNS = 'table.columns.cache';
export const LS_BRAND_UPLOAD_VIEW_TYPE = 'brand.upload.viewtype';
export const LS_BRAND_UPLOAD_EXPANDED = 'brand.upload.expanded';
export const LS_RESOURCE_PICKER_BRAND = 'resource.picker.brand';
export const LS_RESOURCE_PICKER_BRAND_SEARCH = 'resource.picker.brand.search';
export const LS_RESOURCE_PICKER_BRAND_RESOURCE_SEARCH = 'resource.picker.brand.resourcesearch';
export const LS_DCONTENT_PICKER_BRAND_RESOURCE_SEARCH = 'resource.picker.brand.dcontentsearch';
export const LS_BIND_VIEW_TYPE = 'bind.list.view.type';
export const LS_BIND_LIST_PARAMS = 'bind.list.params.cache';
export const LS_PLAYLIST_COMPACT = 'playlist.compact';
export const LS_PLAYLIST_HIGHLIGHT = 'playlist.highlight';
export const LS_HELP_CENTER = 'help.center';
export const LS_FEEDBACK_DIALOG = 'feedback.dialog';
export const LS_AD_REPORT_VIEW_TYPE = 'ad.report.view.type';
export const LS_LOG_UPDATER_STEP = 'log.updater.step';
export const LS_CALENDAR_VIEW = 'calendar.view';
export const LS_ANALYTICS_PARAMS = 'analytics.params';
export const LS_OTS_STATS_PARAMS = 'ots.stats.params';
export const LS_LOGS_CONTENTS_PARAMS = 'logs.contents.params';
export const LS_LOGS_TIME_PARAMS = 'logs.time.params';
export const LS_LOGS_HOUR_POINTS_PARAMS = 'logs.hourpoints.params';
export const LS_LOGS_EXPORT_PARAMS = 'logs.export.params';
export const LS_LOGS_COMMON_PARAMS = 'logs.common.params';
export const LS_OVERVIEW_PARAMS = 'overview.params';
export const LS_OVERVIEW_TABS = 'overview.tabs';
export const LS_SYSTEM_METRICS_SETTINGS = 'system.metrics.settings';
export const LS_AD_REPORT_SETTINGS = 'ad.report.settings';
export const LS_REMOTECONTROL_LAST_PLANT_SEARCHED = 'remotecontrol.lastPlantSearched';
export const LS_LIVE_CIRCUITS = 'remotecontrol.circuits.live';
export const LS_GLOBALS_TIME = 'globals.time';
export const LS_FOLDER_RESOURCES_SELECTOR = 'folder.resources.selector';
export const LS_RESOURCES_SELECTOR_VERSION = 'resources.selector.version';
export const LS_TICKET_NEW = 'ticket.new';
