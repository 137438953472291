import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FieldsMenuInterface, FieldsMenuClickedEvent } from './fields-menu.interface';

@Component({
  selector: 'app-fields-menu',
  templateUrl: './fields-menu.component.html',
  styleUrls: ['./fields-menu.component.scss'],
})
export class FieldsMenuComponent implements OnInit {
  @Input() items: FieldsMenuInterface[];
  @Input() parent: FieldsMenuInterface;
  @Output() clicked: EventEmitter<FieldsMenuClickedEvent> = new EventEmitter<FieldsMenuClickedEvent>();
  @ViewChild('childMenu', { static: true }) public childMenu;

  ngOnInit() {}

  clickEvent(event: MouseEvent, child: FieldsMenuInterface) {
    // console.log('clickEvent', child);
    this.clicked.emit({
      event: event,
      value: child,
      parents: this.normalizedParent && !child.standalone ? [this.normalizedParent] : [],
    });
  }

  subClickEvent($event) {
    if (this.normalizedParent && !$event.value.standalone) {
      $event.parents.unshift(this.normalizedParent);
    }
    // console.log('subClickEvent', $event);
    this.clicked.emit($event);
  }

  get normalizedParent() {
    if (this.parent) {
      const parentCopy = { ...this.parent };
      delete parentCopy.children;
      return parentCopy;
    }
    return;
  }
}
