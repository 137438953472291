export interface DailyPriceInterface {
  days?: number;
  amount?: any;
}

export interface ListOptionInterface {
  name?: string;
  key?: string;
  value?: string;
  [key: string]: any;
}

export class ListOption implements ListOptionInterface {
  name: string;
  key: string;
  value: any;
  // [key: string]: any;
  constructor(private _conf: ListOptionInterface = {}) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface ListOptionOptionsInterface {
  keyField?: string;
  valueField?: string;
  validators?: any;
}

export class ListOptionOptions {
  keyField: string = 'name';
  valueField: string = 'value';
  validators: any;

  constructor(private _t: ListOptionOptionsInterface) {
    Object.assign(this, this._t);
    delete this._t;
  }
}

