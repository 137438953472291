
import {
  Directive,
  ViewContainerRef,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';

/**
 * Show/hide an element if the user is admin
 */
@Directive({
  selector: '[isAdmin]'
})
export class IsAdminDirective implements OnInit {

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private _currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
    if (this._currentUserService.isAdmin()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
