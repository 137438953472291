import { ResolveConstraintService } from '../modules/uploader/service/resolve.constraints';
import { ResourceCreateOptions } from '../modules/resources/resource.options.services';
import { FluidFileType } from 'app/modules/uploader/service/file.type';
import { Resource, ResourcesStatsByCustomerInterface } from 'app/models/resource.model';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BrandInterface } from 'app/models/brand.model';
import { ResizeParameters } from 'app/models/resource.resize.model';
import { Observable, of } from 'rxjs';
import { PaginatedResponse, PaginatedResponseInterface } from 'app/models/pagination.model';
import { CustomerInterface } from 'app/models/customer.model';

@Injectable({
  providedIn: 'root',
})
export class ResourcesRepositoryService {
  constructor(private _http: HttpClient, private _ResolveConstraintService: ResolveConstraintService) { }
  private getFileType(path) {
    let type = FluidFileType.fileTypeDetection(path);
    // TODO: check if there are other types in URL
    if (['image', 'video'].indexOf(type) === -1) {
      type = 'document';
    }
    return type + 's';
  }


  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<Resource[]>}
   * @memberof SystemRepositoryService
   */
  findAll(_params: HttpParams | any = {}): Observable<Resource[]> {
    return this._http.get<Resource[]>('resourcealls', {
      params: _params,
    });
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<Resource>}
   * @memberof SystemRepositoryService
   */
  get(id) {
    return this._http.get<Resource>(`resourcealls/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<Resource>}
   * @memberof SystemRepositoryService
   */
  getImage(id: string): Observable<Resource> {
    return this._http.get<Resource>(`resources/images/${id}`);
  }
  /**
   *
   *
   * @param {string} id
   * @returns {Observable<Resource>}
   * @memberof SystemRepositoryService
   */
  getVideo(id: string): Observable<Resource> {
    return this._http.get<Resource>(`resources/videos/${id}`);
  }
  /**
   *
   *
   * @param {string} q
   * @returns {Observable<Resource[]>}
   * @memberof SystemRepositoryService
   */
  search(q: string): Observable<Resource[]> {
    if (typeof q !== 'string' || !q.trim()) return of([]);
    return this._http.get<Resource[]>(`resourceallreferences/${encodeURIComponent(q.trim())}/search`);
  }
  /**
   *
   *
   * @param {Resource} resource
   * @returns
   * @memberof ResourcesRepositoryService
   */
  deleteResource(resource: Resource) {
    return this._http.delete('resources/' + this.getFileType(resource.source) + '/' + resource.id);
  }
  /**
   *
   *
   * @param {Object} { ids: string [] }
   * @returns
   * @memberof ResourcesRepositoryService
   */
  deleteAll(ids: string[]) {
    return this._http.request('post', 'resourceallreferences/multi/deletes', {
      body: ids,
    });
  }
  /**
   *
   *
   * @param {Resource} resource
   * @returns
   * @memberof ResourcesRepositoryService
   */
  reflow(resource: Resource) {
    return this._http.put(
      'resources/' + this.getFileType(resource.source) + '/' + resource.id + '/' + 'reflow',
      resource,
    );
  }

  resize(resource: Resource, params: ResizeParameters) {
    return this._http.post('resource/' + resource.id + '/resize', params.parse());
  }
  /**
   *
   *
   * @param {ResourceCreateOptions} _options
   * @param {string} constraint
   * @returns
   * @memberof ResourcesRepositoryService
   */
  create(_options: ResourceCreateOptions, constraint: string = 'webm'): Observable<Resource> {
    const isDoc = this.getFileType(_options.source) === 'documents';
    if (isDoc) {
      _options.noWorkflow = true;
    }
    if (this.getFileType(_options.source) === 'videos') {
      _options.constraints = this._ResolveConstraintService.getFromExt(constraint);
    }
    return this._http.post<Resource>('resources/' + this.getFileType(_options.source), _options);
  }

  /**
   *
   *
   * @param {Resource} resource
   * @returns {Observable<Resource>}
   * @memberof ResourcesRepositoryService
   */
  put(resource: Resource): Observable<Resource> {
    const type = resource.type || this.getFileType(resource.source);
    return this._http.put<Resource>(`resources/${type}/${resource.id}`, resource);
  }

  /**
   *
   *
   * @param {Resource} resource
   * @returns {Observable<Resource>}
   * @memberof ResourcesRepositoryService
   */
  patch(resource: Resource, data: any): Observable<Resource> {
    return this._http.patch<Resource>(`resource/${resource.id}/update`, data);
  }

  /**
   *
   *
   * @param {Resource} resource
   * @returns {Observable<Resource>}
   * @memberof ResourcesRepositoryService
   */
  patchSelected(data: any): Observable<Resource> {
    return this._http.patch<Resource>(`resourceall/acl`, data);
  }

  findAllByBrandPaginated(brandId: string, params: HttpParams | any = {}): Observable<PaginatedResponseInterface<Resource>> {
    //Default order to desc
    return this._http.get<PaginatedResponseInterface<Resource>>(`resourceallreferences/${brandId}/brand/paginated`, {
      params: Object.assign({ ascending: false }, params),
    });
  }

  findAllByFolderPaginated(folderId: string, params: HttpParams | any = {}): Observable<PaginatedResponseInterface<Resource>> {
    //Default order to desc
    return this._http.get<PaginatedResponseInterface<Resource>>(`resourceallreferences/${folderId}/folder/paginated`, {
      params: Object.assign({ ascending: false }, params),
    });
  }

  findAllByCustomerPaginated(customerId: string, params: HttpParams | any = {}): Observable<PaginatedResponseInterface<Resource>> {
    //Default order to desc
    return this._http.get<PaginatedResponseInterface<Resource>>(`resourceallreferences/${customerId}/customer/paginated`, {
      params: Object.assign({ ascending: false }, params),
    });
  }

  getResourcesStatsByCustomer(customerId: string): Observable<ResourcesStatsByCustomerInterface> {
    return this._http.get<ResourcesStatsByCustomerInterface>(`resourceallreferences/${customerId}/stats`);
  }
}
