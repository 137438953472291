<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'Apri un Ticket' | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-12 m-0" ngClass.gt-xs="p-24" mat-dialog-content [formGroup]="form">
    <form [formGroup]="form">
      <!-- SUBJECT -->
      <div class="mb-12" *isAdmin>
        <mat-form-field fxFlex="100">
          <input required matInput readonly name="ticket_subject" formControlName="subject"
            [placeholder]="'Oggetto' | translate" />
        </mat-form-field>
      </div>
      <!-- CATEGORY -->
      <div class="mb-12">
        <mat-form-field fxFlex="100">
          <mat-label>{{ 'Categoria' | translate }}</mat-label>
          <mat-select required formControlName="category" [compareWith]="categoryCompare"
            (selectionChange)="onCategoryChange($event)">
            <mat-option *ngFor="let category of TICKET_CATEGORY_LIST"
              [value]="category">{{ category.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- DESCRIPTION -->
      <div class="mb-12">
        <mat-form-field fxFlex="100" appearance="fill">
          <mat-label>{{ 'Descrizione del problema' | translate }}</mat-label>
          <textarea matInput name="ticket_description" required xxmaxlength="TICKET_DESCR_MAX_LENGTH"
            formControlName="description" [placeholder]="'Descrizione del problema' | translate" rows="5"></textarea>
          <mat-error>
            <ng-container
              *ngIf="form.get('description').hasError('required')">{{'Obbligatorio' | translate}}</ng-container>
            <ng-container
              *ngIf="form.get('description').hasError('maxlength')">{{'Lunghezza massima superata' | translate}}:
              {{ form.get('description').errors['maxlength'].actualLength }}/{{
              form.get('description').errors['maxlength'].requiredLength }}</ng-container>
          </mat-error>
          <mat-hint class="w-100-p text-right">
            {{ form.get('description').value?.length }}/{{ TICKET_DESCR_MAX_LENGTH }}
          </mat-hint>
        </mat-form-field>
      </div>
      <!-- PRIORITY -->
      <!-- <div class="mb-12">
        <div class="app-inputs-label-sm mb-8">{{ 'Priorità' | translate }}</div>
        <mat-radio-group name="ticket_priority" formControlName="priority">
          <ng-container *ngFor="let p of TICKET_PRIORITY_LIST">
            <mat-radio-button class="mr-12 mb-12" [value]="p.value">{{ p.label }}</mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div> -->
      <!-- CUSTOM FIELDS -->
      <div [formGroup]="form.get('custom_fields')">
        <!-- SYSTEM NAME -->
        <!-- <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <input matInput name="ticket_cf_impianto" formControlName="cf_impianto"
              [placeholder]="'Nome impianto' | translate" />
          </mat-form-field>
        </div> -->

        <!-- <div class="mb-12">
          <app-system-autocomplete [control]="systemCtrl"
            (changeSelection)="onSystemSelect($event)"></app-system-autocomplete>
        </div> -->

        <!-- PAGE URL -->
        <div class="mb-12">
          <mat-form-field fxFlex="100" appearance="fill">
            <mat-label>{{ 'URL pagina' | translate }}</mat-label>
            <textarea matInput name="ticket_cf_url" formControlName="cf_url" [placeholder]="'URL pagina' | translate"
              xxmaxlength="TICKET_URL_MAX_LENGTH" rows="3"></textarea>
            <mat-error>
              <ng-container
                *ngIf="form.get('custom_fields').get('cf_url').hasError('maxlength')">{{'Lunghezza massima superata' | translate}}:
                {{ form.get('custom_fields').get('cf_url').errors['maxlength'].actualLength }}/{{
                form.get('custom_fields').get('cf_url').errors['maxlength'].requiredLength }}</ng-container>
            </mat-error>

          </mat-form-field>
        </div>

        <div class="mb-12" fxLayout="row">
          <mat-icon class="mr-12">desktop_windows</mat-icon>
          <div fxFlex>
            <app-autocomplete-multiple [formControl]="systemsCtrl" [hideEmptyList]="true" [list]="systemList"
              prop="name" mapBy="id" [label]="'Seleziona impianti coinvolti' | translate" [listLabel]="
                                                            ('Impianti selezionati' | translate) + ' (' + systemsCtrl.value?.length + ')'
                                                          " [placeholder]="'Filtra per impianti' | translate"
              floatLabel="always" forceColumnLayout
              (selectionChange)="onSystemsChange($event)"></app-autocomplete-multiple>
          </div>
        </div>

        <div class="mb-12">
          <button type="button" mat-raised-button [color]="showUploader ? 'accent' : 'primary'"
            (click)="toggleUploader()"><mat-icon>attach_file</mat-icon> {{ 'Allega immagini' | translate }} ({{
            showUploader ? resourcesCtrl.length : 0 }})</button>
        </div>

        <div *ngIf="showUploader" class="p-12 grey-100-bg border-radius-4">
          <!-- RESOURCES -->
          <div class="mb-12">
            <app-uploader-image multiple (beforeUploadItem)="uploadStarted()"
              (resourceUploadedAll)="onAllResourcesUploaded()"
              (uploadedResource)="onUploadedResource($event)"></app-uploader-image>
          </div>

          <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="8px">
            <app-uploader-image *ngFor="let photoControl of resourcesCtrl.controls" fxFlex.xs="0 0 100%"
              fxFlex.gt-xs="0 0 150px" fxFlex.gt-sm="0 0 180px" class="media-photo mb-8" [formControl]="photoControl"
              [removable]="true" [editable]="false" [showRequirements]="true" [hasPlaceholder]="false"
              (remove)="removeFromPhotos($event)">
            </app-uploader-image>
          </div>
        </div>

        <!-- <app-uploader-images-videos [formControl]="resourcesCtrl" [multiple]="true"
          [placeholder]="'Carica immagini o video' | translate"
          (uploadedResource)="onUploadedResource($event)"></app-uploader-images-videos> -->

      </div>
    </form>

    <!-- <pre>{{ form.value | json }}</pre> -->

  </div>

  <div mat-dialog-actions class="m-0 p-12" ngClass.gt-xs="p-24" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <button mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
      <button mat-button (click)="reset()">{{ 'Resetta' | translate }}</button>
    </div>
    <button color="primary" type="button" [disabled]="form.invalid || uploading" mat-raised-button
      (click)="save()">{{ 'Conferma' | translate }}</button>
  </div>
</div>