import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UPLOADER_DRAGOVER_STATE } from '../../uploader-files.directive';
import { UPLOADER_TYPE, UPLOADER_STATUS, UPLOADER_STATUS_MODE } from '../../uploader.interface';

/**
 *
 *
 * @export
 * @class UploaderDropareaComponent
 * @description Uploader component
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-uploader-droparea',
  templateUrl: './uploader-droparea.component.html',
  styleUrls: ['./uploader-droparea.component.scss']
})
export class UploaderDropareaComponent {

  @Input() type: UPLOADER_TYPE = UPLOADER_TYPE.file;
  @Input() status: UPLOADER_STATUS = UPLOADER_STATUS.ready;
  @Input() previewUrl: string;
  @Input() progress: number;
  @Input() accept: string = '.png,.gif,.jpeg,.jpg';
  @Input() capture: boolean;
  @Input() multiple: boolean;
  @Input() showTextInfo: boolean = true;
  @Input() errors: number;

  @Output() added: EventEmitter<any> = new EventEmitter<any>();
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() generate: EventEmitter<any> = new EventEmitter<any>();
  @Output() reset: EventEmitter<any> = new EventEmitter<any>();

  UPLOADER_STATUS = UPLOADER_STATUS;
  UPLOADER_STATUS_MODE = UPLOADER_STATUS_MODE;

  UPLOADER_DRAGOVER_STATE = UPLOADER_DRAGOVER_STATE;
  dragState: number;

  constructor() {
    // setInterval(() => {
    //   console.log('upl status', this.status);
    // }, 2000);
  }

  get isMultiple() {
    return this.multiple;
  }

  get isError() {
    return this.status === this.UPLOADER_STATUS.error;
  }

  get isReady() {
    return this.status === this.UPLOADER_STATUS.ready;
  }

  get isValidating() {
    return this.status === this.UPLOADER_STATUS.validate;
  }

  get isPaused() {
    return this.status === this.UPLOADER_STATUS.pause;
  }

  get isUploading() {
    return this.status === this.UPLOADER_STATUS.upload;
  }

  get isComplete() {
    return this.status === this.UPLOADER_STATUS.complete;
  }

  get isIncomplete() {
    return this.status === this.UPLOADER_STATUS.incomplete;
  }

  get isDisabled() {
    return this.status === this.UPLOADER_STATUS.disabled;
  }

  get isDragHover() {
    return this.dragState >= UPLOADER_DRAGOVER_STATE.hover;
  }

  get isDragInvalid() {
    return this.dragState > UPLOADER_DRAGOVER_STATE.hover;
  }

  get isDragInvalidFile() {
    return this.dragState === UPLOADER_DRAGOVER_STATE.typeNotAllowed;
  }

  get isDragInvalidMultiple() {
    return this.dragState === UPLOADER_DRAGOVER_STATE.multipleNotAllowed;
  }

  dragoverState(state) {
    this.dragState = state;
  }
}
