import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from 'app/modules/global/components/icon/icon.module';
import { NgMaterialModule } from 'app/ng-material/ng-material.module';
import { HintComponent } from '../hint/hint.component';
import { HintComponentsModule } from './components/hint-components.module';
import { HintDialogComponent } from './dialogs/hint-dialog/hint-dialog.component';

@NgModule({
    declarations: [HintComponent, HintDialogComponent],
    imports: [CommonModule, FuseSharedModule, NgMaterialModule, TranslateModule, HintComponentsModule, IconModule],
    exports: [HintComponent, HintDialogComponent]
})
export class HintModule { }
