import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ContentChild,
  TemplateRef,
  forwardRef,
  ElementRef,
  ViewRef,
} from '@angular/core';
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validator,
  NG_VALIDATORS,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject, of, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, startWith, debounceTime, switchMap, tap, map, first } from 'rxjs/operators';
import { Directive } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Directive({
  selector: '[appAutocompleteLabel]',
})
export class AutocompleteLabelChipsDirective {
  constructor(public template: TemplateRef<any>) { }
}

/**
 *
 *
 * @export
 * @class AutocompleteChipsComponent
 * @description AutocompleteChipsComponent to draw <mat-chips> with autocomplete filter
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styleUrls: ['./autocomplete-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteChipsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutocompleteChipsComponent),
      multi: true,
    },
  ],
})
export class AutocompleteChipsComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('autocompleteInput')
  autocompleteInput: ElementRef<HTMLInputElement>;

  @ContentChild(AutocompleteLabelChipsDirective, {
    static: true,
  })
  autocompleteLabel: AutocompleteLabelChipsDirective;

  @ViewChild(MatAutocompleteTrigger, {
    read: MatAutocompleteTrigger
  })
  matAutocompleteTrigger: MatAutocompleteTrigger;

  @Input() list: Fetcher<any[]>;
  @Input() decorateProp: string;
  @Input() prop: string;
  @Input() placeholder: string = this._i18nService.translate(_(`Seleziona un'opzione`));

  @Input() set required(values: boolean) {
    this._required = coerceBooleanProperty(values);
  }
  @Input() set disabled(values: boolean) {
    this._disabled = coerceBooleanProperty(values);
  }

  @Input() logs: boolean = false;
  @Input() sort: boolean = true;
  @Input() delay: number = 400;
  @Input() contains: boolean = true;
  @Input() caseSensitive: boolean;
  @Input() keepPrevSearch: boolean;
  @Input() keepPrevList: boolean = true;
  @Input() allowNew: boolean;
  @Input() removable: boolean = true;
  @Input() addOnBlur: boolean = true;
  @Input() allowDuplicates: boolean;
  @Input() hint: string;
  // LOCAL FILTER FN
  @Input() filterFn: Fetcher<any>; //(term: string, list: any[]) => any[];
  // LIST FILTER FN
  @Input() filterItemsFn: (filteredItems: any[]) => any[];
  // LIST SORT FN
  @Input() sortFn: (a: any, b: any) => number;
  // VALUE DECORATOR FN
  @Input() decorateFn: (values: any) => any;
  // VALUE VIEW TRANSLATOR FN
  @Input() translateFn: (values: any) => any;
  // VALUE CREATE FN
  @Input() createFn: Fetcher<any>;
  // VALUE REMOVE TRANSLATOR FN
  @Input() removeFn: Fetcher<any>;
  @Input() actionsTpl: TemplateRef<any>;
  // CHANGE VALUE ARRAY EMITTER
  @Output() changeSelection: EventEmitter<any> = new EventEmitter<any>();

  component = this;

  protected _disabled = false;
  get disabled() {
    return this._disabled;
  }
  protected _required = false;
  get required() {
    return this._required;
  }

  autocompleteControl: FormControl = new FormControl('');
  loading: boolean = false;
  filteredItems: any[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  get autoActiveFirstOption(): boolean {
    return !this.allowNew;
  }

  private _unsubscribeAll: Subject<any>;
  protected _values: any = null;

  constructor(private _cdr: ChangeDetectorRef, private _i18nService: I18nService) {
    this._unsubscribeAll = new Subject();
  }

  private _onChange = (event: any) => { };
  private _onTouched: any = () => { };

  validate(c: FormControl) {
    // console.log('validation of chips triggered');
    if (c && c.touched) {
      this.autocompleteControl.markAsDirty();
      this.autocompleteControl.markAsTouched();
    }

    if (!this.loading) {
      if (this.required) {
        if (!this.values || !this.values.length) {
          this.autocompleteControl.setErrors({
            required: true,
          });
          // return Validators.required;
        }
      }
    }
    return null;

    // if (this.required) {
    //   return this.autocompleteControl.errors;
    // }
    return null;
  }

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(values: any[] = []) {
    this.log('WRITE new value', values);
    if (Array.isArray(values)) {
      this._values = values;
      this.log('::::::::::::: writeValue', this._values);
    }
  }

  onClick(event) {
    if (!this.disabled && !this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.openPanel();
    }
  }

  get values() {
    return this._values;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _searchInArray(term: string, list: any[] = []) {
    this.log('> _searchInArray, caseSensitive', this.caseSensitive);
    return list.filter((v) => {
      let haystack = this.translate(v).toString();
      let needle = term;
      if (!this.caseSensitive) {
        haystack = haystack.toLowerCase();
        needle = needle.toLowerCase();
      }
      if (this.contains) {
        this.log('> contains');
        return haystack.indexOf(needle) !== -1;
      }
      this.log('> start with');
      return haystack.indexOf(needle) === 0;
    });
  }

  isObject(values) {
    return values !== null && typeof values === 'object';
  }

  ngOnInit() {
    this.log('> ngOnInit', this);
  }

  ngAfterViewInit() {
    this.log('> ngAfterViewInit', this);

    this.autocompleteControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap(() => {
          this.log('>> PRE LOADING ...<<');
          if (this.hasLoader) {
            this.loading = true;
            this.log('>> LOADING ...<<');
          }
        }),
        debounceTime(this.isStaticList ? 0 : this.delay),
        startWith(''),
        switchMap(() => {
          this.log('> switchMap', this.searchValue);

          // RESET SELECTED OBJECT
          if (typeof this.searchValue === 'object') {
            this.log('> resetting object value');
            this.autocompleteControl.setValue(null, { emitEvent: false });

            // KEEP LAST SEARCH
            if (this.keepPrevList) {
              this.log('> keep prev list');
              return of(this.filteredItems);
            }
          }

          // NEXT SEARCH
          const searchText = this.searchValue.toString().trim();
          return this.fetcher(
            searchText,
            this.list,
            // () => this.filter(searchText, this.list)
          );
        }),
        switchMap((items: any[] = []) => {
          this.log('> switchMap2', items);

          if (this.isStaticList && items.length) {
            const searchText = this.searchValue.toString().trim();
            this.log('> filter static list', searchText, items);
            return this.filter(searchText, items);
          }
          return of(items);
        }),
        map((items: any[]) => {
          this.log('> filter selected items', items);
          return items.length ? this.filterUnselectedItems(items) : [];
        }),
      )
      .subscribe((items: any[] = []) => {
        setTimeout(() => {
          this.log('> items', items);
          if (this.hasLoader && this.loading) {
            this.loading = false;
            this.log('>> END LOADING ...<<');
          }
          this.filteredItems = Array.isArray(items) ? items : [];

          // filter results
          if (typeof this.filterItemsFn === 'function') {
            this.filteredItems = this.filterItemsFn(this.filteredItems);
          }

          // sort
          if (this.sort) {
            this.filteredItems.sort((a, b) => this.sortAlgorithm(a, b));
          }

          if (this.isObservableList) {
            // SET STATIC RESULTS TO LIST
            (this.list as any[]) = this.filteredItems;
          }

          if (this._cdr && !(this._cdr as ViewRef).destroyed) {
            this._cdr.detectChanges();
          }
        });
      });
  }

  filterUnselectedItems(serverItems: any[]): any[] {
    return !this.values
      ? serverItems
      : serverItems.filter(
        (serverItem) =>
          !this.values.find((item) => {
            this.log('>>>>>INSIDE FIND', item, serverItem, this.decorateProp);
            if (this.decorateProp || typeof this.decorateFn === 'function') {
              this.log('>>decorate', this.decorate(serverItem), item);
              return this.decorate(serverItem) === item;
            }
            return this.translate(serverItem)?.toLowerCase() === this.translate(item)?.toLowerCase();
          }),
      );
  }

  sortAlgorithm(a, b) {
    if (this.sortFn && typeof this.sortFn === 'function') {
      return this.sortFn(a, b);
    }
    return this.translate(a).localeCompare(this.translate(b));
  }

  filter(term: string, list: any[]) {
    return this.fetcher([term, list], this.filterFn, () => {
      return this._searchInArray(term, list);
    });
  }

  decorate(item: any) {
    if (this.decorateFn && typeof this.decorateFn === 'function') {
      return this.decorateFn(item);
    } else {
      if (this.decorateProp) {
        return item && this.decorateProp ? item[this.decorateProp] : item;
      } else {
        return item;
      }
    }
  }

  decorateValues(values: any[]) {
    if (
      (this.decorateProp || typeof this.decorateFn === 'function') &&
      Array.isArray(values) &&
      Array.isArray(this.list)
    ) {
      const valsMap = this.list.reduce((acc, v) => {
        acc[v[this.decorateProp]] = v;
        return acc;
      }, {});

      this.log('>><<<>><< decorateValues >>', valsMap);
      return values.map((v) => {
        return valsMap[v];
      });
    }
    return values;
  }

  translate(item: any) {
    if (this.translateFn && typeof this.translateFn === 'function') {
      return this.translateFn(item);
    } else {
      if (this.prop) {
        return item && this.prop ? item[this.prop] : item;
      } else {
        return item;
      }
    }
  }

  get searchValue() {
    return this.autocompleteControl.value || '';
  }

  ngOnChanges(changes) {
    this.log('ngOnChanges', changes, 'isObservable', this.list instanceof Observable);
    if (changes.list) {
      if (!changes.list.firstChange) {
        this.filteredItems = [];
        // DOWNLOAD NEW LIST AND SHOW SELECTED ITEM IN THE LIST
        this.log('ngOnChanges updateValueAndValidity', changes);
        this.autocompleteControl.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  get isStaticList() {
    return !this.list || Array.isArray(this.list);
  }

  get isCallbackList() {
    return typeof this.list === 'function';
  }

  get isObservableList() {
    return this.list instanceof Observable;
  }

  get hasLoader() {
    return this.isCallbackList || this.isObservableList;
  }

  isDuplicate(text: string = '', list = []) {
    let compare1 = text.toString().trim();
    if (!this.allowDuplicates && compare1) {
      return list.find((item) => {
        let compare2 = this.translate(item).toString().trim();
        if (!this.caseSensitive) {
          compare1 = compare1.toLowerCase();
          compare2 = compare2.toLowerCase();
        }
        return compare1 === compare2;
      });
    } else {
      return false;
    }
  }

  optionCreated(event: MatChipInputEvent) {
    this.log('> optionCreated', event);
    if (this.allowNew) {
      const value = event.value.toString().trim();
      if (value) {
        const foundItem = this.isDuplicate(value, this.filteredItems);
        if (!foundItem) {
          const foundAddedItem = this.isDuplicate(value, this.values);
          if (!foundAddedItem) {
            this.create(value);
          } else {
            this.log('avoid already added duplicate');
          }
        } else {
          this.log('avoid duplicate, add existent');
          this.add(foundItem);
          // SET CREATED INPUT OBJECT
          this.autocompleteControl.setValue(foundItem);
          // CLOSE PANEL
          this.matAutocompleteTrigger.closePanel();
        }
      }
    }
  }

  optionSelected(event: MatAutocompleteSelectedEvent): void {
    this.log('> optionSelected', this.searchValue, event);
    if (!this.isDuplicate(this.translate(this.searchValue), this.values)) {
      this.add(this.searchValue);
    } else {
      this.log('selection duplicate');
    }
  }

  blur(event) {
    if (this.addOnBlur) {
      const target: HTMLElement = event.relatedTarget as HTMLElement;
      if (!target || target.tagName !== 'MAT-OPTION') {
        const value = this.autocompleteInput.nativeElement.value;
        if ((value || '').trim()) {
          const matChipEvent: MatChipInputEvent = {
            input: this.autocompleteInput.nativeElement,
            value: this.autocompleteInput.nativeElement.value,
          };
          this.optionCreated(matChipEvent);
        }
      }
    }
  }

  clearSearchValue() {
    this.autocompleteInput.nativeElement.value = '';
    this.autocompleteControl.setValue(null);
  }

  add(value) {
    // if ((value || '').trim()) {
    this.log('add', value);
    this._values.push(JSON.parse(JSON.stringify(value)));
    this.updateValue();

    if (!this.keepPrevSearch) {
      this.clearSearchValue();
    }
    // }
  }

  fetcher(args: any | any[], fn?: Fetcher<any>, syncDecorator?: (...args: any) => any): Observable<any> {
    let ob: Observable<any> = fn;
    if (typeof fn === 'function') {
      ob = fn.apply(this, Array.isArray(args) ? args : [args]);
    }
    if (ob instanceof Observable === false) {
      ob = new BehaviorSubject<any>(
        fn ? ob : syncDecorator ? syncDecorator.apply(this, Array.isArray(args) ? args : [args]) : undefined,
      );
    }
    return ob.pipe(first());
  }

  create(value: any): void {
    this.log('create', value);

    this.fetcher(value, this.createFn, (v) => {
      return this.prop ? { [this.prop]: v } : v;
    }).subscribe((response) => {
      this.add(response);
      // SET CREATED INPUT OBJECT
      this.autocompleteControl.setValue(response);
      // CLOSE PANEL
      this.matAutocompleteTrigger.closePanel();
    });
  }

  remove(item: any, index: number): void {
    console.log('remove', item, index);

    this.fetcher(item, this.removeFn).subscribe((response) => {

      let realIndex = index;

      if (!!item) {
        realIndex = this.values.indexOf(item);
      }

      console.log('remove observable', realIndex);
      if (realIndex >= 0) {
        this._values.splice(realIndex, 1);
        this.updateValue();
        // REFRESH LIST
        this.autocompleteControl.updateValueAndValidity();
      }
    });
  }

  reset() {
    this._values.length = 0;
    this.updateValue();
    this.clearSearchValue();
    this.autocompleteControl.setValue(null);
  }

  updateValue() {
    this.log('updateValues: ', this.values);
    this.changeSelection.emit(this.values);
    this._onChange(this.values);
  }

  log(...args) {
    if (this.logs) {
      console.log(...args);
    }
  }
}

export declare type Fetcher<T> = T & Observable<T> & ((value: any) => Observable<T> & T);
