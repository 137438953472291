<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item type="button" [matMenuTriggerFor]="menu.childMenu">
        <span>{{ child.label }}</span>
      </button>
      <app-fields-menu
        #menu
        [parent]="child"
        [items]="child.children"
        (clicked)="subClickEvent($event)"
      ></app-fields-menu>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item type="button" (click)="clickEvent($event, child)">
        <span>{{ child.label }}</span>
      </button>
    </span>
  </span>
</mat-menu>
