import { NgModule } from '@angular/core';
import { ScreenSelectorComponent } from './screen-selector/screen-selector.component';
import { GlobalModule } from 'app/modules/global/global.module';
import { StringModule } from '../string/string.module';
import { DragulaModule } from 'ng2-dragula';
import { UtilsModule } from '../utils/ultils.module';
import { DatetimeModule } from '../datetime/datetime.module';
import { DailyPricesComponent } from './daily-prices/daily-prices.component';
import { ListOptionsComponent } from './list-options/list-options.component';
import { AddressComponent } from './address/address.component';
import { AutocompleteModule } from '../autocompletes/autocomplete.module';
import { ScreenToggleComponent } from './screen-toggle/screen-toggle.component';
import { ScreenInfoDialogComponent } from 'app/modules/editorial/components/dialogs/screen-info-dialog/screen-info-dialog.component';
import { FieldsComponent } from './custom-fields/fields/fields.component';
import { FieldInputComponent } from './custom-fields/field-input/field-input.component';
import { FieldTextareaComponent } from './custom-fields/field-textarea/field-textarea.component';
import { FieldCheckboxComponent } from './custom-fields/field-checkbox/field-checkbox.component';
import { FieldColorComponent } from './custom-fields/field-color/field-color.component';
import { FieldRadioComponent } from './custom-fields/field-radio/field-radio.component';
import { FieldSelectComponent } from './custom-fields/field-select/field-select.component';
import { FieldMultiselectComponent } from './custom-fields/field-multiselect/field-multiselect.component';
import { FieldsMenuComponent } from './custom-fields/fields-menu/fields-menu.component';
import { AppColorpickerModule } from './colorpicker/custom.colorpicker.module';
import { CustomFieldsExportDialogComponent } from './custom-fields/dialogs/custom-fields-export-dialog/custom-fields-export-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FieldListComponent } from './custom-fields/field-list/field-list.component';
import { CodeInputModule } from 'angular-code-input';
import { QuillModule } from 'ngx-quill'
import { TextEditorComponent } from './text-editor/text-editor.component';
import { FieldTextEditorComponent } from './custom-fields/field-text-editor/field-text-editor.component';
import { FieldOptionsWithGroupsComponent } from './custom-fields/field-options-with-groups/field-options-with-groups.component';
import { CustomFieldsModule } from './custom-fields/custom-fields.module';


@NgModule({
    declarations: [
        ScreenSelectorComponent,
        DailyPricesComponent,
        ListOptionsComponent,
        AddressComponent,
        ScreenToggleComponent,
        ScreenInfoDialogComponent,
        TextEditorComponent,
        // CUSTOM FIELDS
        CustomFieldsExportDialogComponent,
        FieldsComponent,
        FieldInputComponent,
        FieldTextareaComponent,
        FieldTextEditorComponent,
        FieldCheckboxComponent,
        FieldColorComponent,
        FieldRadioComponent,
        FieldSelectComponent,
        FieldMultiselectComponent,
        FieldsMenuComponent,
        FieldListComponent,
        FieldOptionsWithGroupsComponent,
    ],
    imports: [
        GlobalModule,
        StringModule,
        DatetimeModule,
        UtilsModule,
        DragulaModule.forRoot(),
        AutocompleteModule,
        AppColorpickerModule,
        GoogleMapsModule,
        CodeInputModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    [
                        "bold",
                        "italic",
                        "underline",
                        "strike"
                    ],
                    [
                        "blockquote",
                        "code-block"
                    ],
                    [
                        {
                            "header": 1
                        },
                        {
                            "header": 2
                        }
                    ],
                    [
                        {
                            "list": "ordered"
                        },
                        {
                            "list": "bullet"
                        }
                    ],
                    [
                        {
                            "script": "sub"
                        },
                        {
                            "script": "super"
                        }
                    ],
                    [
                        {
                            "indent": "-1"
                        },
                        {
                            "indent": "+1"
                        }
                    ],
                    [
                        {
                            "direction": "rtl"
                        }
                    ],
                    [
                        {
                            "size": [
                                "small",
                                false,
                                "large",
                                "huge"
                            ]
                        }
                    ],
                    [
                        {
                            "header": [
                                1,
                                2,
                                3,
                                4,
                                5,
                                6,
                                false
                            ]
                        }
                    ],
                    [
                        {
                            "color": []
                        },
                        {
                            "background": []
                        }
                    ],
                    [
                        {
                            "font": []
                        }
                    ],
                    [
                        {
                            "align": []
                        }
                    ],
                    [
                        "clean"
                    ],
                    [
                        "link",
                    ]
                ]
            }
        }),
        CustomFieldsModule,
    ],
    exports: [
        AppColorpickerModule,
        ScreenSelectorComponent,
        DailyPricesComponent,
        ListOptionsComponent,
        AddressComponent,
        ScreenToggleComponent,
        ScreenInfoDialogComponent,
        TextEditorComponent,
        // CUSTOM FIELDS
        CustomFieldsExportDialogComponent,
        FieldsComponent,
        FieldInputComponent,
        FieldTextareaComponent,
        FieldTextEditorComponent,
        FieldCheckboxComponent,
        FieldColorComponent,
        FieldRadioComponent,
        FieldSelectComponent,
        FieldMultiselectComponent,
        FieldsMenuComponent,
        FieldListComponent,
        FieldOptionsWithGroupsComponent,
        CodeInputModule,
        QuillModule,
    ]
})
export class InputsModule { }
