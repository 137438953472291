import { Component, Input, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GroupInterface } from 'app/models/group.model';

@Component({
  selector: 'app-field-video',
  templateUrl: './field-video.component.html',
  styleUrls: ['./field-video.component.scss']
})
export class FieldVideoComponent implements OnInit, OnChanges {

  @Input() group: FormGroup;

  constructor(
    protected _cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }
}
