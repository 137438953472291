import { Component, OnInit, Input } from '@angular/core';

/**
 *
 *
 * @export
 * @class ColorSquareComponent
 * @description Square color preview
 * @implements {OnInit}
 */
@Component({
  selector: 'app-color-square',
  templateUrl: './color-square.component.html',
  styleUrls: ['./color-square.component.scss']
})
export class ColorSquareComponent implements OnInit {

  @Input() value: string;

  constructor() {
  }

  ngOnInit() {
  }

}
