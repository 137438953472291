<ng-container *ngIf="isIcon && icon">
    <mat-icon>{{ icon }}</mat-icon>
</ng-container>
<ng-container *ngIf="isLabel && labels?.length > 0">
    <ng-container *ngIf="inline">
        {{ labels.join(' ') }}
    </ng-container>
    <ng-container *ngIf="!inline">
        <div *ngFor="let label of labels"><span>{{ label }}</span></div>
    </ng-container>
</ng-container>