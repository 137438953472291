import { Component, Input, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { GroupInterface } from 'app/models/group.model';
import { RoleInterface } from 'app/models/user.model';
import { GroupsEditService } from 'app/modules/common/groups-edit/groups-edit.service';
import { UserRepositoryService } from 'app/repositories/user-repository.service';

@Component({
  selector: 'app-field-options-with-groups',
  templateUrl: './field-options-with-groups.component.html',
  styleUrls: ['./field-options-with-groups.component.scss']
})
export class FieldOptionsWithGroupsComponent implements OnInit, OnChanges {

  @Input() group: FormGroup;
  @Input() groupList: GroupInterface[] = [];

  roleList: RoleInterface[];

  constructor(
    protected _cdr: ChangeDetectorRef,
    private _currentUserService: CurrentUserService,
    private _userRepositoryService: UserRepositoryService,
    private _groupsEditService: GroupsEditService,
  ) {
  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  ngOnInit() {
    // GET USER ROLES
    this.roleList = this._userRepositoryService.getRoles();
  }

  ngOnChanges() {
  }

  openGroupsRolesDialog(event, optionCtrl: FormGroup, index) {

    if (!optionCtrl.get('groups')) {
      optionCtrl.addControl('groups', new FormControl([]));
    }

    if (!optionCtrl.get('roles')) {
      optionCtrl.addControl('roles', new FormControl([]));
    }

    console.log('this.group', this.group);

    const parentGroupsIds = this.group.get('templateOptions')?.get('groups').value || [];
    const parentRolesIds = this.group.get('templateOptions')?.get('roles').value || [];

    const groupsMap = this.groupList.reduce((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});

    const parentGroups = parentGroupsIds.map(v => {
      return groupsMap[v];
    }).filter(v => !!v);

    const rolesMap = this.roleList.reduce((acc, val) => {
      acc[val.value] = val;
      return acc;
    }, {});

    const parentRoles = parentRolesIds.map(v => {
      return rolesMap[v];
    }).filter(v => !!v);

    console.log('openGroupsRolesDialog', {
      parentGroups,
      parentRoles,
      optionCtrl,
    });

    this._groupsEditService.openGroupsRolesDialog({
      groups: optionCtrl.get('groups'),
      roles: optionCtrl.get('roles'),
      groupList: parentGroups.length > 0 ? parentGroups : this.groupList,
      roleList: parentRoles.length > 0 ? parentRoles : this.roleList,
      name: `${optionCtrl.value.name}`,
    })

    // group.addControl('roles', new FormControl(
    //   ['ROLE_SUPER_ADMIN'],
    // ));
  }
}
