<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        {{ 'Sposta risorse' | translate }}
      </span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-0 m-0 ellipsis" mat-dialog-content>
    <div class="ellipsis p-24" fxLayout="column">
      <form [formGroup]="form" novalidate class="mb-24">

        <!-- CUSTOMER -->
        <!-- <div class="mb-12" [hidden]="data.folder.customers.length === 1">
          <app-custom-autocomplete [required]="true" [placeholder]="'Cliente' | translate"
            [list]="data.folder.customers" [formControl]="form.get('customer')"
            (changeSelection)="onCustomerChange($event)" [loadListOnInit]="true"></app-custom-autocomplete>
        </div> -->

        <div [hidden]="hasOneCustomer">
          <div class="h4 font-weight-600 mb-8">{{ 'Seleziona il cliente' | translate }}</div>

          <!-- CUSTOMER -->
          <div class="mb-12">
            <app-customer-autocomplete [required]="true" [placeholder]="'Cliente' | translate"
              [control]="form.get('customer')" (changeSelection)="onCustomerChange($event)"></app-customer-autocomplete>
          </div>
        </div>

        <div class="h4 font-weight-600 mb-8">
          {{ 'Seleziona la cartella di destinazione o creane una nuova' | translate }}
        </div>


        <div class="mb-12 h-300">

          <app-folder-tree-selector [customerId]="form.get('customer').value?.id" [folderCtrl]="form.get('folder')"
            (folderChange)="onFolderChange($event)" [filterList]="[data.folder]"></app-folder-tree-selector>

          <!-- <app-folder-autocomplete [customerId]="form.get('customer').value?.id" [control]="form.get('folder')"
            (changeSelection)="onFolderChange($event)"
            [filterList]="[data.folder]"></app-folder-autocomplete> -->
        </div>

      </form>

      <mat-accordion fxFlex="100">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <!-- TITLE -->
            <mat-panel-title> {{ 'Risorse' | translate }} ({{ data.resources.length }}) </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" fxLayoutGap="4px">
            <div fxFlex.lt-sm="0 0 calc(50% - 4px)" fxFlex.gt-xs="0 0 calc(20% - 4px)"
              *ngFor="let resource of data.resources; let i = index" class="mb-4">
              <app-resource-content [content]="resource" [thumb]="true" [name]="false"
                [modalPreview]="true"></app-resource-content>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="matDialogRef.close()">
      {{ 'Annulla' | translate }}
    </button>
    <button color="primary" type="button" [disabled]="form.invalid" mat-raised-button (click)="save()">
      {{ 'Conferma' | translate }}
    </button>
  </div>
</div>