<div fxLayout="column" [formGroup]="group">
  <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
    <div class="pt-8 mb-8" fxFlex="100" fxFlex.gt-sm="0 1 calc(25% - 16px)" formGroupName="templateOptions">
      <mat-checkbox name="templateOptions.required" formControlName="required">{{
        'Obbligatorio' | translate
      }}</mat-checkbox>
    </div>
    <div class="pt-8 mb-8" fxFlex="100" fxFlex.gt-sm="0 1 calc(25% - 16px)">
      <mat-checkbox name="defaultValue" formControlName="defaultValue">{{ 'Abilitato' | translate }}</mat-checkbox>
    </div>
  </div>
</div>
