import { Resource } from "./resource.model";
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface TicketInterface {
  id?: string;
  category?: TicketCategoryEnum;
  subject?: string;
  description?: string;
  priority?: TicketPriorityEnum;
  // status?: TicketStatusEnum;
  custom_fields?: TicketCustomFieldsInterface;
}

export class Ticket implements TicketInterface {
  id: string;
  category: TicketCategoryEnum = TicketCategoryEnum.editorial;
  subject: string = '';
  description: string = '';
  priority: TicketPriorityEnum;
  // status: TicketStatusEnum;
  custom_fields: TicketCustomFieldsInterface;

  constructor(private _conf: TicketInterface) {
    Object.assign(this, this._conf);
    this.custom_fields = new TicketCustomFields(this.custom_fields);
    delete this._conf;
  }
}

export interface TicketCustomFieldsInterface {
  cf_impianto?: string;
  cf_impianti?: string[];
  cf_url?: string;
  cf_resources?: Resource[];
}

export class TicketCustomFields implements TicketCustomFieldsInterface {

  cf_impianto: string;
  cf_impianti: string[] = [];
  cf_url: string = window.location.href;
  cf_resources: Resource[] = [];

  constructor(private _conf: TicketCustomFieldsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export enum TicketCategoryEnum {
  editorial = 'editorial',
  trigger = 'trigger',
  feed = 'feed',
  other = 'other',
}


export enum TicketPriorityEnum {
  low = 'LOW',
  medium = 'MEDIUM',
  high = 'HIGH',
}


export enum TicketStatusEnum {
  new = 0,
  pending = 1,
  working = 2,
  infoRequired = 3,
  resolved = 4,
  expired = 5,
}

export const TICKET_CATEGORY_LIST = [
  {
    value: TicketCategoryEnum.editorial,
    label: _('Programmazione contenuti'),
    priority: TicketPriorityEnum.high,
  },
  {
    value: TicketCategoryEnum.trigger,
    label: _('Impostazioni Trigger'),
    priority: TicketPriorityEnum.low,
  },
  {
    value: TicketCategoryEnum.feed,
    label: _('Gestione Feed'),
    priority: TicketPriorityEnum.low,
  },
  {
    value: TicketCategoryEnum.other,
    label: _('Altro'),
    priority: TicketPriorityEnum.high,
  },
];

export const TICKET_PRIORITY_LIST = [
  {
    value: TicketPriorityEnum.low,
    label: _('Bassa@@@Priorità'),
  },
  {
    value: TicketPriorityEnum.medium,
    label: _('Media@@@Priorità'),
  },
  {
    value: TicketPriorityEnum.high,
    label: _('Alta@@@Priorità'),
  },
];

export const TICKET_STATUS_LIST = [
  {
    value: TicketStatusEnum.new,
    label: _('Nuovo@@@Ticket status'),
  },
  {
    value: TicketStatusEnum.pending,
    label: _('Creato@@@Ticket status'),
  },
  {
    value: TicketStatusEnum.working,
    label: _('In lavorazione@@@Ticket status'),
  },
  {
    value: TicketStatusEnum.infoRequired,
    label: _('Richieste info@@@Ticket status'),
  },
  {
    value: TicketStatusEnum.resolved,
    label: _('Risolto@@@Ticket status'),
  },
  {
    value: TicketStatusEnum.expired,
    label: _('Scaduto@@@Ticket status'),
  },
];