import { Injectable } from '@angular/core';
const RE_NORMALIZE = /(?:\.([^.]+))?$/;
@Injectable({
  providedIn: 'root'
})
export class FilenameService {
  normalize(text: string) {
    return text.substr(0, text.length - RE_NORMALIZE.exec(text)[0].length).replace(/[^a-z0-9]/gi, '_').toLowerCase() + RE_NORMALIZE.exec(text)[0].toLowerCase();
  }
}
