<mat-form-field
  fxFlex="100"
  class="colorpicker-form-field"
  [(colorPicker)]="value"
  [cpFallbackColor]="value"
  [cpPosition]="'bottom'"
  [cpUseRootViewContainer]="true"
  [cpPositionRelativeToArrow]="true"
  (colorPickerChange)="colorChanged($event)"
  [ngClass]="{ 'fluid-disabled': disabled }"
>
  <app-color-square [value]="value"></app-color-square>
  <!-- <input matInput type="color" name="defaultValue" formControlName="defaultValue" placeholder="Default"> -->
  <!-- readonly -->
  <input
    matInput
    name="colorpicker"
    autocomplete="off"
    [required]="required"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    [clearable]="clearable && !disabled"
    [disableControl]="disabled"
    (blur)="blur($event)"
  />
  <mat-error>{{ 'Obbligatorio' | translate }}</mat-error>
</mat-form-field>
