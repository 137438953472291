import { Component, Input, OnChanges, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';
import { InputsService } from '../inputs.service';

/**
 *
 *
 * @export
 * @class ListOptionsComponent
 * @description ListOptions (value, name) component used to build datasets for Radio, Select, Multiselect programmaticly
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-list-options',
  templateUrl: './list-options.component.html',
  styleUrls: ['./list-options.component.scss'],
})
export class ListOptionsComponent implements OnChanges {
  @Input() formArray: FormArray;

  @Input() keyField: string = 'value';
  @Input() valueField: string = 'name';
  @Input() suggestedKeys: string[] = [];

  @Input() keyPlaceholder: string = this._i18nService.translate(_('Chiave'));
  @Input() valuePlaceholder: string = this._i18nService.translate(_('Etichetta'));

  @Input() btnPlaceholder: string = this._i18nService.translate(_('Aggiungi opzione'));

  @Input() required: boolean = true;
  @Input() uniqueKeys: boolean = false;
  @Input() uniqueValues: boolean = false;
  @Input() additionalKeys: string[] = [];
  @Input() disabled: boolean;

  @Input() otherActionsTpl: TemplateRef<any>;

  validateAsTrueFn = () => true;

  constructor(
    protected _cdr: ChangeDetectorRef,
    private _inputsService: InputsService,
    private _i18nService: I18nService,
  ) { }

  ngOnChanges(changes) {
    this._cdr.detectChanges();
  }

  addOption() {
    this.formArray.push(
      new FormGroup(
        this._inputsService.buildListOption(
          {},
          {
            keyField: this.keyField,
            valueField: this.valueField,
          },
          this.uniqueKeys,
          this.uniqueValues,
          this.additionalKeys,
        ),
      ),
    );
    this._cdr.detectChanges();
  }
}
