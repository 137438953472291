import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormControl } from '@angular/forms';
import { GroupsEditDialogComponent } from './groups-edit-dialog/groups-edit-dialog.component';
import { GroupInterface } from 'app/models/group.model';
import { GroupsRolesDialogComponent } from './groups-roles-dialog/groups-roles-dialog.component';
import { GroupsWithPermissionsDialogComponent } from './groups-with-permissions-dialog/groups-with-permissions-dialog.component';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { GroupRepositoryService } from 'app/repositories/group-repository.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsEditService {
  constructor(
    public _matDialog: MatDialog,
    public _currentUserService: CurrentUserService,
    public _groupRepositoryService: GroupRepositoryService,
  ) { }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  open(groups: FormControl | any, name?: string): MatDialogRef<GroupsEditDialogComponent> {
    let editedGroups;
    if (groups instanceof FormControl) {
      editedGroups = new FormControl([...groups.value]);
    } else {
      editedGroups = new FormControl(groups);
    }
    console.log('open groups', editedGroups);
    const dialog = this._matDialog.open(GroupsEditDialogComponent, {
      panelClass: 'groups-edit-dialog',
      data: {
        groups: editedGroups,
        name: name,
      },
    });
    dialog.afterClosed().subscribe((response: GroupInterface[]) => {
      if (response && groups instanceof FormControl) {
        groups.setValue(response);
      }
    });
    return dialog;
  }

  openGroupsRolesDialog({ groups, roles, groupList, roleList, name }): MatDialogRef<GroupsRolesDialogComponent> {
    let copiedGroups;
    if (groups instanceof FormControl) {
      copiedGroups = new FormControl(Array.isArray(groups.value) ? [...groups.value] : []);
    } else {
      copiedGroups = new FormControl(groups || []);
    }
    let copiedRoles;
    if (roles instanceof FormControl) {
      copiedRoles = new FormControl(Array.isArray(roles.value) ? [...roles.value] : []);
    } else {
      copiedRoles = new FormControl(roles || []);
    }
    console.log('openGroupsRolesDialog', copiedGroups, copiedRoles);
    const dialog = this._matDialog.open(GroupsRolesDialogComponent, {
      panelClass: 'groups-roles-dialog',
      data: {
        groups: copiedGroups,
        roles: copiedRoles,
        groupList,
        roleList,
        name,
      },
    });
    dialog.afterClosed().subscribe((response: { groups: GroupInterface[], roles: string[] }) => {
      console.log('afterClose', response);
      if (!!response) {
        if (groups instanceof FormControl) {
          groups.setValue(response.groups || []);
        }
        if (roles instanceof FormControl) {
          roles.setValue(response.roles || []);
        }
      }
    });
    return dialog;
  }

  openGroupsWithPermissionsDialog({ groups, groupList, name }): MatDialogRef<GroupsWithPermissionsDialogComponent> {
    let copiedGroups;
    if (groups instanceof FormControl) {
      copiedGroups = new FormControl(Array.isArray(groups.value) ? [...groups.value] : []);
    } else {
      copiedGroups = new FormControl(groups || []);
    }

    console.log('openGroupsWithPermissionsDialog', copiedGroups);
    const dialog = this._matDialog.open(GroupsWithPermissionsDialogComponent, {
      panelClass: 'groups-with-permissions-dialog',
      data: {
        groups: copiedGroups,
        groupList,
        name,
      },
    });
    dialog.afterClosed().subscribe((response: { groups: GroupInterface[], roles: string[] }) => {
      console.log('afterClose', response);
      if (!!response) {
        if (groups instanceof FormControl) {
          groups.setValue(response.groups || []);
        }
      }
    });
    return dialog;
  }

  getAllowedGroups(parentGroups: GroupInterface[]): Promise<GroupInterface[]> {
    // console.log('getAllowedGroups')
    return new Promise((resolve, reject) => {
      if (Array.isArray(parentGroups) && parentGroups.length > 0) {
        // console.log('parentGroups', parentGroups)
        resolve(parentGroups);
      } else {
        if (this.isAdmin) {
          this._groupRepositoryService.getAll().subscribe(allGroups => {
            // console.log('allGroups', allGroups)
            resolve(allGroups);
          });
        } else {
          const userGroups = this._currentUserService.getGroups();
          // console.log('userGroups', userGroups)
          resolve(userGroups);
        }
      }
    });
  }
}
