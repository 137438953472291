<mat-form-field fxFlex="100" [floatLabel]="floatLabel">
  <!-- <mat-icon matPrefix>desktop_windows</mat-icon> -->
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input [type]="inputType" name="autocomplete_component" matInput [matAutocomplete]="auto"
    [formControl]="autocompleteControl" [placeholder]="placeholder" [required]="required" [disableControl]="disabled"
    [readonly]="!filterable" [matAutocompleteDisabled]="disabled" (blur)="onBlur($event)" (keyup)="onKeyUp($event)"
    (click)="onClick($event)" />
  <mat-icon matSuffix *ngIf="loading" class="app-icon-xs app-rotate">autorenew</mat-icon>
  <button
    *ngIf="clearable && !disabled && (inputType === 'text' ? searchValue?.length > 0 : searchValue !== null) && !loading"
    matSuffix class="clearable" mat-icon-button (click)="remove()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete [autoActiveFirstOption]="autoActiveFirstOption" #auto="matAutocomplete"
    (optionSelected)="optionSelected($event)">
    <ng-container *ngIf="listTitle">
      <mat-optgroup [label]="listTitle">
        <mat-option *ngFor="let item of filteredItems; let index = index" [value]="decorate(item)">
          <ng-container *ngIf="autocompleteOption?.template">
            <ng-container
              *ngTemplateOutlet="autocompleteOption?.template; context: { item: item, index: index }"></ng-container>
          </ng-container>
          <ng-container *ngIf="!autocompleteOption?.template">
            {{ translate(item) }}
          </ng-container>
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-container *ngIf="!listTitle">
      <mat-option *ngFor="let item of filteredItems; let index = index" [value]="decorate(item)">
        <ng-container *ngIf="autocompleteOption?.template">
          <ng-container
            *ngTemplateOutlet="autocompleteOption?.template; context: { item: item, index: index }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!autocompleteOption?.template">
          {{ translate(item) }}
        </ng-container>
      </mat-option>
    </ng-container>

  </mat-autocomplete>
  <mat-hint>
    <!-- && isStaticList -->
    <span class="app-autocomplete-hint" *ngIf="!hint && showFullList">{{ 'Opzioni' | translate }}:
      {{ list.length }}</span>
    <span class="app-autocomplete-hint" *ngIf="hint">{{ hint }}</span>
  </mat-hint>
  <mat-error>
    <span *ngIf="autocompleteControl.hasError('required')">{{ 'Obbligatorio' | translate }}</span>
    <span *ngIf="autocompleteControl.hasError('invalidValue')">{{ 'Valore non valido' | translate }}</span>
  </mat-error>
</mat-form-field>