<div fxLayout fxLayoutAlign="start center">
  <div fxFlex="100" fxLayout="column" fxFlex.gt-xs="0 1 300px">
    <h1 mat-dialog-title class="m-0 mb-8">
      <!-- <mat-icon>library_books</mat-icon> -->
      <div *ngIf="!config.dynamic">{{ 'Seleziona risorse' | translate }}</div>
      <div *ngIf="config.dynamic">{{ 'Contenuti dinamici' | translate }}</div>
    </h1>

    <ng-container *isAdmin>
      <div *ngIf="!config.dynamic" class="mb-12" ngClass.gt-xs="mr-12">
        <button class="w-100-p" type="button" mat-button color="accent"
          (click)="openNewSelector()">{{ 'Vai al nuovo selettore' | translate }}</button>

      </div>
    </ng-container>

  </div>
  <div fxFlex="100" fxFlex.gt-xs="1 0 60" fxHide fxShow.gt-xs fxLayout fxLayoutAlign="end center"
    class="resources-filter">
    <ng-container *ngTemplateOutlet="searchResources"></ng-container>
  </div>
</div>

<div fxHide.gt-xs fxLayout="column">
  <!-- <app-brand-autocomplete
    [control]="brandControl"
    (changeSelection)="brandAutocompleteChange($event)"
  ></app-brand-autocomplete> -->

  <mat-form-field>
    <mat-label>{{ 'Seleziona brand' | translate }}</mat-label>
    <input type="text" autocomplete="off" matInput [matAutocomplete]="brandList"
      [placeholder]="'Cerca brand' | translate" [formControl]="brandSearchTermControl" [required]="!brandSelected"
      clearable />
    <mat-autocomplete #brandList="matAutocomplete" (optionSelected)="brandAutocompleteChange($event)">
      <mat-option *ngFor="let brand of brands" [value]="brand">
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
          <image-thumb class="no-min-width mr-8" fxFlex="40px" matListAvatar [value]="brand.thumb"></image-thumb>

          <span fxFlex class="ellipsis">{{ brand.name }}</span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div fxHide.gt-xs>
  <ng-container *ngTemplateOutlet="searchResources"></ng-container>
</div>
<mat-dialog-content style="overflow: initial">
  <div class="main-template" fxLayoutAlign="stretch" fxLayout.xs="column" fxFlexFill>
    <aside fxHide fxShow.gt-xs fxLayout="column">
      <div class="toolbar px-12" fxFlex="57px">
        <mat-form-field [floatLabel]="'never'">
          <input autocomplete="off" matInput [placeholder]="'Cerca brand' | translate"
            [formControl]="brandSearchTermControl" clearable />
          <mat-error *ngIf="!brandSelected">{{ 'Obbligatorio' | translate }}</mat-error>
          <!-- (change)="brandSearchTermChanged($event)" -->
          <!-- [(ngModel)]="brandSearchTerm" -->
        </mat-form-field>
      </div>
      <div class="brand-list" fxFlex fusePerfectScrollbar>
        <div fxFlex fxLayoutAlign="center center" *ngIf="loadingBrands">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
        <mat-list style="flex: 1" fxLayout="column" fxLayoutAlign="stretch" class="p-0" *ngIf="!loadingBrands">
          <mat-list-item [attr.brand_id]="brand.id"
            [ngClass]="{ current: brandSelected && brandSelected?.id === brand.id }" class="cursor-pointer"
            (click)="selectBrand(brand)" *ngFor="let brand of brands">
            <image-thumb class="no-min-width" matListAvatar [value]="brand.thumb"></image-thumb>
            <h3 matLine>{{ brand.name }}</h3>
          </mat-list-item>
        </mat-list>

        <div *ngIf="!brands" fxLayout fxLayoutAlign="center center">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      </div>
    </aside>
    <div fxLayout="column" class="resources" fxFlex>
      <div fxFlex class="list" fxLayout="row">
        <!-- DEFAULT LAYOUT OF ALL ITEMS -->
        <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start"
          *ngIf="brandSelected && !showSelected && !loading && resourceReferences?.items.length > 0">
          <!-- RESOURCE COMPONENT -->
          <ng-container *ngIf="!config.dynamic">
            <resource-card class="content-card"
              [ngClass]="{ current: isSelected(resource), 'fluid-disabled fluid-checked': !!config.excludedIdsMap[resource.id] }"
              (selectEvent)="select(resource)" [resource]="resource"
              *ngFor="let resource of resourceReferences?.items"></resource-card>
          </ng-container>
          <!-- DATASET COMPONENT -->
          <ng-container *ngIf="config.dynamic">
            <dcontent-card class="content-card"
              [ngClass]="{ current: isSelected(dcontent), 'fluid-disabled fluid-checked': !!config.excludedIdsMap[dcontent.id] }"
              (selectEvent)="select(dcontent)" [dcontent]="dcontent"
              *ngFor="let dcontent of resourceReferences?.items"></dcontent-card>

            <!-- <div
              class="border p-12 m-12"
              *ngFor="let dcontent of resourceReferences?.items"
              [ngClass]="{ 'green-400-bg': isSelected(dcontent) }"
              (click)="select(dcontent)"
            >
              {{ dcontent.name }}
            </div> -->
          </ng-container>
        </div>

        <!-- SHOW CURRENT SELECTED ITEMS -->
        <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" *ngIf="showSelected && !loading">
          <!-- RESOURCE COMPONENT -->
          <ng-container *ngIf="!config.dynamic">
            <resource-card class="content-card" [ngClass]="{ current: isSelected(resource) }"
              (selectEvent)="select(resource)" [resource]="resource"
              *ngFor="let resource of collection"></resource-card>
          </ng-container>
          <!-- DATASET COMPONENT -->
          <ng-container *ngIf="config.dynamic">
            <dcontent-card class="content-card" [ngClass]="{ current: isSelected(dcontent) }"
              (selectEvent)="select(dcontent)" [dcontent]="dcontent"
              *ngFor="let dcontent of collection"></dcontent-card>

            <!-- <div
              class="border p-12 m-12"
              *ngFor="let dcontent of collection"
              [ngClass]="{ 'green-400-bg': isSelected(dcontent) }"
              (click)="select(dcontent)"
            >
              {{ dcontent.name }}
            </div> -->
          </ng-container>
        </div>

        <div fxFlex fxLayoutAlign="center center" *ngIf="!brandSelected && !showSelected">
          {{ 'Seleziona un brand' | translate }}
        </div>
        <div fxFlex fxLayoutAlign="center center" *ngIf="brandSelected && loading">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
        <div fxFlex fxLayoutAlign="center center" *ngIf="brandSelected && empty && !loading">
          {{ 'Nessuna risorsa trovata' | translate }}
        </div>
      </div>
      <!-- [hidden]="resourceReferences?.pagination?.pageCount < 2" -->
      <mat-paginator fxLayoutAlign="end center" [length]="resourceReferences?.pagination.totalCount" [pageSize]="20"
        [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
      <footer fxFlex="60px" fxLayout fxLayoutGap="10px" fxLayoutAlign="end center" class="p-16">
        <div fxFlex fxLayout fxLayoutAlign="start center" fxLayoutGap="10px" fxHide fxShow.gt-xs>
          <ng-container *ngTemplateOutlet="selectedTools"></ng-container>
        </div>
        <mat-form-field style.marginTop="-10px" floatLabel="never" *ngIf="hasMultipleScreens">
          <mat-select name="selectedScreen" [(ngModel)]="selectedScreen"
            [placeholder]="'Seleziona risoluzione' | translate" (selectionChange)="screenChanged()">
            <mat-option *ngIf="config.screens?.length > 1" [value]="''">{{
              'Tutte risoluzioni' | translate
            }}</mat-option>
            <mat-option *ngFor="let screen of config.screens" [value]="screen">
              <app-resolution class="ellipsis" [width]="screen.width" [height]="screen.height"></app-resolution>
            </mat-option>
          </mat-select>
          <mat-hint class="ellipsis">
            <span>
              {{
                '{count} {count, plural, one{risoluzione} other{risoluzioni} }'
                  | translate: { count: config.screens.length }
              }}</span>
            <!-- <span class="ellipsis" *ngFor="let screen of config.screens; let last = last;">
                <app-resolution [width]="screen.width" [height]="screen.height"></app-resolution><span *ngIf="!last">, </span>
              </span> -->
          </mat-hint>
        </mat-form-field>
        <div *ngIf="!hasMultipleScreens" fxLayout="row" fxLayoutAlign="flex-start center">
          <mat-icon class="app-icon-xs">swap_horiz</mat-icon> {{ config.width }} {{ config.width !== '*' ? 'px' : '' }}
        </div>
        <div *ngIf="!hasMultipleScreens" fxLayout="row" fxLayoutAlign="flex-start center">
          <mat-icon class="app-icon-xs">swap_vert</mat-icon> {{ config.height }} {{ config.height !== '*' ? 'px' : '' }}
        </div>
      </footer>
      <footer fxFlex="40px" fxLayout fxLayoutGap="10px" fxLayoutAlign="end center" class="p-16" fxShow fxHide.gt-xs>
        <div fxFlex fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
          <ng-container *ngTemplateOutlet="selectedTools"></ng-container>
        </div>
      </footer>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annulla</button>
  <button [disabled]="collection.length < 1" mat-raised-button color="accent" [mat-dialog-close]="collection">
    {{ 'Conferma' | translate }}
  </button>
  <!-- cdkFocusInitial is not focusable -->
</mat-dialog-actions>

<ng-template #selectedTools>
  <div *ngIf="config.multi" fxLayout="row" fxLayoutAlign="flex-start center" matRipple
    (click)="showSelected = !showSelected" class="cursor-pointer">
    <mat-icon [matTooltip]="'Mostra selezionati' | translate" [matTooltipPosition]="'above'"
      [ngClass]="{ 'accent-fg': showSelected }" class="mr-4">layers</mat-icon>
    <small>{{ 'Selezionati' | translate }}: {{ collection.length }}</small>
  </div>
  <mat-icon [matTooltipShowDelay]="1000" [matTooltip]="'Deseleziona' | translate" [matTooltipPosition]="'above'"
    (click)="clear()" *ngIf="collection.length > 0" class="warn-fg cursor-pointer">delete</mat-icon>
</ng-template>
<ng-template #searchResources>
  <div fxLayout="row" fxFlex="100" fxLayoutGap="12px">
    <div *ngIf="!config.dynamic">
      <mat-button-toggle-group multiple [(ngModel)]="selectedResourceTypes"
        [disabled]="selectedResourceTypesDisabled || loadingBrands || loading" (change)="onResourceTypeChange($event)">
        <ng-container *ngFor="let resType of resourceTypes">
          <mat-button-toggle [value]="resType.value" [matTooltip]="resType.label" [aria-label]="resType.label">
            <mat-icon>{{ resType.icon }}</mat-icon>
          </mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </div>
    <div fxFlex>
      <mat-form-field fxFlex="100" [floatLabel]="'never'">
        <input autocomplete="off" matInput
          [placeholder]="!config.dynamic ? ('Cerca risorse' | translate) : ('Cerca contenuti' | translate)"
          [(ngModel)]="resourceSearchTerm" clearable />
        <!-- <button mat-button *ngIf="resourceSearchTerm" (click)="resourceSearchTerm = null" matSuffix mat-icon-button>
          <mat-icon>close</mat-icon>
        </button> -->
      </mat-form-field>
    </div>
  </div>
</ng-template>