<div fxLayout="row wrap" class="dropdown-component" fxLayoutAlign="flex-end center">
  <!-- STANDALONES LIST -->
  <ng-container *ngIf="hasStandalone && isRoot">
    <!-- <span>{{ standaloneItems?.length > 0 ? standaloneItems[0].label : ''  }}</span> -->
    <!-- <span>{{ item?.monitor?.warningCount || 0  }}</span> -->
    <!-- <span>{{ standaloneItems?.length  }}</span> -->
    <!-- <pre>{{ standaloneItems[0] | json  }}</pre> -->

    <ng-container *ngFor="let option of standaloneItems">

      <!-- {{ option.label }} -->

      <!-- GROUP -->
      <ng-container *ngIf="isGroup(option)">
        <!-- DYNAMIC MENU -->
        <app-dropdown #standAloneMenu [options]="option.children" [item]="item" [index]="index"
          (optionClick)="submenuOptionClickHandler($event)" [isRoot]="false"></app-dropdown>
        <!-- DYNAMIC TRIGGER -->
        <ng-container *ngIf="loaded && !!standAloneMenu && !!standAloneMenu.childMenu">
          <button mat-icon-button [matMenuTriggerFor]="standAloneMenu.childMenu" [disabled]="option.disabled"
            [ngClass]="option.classes" [hidden]="option.hidden" (click)="$event.stopPropagation()"
            [matTooltip]="option.label" [fxHide.lt-md]="!(standaloneItems?.length <= 2 && visibleItems?.length <= 1)">
            <mat-icon>{{ option.icon }}</mat-icon>
          </button>
        </ng-container>
      </ng-container>


      <!-- ITEM -->
      <ng-container *ngIf="isItem(option)">
        <!-- MENU ITEM -->
        <a *ngIf="option.navigate" mat-icon-button [routerLink]="option.navigate.routerLink"
          [queryParams]="option.navigate.queryParams" [disabled]="option.disabled" [ngClass]="option.classes"
          [hidden]="option.hidden" (click)="$event.stopPropagation()" [matTooltip]="option.label"
          [fxHide.lt-md]="!(standaloneItems?.length <= 2 && visibleItems?.length <= 1)">
          <mat-icon>{{ option.icon }}</mat-icon>
        </a>
        <button *ngIf="!option.navigate" mat-icon-button (click)="optionClickHandler($event, option)"
          [disabled]="option.disabled" [ngClass]="option.classes" [hidden]="option.hidden"
          (click)="$event.stopPropagation()" [matTooltip]="option.label"
          [fxHide.lt-md]="!(standaloneItems?.length <= 2 && visibleItems?.length <= 1)">
          <mat-icon>{{ option.icon }}</mat-icon>
          <!-- <span>C: {{ option.classes }}</span> -->
        </button>
      </ng-container>

    </ng-container>
  </ng-container>

  <!-- MAIN MENU -->
  <mat-menu #childMenu="matMenu" [overlapTrigger]="false">
    <ng-container *ngFor="let option of items">

      <!-- GROUP -->
      <ng-container *ngIf="isGroup(option)">
        <!-- DYNAMIC MENU -->
        <app-dropdown #subMenu [options]="option.children" [item]="item" [index]="index"
          (optionClick)="submenuOptionClickHandler($event)" [isRoot]="false"></app-dropdown>
        <!-- DYNAMIC TRIGGER -->
        <ng-container *ngIf="loaded && !!subMenu && !!subMenu.childMenu">
          <button mat-menu-item [matMenuTriggerFor]="subMenu.childMenu"
            [fxHide.gt-sm]="standaloneMap[option.type] === true" [disabled]="option.disabled" [ngClass]="option.classes"
            [hidden]="option.hidden">
            <mat-icon>{{ option.icon }}</mat-icon>
            <span>{{ option.label }}</span>
          </button>
        </ng-container>
      </ng-container>

      <!-- ITEM && SEPARATOR -->
      <ng-container *ngIf="!isGroup(option)">

        <!-- SEPARATOR -->
        <ng-container *ngIf="isSeparator(option)">
          <mat-divider [ngClass]="option.classes" [hidden]="option.hidden"></mat-divider>
        </ng-container>

        <!-- ITEM -->
        <ng-container *ngIf="isItem(option)">
          <!-- MENU ITEM -->
          <a *ngIf="option.navigate" mat-menu-item [routerLink]="option.navigate.routerLink"
            [queryParams]="option.navigate.queryParams" [fxHide.gt-sm]="standaloneMap[option.type] === true"
            [disabled]="option.disabled" [ngClass]="option.classes" [hidden]="option.hidden">
            <mat-icon>{{ option.icon }}</mat-icon>
            <span>{{ option.label }}</span>
          </a>
          <button *ngIf="!option.navigate" mat-menu-item [fxHide.gt-sm]="standaloneMap[option.type] === true"
            (click)="optionClickHandler($event, option)" [disabled]="option.disabled" [ngClass]="option.classes"
            [hidden]="option.hidden">
            <mat-icon>{{ option.icon }}</mat-icon>
            <span>{{ option.label }}</span>
          </button>
        </ng-container>

      </ng-container>

    </ng-container>
  </mat-menu>

  <!-- MAIN MENU TRIGGER -->
  <ng-container *ngIf="hasTrigger && isRoot && childMenu">
    <button mat-icon-button [matMenuTriggerFor]="childMenu" (click)="$event.stopPropagation()"
      [fxHide.gt-sm]="visibleItems?.length <= 1"
      [fxHide.lt-md]="(standaloneItems?.length <= 2 && visibleItems?.length <= 1)">
      <mat-icon>more_vert</mat-icon>
    </button>
  </ng-container>
</div>