export enum UPLOADER_TYPE {
  file,
  image,
  video,
  document,
  imagesAndVideos
}

export enum UPLOADER_TYPE_MODE {
  icon,
  label
}

export enum UPLOADER_STATUS {
  disabled,
  required,
  error,
  ready,
  validate,
  pause,
  upload,
  complete,
  incomplete
}

export enum UPLOADER_STATUS_MODE {
  icon,
  label
}

export enum UPLOADER_FILE_ERROR {
  unknown,
  server,
  accept,
  minSize,
  maxSize,
  minWidth,
  minHeight
}
