import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Customer, CustomerInterface } from 'app/models/customer.model';
import { License, CustomerLicenseGeneratorInterface } from 'app/models/license.model';
import DateTimeFormat = Intl.DateTimeFormat;
import { map } from 'rxjs/operators';
import { PaginatedResponse } from 'app/models/pagination.model';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { CachedParamsService } from 'app/core/auth/services/cached-params.service';
import { LS_FOLDER_RESOURCES_SELECTOR } from 'app/modules/global/localstorage';

@Injectable({
  providedIn: 'root',
})
export class CustomerRepositoryService {
  /**
   *Creates an instance of CustomerRepositoryService.
   * @param {HttpClient} _http
   * @memberof CustomerRepositoryService
   */
  constructor(
    private _http: HttpClient,
    private _currentUserService: CurrentUserService,
    private _cachedParamsService: CachedParamsService,
  ) { }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<PaginatedResponse<CustomerInterface>>}
   * @memberof CustomerRepositoryService
   */
  findAll(_params: any = {}): Observable<PaginatedResponse<CustomerInterface>> {
    if (this.isAdmin) {
      return this._http.get<PaginatedResponse<CustomerInterface>>('customers', {
        params: _params,
      });
    } else {
      const items = this._currentUserService.currentUser?.customers;
      return of(new PaginatedResponse({
        pagination: {
          totalCount: items.length,
          current: 1,
          pageCount: 1,
        },
        items,
      }));
    }
  }
  /**
   *
   *
   * @returns {Observable<PaginatedResponse<CustomerInterface>>}
   * @memberof CustomerRepositoryService
   */
  getAll(): Observable<CustomerInterface[]> {
    return this._http.get<CustomerInterface[]>(`customers/all`);
  }

  get hasOneCustomer() {
    return !this.isAdmin && this._currentUserService.currentUser.customers.length === 1;
  }

  getFirstCustomer() {
    return this._currentUserService.currentUser.customers[0];
  }

  async getSelectedCustomer() {
    const folderConf = this._cachedParamsService.getCachedParams(LS_FOLDER_RESOURCES_SELECTOR);
    if (this.isAdmin) {
      return folderConf.cid ? await this.get(folderConf.cid).toPromise() : null;
    } else {
      if (this.hasOneCustomer || !folderConf.cid) {
        return this.getFirstCustomer();
      } else {
        return await this.get(folderConf.cid).toPromise() || this.getFirstCustomer();
      }
    }
  }

  /**
   *
   *
   * @param {string} q
   * @returns {Observable<CustomerInterface[]>}
   * @memberof CustomerRepositoryService
   */
  search(q: string): Observable<CustomerInterface[]> {
    if (typeof q !== 'string' || !q.trim()) return of([]);
    return this.findAll({
      filters: JSON.stringify([{
        field: 'name',
        type: 'search',
        value: q.trim(),
      }]),
    }).pipe(
      map(v => {
        return v.items;
      })
    )
    // return this._http.get<PaginatedResponse<CustomerInterface>>(`groups/${encodeURIComponent(q)}/search`);
  }


  /**
   *
   *
   * @param {string} id
   * @returns {Observable<CustomerInterface>}
   * @memberof CustomerRepositoryService
   */
  get(id: string): Observable<CustomerInterface> {
    if (this.isAdmin) {
      return this._http.get<CustomerInterface>(`customers/${id}`);
    } else {
      const cs = this._currentUserService.currentUser?.customers;
      const foundCustomer = (cs || []).find(c => c.id === id);
      return of(foundCustomer);
    }
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<Customer>}
   * @memberof CustomerRepositoryService
   */
  create(data: CustomerInterface): Observable<any> {
    return this._http.post(`customers`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<Customer>}
   * @memberof CustomerRepositoryService
   */
  delete(id: string): Observable<Customer> {
    return this._http.delete<Customer>(`customers/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<Customer>}
   * @memberof CustomerRepositoryService
   */
  update(id: string, data: CustomerInterface): Observable<any> {
    return this._http.put(`customers/${id}`, data);
  }

  /**
   *
   *
   * @param {string} id_customer
   * @returns {Observable<License[]>}
   * @memberof CustomerRepositoryService
   */
  getCustomerLicenses(id_customer: string): Observable<License[]> {
    return this._http.get<License[]>(`customers/${id_customer}/licenses`);
  }

  /**
   *
   *
   * @param {string} id_customer
   * @param {DateTimeFormat} billingFrom
   * @param {DateTimeFormat} billingTo
   * @returns {Observable<any>}
   * @memberof CustomerRepositoryService
   */
  getCustomerLicensesCounter(id_customer: string, billingFrom: Date = null, billingTo: Date = null): Observable<any> {
    const filterQuery =
      billingFrom && billingTo ? `?billingFrom=${billingFrom.toISOString()}&billingTo=${billingTo.toISOString()}` : ``;
    return this._http.get<any>(`customers/${id_customer}/licenses_counter${filterQuery}`);
  }

  /**
   * @return {Observable<any>}
   * @memberof CustomerRepositoryService
   */
  getCustomerLicenseGrandTotal(): Observable<any> {
    return this._http.get<any>(`customer/license/grand/total`);
  }

  /**
   *
   *
   * @param {string} id_customer
   * @returns {Observable<License[]>}
   * @memberof CustomerRepositoryService
   */
  getCustomerUnusedLicenses(id_customer: string, id_system: string): Observable<License[]> {
    return this._http.get<License[]>(`customers/${id_customer}/licenses/unused`, { params: { system: id_system } });
  }

  /**
   *
   *
   * @param {string} id_customer
   * @param {CustomerLicenseGeneratorInterface} data
   * @returns {Observable<void>}
   * @memberof CustomerRepositoryService
   */
  generateLicenses(id_customer: string, data: CustomerLicenseGeneratorInterface): Observable<any> {
    return this._http.post(`customers/${id_customer}/generatelicenses`, data);
  }

  /**
   *
   *
   * @param {string} id_customer
   * @param {string} id_license
   * @returns {Observable<Customer>}
   * @memberof CustomerRepositoryService
   */
  deleteLicense(id_customer: string, id_license: string): Observable<Customer> {
    return this._http.delete<Customer>(`customers/${id_customer}/licenses/${id_license}/remove`);
    // return this._http.request<Customer>('delete', `customers/${id_customer}/licenses/${id_license}`, { body: {} });
  }
}
