<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="py-4">
    <!-- WARN ICON -->
    <div fxHide.gt-sm fxFlex="0 1 calc(24px - 8px)">
        <mat-icon color="warn">warning</mat-icon>
    </div>
    <!-- NAME -->
    <div fxLayout="column" fxFlex fxFlex.gt-sm="0 1 calc(300px - 8px)" class="ellipsis">
        <!-- NAME -->
        <app-name-ext [value]="item.name"></app-name-ext>
        <!-- ERRORS -->
        <div fxHide.gt-sm style="white-space: normal;" class="red-400-fg">
            <small>
                <app-uploader-queue-invalid-file-error [error]="item.error"></app-uploader-queue-invalid-file-error>
            </small>
        </div>
    </div>
    <!-- SIZE -->
    <div fxHide.lt-md fxFlex="1 1 0%" class="grey-400-fg text-center">
        <app-filesize style="white-space: nowrap;" [bytes]="item.size"></app-filesize>
    </div>
    <!-- RESOLUTION (optional) -->
    <div fxHide.lt-md fxFlex="1 1 0%" class="grey-400-fg text-center">
        <span *ngIf="!item.customRawFile.customData?.width || !item.customRawFile.customData?.height">&nbsp;</span>
        <app-resolution *ngIf="item.customRawFile.customData?.width && item.customRawFile.customData?.height"
            [width]="item.customRawFile.customData?.width"
            [height]="item.customRawFile.customData?.height"></app-resolution>
    </div>
    <!-- ERRORS -->
    <div fxHide.lt-md fxFlex="0 1 calc(30% - 8px)" class="red-400-fg">
        <div style="white-space: normal;">
            <app-uploader-queue-invalid-file-error [error]="item.error"></app-uploader-queue-invalid-file-error>
        </div>
    </div>
    <!-- EMPTY SPACE -->
    <div fxFlex="0 1 calc(48px - 8px)">&nbsp;</div>
</div>