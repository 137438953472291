import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { GlobalModule } from "app/modules/global/global.module";
import { ColorPickerModule } from "ngx-color-picker";
import { UtilsModule } from "../../utils/ultils.module";
import { ColorpickerComponent } from "./colorpicker.component";


@NgModule({
    declarations: [
        ColorpickerComponent,
    ],
    imports: [
        GlobalModule,
        ColorPickerModule,
        UtilsModule,
    ],
    exports: [
        ColorpickerComponent,
        ColorPickerModule,
        MatDialogModule,
    ]
})
export class AppColorpickerModule {}
