import { Injectable } from '@angular/core';
import {
  FieldInterface,
  FieldOptionsColumnInterface,
  FieldOptionsColumn,
  FieldOptionsInterface,
  FieldOptions,
  Field,
  FieldEnum,
  FieldFeedEnum,
  FieldFeedTypeKeyList,
  FieldType,
} from './fields.interface';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { InputsService } from 'app/modules/common/inputs/inputs.service';
import { FieldsMenuInterface } from '../fields-menu/fields-menu.interface';

@Injectable({
  providedIn: 'root',
})
export class FieldService {
  constructor(private _inputsService: InputsService) { }

  buildItems(items: FieldInterface[] = []) {
    return items.map((item, index) => {
      return new FormGroup(this.buildItem(item));
    });
  }

  buildTemplateOptionsColumn(column?: FieldOptionsColumnInterface) {
    column = new FieldOptionsColumn(column);
    return {
      main: new FormControl(column.main),
      hidden: new FormControl(column.hidden),
    };
  }

  buildTemplateOptions(options?: FieldOptionsInterface) {
    options = new FieldOptions(options);
    return {
      label: new FormControl(options.label, [
        (control) => {
          if (control && control.parent && control.parent.parent) {
            if (
              !FieldFeedTypeKeyList.includes(control.parent.parent.get('type').value)
            ) {
              return Validators.required(control) || this.uniqueLabelValidator(control);
            }
          }
          return null;
        },
      ]),
      placeholder: new FormControl(options.placeholder),
      required: new FormControl(options.required),
      type: new FormControl(options.type),
      rows: new FormControl(options.rows),
      column: new FormGroup(this.buildTemplateOptionsColumn(options.column)),
      options: new FormArray(this._inputsService.buildListOptions(options.options, {}, false, false, ['groups', 'roles'])),
      fields: new FormArray(this.buildItems(options.fields)),
      // NEW 27 MAY 2024
      groups: new FormControl(options.groups),
      roles: new FormControl(options.roles),
    };
  }

  buildItem(item?: FieldInterface) {
    item = new Field(item);
    return {
      key: new FormControl(item.key, [
        (control) => {
          if (control && control.parent) {
            if (
              !FieldFeedTypeKeyList.includes(control.parent.get('type').value)
            ) {
              return Validators.required(control) || this.uniqueKeyValidator(control);
            }
          }
          return null;
        },
      ]),
      templateOptions: new FormGroup(this.buildTemplateOptions(item.templateOptions)),
      type: new FormControl(item.type),
      defaultValue: new FormControl(item.defaultValue),
    };
  }

  uniqueLabelValidator(control: AbstractControl) {
    const _normalizeValue = (value: any) => {
      return (value + '').toLowerCase();
    };
    const prop = 'templateOptions';
    const subProp = 'label';
    if (control.parent && control.parent.parent && control.parent.parent.parent) {
      const propMap = {};
      for (const ctrlItem of <any>control.parent.parent.parent.controls) {
        const key = _normalizeValue(ctrlItem.get(prop).get(subProp).value);
        if (!propMap[key]) {
          propMap[key] = 1;
        } else {
          if (key === _normalizeValue(control.value)) {
            return {
              duplicate: true,
            };
          }
        }
      }
    }
    return null;
  }

  uniqueKeyValidator(control: AbstractControl) {
    const _normalizeValue = (value: any) => {
      return (value + '').toLowerCase();
    };
    const prop = 'key';
    if (control.parent && control.parent.parent) {
      const propMap = {};
      for (const ctrlItem of <any>control.parent.parent.controls) {
        const key = _normalizeValue(ctrlItem.get(prop).value);
        if (!propMap[key]) {
          propMap[key] = 1;
        } else {
          if (key === _normalizeValue(control.value)) {
            return {
              duplicate: true,
            };
          }
        }
      }
    }
    return null;
  }

  removeMainColumn(fields: AbstractControl) {
    (fields as FormArray).controls.map((fieldGroup: FormGroup) => {
      if (this.isMainColumn(fieldGroup)) {
        fieldGroup.get('templateOptions').get('column').get('main').setValue(false);
      }
    });
  }

  hasMainColumn(fields: AbstractControl) {
    return !!(fields as FormArray).controls.find((fieldGroup: FormGroup) => {
      return this.isMainColumn(fieldGroup);
    });
  }

  isMainColumn(group: FormGroup) {
    return (
      group.get('templateOptions') &&
      group.get('templateOptions').get('column') &&
      group.get('templateOptions').get('column').get('main') &&
      group.get('templateOptions').get('column').get('main').value
    );
  }

  mapTypes(types: FieldsMenuInterface[], abstractParent: boolean = true) {
    // console.log('mapTypes', types);
    if (Array.isArray(types)) {
      return types.reduce((acc, val) => {
        if (val.children && Array.isArray(val.children)) {
          // add folder as type
          if (!val.abstract) {
            acc[val.value] = val.label;
          }
          acc = { ...acc, ...this.mapTypes(val.children, !!val.abstract) };
        } else {
          // console.log(
          //   'mapping',
          //   val.value,
          //   'is a abstractParent?',
          //   abstractParent,
          //   'abstract item?',
          //   val.abstract,
          //   'standalone item',
          //   val.standalone,
          // );
          if (abstractParent || val.standalone) {
            acc[val.value] = val.label;
          }
        }
        return acc;
      }, {});
    }
    return {};
  }

  getLabel(group: AbstractControl) {
    return group.value.templateOptions.label;
  }

  isMain(group: AbstractControl) {
    return !!group.value.templateOptions.column.main;
  }

  isHidden(group: AbstractControl) {
    return !!group.value.templateOptions.column.hidden;
  }

  getType(type: AbstractControl | string) {
    return type instanceof AbstractControl ? type.value.type : type;
  }

  isTypeInput(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.input;
  }

  isTypeCheckbox(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.checkbox;
  }

  isTypeSelect(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.select;
  }

  isTypeMultiselect(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.multiselect;
  }

  isTypeRadio(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.radio;
  }

  isTypeTextarea(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.textarea;
  }

  isTypeTextEditor(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.textEditor;
  }

  isTypeList(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.list;
  }

  isTypeColor(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.minicolors;
  }

  isTypeImage(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.image;
  }

  isTypeVideo(type: AbstractControl | string) {
    return this.getType(type) === FieldEnum.video;
  }

  isFeedBased(type: FieldType) {
    return FieldFeedTypeKeyList.includes(<FieldFeedEnum>type);
  }

  isHiddable(type: FieldType) {
    return !this.isFeedBased(type) && type !== FieldEnum.textEditor;
  }

  isNamable(type: FieldType) {
    return !this.isFeedBased(type);
  }
}
