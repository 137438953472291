import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GuidService {
  private _s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  generate(): string {
    return (
      this._s4() +
      this._s4() +
      '-' +
      this._s4() +
      '-' +
      this._s4() +
      '-' +
      this._s4() +
      '-' +
      this._s4() +
      this._s4() +
      this._s4()
    );
  }

  private static _s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  static generate(): string {
    return (
      this._s4() +
      this._s4() +
      '-' +
      this._s4() +
      '-' +
      this._s4() +
      '-' +
      this._s4() +
      '-' +
      this._s4() +
      this._s4() +
      this._s4()
    );
  }
}
