import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('childMenu') childMenu: MatMenu;

  @Input() item: any;
  @Input() index: number;
  @Input() hasStandalone: boolean = true;
  @Input() hasTrigger: boolean = true;
  @Input() isRoot: boolean = true;
  @Input() options: DropdownOptionInterface[] = [];
  @Output() optionClick: EventEmitter<DropdownOptionClickEvent> = new EventEmitter<DropdownOptionClickEvent>();

  standaloneMap: any = {};

  items: DropdownOptionInterface[] = [];

  visibleItems: DropdownOptionInterface[] = [];
  standaloneItems: DropdownOptionInterface[] = [];

  loaded = false;

  constructor(
  ) { }

  ngOnInit() {
    this.refresh();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loaded = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !!changes.options && !changes.options.firstChange ||
      !!changes.item && !changes.item.firstChange ||
      !!changes.index && !changes.index.firstChange
    ) {
      this.refresh();
    }
  }

  hideEmptySubmenus(items) {
    this.visibleItems = [];
    items.forEach(item => {
      if (!this.isSeparator(item)) {
        if (!item.hidden && !this.isStandalone(item)) {
          this.visibleItems.push(item);
        }
      }
    });

    if (this.visibleItems.length === 1) {
      this.standaloneItems.push(this.visibleItems[0]);
    }
  }

  refresh() {
    this.items = this.options.map(option => {
      return { ...option, ...this.getNormalizedItems(option) };
    }).filter(item => {
      return !item.hidden;
    });
    this.standaloneItems = this.getStandaloneItems(this.items);
    this.hideEmptySubmenus(this.items);

    // console.log('refresh', this.item.name, this.items);
  }

  getStandaloneItems(options: DropdownOptionInterface[]) {
    return options.reduce((acc, val) => {
      if (this.isStandalone(val)) {
        this.standaloneMap[val.type] = val.standalone;
        acc.push(val);
      } else {
        if (this.isGroup(val)) {
          acc = [...acc, ...this.getStandaloneItems(val.children)];
        }
      }
      return acc;
    }, []);
  }

  getNormalizedItems(option: DropdownOptionInterface) {

    const fields: any = {};

    // SET HIDDEN
    if (typeof option.$hidden === 'function') {
      fields.hidden = option.$hidden(this.item, this.index, option, this);
    }

    // SET CLASSES
    if (typeof option.$classes === 'function') {
      fields.classes = option.$classes(this.item, this.index, option, this);
    }

    // SET LABEL
    if (typeof option.$label === 'function') {
      fields.label = option.$label(this.item, this.index, option, this);
      // console.log('LABEL IS', this.item.name, option.label);
    }

    // SET ICON
    if (typeof option.$icon === 'function') {
      fields.icon = option.$icon(this.item, this.index, option, this);
    }

    // SET DISABLED
    if (typeof option.$disabled === 'function') {
      fields.disabled = option.$disabled(this.item, this.index, option, this);
    }

    // SET STANDALONE
    if (typeof option.$standalone === 'function') {
      fields.standalone = option.$standalone(this.item, this.index, option, this);
    }

    // ROUTING
    if (typeof option.$navigate === 'function') {
      fields.navigate = this.buildNavigate(option);
    }

    if (this.isGroup(option)) {
      fields.children = option.children.map(child => { return { ...child, ...this.getNormalizedItems(child) } });
    }

    // console.log('normalized item is', fields);

    return fields;
  }

  buildNavigate(option: DropdownOptionInterface) {
    const response = {
      routerLink: null,
      queryParams: null,
    };
    const navigation = option.$navigate(this.item, this.index, option, this);
    response.routerLink = navigation[0];
    if (navigation[1]) {
      response.queryParams = navigation[1].queryParams;
    }
    return response;
  }

  isStandalone(option: DropdownOptionInterface) {
    return this.hasStandalone &&
      (this.isItem(option) || this.isGroup(option)) && option.standalone;
  }

  isSeparator(option: DropdownOptionInterface) {
    return option.kind === 'separator';
  }

  isGroup(option: DropdownOptionInterface) {
    return Array.isArray(option.children) && option.children.length;
  }

  isItem(option: DropdownOptionInterface) {
    return !this.isSeparator(option) && !this.isGroup(option);
  }

  submenuOptionClickHandler(event: DropdownOptionClickEvent) {
    // console.log('submenuOptionClickHandler', event);
    this.optionClickHandler(event.event, event.option);
  }

  optionClickHandler(event: MouseEvent, option: DropdownOptionInterface) {
    // console.log('optionClickHandler', event);
    const dropdownClickEvent: DropdownOptionClickEvent = {
      event: event,
      item: this.item,
      index: this.index,
      option: option,
      dropdown: this,
    };
    if (typeof option.$click === 'function') {
      option.$click(dropdownClickEvent);
    }
    this.optionClick.emit(dropdownClickEvent);
  }
}

export interface DropdownOptionCommonInterface {
  kind?: 'item' | 'separator' | 'group';
  hidden?: boolean;
  classes?: string | object;
  $hidden?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): boolean;
  $classes?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): string | object;
}

export interface DropdownOptionSeparatorInterface extends DropdownOptionCommonInterface {
}

export interface DropdownOptionItemInterface extends DropdownOptionCommonInterface {
  type?: string;

  label?: string;
  icon?: string;
  disabled?: boolean;
  standalone?: boolean | 'both';
  navigate?: {
    routerLink: any[];
    queryParams?: any;
  };

  $label?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): string;
  $icon?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): string;
  $disabled?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): boolean;
  $standalone?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): boolean | 'both';
  $navigate?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): [any[], NavigationExtras?];

  $click?(event: DropdownOptionClickEvent): void;
}

export interface DropdownOptionGroupInterface extends DropdownOptionCommonInterface {
  children?: DropdownOptionInterface[];

  label?: string;
  icon?: string;
  disabled?: boolean;

  $label?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): string;
  $icon?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): string;
  $disabled?(item: any, index: number, option: DropdownOptionInterface, dropdown: DropdownComponent): boolean;
}

export interface DropdownOptionSeparatorInterface extends DropdownOptionCommonInterface {
}

export type DropdownOptionInterface = DropdownOptionSeparatorInterface & DropdownOptionItemInterface & DropdownOptionGroupInterface;

export interface DropdownOptionClickEvent {
  event: MouseEvent;
  item: any;
  index: number;
  option: DropdownOptionInterface;
  dropdown: DropdownComponent;
}
