import { NgModule } from '@angular/core';
import { GlobalModule } from 'app/modules/global/global.module';
import { AutocompleteModule } from '../common/autocompletes/autocomplete.module';
import { ResourcesModule } from '../resources/resources.module';
import { FolderEditDialogComponent } from './components/dialogs/folder-edit-dialog/folder-edit-dialog.component';
import { FolderResourcesDialogComponent } from './components/dialogs/folder-resources-dialog/folder-resources-dialog.component';
import { FolderInfoSidebarModule } from './components/sidebars/folder-info-sidebar/folder-info-sidebar.module';
import { FolderResourcesSelectorDialogComponent } from './components/dialogs/folder-resources-selector-dialog/folder-resources-selector-dialog.component';
import { DatetimeModule } from '../common/datetime/datetime.module';
import { StringModule } from '../common/string/string.module';
import { NewUploaderModule } from '../common/uploader/uploader.module';
import { FolderTreeSelectorComponent } from './components/folder-tree-selector/folder-tree-selector.component';
import { FolderImportDialogComponent } from './components/dialogs/folder-import-dialog/folder-import-dialog.component';

@NgModule({
  declarations: [
    FolderEditDialogComponent,
    FolderResourcesDialogComponent,
    FolderResourcesSelectorDialogComponent,
    FolderTreeSelectorComponent,
    FolderImportDialogComponent,
  ],
  imports: [
    GlobalModule,
    FolderInfoSidebarModule,
    AutocompleteModule,
    ResourcesModule,
    DatetimeModule,
    StringModule,
    NewUploaderModule,
  ],
  exports: [
    FolderEditDialogComponent,
    FolderInfoSidebarModule,
    FolderResourcesDialogComponent,
    FolderResourcesSelectorDialogComponent,
    FolderTreeSelectorComponent,
    FolderImportDialogComponent,
  ]
})
export class FolderComponentsModule { }
