import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { LS_BRAND_UPLOAD_EXPANDED } from 'app/modules/global/localstorage';
import { S3FileUploader } from 'app/modules/uploader/s3';
import { FileLikeObject } from 'ng2-file-upload';

/**
 *
 *
 * @export
 * @class UploaderQueueComponent
 * @description Full name formatter from User entity object or from 'name' and 'surname'
 * @implements {OnInit}
 */
@Component({
  selector: 'app-uploader-queue',
  templateUrl: './uploader-queue.component.html',
  styleUrls: ['./uploader-queue.component.scss']
})
export class UploaderQueueComponent {

  @Input() uploader: S3FileUploader;
  @Input() invalidFiles: FileLikeObject[];
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  panelExpanded: boolean = !!JSON.parse(window.localStorage.getItem(LS_BRAND_UPLOAD_EXPANDED));

  constructor() {
  }

  expandToggle(panel: MatExpansionPanel) {
    this.panelExpanded = panel.expanded;
    window.localStorage.setItem(LS_BRAND_UPLOAD_EXPANDED, !!panel.expanded ? '1' : '0');
  }

}
