<div [formGroup]="form" class="app-folder-tree-selector-component h-100-p" fxLayout="column">
    <!-- SEARCH OR CREATE -->
    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="12px">
        <mat-form-field class="w-100-p">
            <input matInput name="folder_name" formControlName="searchText" [placeholder]="'Cerca cartella' | translate"
                autocomplete="off" [readonly]="disabled" />
            <!-- <mat-icon matSuffix>search</mat-icon> -->
        </mat-form-field>
        <button [matTooltip]="'Crea cartella'|translate" [disabled]="disabled || loadingFolders" mat-icon-button
            (click)="addFolder()">
            <mat-icon>create_new_folder</mat-icon>
        </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="flex-start center">
        <div class="mb-4 app-inputs-label-sm">
            <span *ngIf="foldersList?.pagination.totalCount > 0">
                <ng-container *ngIf="!form.get('searchText').value">
                    {{ 'Cartelle' | translate }}: {{ foldersList?.pagination.totalCount || 0 }}
                </ng-container>
                <ng-container *ngIf="form.get('searchText').value">
                    {{ 'Cartelle trovate' | translate }}: {{ foldersList?.pagination.totalCount || 0 }}
                </ng-container>
            </span>
            <span *ngIf="foldersList?.pagination.totalCount <= 0">
                {{ 'Nessuna cartella trovata' | translate }}
            </span>
        </div>

        <div fxFlex></div>

        <div class="mb-12 mr-4">
            <button class="app-icon-btn-sm" [matTooltip]="'Aggiorna contenuti' | translate" mat-icon-button
                [disabled]="disabled || loadingFolders" (click)="refreshFolders()">
                <mat-icon [ngClass]="{ 'app-rotate': loadingFolders }">autorenew</mat-icon>
            </button>
        </div>

        <div class="mb-12 mr-8">
            <button class="app-icon-btn-sm"
                [matTooltip]="form.get('ascending').value ? ('Ordine ascendente' | translate) : ('Ordine discendente' | translate)"
                [disabled]="disabled || loadingFolders" mat-icon-button (click)="toggleDirection()">
                <mat-icon *ngIf="!form.get('ascending').value">arrow_downward</mat-icon>
                <mat-icon *ngIf="form.get('ascending').value">arrow_upward</mat-icon>
            </button>
        </div>

    </div>

    <!-- FOLDERS LIST -->
    <div [ngClass]="{ 'XXloading': loadingFolders, 'fluid-disabled': disabled || loadingFolders }"
        ngClass.gt-sm="fr-overflow" fxFlex>
        <div *ngFor="let folderItem of foldersList?.items" matRipple class="border-radius-4 cursor-pointer p-8"
            (click)="selectFolder(folderItem)" [ngClass]="{ 'orange-400': folder?.id === folderItem.id }">
            <div fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
                <mat-icon class="mr-8">folder</mat-icon>
                <div class="ellipsis">
                    <div class="ellipsis">
                        {{ folderItem.name }}
                    </div>
                    <div class="ellipsis font-size-10">
                        <app-date [date]="folderItem.updated_at" formatter="short"></app-date>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>