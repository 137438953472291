import { Customer, CustomerInterface } from './customer.model';
import { GroupInterface } from './group.model';
import { UpdatedBy, UpdatedByInterface } from './update-by.model';

export interface FolderInterface {
  id?: string;
  name?: string;
  description?: string;
  created_at?: Date;
  updated_at?: Date;
  updated_by?: UpdatedByInterface;
  nFiles?: number;
  customers?: CustomerInterface[];
  groups?: GroupInterface[];
  lock?: boolean;
}

export class Folder implements FolderInterface {
  id: string;
  name: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  updated_by: UpdatedByInterface;
  nFiles: number;
  customers: CustomerInterface[] = [];
  groups: GroupInterface[] = [];
  lock: boolean = false;

  /**
   * Constructor
   *
   * @param _folder
   */
  constructor(private _folder?: FolderInterface) {
    Object.assign(this, this._folder);

    this.updated_by = new UpdatedBy(this._folder.updated_by);

    this.customers = (this._folder.customers || []).map(c => new Customer(c));

    delete this._folder;
  }
}
