import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatAccordion } from '@angular/material/expansion';
import { DragulaService } from 'ng2-dragula';
import { GuidService } from 'app/modules/global/services/guid.service';
import { Screen } from 'app/modules/system/components/screens/screens.interface';
import {
  FIELD_INPUT_TYPES_ARRAY,
  FieldInterface,
  FIELD_TYPES_ARRAY,
  FieldEnum,
  FieldType,
  FieldInputEnum,
  FieldFeedEnum,
  FieldFeedTypeKeyList,
} from './fields.interface';
import { FieldService } from './fields.service';
import { FormUtils } from 'app/modules/global/classes/form-utils';
import { FieldsMenuClickedEvent, FieldsMenuInterface } from '../fields-menu/fields-menu.interface';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { REGEXP_SLUG } from 'app/modules/global/regexps';
import { GroupInterface } from 'app/models/group.model';
import { CurrentUserService } from 'app/core/auth/services/current-user.service';
import { RoleInterface } from 'app/models/user.model';
import { UserRepositoryService } from 'app/repositories/user-repository.service';

/**
 *
 *
 * @export
 * @class FieldsComponent
 * @description Fields (type, contents, conditions, ranges, times) component used in Editorial entity item
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss'],
})
export class FieldsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input() formArray: FormArray;

  @Input() required: boolean;
  @Input() hasPrimary: boolean = true;
  @Input() hasVisibility: boolean = true;
  @Input() index: number = 0;

  @Input() public screen: Screen;
  @Input() public isPlaylist: boolean;

  @Input()
  set fieldtypes(values: any[]) {
    this._fieldtypes = this._i18nService.translateArray(values, ['label']);
  }
  get fieldtypes() {
    return this._fieldtypes;
  }
  protected _fieldtypes = this._i18nService.translateArray(FIELD_TYPES_ARRAY, ['label']);

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.formArray.disable();
    } else {
      this.formArray.enable();
    }
  }
  get disabled() {
    return this._disabled;
  }
  protected _disabled = false;

  @Input() groupList: GroupInterface[] = [];

  model: any = {};
  dragGroupName: string;
  selectedTab: number;

  fieldinputtypes = this._i18nService.translateArray(FIELD_INPUT_TYPES_ARRAY, ['label']);

  fieldtypesMap: any = {};
  fieldinputtypesMap: any = {};

  REGEXP_SLUG = REGEXP_SLUG;

  roleList: RoleInterface[];

  FieldEnum = FieldEnum;

  constructor(
    public fieldService: FieldService,
    private _dragulaService: DragulaService,
    private _guid: GuidService,
    private _cdr: ChangeDetectorRef,
    private _i18nService: I18nService,
    private _currentUserService: CurrentUserService,
    private _userRepositoryService: UserRepositoryService,
  ) {
    console.log('FIELDS COMPONENT CONSTRUCTOR');
  }

  get isAdmin() {
    return this._currentUserService.isAdmin();
  }

  parsedGroupList: any[];

  refreshParsedGroups() {
    this.parsedGroupList = (JSON.parse(JSON.stringify(this.groupList)) || []).map(group => {
      return {
        id: group.id,
        name: group.name,
        write: true,
      }
    });
  }

  ngOnInit() {

    this.refreshParsedGroups();

    // GET USER ROLES
    this.roleList = this._userRepositoryService.getRoles();

    this.fieldtypesMap = this.fieldService.mapTypes(this.fieldtypes);
    // console.log('fieldtypesMap', this.fieldtypesMap);
    this.fieldinputtypesMap = this.fieldService.mapTypes(this.fieldinputtypes);
    // console.log('fieldinputtypesMap', this.fieldinputtypesMap);
    // INIT DRAGULA
    this.dragGroupName = this._guid.generate();
    // drag only `fluid-drag` classed elements
    const dragula = this._dragulaService.createGroup(this.dragGroupName, {
      moves: (el, container, handle) => {
        // console.log('handle', { a: handle });
        if (typeof handle.className.includes === 'function') {
          return handle.className.includes(`fluid-drag-${this.dragGroupName}`);
        } else {
          return false;
        }
      },
    });
  }

  ngOnChanges(changes) {
    if (changes && changes.fieldtypes && !changes.fieldtypes.firstChange) {
      this.fieldtypesMap = this.fieldService.mapTypes(this.fieldtypes);
    }
    if (changes && changes.groupList && !changes.groupList.firstChange) {
      this.refreshParsedGroups();
    }
  }

  ngOnDestroy() {
    this._dragulaService.destroy(this.dragGroupName);
  }

  onOrderChange(event) {
    // console.log('order changed', event.map(v => v.value.templateOptions.label));
    this.formArray.controls = event;
    this.formArray.updateValueAndValidity();
    // console.log('current order', this.formArray.controls.map(v => v.value.templateOptions.label));
  }

  isMain(group) {
    return !!group.get('templateOptions').get('column').get('main').value;
  }

  setMain(currentGroup) {
    this.formArray.controls.forEach((group) => {
      group.get('templateOptions').get('column').get('main').setValue(false);
    });
    currentGroup.get('templateOptions').get('column').get('main').setValue(true);
  }

  duplicate(event: MouseEvent, currentGroup: FormGroup, currentIndex: number) {
    const newObj = JSON.parse(JSON.stringify(currentGroup.value));
    newObj.templateOptions.label += ' copy';

    if (this.isMain(currentGroup)) {
      newObj.templateOptions.column.main = false;
    }

    const fa = this.formArray.controls;
    fa.splice(currentIndex + 1, 0, new FormGroup(this.fieldService.buildItem(newObj)));
    this.formArray.updateValueAndValidity();
    // this.formArray.controls(new FormGroup(this.fieldService.buildItem(newObj)));
    FormUtils.unvalidateFormField(this.formArray);
    this._cdr.detectChanges();
  }

  hasOptions(type: FieldType) {
    return type === FieldEnum.radio || type === FieldEnum.select || type === FieldEnum.multiselect;
  }

  isTimeBased(type: FieldInputEnum) {
    return type === FieldInputEnum.time || type === FieldInputEnum.date || type === FieldInputEnum.month;
  }

  isPrimariable(type: FieldType) {
    return (
      type === FieldEnum.input ||
      type === FieldEnum.select ||
      type === FieldEnum.textarea ||
      type === FieldEnum.textEditor ||
      type === FieldEnum.minicolors ||
      type === FieldEnum.radio ||
      type === FieldEnum.list
    );
  }

  isFeedBased(type: FieldType) {
    return this.fieldService.isFeedBased(type);
  }

  isHiddable(type: FieldType) {
    return this.fieldService.isHiddable(type);
  }

  isNamable(type: FieldType) {
    return this.fieldService.isNamable(type);
  }

  onTypeChange(currentGroup) {
    const v: FieldInterface = currentGroup.value;
    const i = this.formArray.controls.indexOf(currentGroup);

    const obj: FieldInterface = {
      type: v.type,
      key: v.key,
      templateOptions: {
        type: v.type === FieldEnum.input ? v.templateOptions.type : undefined,
        label: v.templateOptions.label,
        placeholder: !this.isTimeBased(v.templateOptions.type) ? v.templateOptions.placeholder : undefined,
        required: v.templateOptions.required,
        options: this.hasOptions(v.type) ? v.templateOptions.options : [],
        column: {},
        // NEW 27MAY 2024
        groups: v.templateOptions.groups,
        roles: v.templateOptions.roles,
      },
    };

    if (this.hasPrimary && this.isPrimariable(v.type)) {
      obj.templateOptions.column.main = !!v.templateOptions.column.main;
    }

    if (this.hasVisibility && this.isHiddable(v.type)) {
      obj.templateOptions.column.hidden = !!v.templateOptions.column.hidden;
    }

    this.formArray.controls[i] = new FormGroup(this.fieldService.buildItem(obj));
    this.selectedTab = i;
    this.formArray.updateValueAndValidity();
  }

  onFieldEditClick(event: MouseEvent) {
    console.log('onFieldEditClick', event);
    event.stopPropagation();
  }

  editField(event: FieldsMenuClickedEvent, group: FormGroup) {
    console.log('editField', event, group);

    let fieldType = event.value.value;
    let typology = null;

    if (event.parents.length) {
      const foundFieldType = event.parents.find((item) => {
        return !item.abstract;
      });
      if (foundFieldType) {
        fieldType = foundFieldType.value;
        typology = event.value.value;
      }
    }

    console.log('fieldType', fieldType, 'typology', typology);

    group.get('type').setValue(fieldType);

    if (typology) {
      group.get('templateOptions').get('type').setValue(typology);
    }

    // REMOVE MAIN FIELD IF NOT ALLOWED
    if (group.value.templateOptions?.column?.main) {
      if (!this.isPrimariable(fieldType)) {
        group.get('templateOptions').get('column').get('main').setValue(false);
      }
    }

  }

  addField(event: FieldsMenuClickedEvent) {
    let fieldType = event.value.value;
    let typology = null;

    if (event.parents.length) {
      const foundFieldType = event.parents.find((item) => {
        return !item.abstract;
      });
      if (foundFieldType) {
        fieldType = foundFieldType.value;
        typology = event.value.value;
      }
    }

    // console.log('addField', event);
    console.log('addField result:', fieldType, typology);

    let key = '';
    if (this.isFeedBased(fieldType)) {
      key = fieldType;
    }

    if (fieldType) {
      // INIT NEW OBJ
      const obj: FieldInterface = {
        type: fieldType,
        key: key,
        templateOptions: {},
      };
      // SET PRIMARY COLUMN IF ALLOWED AND NONE IS SET
      if (!this.fieldService.hasMainColumn(this.formArray) && this.hasPrimary && this.isPrimariable(fieldType)) {
        obj.templateOptions.column = {
          main: true,
        };
      }
      // SET TYPOLOGY
      if (typology) {
        obj.templateOptions.type = typology;
      }
      console.log('obj is', typology, obj);
      // PUSH TO ARRAY
      this.formArray.push(new FormGroup(this.fieldService.buildItem(obj)));
      FormUtils.unvalidateFormField(this.formArray);
      this._cdr.detectChanges();
      // SET LAST ITEM SELECTED
      this.selectedTab = this.formArray.length - 1;
      console.log('sent fields validation update');
    }
  }
}
