import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BrandInterface } from 'app/models/brand.model';
import { BrandRepositoryService } from 'app/repositories/brand-repository.service';
import { Observable } from 'rxjs';
import { AutocompleteList } from '../autocomplete/autocomplete.component';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-brand-autocomplete',
  templateUrl: './brand-autocomplete.component.html',
  styleUrls: ['./brand-autocomplete.component.scss'],
})
export class BrandAutocompleteComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() placeholder: string = this._i18nService.translate(_('Cerca un brand'));
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() full: boolean;
  @Input() hint: string;
  @Input() loading: boolean;
  @Input() floatLabel: string;
  // LIST FILTER FN
  @Input() filterItemsFn: (filteredItems: any[]) => any[];
  @Output() changeSelection: EventEmitter<BrandInterface> = new EventEmitter<BrandInterface>();
  @Output() changeFilteredList: EventEmitter<BrandInterface[]> = new EventEmitter<BrandInterface[]>();

  brandList: AutocompleteList<BrandInterface>;

  constructor(private _brandRepositoryService: BrandRepositoryService, private _i18nService: I18nService) { }

  ngOnInit() {
    this.brandList = (q: string): Observable<BrandInterface[]> => {
      if (this.full) {
        return this._brandRepositoryService.fullSearch(q);
      } else {
        return this._brandRepositoryService.search(q);
      }
    };
  }

  onBrandChange(brand: BrandInterface) {
    console.log('BrandAutocompleteComponent onBrandChange');
    this.control?.setValue(brand);
    this.changeSelection.emit(this.control.value);
  }

  ngOnChanges(changes) {
    // if (changes.control && changes.control.currentValue) {
    //   console.log('BrandAutocompleteComponent ngOnChanges', changes.control.currentValue, changes.control.previousValue);
    //   this.changeSelection.emit(this.control.value);
    // }
  }
}
