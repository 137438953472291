import { BackgroundImageDirective } from './directives/background-image.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './pipes/filter.pipe';
import { NgMaterialModule } from '../../ng-material/ng-material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DisableControlDirective } from './directives/disable-control.directive';
import { BackwardsDirective } from './directives/backwards.directive';
import { ClickConfirmDirectiveModule } from './directives/click-confirm/click-confirm.module';
import { InputOptionsModule } from './directives/input-options/input-options.module';
import { OrderPipe } from './pipes/orderby.pipe';
import { FluidDatePipe } from './pipes/fluidDate.pipe';
import { FluidSecondsToDurationPipe } from './pipes/fluidDuration.pipe';
import { BitratePipe } from './pipes/bitrate.pipe';
import { ViewportChangeDirective } from './directives/viewport-change.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { OpenResourceDirective } from './directives/open-resource.directive';
import { ProgressClassDirective } from './directives/progress-class.directive';
import { ShowAuthedDirective } from './directives/show-authed.directive';
import { SubmitDirectiveModule } from './directives/submit.directive.module';
import { TimeagoCustomModule } from '../common/datetime/timeago/timeago.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonTypeDirective } from './directives/button-type.directive';
import { FluidCurrencyDirective } from './directives/currency.directive';
import { FluidCurrencyPipe } from './pipes/currency.pipe';
import { HintModule } from '../common/hint/hint.module';
import { HintDirectiveModule } from './directives/hint/hint.module';
import { InViewDirective } from './directives/in-view.directive';
import { IconModule } from './components/icon/icon.module';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ConsoleLogComponent } from './components/consolelog.component';
import { IsAdminDirective } from './directives/is-admin.directive';
// import { CustomTranslatePipe } from 'app/services/i18n.service';

@NgModule({
  declarations: [
    FilterPipe,
    OrderPipe,
    FluidDatePipe,
    FluidSecondsToDurationPipe,
    BitratePipe,
    FileSizePipe,
    FluidCurrencyPipe,
    BackgroundImageDirective,
    DisableControlDirective,
    BackwardsDirective,
    ProgressClassDirective,
    OpenResourceDirective,
    ViewportChangeDirective,
    ShowAuthedDirective,
    IsAdminDirective,
    ButtonTypeDirective,
    FluidCurrencyDirective,
    InViewDirective,
    AutofocusDirective,
    ConsoleLogComponent,
    // CustomTranslatePipe,
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    NgMaterialModule,
    ClickConfirmDirectiveModule,
    InputOptionsModule,
    SubmitDirectiveModule,
    TimeagoCustomModule,
    TranslateModule,
    HintModule,
    HintDirectiveModule,
    IconModule,
  ],
  exports: [
    // COMMON MODULES
    CommonModule,
    FuseSharedModule, // fuse forms and more...
    NgMaterialModule, // material components
    // COMPONENT MODULES
    ClickConfirmDirectiveModule,
    InputOptionsModule,
    // COMMON UTILS
    FilterPipe,
    OrderPipe,
    FluidDatePipe,
    BitratePipe,
    FileSizePipe,
    FluidSecondsToDurationPipe,
    FluidCurrencyPipe,
    // CustomTranslatePipe,
    // COMMON DIRECTIVES
    BackgroundImageDirective,
    DisableControlDirective,
    BackwardsDirective,
    ProgressClassDirective,
    OpenResourceDirective,
    ViewportChangeDirective,
    ShowAuthedDirective,
    IsAdminDirective,
    ButtonTypeDirective,
    SubmitDirectiveModule,
    TimeagoCustomModule,
    NgxPermissionsModule,
    FluidCurrencyDirective,
    AutofocusDirective,
    TranslateModule,
    HintModule,
    HintDirectiveModule,
    InViewDirective,
    IconModule,
    ConsoleLogComponent,
  ],
  providers: [FluidCurrencyPipe],
})
export class GlobalModule { }
