export const mimes = {
  images: [
    'image/png',
    'image/jpeg',
    'image/x-png'
  ],
  videos: [
    'video/3gpp',
    'video/quicktime',
    'video/mp4',
    'video/3gpp2',
    'video/x-msvideo',
    'video/x-f4v',
    'video/x-flv',
    'video/h264',
    'video/jpeg',
    'video/x-ms-asf',
    'video/x-ms-wm',
    'video/x-ms-wmv',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/avi'
  ],
  documents: [
    'application/octet-stream',
    'application/x-rar-compressed',
    'application/x-zip-compressed',
    'application/zip',
  ],
};

export const mimeExtensions = {
  images: [
    'png',
    'jpeg',
    'jpg'
  ],
  videos: [
    '3gp',
    'mov',
    'qt',
    'mp4',
    '3g2',
    'avi',
    'f4v',
    'flv',
    'h264',
    'jpgv',
    'asf',
    'wm',
    'wmv',
    'mpeg',
    'mpg',
    'mpe',
    'm1v',
    'm2v',
    'ogv',
    'webm'
  ], documents: [
    // 'bin',
    // 'rar',
    'zip'
  ]
}