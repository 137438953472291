import { Injectable } from '@angular/core';
import { DataTransformAbstractDown } from 'app/modules/global/classes/data.transform.abstract.down';
import { DatePipe } from '@angular/common';
import { FolderInterface } from 'app/models/folder.model';

@Injectable({
  providedIn: 'root',
})
export class FolderDataTransformDown extends DataTransformAbstractDown {

  constructor(protected datePipe: DatePipe) {
    super();
  }

  transform(folder: FolderInterface): FolderInterface {
    folder.customers = (folder.customers || []).map(v => v.id) as any;
    return folder;
  }
}
