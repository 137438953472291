<div fxLayout="column" fxFlex="100">
  <!-- SELECTOR -->
  <mat-button-toggle-group fxFlex="100" [(ngModel)]="selector" (change)="selectorChange($event)" class="ellipsis">
    <mat-button-toggle *ngFor="let selectorItem of selectorsList" [value]="selectorItem.name"
      [checked]="selector === selectorItem.name" [disabled]="disabled"
      [matTooltip]="lastSelector === selectorItem.name ? '' : selectorItem.label" [matTooltipPosition]="'above'"
      [fxFlex]="lastSelector === selectorItem.name ? '100' : ''"
      [ngClass]="{ 'loading': loading && selector === selectorItem.name, 'ellipsis': lastSelector === selectorItem.name }">
      <!-- [fxFlex]="selector === selectorItem.name ? '100' : ''" [hidden]="!!selector && selector !== selectorItem.name" -->
      <mat-icon>{{ selectorItem.icon }}</mat-icon>
      <span *ngIf="lastSelector === selectorItem.name" class="ml-4 ellipsis"
        [ngClass]="{ 'app-required': required, 'red-400-fg': touched && required && !selectedList?.length }">{{ selectorItem.label }}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <!-- SEARCH -->
  <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" [matMenuTriggerData]="menuElementData"
    (menuOpened)="menuOpened()" (menuClosed)="menuClosed()" (click)="$event.stopPropagation()"></div>
  <mat-menu #menu="matMenu" class="entities-selector-menu" [overlapTrigger]="false">
    <ng-template matMenuContent let-width="width">
      <div [style.width.px]="width" (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()" (click)="$event.stopPropagation()">
        <div class="px-12 pb-8 grey-100-bg">
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <mat-form-field fxFlex="100" floatLabel="never">
              <input matInput [formControl]="filterControl" [disabled]="disabled" appInputAutofocus autocomplete="off"
                name="entities_selector_filter" [placeholder]="filterPlaceholder">
              <mat-hint
                *ngIf="selector === EntitiesSelectorTypeEnum.search && filterControl.value && filteredList[selector] && filteredList[selector].length > 0">
                {{ '{count} elementi' | translate: { count: filteredList[selector]?.length || 0 } }}
              </mat-hint>
              <mat-hint *ngIf="selector === EntitiesSelectorTypeEnum.favorite">
                {{ '{count} elementi' | translate: { count: filteredList[selector]?.length || 0 } }}
              </mat-hint>
              <mat-hint *ngIf="selector === EntitiesSelectorTypeEnum.history">
                {{ '{count} elementi' | translate: { count: filteredList[selector]?.length || 0 } }}
              </mat-hint>
            </mat-form-field>
            <div *ngIf="multiple && filteredList[selector]?.length > 0" class="ml-4">
              <button type="button" mat-icon-button (click)="addAll($event)" class="app-icon-btn-sm"
                [matTooltip]="'Seleziona tutti' | translate" [matTooltipPosition]="'above'">
                <mat-icon>checklist</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <!-- fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true }" -->
        <div class="entities-selector-menu-body">
          <ng-container
            *ngTemplateOutlet="listTpl ? listTpl : defaultListTpl; context: { component: component }"></ng-container>
        </div>
        <ng-container *ngIf="selector !== EntitiesSelectorTypeEnum.search && filterControl.value">
          <div class="text-center p-4">
            <button [disabled]="disabled" type="button" class="w-100-p" mat-flat-button
              (click)="searchAll($event)">{{'cerca tutti' | translate}}</button>
          </div>
        </ng-container>
        <!-- <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button> -->
      </div>
    </ng-template>
  </mat-menu>
  <!-- SELECTED LIST -->
  <ng-container
    *ngTemplateOutlet="selectedListTpl ? selectedListTpl : defaultSelectedListTpl; context: { component: component }"></ng-container>
</div>

<!-- SEARCH LIST -->

<ng-template #defaultPreItemTpl let-item="item" let-component="component"></ng-template>

<ng-template #defaultItemTpl let-item="item" let-component="component">
  <span fxFlex *ngIf="component.fullName" class="word-break-all lh1">
    {{ item.name }}
  </span>
  <app-name-ext fxFlex *ngIf="!component.fullName" [title]="item.name" [value]="item.name"></app-name-ext>
  <!-- separator="_" [rtl]="true" -->
</ng-template>

<ng-template #defaultItemActionsTpl let-item="item" let-component="component">
  <div class="ml-4" *ngIf="component.hasFavorites">
    <button [disabled]="disabled" mat-icon-button class="app-icon-btn-sm"
      (click)="component.toggleFavorite($event, item)">
      <mat-icon *ngIf="!component.favoritesService.is(favoritesType, item)">favorite_border</mat-icon>
      <mat-icon *ngIf="component.favoritesService.is(favoritesType, item)" class="red-400-fg">favorite</mat-icon>
    </button>
  </div>
  <div class="ml-4" *ngIf="component.multiple">
    <button [disabled]="disabled" mat-icon-button class="app-icon-btn-sm" (click)="component.toggleMulti($event, item)">
      <mat-icon *ngIf="!component.isSelected(item)">add</mat-icon>
      <mat-icon *ngIf="component.isSelected(item)" class="green-400-fg">check_circle</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #defaultListTpl let-component="component">
  <div *ngIf="!component.loading && !!filterControl.value && !component.filteredList[selector]?.length"
    class="px-12 py-8 grey-400-fg text-center">{{ 'nessun risultato' | translate }}</div>

  <ng-container *ngIf="component.filteredList[selector]?.length > 0">
    <cdk-virtual-scroll-viewport [style.height]="menuHeight  + 'px'" itemSize="48" fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
      <div *cdkVirtualFor="let item of component.filteredList[selector]; let index = index;">
        <div [style.height]="rowHeight + 'px'" fxLayout="row" fxLayoutAlign="flex-start center" matRipple
          class="pl-12 pr-8 py-12 font-size-12" (click)="component.selectSingle($event, item)"
          [ngClass]="{'grey-200-bg': !component.multiple && component.isSelected(item)}">
          <ng-container
            *ngTemplateOutlet="component.preItemTpl ? component.preItemTpl : defaultPreItemTpl; context: { item: item, index: index, component: component }"></ng-container>
          <ng-container
            *ngTemplateOutlet="component.itemTpl ? component.itemTpl : defaultItemTpl; context: { item: item, index: index, component: component }"></ng-container>
          <ng-container
            *ngTemplateOutlet="component.itemActionsTpl ? component.itemActionsTpl : defaultItemActionsTpl; context: { item: item, index: index, component: component }"></ng-container>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>

</ng-template>

<!-- SELECTED LIST -->

<ng-template #defaultSelectedItemActionsTpl let-item="item" let-index="index" let-component="component">
  <div class="ml-4">
    <button [disabled]="disabled" mat-icon-button color="warn" class="app-icon-btn-sm"
      (click)="component.deselect($event, item, index)">
      <mat-icon>remove_circle</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #defaultSelectedListHeaderActionsTpl let-items="items" let-component="component">
  <div class="ml-4 pr-8">
    <button mat-icon-button [matTooltip]="'Deseleziona tutti' | translate" [matTooltipPosition]="'above'"
      [disabled]="disabled" color="warn" class="app-icon-btn-sm" (click)="component.removeAll($event, item, index)">
      <mat-icon>delete_sweep</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #defaultSelectedListHeaderTpl let-items="items" let-component="component">
  <div *ngIf="component.multiple && items?.length > 0" class="mt-8" fxLayout="row" fxLayoutAlign="flex-start center">
    <!-- HEADER TEXT -->
    <div class="font-size-12" fxFlex>{{ 'Elementi selezionati' | translate }} ({{ items?.length || 0 }})</div>
    <!-- HEADER ACTIONS -->
    <ng-container
      *ngTemplateOutlet="component.selectedListHeaderActionsTpl ? component.selectedListHeaderActionsTpl : defaultSelectedListHeaderActionsTpl; context: { items: component.selectedList, component: component }"></ng-container>
  </div>
</ng-template>

<ng-template #defaultSelectedListTpl let-component="component">
  <div *ngIf="!component.selectedList?.length" class="mt-8"
    [ngClass]="{ 'grey-400-fg': !component.touched || !component.required, 'red-400-fg': component.touched && component.required && !component.selectedList?.length }">
    <div class="font-size-12">{{ listEmptyLabel }} </div>
  </div>
  <!-- <div *ngIf="!component.selectedList?.length" class="border mt-8 px-12 py-8 text-center"
    [ngClass]="{ 'grey-400-fg': !component.touched || !component.required, 'red-400-fg': component.touched && component.required && !component.selectedList?.length }">
    {{ listEmptyLabel }}
  </div> -->
  <!-- HEADER -->
  <ng-container
    *ngTemplateOutlet="component.selectedListHeaderTpl ? component.selectedListHeaderTpl : defaultSelectedListHeaderTpl; context: { items: component.selectedList, component: component }"></ng-container>
  <!-- LIST -->
  <div class="border mt-8" *ngIf="component.selectedList?.length > 0">
    <div *ngFor="let item of component.selectedList; let index = index; let first = first; let last = last;"
      fxLayout="row" fxLayoutAlign="flex-start center" class="pl-12 pr-8 py-8" [ngClass]="{
        'border-bottom': !last,
        'selectable': selectable,
        'blue-100-bg': selectable && chosenItem === item
      }" matRipple [matRippleDisabled]="!selectable" (click)="selectable && component.choose($event, item, index)">
      <!-- PRE ITEM -->
      <ng-container
        *ngTemplateOutlet="component.selectedPreItemTpl ? component.selectedPreItemTpl : defaultPreItemTpl; context: { item: item, index: index, first: first, last: last, component: component }"></ng-container>
      <!-- ITEM -->
      <ng-container
        *ngTemplateOutlet="component.selectedItemTpl ? component.selectedItemTpl : defaultItemTpl; context: { item: item, index: index, first: first, last: last, component: component }"></ng-container>
      <!-- ACTIONS -->
      <ng-container
        *ngTemplateOutlet="component.selectedItemActionsTpl ? component.selectedItemActionsTpl : defaultSelectedItemActionsTpl; context: { item: item, index: index, first: first, last: last, component: component }"></ng-container>
    </div>
  </div>
</ng-template>

<!-- <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="12px" [ngClass]="{ 'mb-12': systems.length > 0 }">
  <div fxFlex>
    <app-system-autocomplete [control]="system"></app-system-autocomplete>
  </div>
  <div>
    <button mat-raised-button [disabled]="loading" (click)="addSystem(system.value)">
      {{ 'Aggiungi' | translate }}
    </button>
  </div>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="flex-start center"
  *ngFor="let system of systems; let i = index; let last = last"
  [ngClass]="{ 'mb-8': !last }"
>
  <div fxFlex fxLayout="row" class="ellipsis">
    <span class="mr-4">{{ i + 1 }})</span>
    <app-name-ext class="ellipsis" [value]="system.name" [rtl]="false"></app-name-ext>
  </div>
  <div>
    <button mat-icon-button aria-label="remove" (click)="removeSystem(i, system)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>

<div class="pt-8" [hidden]="systems.length < 2" fxLayout="row" fxLayoutAlign="flex-end center">
  <div id="removeAll">
    <button mat-button color="warn" (click)="clearSystems()">
      {{ 'Rimuovi tutti' | translate }}
    </button>
  </div>
</div> -->