import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { FieldsMenuInterface } from '../fields-menu/fields-menu.interface';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export enum FieldEnum {
  input = 'input',
  checkbox = 'checkbox',
  select = 'select',
  multiselect = 'multiselect',
  radio = 'radio',
  list = 'list',
  textarea = 'textarea',
  minicolors = 'minicolors',
  image = 'image',
  video = 'video',
  textEditor = 'textEditor',
}

// SPECIALS FOR FEED
export enum FieldFeedEnum {
  feed = 'feed',
  feed_collection = 'feed_collection',
  feed_filter = 'feed_filter',
}

export const FieldFeedTypeList = [
  {
    value: FieldFeedEnum.feed,
    label: _('Feed singolo'),
  },
  {
    value: FieldFeedEnum.feed_collection,
    label: _('Lista Feed'),
  },
  {
    value: FieldFeedEnum.feed_filter,
    label: _('Filtri Feed'),
  },
]

export const FieldFeedTypeKeyList = FieldFeedTypeList.map(v => v.value);

// CUSTOM OVERWRITES
export enum FieldCustomEnum {
  colorpicker = 'colorpicker',
  chips = 'chips',
  timepicker = 'timepicker',
  datepicker = 'datepicker',
  monthpicker = 'monthpicker',
  number = 'number',
  textEditor = 'textEditor',
}

export type FieldType = FieldEnum | FieldFeedEnum | FieldCustomEnum;

export enum FieldInputEnum {
  text = 'text',
  number = 'number',
  url = 'url',
  date = 'date',
  month = 'month',
  password = 'password',
  time = 'time',
}

export const FIELD_INPUT_TYPES_ARRAY: FieldsMenuInterface[] = [
  { value: FieldInputEnum.text, label: _('Testo') },
  { value: FieldInputEnum.number, label: _('Numerico') },
  { value: FieldInputEnum.date, label: _('Data') },
  { value: FieldInputEnum.month, label: _('Mese') },
  { value: FieldInputEnum.time, label: _('Ora') },
  { value: FieldInputEnum.url, label: _('URL') },
  { value: FieldInputEnum.password, label: _('Password') },
];

export const FIELD_TYPES_ARRAY: FieldsMenuInterface[] = [
  {
    value: FieldEnum.input,
    label: _('Input'),
    children: FIELD_INPUT_TYPES_ARRAY,
  },
  { value: FieldEnum.checkbox, label: _('Checkbox') },
  { value: FieldEnum.select, label: _('Select') },
  {
    value: '',
    label: _('Multiplo'),
    abstract: true,
    children: [
      { value: FieldEnum.multiselect, label: _('MultiSelect') },
      { value: FieldEnum.radio, label: _('Radio') },
    ],
  },
  { value: FieldEnum.textarea, label: _('Textarea') },
  { value: FieldEnum.textEditor, label: _('Editor di testo') },
  { value: FieldEnum.minicolors, label: _('Colore') },
  { value: FieldEnum.image, label: _('Immagine') },
  { value: FieldEnum.video, label: _('Video') },
  { value: FieldEnum.list, label: _('Lista di campi') },
];

export const FIELD_FEED_TYPES_ARRAY: FieldsMenuInterface[] = [
  ...FIELD_TYPES_ARRAY,
  {
    label: _('Gestisci Feed'),
    abstract: true,
    children: FieldFeedTypeList,
  },
];

export interface FieldInterface extends FormlyFieldConfig {
  key?: string;
  type?: FieldType;
  // @deprecated Formly v6
  templateOptions?: FieldOptionsInterface;
  // #new Formly v6
  props?: FieldOptionsInterface;
  defaultValue?: string | Date | number | boolean;
}

export class Field implements FieldInterface {
  key: string;
  type: FieldType;
  // @deprecated Formly v6
  templateOptions: FieldOptionsInterface;
  // #new Formly v6
  props?: FieldOptionsInterface;
  defaultValue: string | Date | number | boolean;

  constructor(private _conf?: FieldInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface FieldOptionsInterface extends FormlyTemplateOptions {
  label?: string;
  placeholder?: string;
  required?: boolean;
  type?: FieldInputEnum;
  rows?: number;
  column?: FieldOptionsColumnInterface;
  options?: any[];
  // #new Formly v6
  labelProp?: string;
  valueProp?: string;
  // advanced list of objects
  fields?: FieldInterface[];
  groups?: { id: string, name: string, write: boolean }[];
  roles?: string[];
}

export class FieldOptions implements FieldOptionsInterface {
  label: string;
  placeholder: string;
  required: boolean;
  type: FieldInputEnum;
  rows: number;
  column: FieldOptionsColumnInterface;
  options: any[];
  // #new Formly v6
  valueProp: string = 'value';
  labelProp: string = 'label';
  // advanced list of objects
  fields?: FieldInterface[] = [];
  groups?: { id: string, name: string, write: boolean }[] = [];
  roles?: string[] = [];

  constructor(private _conf?: FieldOptionsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export interface FieldOptionsColumnInterface {
  main?: boolean;
  hidden?: boolean;
}

export class FieldOptionsColumn implements FieldOptionsColumnInterface {
  main: boolean;
  hidden: boolean;
  constructor(private _conf?: FieldOptionsColumnInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}
