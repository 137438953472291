import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { S3FileUploader, S3Policy } from '../s3';

import { S3SignatureOptions } from 'app/repositories/aws-repository.service';
import { S3UploadOptions, defaultS3UploadOptions } from '../s3/policy-interface';
import { S3ResourceService } from './s3.resource.service';

@Injectable({
  providedIn: 'root'
})
export class S3FileuploaderFactoryService {
  constructor(
    private _S3ResourceService: S3ResourceService
  ) {}

  create(policy: S3Policy, options: S3UploadOptions = defaultS3UploadOptions) {

    const opts = Object.assign(options, {
      url: environment.s3BaseUrl + environment.s3.bucket + '/',
      additionalParameter: Object.assign({
        AWSAccessKeyId: policy.key,
        acl: S3SignatureOptions.ACL_PUBLIC,
        signature: policy.signature,
        policy: policy.policy
      }, options.additionalParameter)
    });

    return new S3FileUploader(opts, this._S3ResourceService);
  }
}
