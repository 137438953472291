import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DatasetInterface } from 'app/models/dataset.model';

@Component({
  selector: 'dcontent-card',
  templateUrl: './dcontent-card.component.html',
  styleUrls: ['./dcontent-card.component.scss'],
})
export class DcontentCardComponent implements OnInit, OnDestroy {
  @Input()
  dcontent: DatasetInterface;

  @Output()
  selectEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  choose() {
    if (this.isSelectable) {
      this.selectEvent.emit(this.dcontent);
    }
  }

  get isSelectable() {
    return this.selectEvent.observers.length > 0;
  }

  ngOnDestroy() {}
}
