import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  constructor() { }

  isInArray(
    term: string,
    list: any[] = [],
    settings: { rule?: string; caseSensitive?: boolean; translate?: Function } = {},
  ) {
    const defaultSettings = {
      rule: 'contains',
      caseSensitive: false,
      translate: (v) => v,
    };
    settings = { ...defaultSettings, ...settings };

    return list.filter((v) => {
      let haystack = settings.translate(v);
      let needle = term;

      // CAST TO STRING (IF NUMBER TYPE)
      haystack = haystack ? String(haystack) : '';
      needle = needle ? String(needle) : '';

      if (!settings.caseSensitive) {
        haystack = haystack.toLowerCase();
        needle = needle.toLowerCase();
      }

      switch (settings.rule) {
        case 'contains': {
          return haystack.indexOf(needle) !== -1;
        }
        case 'startsWith': {
          return haystack.indexOf(needle) === 0;
        }
        default: {
          throw new Error(`Rule "${settings.rule}" is not allowed`);
        }
      }
    });
  }

  isObject(value) {
    return value !== null && typeof value === 'object';
  }

  isSameObject(obj1: object, obj2: object, prop?: string) {
    if (prop) {
      // console.log('confronto per prop');
      if (!!obj1 && !!obj2 && obj1[prop] === obj2[prop]) {
        return true;
      }
    } else {
      // console.log('confronto per stringify');
      if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
        return true;
      }
    }
    return false;
  }

  isStaticList(list) {
    return !list || Array.isArray(list);
  }

  isObservableList(list) {
    return list instanceof Observable;
  }

  isCallbackList(list) {
    return typeof list === 'function';
  }

  isAsyncList(list) {
    return this.isObservableList(list) || this.isCallbackList(list);
  }

  getExistentMatchedOption(term: string, list: any[] = [], settings: { translate?: (listItem: any) => any } = {}) {
    const defaultSettings = {
      translate: (listItem: any) => listItem,
    };
    settings = { ...defaultSettings, ...settings };

    for (let i = 0; i < list.length; i++) {
      const listItem = list[i];
      if (term.toLowerCase() === settings.translate(listItem).toLowerCase()) {
        return listItem;
      }
    }
    return null;
  }
}
