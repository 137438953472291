<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'Imposta gruppi e ruoli' | translate }}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-12 m-0" ngClass.gt-xs="p-24" mat-dialog-content fxLayout="column">
    <div *ngIf="name" class="mb-12">
      {{ 'Gruppi e Ruoli per' | translate }}: <strong>{{ name }}</strong>
    </div>

    <!-- GROUPS & ROLES -->
    <ng-container *ngIf="isAdmin">
      <div fxFlex="100" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
        <ng-container *ngIf="groupList?.length > 0">
          <app-autocomplete-chips fxFlex="100" fxFlex.gt-sm="0 1 calc(50% - 16px)" [formControl]="groups" prop="name"
            decorateProp="id" [list]="groupList" [placeholder]="'Gruppi' | translate"></app-autocomplete-chips>
        </ng-container>
        <ng-container *ngIf="roleList?.length > 0">
          <app-autocomplete-chips fxFlex="100" fxFlex.gt-sm="0 1 calc(50% - 16px)" [formControl]="roles" prop="label"
            decorateProp="value" [list]="roleList" [placeholder]="'Ruoli' | translate"></app-autocomplete-chips>
        </ng-container>
      </div>
    </ng-container>

  </div>

  <div mat-dialog-actions class="m-0 p-12" ngClass.gt-xs="p-24" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button (click)="matDialogRef.close()">{{ 'Annulla' | translate }}</button>
    <button color="primary" type="button" mat-raised-button (click)="save()">{{ 'Conferma' | translate }}</button>
  </div>
</div>