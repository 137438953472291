import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatasetInterface } from 'app/models/dataset.model';
import { GroupInterface } from 'app/models/group.model';
import { map } from 'rxjs/operators';
import { PaginatedResponseInterface } from 'app/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class DatasetRepositoryService {
  /**
   *Creates an instance of DatasetRepositoryService.
   * @param {HttpClient} _http
   * @memberof DatasetRepositoryService
   */
  constructor(private _http: HttpClient) {}

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<DatasetInterface[]>}
   * @memberof DatasetRepositoryService
   */
  findAll(_params: HttpParams | any = {}): Observable<DatasetInterface[]> {
    return this._http.get<DatasetInterface[]>('dcontentdatas', {
      params: _params,
    });
  }

  /**
   *
   *
   * @param {string} id
   * @param {*} [_params={}]
   * @returns {Observable<PaginatedResponseInterface<DatasetInterface>>}
   * @memberof DatasetRepositoryService
   */
  findAllByBrandPaginated(
    id: string,
    _params: HttpParams | any = {},
  ): Observable<PaginatedResponseInterface<DatasetInterface>> {
    return this._http.get<PaginatedResponseInterface<DatasetInterface>>(`dcontentdatas/${id}/brand`, {
      params: _params,
    });
  }

  // /**
  //  *
  //  *
  //  * @param {string} id
  //  * @param {*} [_params={}]
  //  * @returns {Observable<DatasetInterface[]>}
  //  * @memberof DatasetRepositoryService
  //  */
  //  findAllByBrandPaginated(id: string, _params: HttpParams | any = {}): Observable<DatasetInterface[]> {
  //   return this._http.get<DatasetInterface[]>(`dcontentdatas/${id}/brand`, {
  //     params: _params,
  //   });
  // }

  // /**
  //  *
  //  *
  //  * @param {string} brandId
  //  * @param {HttpParams} params
  //  * @returns
  //  * @memberof DatasetRepositoryService
  //  */
  // findAllByBrandPaginated2(brandId: string, params: HttpParams | any = {}): Observable<PaginatedResponseInterface<DatasetInterface>> {
  //   // TODO: set a real pagination endpoint for SmartObjects
  //   return this.findAllByBrand(brandId, params).pipe(map(response => {
  //     return {
  //       items: response,
  //       pagination: {
  //         totalCount: response.length,
  //         pageCount: 1,
  //       },
  //     };
  //   }));
  // }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<DatasetInterface>}
   * @memberof DatasetRepositoryService
   */
  get(id: string): Observable<DatasetInterface> {
    return this._http.get<DatasetInterface>(`dcontentdatas/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<DatasetInterface>}
   * @memberof DatasetRepositoryService
   */
  delete(id: string): Observable<DatasetInterface> {
    return this._http.delete<DatasetInterface>(`dcontentdatas/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<DatasetInterface>}
   * @memberof DatasetRepositoryService
   */
  update(id: string, data: DatasetInterface): Observable<any> {
    return this._http.put(`dcontentdatas/${id}`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<DatasetInterface>}
   * @memberof DatasetRepositoryService
   */
  create(data: DatasetInterface): Observable<any> {
    return this._http.post(`dcontentdatas`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<DatasetInterface>}
   * @memberof DatasetRepositoryService
   */
  clone(data: DatasetInterface): Observable<DatasetInterface> {
    // const newData = Object.assign({}, data);
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    newData.name += ' copy';
    return this._http.post(`dcontentdatas`, newData);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<GroupInterface[]>}
   * @memberof DatasetRepositoryService
   */
  getGroups(id: string): Observable<GroupInterface[]> {
    return this._http.get<GroupInterface[]>(`dcontentdatas/${id}/groups`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<{ groups: GroupInterface[] }>}
   * @memberof DatasetRepositoryService
   */
  updateGroups(id: string, groups: GroupInterface[]): Observable<any> {
    return this._http.patch(`dcontentdatas/${id}/groups`, { groups: groups });
  }
}
